import React from "react";
import Dialog from "./BaseDialog";

export default function VideoDialog(props) {
    const { onClose, children, className } = props;
    return (
        <Dialog
            withoutScrollbar
            className={className}
            dialogType="video"
            onClose={onClose}
        >
            {children}
        </Dialog>
    );
}
