import React, { useRef, useEffect, useContext, useState } from "react";
import Navbar from "components/main/Navbar";
import { Scrollbar } from "components/common/Scrollbar";
import Footer from "./FooterNew";
import styles from "./Main.module.scss";
import routerDecorator from "components/helpers/withRouter";
import { getTextOrNull as t } from "helpers/getText";
import { ContentContext } from "../../context/ContentContext";

function Main(props) {
    const {
        location: { pathname }
    } = props;
    const scrollRef = useRef(null);
    const lastLocation = useRef(null);
    const [headerOpacity, setHeaderOpacity] = useState(0);
    const { setScrollbar } = useContext(ContentContext);
    function smoothScroll() {
        const height = scrollRef.current.viewScrollTop || 0;
        let cur = height;
        const step = height / 50;
        function scroll() {
            cur -= step;
            if (scrollRef.current) {
                scrollRef.current.view.scrollTop = cur;
                if (cur > 0) {
                    requestAnimationFrame(scroll);
                }
            }
        }
        requestAnimationFrame(scroll);
    }

    useEffect(() => {
        const alwaysScrollPages = ["rotation"];
        const old = lastLocation.current
            ? (lastLocation.current.match(/[^\/]+/) || " ")[0]
            : " ";
        const cur = (pathname.match(/[^\/]+/) || " ")[0];
        if (old !== cur || alwaysScrollPages.some(elem => elem === old)) {
            scrollRef && smoothScroll();
            lastLocation.current = pathname;
        }
    }, [pathname]);

    useEffect(() => {
        document.title =
            t(`titles.${pathname}`) ||
            t(`titles.${pathname.match(/\/[^\/]+/)[0]}`) ||
            t(`titles.default`);
    }, [pathname]);

    useEffect(() => {
        setScrollbar(scrollRef);
    }, []);

    const changeHeaderOpacity = ({ current }) => {
        const offset = (current && current.viewScrollTop) || 0;
        const opacity = offset > 8 ? 1 : 0;
        setHeaderOpacity(opacity);
    };
    const onScrollHandler = ref => {
        changeHeaderOpacity(ref);
    };
    const isHome = false;

    return (
        <Scrollbar
            onScroll={() => onScrollHandler(scrollRef)}
            onScrollStop={() => onScrollHandler(scrollRef)}
            id="RootScrollbar"
            autoHeight
            width="100%"
            autoHeightMin="100vh"
            autoHeightMax="100vh"
            autoHide
            scrollbarRef={scrollRef}
            autoHideTimeout={1000}
            autoHideDuration={300}
        >
            { pathname !== "/home" &&
                <div className={styles.container}>
                    <div className={styles.header}>
                        <Navbar opacity={headerOpacity} isHome={isHome}  />
                    </div>
                    <div className={styles.content}>{props.children}</div>
                    <Footer className={styles.footer} />
                </div>
            }
            { pathname === "/home" &&    
                <div className={styles.content}>{props.children}</div>
            }
        </Scrollbar>
    );
}

export default routerDecorator(Main);
