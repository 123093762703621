import React from "react";

export default function ArrowLeftDouble() {
    const color = "#BB1D7E";
    return (
        <svg width="14" height="12" viewBox="0 0 14 12">
            <g fill="none" fillRule="evenodd" stroke={color} strokeWidth="1.5">
                <path d="M7.243 1L2 6.243l5.243 5.242M13.243 1L8 6.243l5.243 5.242" />
            </g>
        </svg>
    );
}
