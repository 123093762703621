import React, { useContext, useState, useEffect, useRef } from "react";
import classnames from "classnames";

import { ContentContext } from "context/ContentContext";
import { Button, BaseSlider, IconButton } from "components/common/ControlElems";
import GenreCheckbox from "./GenreCheckbox";
import { ClosePlayer } from "components/common/icons";
import { splitArray, DEFAULT_VALUES } from "./SideFIlterHelper";
import { LITERAL_KEYS } from "context/helpers/contentContextHelper";
import styles from "./AllFilters.module.scss";

export default function AllFilters({
    showFilters,
    setShowFilters,
    setCurrentPreset,
    resetFilters
}) {
    const {
        presetName,
        requestTracksWithRetry,
        allGenres = [],
        // allVersions = [],
        keys = [],
        genres = [],
        versions = [],
        searchQuery,
        sortField,
        type,
        bpm = [0, 256],
        VersionsData = [],
        fetchGenres
    } = useContext(ContentContext);

    const [versionList, setVersionList] = useState([]);
    const defaultFilters =
        presetName === "custom"
            ? { genres, versions, keys, bpm }
            : { genres: [], versions: [], keys: [], bpm: [0, 256] };
    const [filters, setFilters] = useState(defaultFilters);
    const [columns, setColumns] = useState(
        window.innerWidth > 700 ? (window.innerWidth > 1140 ? 3 : 2) : 1
    );

    window.addEventListener("resize", () => {
        window.innerWidth > 700
            ? window.innerWidth > 1140
                ? setColumns(3)
                : setColumns(2)
            : setColumns(1);
    });
    const onApplyHandler = () => {
        requestTracksWithRetry({
            needToResetList: true,
            commonParams: { ...filters, sortField, type, searchQuery },
            presetName: "custom"
        });
        setShowFilters(false);
        setCurrentPreset("custom");
    };
    const hideHandler = useRef({ current: null });
    const filterContainerRef = useRef(null);
    useEffect(() => {
        if(VersionsData && VersionsData.length > 0 && versionList.length === 0) {
            let versions = VersionsData.map((version) => {return version.downloadBtn}).sort((a,b) => {return a.length - b.length});
            setVersionList(versions);
        } else {
            fetchGenres();
        }
    },['',VersionsData]);

    useEffect(() => {
        if (showFilters) {
            const documentClickHandler = event => {
                if (
                    event.type === "click" &&
                    filterContainerRef.current &&
                    !filterContainerRef.current.contains(event.target)
                ) {
                    setShowFilters(false);
                } else if (event.type === "keydown" && event.keyCode === 27) {
                    setShowFilters(false);
                }
            };
            hideHandler.current = documentClickHandler;
            document.addEventListener("click", documentClickHandler);
            document.addEventListener("keydown", documentClickHandler);
        } else {
            document.removeEventListener("click", hideHandler.current);
            document.removeEventListener("keydown", hideHandler.current);
        }
        return () => {
            document.removeEventListener("click", hideHandler.current);
            document.removeEventListener("keydown", hideHandler.current);
        };
    }, [showFilters]);

    const onResetFilters = () => {
        requestTracksWithRetry({
            commonParams: { ...DEFAULT_VALUES, sortField },
            presetName: "All"
        });
        setFilters({ genres: [], versions: [], keys: [], bpm: [0, 256] });
    };

    return (
        <div ref={filterContainerRef} className={styles.allFiltersContainer}>
            <div className={classnames(styles.topBlock, styles.genresBlock)}>
                <div className={styles.subHeader}>Genres</div>
                {renderRowsOfN({
                    columns,
                    filters,
                    commonKey: "genres",
                    setFilters,
                    list: allGenres
                })}
            </div>
            <div className={classnames(styles.topBlock, styles.versionsBlock)}>
                <div className={styles.subHeader}>Versions</div>
                {renderRowsOfN({
                    columns,
                    filters,
                    commonKey: "versions",
                    setFilters,
                    list: versionList
                })}
            </div>
            <div className={classnames(styles.topBlock, styles.keysBlock)}>
                <div className={styles.subHeader}>Key</div>
                <div className={styles.keysRows}>
                    {renderRowsOfKeys({ filters, setFilters })}
                </div>
            </div>
            <div className={styles.bpmBlock}>
                <div className={styles.subHeader}>BPM</div>
                <div className={styles.sliderContainer}>
                    <div className={styles.range}>
                        <div>{filters.bpm[0]}</div>
                        <div>{filters.bpm[1]}</div>
                    </div>
                    <BaseSlider
                        onChange={newBpm =>
                            setFilters({ ...filters, bpm: newBpm })
                        }
                        value={filters.bpm}
                        max={256}
                        range
                    />
                </div>
            </div>
            <div className={styles.buttonsBlock}>
                <Button label="Reset" withoutPadding onClick={onResetFilters} />
                <Button
                    label="Apply"
                    color="bright"
                    setShowFilters
                    onClick={onApplyHandler}
                />
                <div className={styles.closeButton}>
                    <IconButton onClick={() => setShowFilters(false)}>
                        <ClosePlayer color="var(--theme-muffled-color)" />
                    </IconButton>
                </div>
            </div>
        </div>
    );
}

function renderRowsOfN({ filters, commonKey, setFilters, list, columns }) {
    return splitArray(list, columns).map(columnOfValues => {
        return (
            <div className={styles.column}>
                {columnOfValues.map(version => (
                    <GenreCheckbox
                        key={version}
                        commonKey={commonKey}
                        filters={filters}
                        setFilters={setFilters}
                        elem={version}
                    />
                ))}
            </div>
        );
    });
}

function renderRowsOfKeys({ filters, setFilters }) {
    return Object.keys(LITERAL_KEYS).map(key => {
        return (
            <GenreCheckbox
                key={key}
                commonKey="keys"
                filters={filters}
                setFilters={setFilters}
                elem={key}
            />
        );
    });
}
