import React from "react";
// import { NavLink, Link } from 'react-router-dom';
import './curatedsets.css'

const CuratedSets = () => {


    return (
        <div className="spin-sets-wrap">
            <div className="spin-sets-content">
                <h2 className="sec-title main-title">
                    CURATED SETS AVAILABLE IN <br /><span>SPINS</span> DESKTOP APP
                </h2>
                <h3 className="sec-title sub-head-title">
                    Download Spins to access a huge variety
                    <br />of curated sets
                </h3>
                <h4 className="sec-title hip-hop-title">
                    HIP HOP, R&B, REGGAE, SOCA, LATIN, POP, ROCK, COUNTREY,
                    MAINSTREAM, HOUSE, THROWBACKS & MORE
                </h4>
                <h5 className="sec-title hip-hop-title">
                    Top tracks curated by BPM for easy mixing
                    simply download in individully or zipped and start rocking.
                </h5>
                <h3 className="sec-title download-title">
                    Click to download
                </h3>
                <div className="btn-wrap">
                    <button className="btn" type="button"
                        onClick={(e) => {
                            e.preventDefault();
                            window.location.href = 'https://spinsrelease.frp.live/download/latest/osx';
                        }}>
                        Mac

                    </button>
                    <button className="btn" type="button"
                        onClick={(e) => {
                            e.preventDefault();
                            window.location.href = 'https://spinsrelease.frp.live/download/latest/windows_64';
                        }}>
                        Win
                    </button>
                </div>
            </div>
        </div>
    );
};

export default React.memo(CuratedSets);
