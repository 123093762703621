import React from "react";
import PropTypes from "prop-types";

LightTheme.propTypes = {
    className: PropTypes.string,
    color: PropTypes.string,
    isActive: PropTypes.bool
};

LightTheme.defaultProps = {
    className: "",
    color: "#0E1521",
    isActive: false
};

export default function LightTheme(props) {
    const { color, className, isActive } = props;
    return (
        <svg className={className} width="16" height="16" viewBox="0 0 16 16">
            <path
                fill={color}
                fillRule="evenodd"
                d="M8 4.08a3.92 3.92 0 1 1 0 7.84 3.92 3.92 0 0 1 0-7.84zm-.603-.979V0h1.207v3.101H7.397zm0 12.899v-3.101h1.207V16H7.397zm5.502-7.397V7.396H16v1.207h-3.101zM0 8.603V7.396h3.101v1.207H0zM14.084 2.77L11.89 4.963l-.854-.854 2.193-2.193.854.854zM1.916 13.23l2.194-2.193.853.854-2.193 2.192-.854-.853zm3.047-9.12l-.853.853L1.916 2.77l.854-.854L4.963 4.11zm6.074 7.78l.854-.853 2.193 2.193-.854.853-2.193-2.192z"
                opacity={isActive ? "1" : ".2"}
            />
        </svg>
    );
}
