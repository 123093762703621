import React from "react";
import Tracks from "components/Tracks/Tracks";
import styles from "./TracksPage.module.scss";

export default function TracksPage(props) {
    return (
        <div className={styles.container}>
            <Tracks />
        </div>
    );
}
