import React from "react";
import PropTypes from "prop-types";

FRPSmallLogo.propTypes = {
    className: PropTypes.string,
    color: PropTypes.string
};

FRPSmallLogo.defaultProps = {
    className: "",
    color: "#57636F"
};

function FRPSmallLogo(props) {
    const { color, className } = props;
    return (
        <svg className={className} width="22" height="24" viewBox="0 0 22 24">
            <g fill="none" fillRule="evenodd">
                <g transform="translate(0 .009)">
                    <path
                        fill={color}
                        d="M12.73 21.726c-.58-.54-1.527-.539-2.803-.478l-.127.006c-1.332.064-2.385.114-3.013-.555-.666-.71-.114-1.954.375-2.716l-.167-.14.854-1.012a4.48 4.48 0 0 1 4.52-7.514 25.62 25.62 0 0 0 1.089-1.868c.67-1.27 1.423-2.956 1.61-4.413.008-.07.016-.138.022-.206a6.284 6.284 0 0 0 .02-.335c.017-.71-.136-1.337-.545-1.798A2.044 2.044 0 0 0 13.882.2c-1.5-.693-3.072.806-4.055 2.053C4.312 2.783 0 7.43 0 13.084c0 6.01 4.872 10.883 10.882 10.883.87 0 1.715-.102 2.526-.295-.008-.848-.152-1.457-.677-1.946"
                        mask="url(#b)"
                    />
                </g>
                <g transform="translate(7.02 2.048)">
                    <path
                        fill={color}
                        d="M11.5 3.293c.244-1.38.037-2.61-1.153-3.159a1.909 1.909 0 0 0-.282-.105.807.807 0 0 1 .11.134c.19.302.27.705.27 1.174v.077l-.001.034v.002a4.312 4.312 0 0 1-.013.244l-.004.051-.001.015a4.635 4.635 0 0 1-.013.128l-.012.099a7 7 0 0 1-.084.513 9.731 9.731 0 0 1-.15.652l-.026.096a10.316 10.316 0 0 1-.269.883l-.033.099a5.807 5.807 0 0 1-.034.1l-.035.098c-.063.18-.13.36-.198.54l-.042.108c-.046.118-.092.236-.14.353l-.043.108c-.115.28-.233.555-.35.819l-.059.133-.062.137-.053.116c-.015.034-.03.069-.047.102a37.67 37.67 0 0 1-.14.299l-.045.096c-.076.16-.15.313-.221.458l-.043.086c-.055.113-.11.221-.161.323a32.57 32.57 0 0 1-.515.981 4.481 4.481 0 0 1-5.957 6.335l-.903 1.07-.21-.178c-.148.238-.291.511-.394.78-.146.384-.266.899-.005 1.177.448.478 1.385.433 2.572.376l.127-.006c1.416-.067 2.475-.061 3.217.63.68.632.836 1.418.857 2.21 4.504-1.331 7.79-5.5 7.79-10.436 0-3.034-1.242-5.778-3.245-7.752"
                        mask="url(#d)"
                    />
                </g>
                <path
                    fill={color}
                    d="M7.804 15.993h1.142v-.556H7.804zM7.804 15.037h1.142v-.556H7.804zM7.804 14.081h1.142v-.556H7.804zM9.52 15.993h1.142v-.556H9.52zM9.52 15.037h1.142v-.556H9.52zM9.52 14.081h1.142v-.556H9.52zM9.52 13.125h1.142v-.556H9.52zM9.52 12.17h1.142v-.557H9.52zM9.52 11.213h1.142v-.555H9.52zM11.19 15.993h1.141v-.556H11.19zM11.19 15.037h1.141v-.556H11.19zM11.19 14.081h1.141v-.556H11.19zM11.19 13.125h1.141v-.556H11.19zM11.19 12.17h1.141v-.557H11.19zM12.858 15.993h1.141v-.556h-1.141zM12.858 15.037h1.141v-.556h-1.141zM12.858 14.081h1.141v-.556h-1.141zM12.858 13.125h1.141v-.556h-1.141z"
                    mask="url(#f)"
                />
            </g>
        </svg>
    );
}

export default React.memo(FRPSmallLogo);
