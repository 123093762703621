import React, { useContext, useMemo } from "react";
import classnames from "classnames";

import { UIContext } from "context/UIContext";
import { useEnvironmentContext } from "context/EnvironmentContext";

import t from "helpers/getText";

import FeaturesList from "./FeaturesList";

import styles from "./Spins.module.scss";
import spinDesktop from "assets/images/spin-desktop.png";
import frpImage from "assets/images/spin-desktop.jpg";

export default function Spins() {
    const { theme } = useContext( UIContext );
    const {
        latestSpinsVersion,
        environment,
        getSpinsAppLinks
    } = useEnvironmentContext();

    const links = useMemo(
        () => getSpinsAppLinks( environment, latestSpinsVersion ),
        [latestSpinsVersion, environment]
    );

    return (
        <div className={ styles.container }>
            <div className={ classnames( styles.headContainer, styles[theme] ) }>
                <div className={ classnames( styles.appBack, styles.spinsBack ) }>
                    <div className={ styles.flexContainer }>
                        <div className={ styles.bannerTextContent }>
                            <span className={ styles.headerTitle1 }>Spins</span>
                            <div className={ styles.headerText }>
                                { t( "apps.spins.text1" ) }
                            </div>
                        </div>
                        <div className={ styles.bannerImage }>
                            <img
                                className={ styles.icon }
                                src={ spinDesktop }
                                alt="banner frp image"
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div className={ `${ styles.boxWrap } ${ styles.featureBoxWrap }` }>
                <div className={ styles.flexContainer }>
                    <div className={ styles.textContainer }>
                        <h3>{ t( "apps.spins.textHeader" ) }</h3>
                        <FeaturesList
                            className={ ` ${ styles.features } ${ styles.featuresWrap } ` }
                            items={ t( "apps.spins.list" ) || [] }
                        />
                        <div className={ ` ${ styles.buttonsContainer } ${ styles.btnMobile } ` }>
                            { ["mac_x64", "mac_arm64", "windows"].map( os => (
                                <a href={ links[os] } className={ styles[theme] }>
                                    { t( `apps.spins.download.${ os }` ) }
                                </a>
                            ) ) }
                        </div>
                    </div>
                    <div className={ styles.boxImageWrap }>
                        <div>
                            <img
                                className={ styles.icon }
                                src={ frpImage }
                                alt="frp Image mobile"
                            />

                        </div>
                        <div className={ ` ${ styles.imageTextContainer } ${ styles.imageTextContainerMob } ` }>
                            <a href="/spins-guide" className={ styles.redText }>
                                Click HERE for a step by step guide ON our SPINS desktop software
                            </a>
                        </div>

                    </div>
                </div>
                <div className={ styles.flexContainerBtn }>
                    <div className={ styles.buttonsContainer }>
                        { ["mac_x64", "mac_arm64", "windows"].map( os => (
                            <a href={ links[os] } className={ styles[theme] }>
                                { t( `apps.spins.download.${ os }` ) }
                            </a>
                        ) ) }
                    </div>
                    <div className={ styles.imageTextContainer }>
                        <a href="/spins-guide" className={ styles.redText }>
                                Click HERE for a step by step guide ON our SPINS desktop software
                        </a>
                    </div>
                </div>
            </div>

        </div>
    );
}
