import React, { useContext } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import { LightTheme, DarkTheme } from "components/common/icons";
import { IconButton } from "components/common/ControlElems";
import { UIContext } from "context/UIContext";

import styles from "./ThemeSwitcher.module.scss";

ThemeSwitcher.propTypes = {
    className: PropTypes.string
};

ThemeSwitcher.defaultProps = {
    className: ""
};

function ThemeSwitcher({ className }) {
    const { theme, setColorTheme } = useContext(UIContext);
    return (
        <div className={classnames(styles.container, className)}>
            <IconButton
                className={styles.button}
                onClick={() => setColorTheme("dark")}
            >
                <DarkTheme
                    color="var(--theme-main-color)"
                    isActive={theme === "dark"}
                />
            </IconButton>
            <IconButton
                className={styles.button}
                onClick={() => setColorTheme("light")}
            >
                <LightTheme
                    color="var(--theme-main-color)"
                    isActive={theme === "light"}
                />
            </IconButton>
        </div>
    );
}

export default React.memo(ThemeSwitcher);
