import classnames from "classnames";
import rawStyles from "../TracksList.module.scss";
import React from "react";
import { PlayButton } from "../../ControlElems";
import AlbumTrackContent from "./AlbumTrackContent";

const Albums = ({
    activeLetter,
    track,
    index,
    size,
    presetName,
    released: versionReleased,
    superShortScrollbarRef,
    styles = rawStyles,
    key
}) => {
    const bpm = null;
    const trackReleased = track.released;
    const genre = (track && track.tracks && track.tracks[0]) && track.tracks[0].genre;
    const artist = track.artist;
    const released = trackReleased;
    const classNameVersion = classnames(
        ""
    );
     const trackName = track.name;
     const style = size === "longVersion" ? { zIndex: 100 - index } : {};
     const currentVersion = track && track.versions[0] && track.versions[0].id ? track.versions[0].id:0;
    return (
        <div>
        <div
            style={style}
            className={classnames({
                [rawStyles.trackContainer]: true,
                [rawStyles.versionContainer]: size === "longVersion",
                [rawStyles.isVersionsVisiable]: activeLetter,
                [classNameVersion]: true,
                [rawStyles.trackVersion]: size === "longVersion"
            })}
        >
            <div className={rawStyles.index}>{index}</div>
            <div
                className={rawStyles.playInfo}
                data-rank={""}
            >
               <PlayButton
                    showCover={true}
                    presetName={'Promo Albums'}
                    track={track}
                    className={rawStyles.playIcon}
                    isTrack={true}
                    currentVersion={currentVersion}
                />
                <div className={`${rawStyles.nameAndArtist} ${rawStyles.album}`}>
                    <span title={trackName}>{trackName}</span>
                    <span title={artist}>{artist}</span>
                </div>
            </div>
            <AlbumTrackContent
                bpm={bpm}
                genre={genre}
                musicKey={key}
                track={track}
                released={released}
                styles={styles}
                size={size}
                superShortScrollbarRef={superShortScrollbarRef}
                presetName={presetName}
                activeLetter={activeLetter}
                index={index}
            />
        </div>
        </div>
    );
};
export default React.memo(Albums);