const home = {
    home: {
        galery: [
            {
                title: "FRP Spins Software",
                text:
                    "Search, listen, download music to pc at home, create folders, folders within folders, delete or copy tracks between folders.",
                button: "Learn more"
            }
        ],
        music: {
            navbar: {
                latest: "New Release",
                top: "Top tracks",
                audio: "Audio",
                video: "Video",
                allGenre: "All Genre"
            },
            table: {
                headers: {
                    track: "Track / artist",
                    time: "Time",
                    genre: "Genre",
                    released: "Released",
                    rating: "Rating"
                }
            }
        },
        spotlight: {
            label: "Artist Spotlight",
            name: "Benny Blanco",
            text:
                "Reach the largest database of radio DJs , mobile DJs and club DJs on the planet. The only service that built the tools you need from the ground up"
        },
        rotation: {
            label: "FRP Heavy Artist"
        },
        flyer: {
            option1: "Continue to website",
            option2: "Don't show this message"
        }
    }
};

export default home;
