import React, { useContext } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import { ContentContext } from "context/ContentContext";
import { Play } from "components/common/icons";
import { Spinner } from "components/common/infoElems";
import { IconButton } from ".";

import styles from "./PlayButton.module.scss";

PlayButton.propTypes = {
    className: PropTypes.string,
    isRemixPreset: PropTypes.bool,
    presetName: PropTypes.string,
    rating: PropTypes.string,
    rootClassName: PropTypes.string,
    track: PropTypes.object,
    showCover: PropTypes.bool,
    withSpinner: PropTypes.bool,
    isBillboard: PropTypes.bool,
    size: PropTypes.string,
    currentVersion: PropTypes.number,
};
 
PlayButton.defaultProps = {
    presetName: "All",
    isRemixPreset: false,
    showCover: false,
    track: {},
    isBillboard: false,
    size: 'normal',
    isPLayer: false,
    isTrack: false,
    currentVersion: 0
};

export default function PlayButton({
    className,
    rootClassName,
    showCover,
    track,
    withSpinner,
    presetName,
    isBillboard,
    size,
    isPLayer,
    isTrack,
    currentVersion
}) {
    const { choosenTrack, pausePlaying, startPlaying } = useContext(
        ContentContext
    );
    const isActive = isActiveTrack({ track, choosenTrack, size });
    const isPaused = isPausedTrack({ track, choosenTrack });
    const hasVersions = isHasVersions(track);

    const { cover } = track ? track : {};
    return (
        <IconButton
            style={{ backgroundImage: showCover && `url('${cover}')` }}
            className={classnames(
                rootClassName,
                showCover && cover && styles.billboard
            )}
            onClick={() =>
                hasVersions &&
                handleClickPlayButton({
                    //isActive,
                    pausePlaying,
                    startPlaying,
                    track,
                    presetName,
                    isBillboard,
                    isPLayer,
                    isTrack,
                    currentVersion,
                    choosenTrack,
                    size
                })
            }
        >
            {hasVersions && (
                <Play
                    isActive={isActive}
                    isPaused={isPaused}
                    playColor="#bb1d7e"
                    lineColor="#bb1d7e"
                    className={classnames(styles.playIcon, className)}
                    track={track}
                    isTrack={isTrack}
                    currentVersion={currentVersion}
                />
            )}
            {withSpinner && <Spinner className={styles.spinner} />}
        </IconButton>
    );
}

async function handleClickPlayButton({
    //isActive,
    pausePlaying,
    startPlaying,
    track,
    presetName,
    isBillboard,
    isPLayer,
    isTrack,
    currentVersion,
    choosenTrack,
    size
}) {
    const isActive = await isActiveTrack({ track, choosenTrack, size, isTrack });
    if (isActive) {
        pausePlaying();
    } else {
        const params = { uiId: track.uiId };
        let filteredVersions, lastVersion;
        if (presetName.toLowerCase().trim() === "promo albums") {
            console.log(isTrack, track, "promo albums-------------1");
            filteredVersions = isTrack && track && track.tracks && track.tracks.find(
                trk => {
                    let result = false;
                    result = parseInt(trk.trackId) === parseInt(track.uiId);
                    return result;
                }
            );
            console.log(filteredVersions, "promo albums-------------2");
            filteredVersions = track && track.versions && track.versions.filter(
                trk => {
                    let result = false;
                    result = parseInt(trk.trackId) === parseInt(track.uiId);
                    return result;
                }
            );
            console.log(filteredVersions, "promo albums-------------3");

            if(!filteredVersions || filteredVersions.length === 0){
                lastVersion = (isTrack && track && track.tracks ? track.tracks : track.versions)[0] || { TvId: params.versionId };
            } else {
                lastVersion = filteredVersions[0] || { TvId: params.versionId };
            }
            console.log(lastVersion, "promo albums-------------4");
            params.uiId = lastVersion.uiId;
        } else {
            filteredVersions = track.versions.sort((a, b) => {
                if (a.displayOrder === null && b.displayOrder === null) return 0; // both are null, so they're equal
                if (a.displayOrder === null) return 1; // a is null, so it should appear after b
                if (b.displayOrder === null) return -1; // b is null, so it should appear after a
                return a.displayOrder - b.displayOrder; // otherwise, sort by displayOrder
                }).filter(
                version => {
                    let result = false;
                    if (track.presetId === 9) {
                        result =
                            parseInt(version.presetId) ===
                                parseInt(track.presetId) &&
                            parseInt(version.trackId) === parseInt(track.uiId);
                    } else {
                        result =
                            parseInt(version.presetId) === parseInt(track.presetId);
                    }
                    return result;
                }
            );
            lastVersion = (filteredVersions.length === 0
                ? track.versions
                : filteredVersions
            )[0] || { id: params.versionId };
        }
        params.versionId = (lastVersion.TvId) ? lastVersion.TvId : lastVersion.id;
        params.isAlbumTrack = (lastVersion.TvId) ? true : false;
        params.versionId = isPLayer === true ? track.versionId : params.versionId;
        startPlaying(params);
        console.log('startPlaying=============', params);
    }
}

function isActiveTrack({ track, choosenTrack, size, isTrack = false }) {
    if(size === "long"){
        return track && track.versions && track.versionId && track.versions.length > 0 && track.versions[0].id === track.versionId;
    }
    const { versionId, uiId, album_id, uniqueUid } = choosenTrack.track || {};
    const isTrackContainVersion = Boolean(
        track && track.versions.find(elem => elem.id === versionId)
    );
    const isAlbumContainTracks = Boolean(
        track && track.tracks && track.tracks.find(elem => elem.TvId === versionId)
    );

    if(album_id > 0){
        return (
            isAlbumContainTracks &&
            uniqueUid === track.uniqueUid &&
            versionId === track.versionId &&
            choosenTrack.isPlaying
        );
    }else{
        return (
            isTrackContainVersion &&
            uiId === track.uiId &&
            (isTrack || versionId === track.versionId) &&
            choosenTrack.isPlaying
        );
    }
}

function isPausedTrack({ track, choosenTrack }) {
    const { versionId, uiId, album_id, uniqueUid } = choosenTrack.track || {};
    const isTrackContainVersion = Boolean(
        track && track.versions.find(elem => elem.id === versionId)
    );

    const isAlbumContainTracks = Boolean(
        track && track.tracks && track.tracks.find(elem => elem.TvId === versionId)
    );

    if(album_id > 0){
        return (
            isAlbumContainTracks &&
            uniqueUid === track.uniqueUid &&
            versionId === track.versionId &&
            !choosenTrack.isPlaying
        );
    }else{
        return (
            isTrackContainVersion &&
            uiId === track.uiId &&
            versionId === track.versionId &&
            !choosenTrack.isPlaying
        );
    }
}

function isHasVersions(track) {
    return Boolean(track && track.versions && track.versions.length > 0) || Boolean(track && track.tracks && track.tracks.length > 0);
}
