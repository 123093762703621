import React, { useEffect, useContext } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import { ContentContext } from "context/ContentContext";

import { Button } from "components/common/ControlElems";
import routerDecorator from "components/helpers/withRouter";
import { Banner } from "../../Tracks/Banner";
import Links from "./Links";

import styles from "./NavigationPanel.module.scss";

const PATH_QUERY = {
    rank: "rank",
    top: "downloads",
    all: "createdAt",
    curated: "curated"
};

NavigationPanel.propTypes = {
    links: PropTypes.array,
    className: PropTypes.string,
    location: PropTypes.object,
    showMainTypes: PropTypes.bool,
    initRequestForTrack: PropTypes.bool,
    megaLinks: PropTypes.array,
    isSearch: PropTypes.bool,
    withoutRequest: PropTypes.bool
};

NavigationPanel.defaultProps = {
    initRequestForTrack: true,
    showMainTypes: true,
    isSearch: false,
    links: [],
    megaLinks: [],
    withoutRequest: false
};

function NavigationPanel(props) {
    const {
        location: { pathname },
        initRequestForTrack,
        megaLinks,
        className,
        isSearch,
        withoutRequest
    } = props;

    const { type, sortField, requestTracksWithRetry } = useContext(
        ContentContext
    );

    useEffect(() => {
        const newSortField = getSortField(pathname, sortField);

        //TODO: delete when the curated page is added
        if (newSortField === PATH_QUERY.curated) return;

        if (newSortField && initRequestForTrack && withoutRequest === false) {
            requestTracksWithRetry({
                needToResetList: true,
                commonParams: {
                    sortField: newSortField,
                    type: "audio"
                }
            });
        }
    }, [pathname]);

    return (
        <div
            className={classnames(
                styles.container,
                megaLinks.length > 0 && styles.withMegaLinks,
                className
            )}
        >   
            <Banner/>
            {megaLinks.length > 0 && (
                <Links
                    prefix="tracks.headers.mega"
                    className={styles.megaLinks}
                    links={megaLinks}
                    linePosition="below"
                />
            )}
            {isSearch && pathname !== "/tracks/rank" && (
                <div className={styles.mediaTypeButtons}>
                    <Button
                        label="Audio"
                        className={styles.mediaTypeButton}
                        underlined
                        style={{
                            fontSize: "14px",
                            letterSpacing: "normal",
                            paddingBottom: "0.5rem",
                            width: "44px",
                            marginRight: "0.5rem"
                        }}
                        withoutPadding
                        isUpperCase={false}
                        active={type === "audio"}
                        onClick={() =>
                            requestTracksWithRetry({
                                commonParams: { type: "audio", sortField }
                            })
                        }
                    />
                    <Button
                        label="Video"
                        className={styles.mediaTypeButton}
                        isUpperCase={false}
                        underlined
                        style={{
                            fontSize: "14px",
                            letterSpacing: "normal",
                            paddingBottom: "0.5rem",
                            width: "44px"
                        }}
                        active={type === "video"}
                        onClick={() =>
                            requestTracksWithRetry({
                                commonParams: { type: "video", sortField }
                            })
                        }
                    />
                </div>
            )}
        </div>
    );
}

function getSortField(pathname) {
    const pathArray = pathname.split("/");
    let result = "createdAt";
    const pathQuery = PATH_QUERY[pathArray[pathArray.length - 1]];

    if (PATH_QUERY[pathArray[pathArray.length - 1]]) {
        result = pathQuery;
    }
    return result;
}

export default routerDecorator(NavigationPanel);
