import validator from "validator";

export default function checkForEmail(email, addError) {
    if (!email.trim()) addError("email", "Please enter email");

    if (email.length > 1000)
        addError("email", "Email cannot be longer than 1000 characters");

    if (!validator.isEmail(email))
        addError("email", "Please enter a valid email");
}
