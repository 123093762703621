import styles from "./Rotation.module.scss";
import React from "react";

export default function TextBlock({ bio, width }) {
    if (!bio) return <></>;
    const numberOfRows = 3;
    const lengthOfRows = width || 50;
    const words = bio.split(" ");
    const fullText = words.reduce(
        (accum, word) => {
            let actualLength =
                accum.index !== numberOfRows - 1
                    ? lengthOfRows
                    : lengthOfRows - 15;
            if (accum.list[accum.index].length + word.length > actualLength) {
                accum.index = accum.index + 1;
                accum.list[accum.index] = word;
            } else {
                accum.list[accum.index] += " " + word;
            }
            return accum;
        },
        { index: 0, list: [""] }
    );
    const fullTextList = fullText.list;
    let cutText = [];
    if (fullText.index > numberOfRows - 1) {
        cutText = fullTextList.slice(0, numberOfRows);
        cutText[numberOfRows - 1] = cutText[numberOfRows - 1] + "...";
    } else {
        cutText = fullText.list;
    }
    return (
        <div className={styles.bio}>
            {cutText.map((elem, index) => (
                <div key={index}>
                    {elem}
                    {index === numberOfRows - 1 &&
                        fullText.index >= numberOfRows && (
                            <div className={styles.learnMoreButton}>
                                {"\u0000 Learn more"}
                            </div>
                        )}
                </div>
            ))}
        </div>
    );
}
