import React from "react";
import AuthPageLayout from "components/pages/Auth/AuthPageLayout";
import ResetPassword from "./ResetPassword";

export default function ResetPasswordPage() {
    return (
        <AuthPageLayout title="Password Request">
            <ResetPassword />
        </AuthPageLayout>
    );
}
