import React, { useContext, useEffect, useState, useRef } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import { ContentContext } from "context/ContentContext";
import { LoaderPlaceholder } from "components/common/LoaderPlaceholder";
import styles from "./TracksList.module.scss";
import { filterVersions, filterTracks } from "./Helpers";
import { Track, BillboardTrack, CuratedSets, Albums } from "./Components";
import t from "helpers/getText";
import withRouter from "../../helpers/withRouter";
// import DownloadButton from './Components/DownloadAlbum';
const HEADERS = [
    "#",
    "TRACK / ARTIST",
    "TRACK INFO",
    "DATE / DL"
];

const HEADERS_ALBUMS = [
    "#",
    "TRACK / ARTIST",
    "GENRE",
    "BPM",
    "KEY",
    "RELEASED",
    "MORE VERSIONS"
];


const HEADERS_STYLE_MAP = {
    "#": styles.index,
    "TRACK / ARTIST": undefined,
    "TRACK INFO":  styles.released,
    "DATE / DL": styles.buttons
};

const HEADERS_STYLE_MAP_ALBUMS = {
    "#": styles.index,
    "TRACK / ARTIST": undefined,
    GENRE: styles.genre,
    BPM: styles.bpm,
    KEY: styles.key,
    RELEASED: styles.released,
    "TRACK INFO":  styles.released,
    "MORE VERSIONS": styles.buttons
};

TracksList.propTypes = {
    className: PropTypes.string,
    list: PropTypes.array,
    limit: PropTypes.number,
    tracksSource: PropTypes.array,
    size: PropTypes.oneOf(["forHome", "long", "longVersion"]),
    indexShift: PropTypes.number,
    hideHeaders: PropTypes.bool,
    isDownloadTracksPage: PropTypes.bool,
};

TracksList.defaultProps = {
    className: "",
    limit: 10,
    size: "long",
    indexShift: 0,
    hideHeaders: false,
    isDownloadTracksPage: false
};

Track.defaultProps = {
    versions: [],
    version: null,
    rating: 0
};

function TracksList(props) {
    const {
        isDownloadTracksPage,
        className,
        limit,
        size,
        indexShift,
        hideHeaders,
        location: { pathname }
    } = props;
    const {
        audio,
        resetDisplayOfTrackVersions,
        presetName,
        sortField,
        requestTracksProcessing
    } = useContext(ContentContext);

    useEffect(() => {
        return () => resetDisplayOfTrackVersions();
    }, []);

    const list = normalizeList({ props, audio, limit });

    if (pathname === "/tracks/curated") {
        return (
           <div>
                <CuratedSets/>
           </div>
               
          
        )
    }

    if (audio.empty) {
        return (
            <div
                className={classnames(
                    styles.noTracks,
                    size === "forHome" && styles.autoWidth
                )}
            >
                <h3>{(presetName.toLowerCase() === 'promo albums') ? t("rotation.artist.noAlbum") : t("rotation.artist.noTrack")}</h3>
            </div>
        );
    }

    if (audio.count === 0) {
        return (
            <div
                className={classnames(
                    styles.mainContainer,
                    styles[`mainContainer_${size}`],
                    className
                )}
            >
                <Loader size={size} />
            </div>
        );
    }

    return (
        <>
        <div className={styles.trackVersionColorType}>
            <div className={`${styles.ColorTypeThree} ${styles.ColorField}`}>
                <span></span>
                <p>Not Downloaded</p>
            </div>
            <div className={`${styles.ColorTypeOne} ${styles.ColorField}`}>
                <span></span>
                <p>Downloaded</p>
            </div>
            <div className={`${styles.ColorTypeTwo} ${styles.ColorField}`}>
                <span></span>
                <p>New Version Added</p>
            </div>
        </div>
        <div
            className={classnames(
                styles.mainContainer,
                styles[`mainContainer_${size}`],
                className
            )}
        >
            <div>
                <div className={styles.tracksColumnContainer}>
                    {size === "long" && (
                        <div
                            className={classnames(
                                styles.tracksColumnHeader,
                                hideHeaders && styles.hideHeaders,
                                sortField === "rank" && styles.billboard
                            )}
                        >
                            {(presetName.toLowerCase() !== 'promo albums' || isDownloadTracksPage === true) && HEADERS.map((head, index) => {
                                return (
                                    <div
                                        key={head}
                                        className={classnames(
                                            styles[`head_${index}`],
                                            HEADERS_STYLE_MAP[head]
                                        )}
                                    >
                                        {head}
                                    </div>
                                );
                            })}
                            {(presetName.toLowerCase() === 'promo albums' && isDownloadTracksPage === false) && HEADERS_ALBUMS.map((head, index) => {
                                return (
                                    <div
                                        key={head}
                                        className={classnames(
                                            styles[`head_${index}`],
                                            HEADERS_STYLE_MAP_ALBUMS[head]
                                        )}
                                    >
                                        {head}
                                    </div>
                                );
                            })}
                        </div>
                    )}
                    {renderList({
                        list,
                        audio,
                        indexShift,
                        size,
                        presetName
                    })}
                    {requestTracksProcessing && <Loader size={size} />}
                </div>
            </div>
        </div>
        </>
    );
}

const renderList = ({ list, audio, size, indexShift, presetName }) => {
    return list.map((track, index) => (
        <TrackItem
            key={track.uiId || index}
            track={track}
            index={index}
            indexShift={indexShift}
            size={size}
            presetName={presetName}
            audio={audio}
        />
    ));
};


function renderVersionsLists(lists, track) {
    const listNames = ["original", "introOutro", "remix"];
    return listNames.reduce((result, listName) => {
        const list = lists[listName];

        const listIndex = listNames.indexOf(listName);

        if (list.length > 0) {
            result.push(
                <div className={styles.versionHeader} key={listName}>
                    <span className={styles.versionText}>
                        {t(`tracks.labels.${listName}`)}
                    </span>
                    <div className={styles.headerLine} />
                </div>
            );

            list.sort((a, b) => {
                if (a.displayOrder === null && b.displayOrder === null) return 0; // both are null, so they're equal
                if (a.displayOrder === null) return 1; // a is null, so it should appear after b
                if (b.displayOrder === null) return -1; // b is null, so it should appear after a
                return a.displayOrder - b.displayOrder; // otherwise, sort by displayOrder
                }).forEach((version, index) => {
                const bpm = version.bpm || track.bpm;
                result.push(
                    <Track
                        key={version.id}
                        index={listIndex + index}
                        track={{
                            ...track,
                            bpm,
                            key: version.key,
                            versions: [version]
                        }}
                        released={version.released}
                        size="longVersion"
                        currentVersion = {version.id}
                    />
                );
            });
        }
        return result;
    }, []);
}

function renderAlbumTracksLists(lists, track) {
    const listNames = ["original", "introOutro", "remix"];
    return listNames.reduce((result, listName) => {
        const list = lists[listName];

        const listIndex = listNames.indexOf(listName);

        if (list.length > 0) {
            result.push(
                <div className={styles.versionHeader} key={listName}>
                    <span className={styles.versionText}>
                        {t(`tracks.labels.${listName}`)}
                    </span>
                    <div className={styles.headerLine} />
                </div>
            );

            list.forEach((version, index) => {
                const bpm = version.bpm || track.bpm;
                version.versionId = version.TvId;
                version.id = version.trackId;
                version.released = version.tvCreatedAt.replace(".000Z", "");
                version.rating = (typeof version.rating !== 'undefined' && version.rating > 0 ) ? version.rating : 0;
                result.push(
                    <Albums
                        list={false}
                        key={version.id}
                        index={listIndex + index}
                        track={{
                            ...version,
                            bpm,
                            key: version.key,
                            versions: [version]
                        }}
                        released={version.tvCreatedAt.replace(".000Z", "")}
                        size="longVersion"
                    />
                );
            });
        }
        return result;
    }, []);
}

function Loader(size) {
    return (
        <div
            className={classnames(
                styles.loader,
                size === "forHome" && styles.autoWidth
            )}
        >
            <LoaderPlaceholder />
        </div>
    );
}

function normalizeList({ props, audio, limit }) {
    let list = props.list;
    if (!list) {
        list = audio.list;
    }
    if (!list || list.length === 0) {
        list = getLoaderList(limit);
    }
    return list;
}

function getLoaderList(limit) {
    const result = [];
    let count = limit;
    while (count) {
        result.push({ placeHolderInfo: true, versions: [] });
        count -= 1;
    }
    return result;
}

function TrackItem({ track, index, indexShift, size, presetName, audio }) {
    const listNames = {"O": "Original","I": "Intro / Outro","R": "Remix"};
    const [isActiveLetter, setIsActiveLetter] = useState(false);
    const [isVisible, setIsVisible] = useState(false);
    const [contentHeight, setContentHeight] = useState(0);
    const [closeEffectClass, setCloseEffectClass] = useState('');
    const contentRef = useRef(null);
    const activeLetter = (audio.displayVersions &&
        audio.displayVersions.id === track.uiId &&
        audio.displayVersions.uniqueUid === track.uniqueUid &&
        audio.displayVersions.displayType) ? (audio.displayVersions &&
        audio.displayVersions.id === track.uiId &&
        audio.displayVersions.displayType) : false;
    useEffect(() => {
        let closeEffectClass = styles.closeEffect1;
        if (contentHeight < 450) {
            closeEffectClass = styles.closeEffect1;
        } else if (contentHeight < 750) {
            closeEffectClass = styles.closeEffect2;
        } else if (contentHeight < 1000) {
            closeEffectClass = styles.closeEffect3;
        } else if (contentHeight < 1400) {
            closeEffectClass = styles.closeEffect4;
        } else if (contentHeight < 2000) {
            closeEffectClass = styles.closeEffect5;
        }/* else if (contentHeight < 3000) {
            closeEffectClass = styles.closeEffect6;
        } else if (contentHeight < 4000) {
            closeEffectClass = styles.closeEffect7;
        } else {
            closeEffectClass = styles.closeEffect8;
        }*/
        else{
            closeEffectClass = styles.closeEffect5;
        }
        setCloseEffectClass(closeEffectClass);
    }, [contentHeight]);

    useEffect(() => {
        if (contentRef.current) {
            setContentHeight(contentRef.current.scrollHeight);
        }
        }, [isVisible,activeLetter]);

        const transitionEndHandler = () => {
            if (!isVisible) {
                //setContentHeight(0); // Set to 0 only after the transition ends
            }
        };
        useEffect(() => {
            const refCurrent = contentRef.current;
            if (refCurrent) {
                refCurrent.addEventListener('transitionend', transitionEndHandler);
            }
            return () => {
                if (refCurrent) {
                    refCurrent.removeEventListener('transitionend', transitionEndHandler);
                }
            };
        }, []);   
    // console.log(activeLetter,isActiveLetter, isVisible, 'activeLetter----------');
    useEffect(() => {
        if (activeLetter && !isActiveLetter && !isVisible) {
            setTimeout(function(){
                setIsActiveLetter(true);
            },100);
            setTimeout(function(){
                setIsVisible(true);
            },160 );
        } else if (!activeLetter && isActiveLetter && isVisible) {
            setIsVisible(false);
            let tt = 220;
            if (contentHeight < 750) {
                tt = 320;
            } else if (contentHeight < 1000) {
                tt = 420;
            } else if (contentHeight < 1400) {
                tt = 520;
            } else if (contentHeight < 2000) {
                tt = 720;
            }/* else if (contentHeight < 3000) {
                tt = 920;
            } else if (contentHeight < 4000) {
                tt = 1120;
            } else {
                tt = 1420;
            }*/
            else {
                tt = 720;
            }
            setTimeout(function(){
                setIsActiveLetter(false);
            },tt);
        }
       }, [activeLetter, isVisible, isActiveLetter]);

    let original = [];
        let introOutro = [];
        let remix = [];
        if (track.album_id > 0 && track.presetId === 13 && !track.isAlbumTrackSearch) {
            let album = track;
            track.id = track.trackId;
            ({ original = [], introOutro = [], remix = [] } = isActiveLetter ? filterTracks({ activeLetter, album }) : {});
        } else {
            ({ original = [], introOutro = [], remix = [] } = isActiveLetter ? filterVersions({ activeLetter, track }) : {});
        }
        track.versionId = track.TvId;
        track.rating = (typeof track.rating !== 'undefined' && track.rating > 0 ) ? track.rating : 0;
        const noVersions =
            original.length === 0 &&
            introOutro.length === 0 &&
            remix.length === 0;

        // const versionWrapClass = () => {
        //         if (activeLetter === 'A') {
        //             return styles.allVersions;
        //         }
        //         if (activeLetter === 'O') {
        //             return styles.originalVersions;
        //         }
        //         if (activeLetter === 'I') {
        //             return styles.introOutroVersions;
        //         }
            
        //     return styles.remixeVersions;
        //     };
        const currentVersion = track && track.versions && track.versions.length > 0 ? track.versions.sort((a, b) => {
            if (a.displayOrder === null && b.displayOrder === null) return 0; // both are null, so they're equal
            if (a.displayOrder === null) return 1; // a is null, so it should appear after b
            if (b.displayOrder === null) return -1; // b is null, so it should appear after a
            return a.displayOrder - b.displayOrder; // otherwise, sort by displayOrder
            })[0].id : 0;
    return (
        <div key={track.uiId || index}>
            {track.cover && (
                    <BillboardTrack
                        isVersionsVisiable={isActiveLetter}
                        presetName={presetName}
                        size={size}
                        key={track.id}
                        showCover
                        index={index + 1 + indexShift} 
                        track={track}
                        activeLetter={activeLetter}
                    />
                )}
                {!track.cover && (track.presetId === 13 && track.album_id > 0) && (!track.isAlbumTrackSearch) && (
                    <div className={styles.albums}>
                    <Albums
                        isVersionsVisiable={isActiveLetter}
                        size={size}
                        key={track.uiId}
                        presetName={presetName}
                        index={index + 1 + indexShift}
                        track={track}
                        styles={styles}
                        activeLetter={activeLetter}
                    />
                    </div>
                )}
                {!track.cover && (!track.album_id || track.album_id === 0 || track.isAlbumTrackSearch) && (
                     <div>
                        <Track
                            isVersionsVisiable={isActiveLetter}
                            size={size}
                            key={track.uniqueUid}
                            presetName={presetName}
                            showCover
                            index={index + 1 + indexShift}
                            track={track}
                            activeLetter={activeLetter}
                            isTrack={true}
                            currentVersion={currentVersion}
                        />
                    </div>
                )}
                {/* {isActiveLetter && ( */}
                <div 
                style={{ maxHeight: isVisible ? `${contentHeight+30}px` : '0px' }}
                ref={contentRef}
                className={classnames({
                    [styles.displayVersion]: true,
                    [styles.DownloadButtonInfo]: true,
                    [styles.hidden]: !isVisible,
                    [styles.panel]: true,
                    [closeEffectClass]: true
                })}>
                    {noVersions &&
                        <>
                            <div className={styles.versionHeader} key={listNames[activeLetter]}>
                                <span className={styles.versionText}>
                                    {t(listNames[activeLetter])}
                                </span>
                                <div className={styles.headerLine} />
                            </div>
                            <h4 style={{margin: '20px auto', padding: '20px'}}>No tracks available</h4>
                        </>
                    }
                    {noVersions === false && (track.album_id === 0 || track.presetId !== 13 || track.isAlbumTrackSearch) &&
                        renderVersionsLists(
                            { original, remix, introOutro },
                            track
                    )}

                    {noVersions === false && track.presetId === 13 && track.album_id > 0 && !track.isAlbumTrackSearch &&
                        renderAlbumTracksLists(
                            { original, remix, introOutro },
                            track
                    )}
                </div>
            {/* )} */}
        </div>
    );
}


export default React.memo(withRouter(TracksList));
