const browserVersion = navigator.userAgent.toLowerCase();
const isSafari =
    browserVersion.includes("safari") &&
    browserVersion.includes("chrome") === false;
export default function smoothScrollUp(scrollbar, trackContainer) {
    if (scrollbar && trackContainer.current && isSafari) {
        const aimOffset = trackContainer.current.offsetTop;
        const dif = scrollbar.current.viewScrollTop - aimOffset;
        if (dif > 0) {
            let step = dif / 15;
            if (step < 10) step = 10;
            let current = scrollbar.current.viewScrollTop;
            const scroll = () => {
                current -= step;
                scrollbar.current.view.scrollTop = current;
                if (current > aimOffset + step - 400)
                    requestAnimationFrame(scroll);
            };
            requestAnimationFrame(scroll);
        }
    } else if (
        scrollbar &&
        scrollbar.current &&
        scrollbar.current.view &&
        trackContainer.current
    ) {
        scrollbar.current.view.scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth"
        });
    }
}
