import React, { useContext, useState } from "react";
import { AuthContext } from "context/AuthContext";
import TextField from "components/common/form/TextField";
import CheckBoxField from "components/common/form/CheckBoxField";
import Button from "components/common/ControlElems/Button";
import classnames from "classnames";
import { fieldProps } from "components/helpers/forms/formFieldHelper";
import { NavLink } from "react-router-dom";
import parseServerValidationErrors from "sources/api/parseServerValidationErrors";
import MoonLoader from "react-spinners/MoonLoader";

import styles from "components/userRegister/Auth.module.scss";

export default function Login() {
    const [fields, setFields] = useState({
        login: "",
        password: "",
        remember: false
    });
    const {
        login,
        loginProcessing,
        loginError,
        resetPasswordResult,
        clearResetPasswordResult
    } = useContext(AuthContext);
    const serverErrors = parseServerValidationErrors(loginError);
    const { user: userErrors, email: emailErrors } = serverErrors;
    const commonError = userErrors || emailErrors;
    const onKeyDown = e => {
        if (e.keyCode === 13) {
            login(fields);
        }
    };
    return (
        <section className={styles.container} onKeyDown={onKeyDown}>
            <div className={classnames(styles.center, styles.narrow)}>
                <TextField
                    label="Login or email"
                    {...fieldProps("login", "string", { fields, setFields })}
                />
                <TextField
                    label="Password"
                    {...fieldProps("password", "password", {
                        fields,
                        setFields
                    })}
                />
                <CheckBoxField
                    label="Remember me"
                    value={fields.remember}
                    onChange={() =>
                        setFields({ ...fields, remember: !fields.remember })
                    }
                />

                <div
                    className={classnames(
                        styles.commonError,
                        commonError && styles.active
                    )}
                >
                    {commonError}
                </div>
                <div className={styles.loading}>
                    <MoonLoader
                        size={30}
                        color={"#5c8ea1"}
                        loading={loginProcessing}
                    />
                </div>
                <Button
                    label="Log In"
                    disabled={loginProcessing}
                    fullWidth
                    onClick={() => {
                        login(fields);
                    }}
                />

                <NavLink to="/resetPassword">
                    <Button
                        withoutBorder
                        size="small"
                        className={styles.fadedButton}
                        label="Forgot password"
                        onClick={() => {
                            clearResetPasswordPage(
                                resetPasswordResult,
                                clearResetPasswordResult
                            );
                        }}
                    />
                </NavLink>
            </div>
        </section>
    );
}

function clearResetPasswordPage(result, callback) {
    if (result) callback();
}
