import React, { useContext } from "react";
import { UIContext } from "context/UIContext";
import FeaturesList from "../Apps/FeaturesList";
import styles from "./FrpApp.module.scss";
import t from "helpers/getText";

export default function Spin( props ) {
    const { theme } = useContext( UIContext );
    const { text, head, backend } = props;
    return (
        <>
            <div className={ styles.container }>
                <div className={ styles.content }>
                    <div className={ styles.bannerContentWrap }>
                        <h1 className={ styles.mainTitle } >FRP Live App Help Guide</h1>
                        <p className={ styles.bannerTextField }>FRP LIVE is a unique app that elevates the music experience by enabling DJs to listen to and download music to mobile & onto a desktop device while on the move. Use our Mobile Application to discover music on FRP and incorporate it into your collection. Then, combine it with SPINS Desktop App for speedy and effortless downloading of music onto your desktop.
                        </p>
                    </div>
                    <div className={ styles.FeaturesListSection }>
                        <div className={ styles.leftSection }>
                            <div className={ styles.secHeadingContent }>
                                <h2 className={ styles.secTitle }>DOWNLOADING AND RUNNING THE FRP LIVE MOBILE APP</h2>
                                <p className={ styles.Section }>The App is available for Android and Apple users. Here are the steps to download the App.</p>
                            </div>
                            <div className={ styles.secList }>
                                <h4 className={ styles.secTitle }>For Apple Devices:</h4>
                                <FeaturesList
                                    items={ t( "spin.list" ) || [] }
                                />
                                <h4 className={ styles.secTitle }>For Android Devices:</h4>
                                <FeaturesList
                                    items={ t( "spin.listRight" ) || [] }
                                />
                            </div>
                            <div className={ styles.noteContent }>
                                <b>NOTE:</b><p>The downloaded tracks via the App will be synced automatically to your desktop or PC.</p>
                            </div>
                        </div>
                        <div className={ styles.rightSection }>
                            <img
                                className={ styles.icon }
                                src={
                                    theme === "light"
                                        ? require( `assets/images/for-apple-devices.png` )
                                        : require( `assets/images/for-apple-devices.png` )
                                }
                            />
                        </div>
                    </div>

                    <div className={ styles.worksListSection }>
                        <div className={ styles.leftSection }>
                            <img
                                className={ styles.icon }
                                src={
                                    theme === "light"
                                        ? require( `assets/images/music-select.png` )
                                        : require( `assets/images/music-select.png` )
                                }
                            />
                            <div className={ styles.secContent }>
                                <h4 className="sec-title">How It Works</h4>
                                <p className={ styles.secContentText }>Once you login, you will enter the Home Page which will contain a Search Bar at the top, Featured Collections section containing three options (New Release, Top Tracks, & Billboard 100) and Music Select section where you view the music based on categories.</p>
                            </div>
                        </div>
                        <div className={ styles.rightSection }>
                            <img
                                className={ styles.icon }
                                src={
                                    theme === "light"
                                        ? require( `assets/images/music-select.png` )
                                        : require( `assets/images/music-select.png` )
                                }
                            />
                            <div className={ styles.secContent }>
                                <h4 className="sec-title">Search Bar</h4>
                                <p className={ styles.secContentText }>A search bar is located at the top of the screen regardless of where you are in the App. This allows you quick and easy access to search for any track without having to go to a specific page. You can enter any keyword(s) here, and it will display all the tracks related to the keyword(s).</p>
                            </div>
                        </div>

                    </div>
                    <div className={ styles.headingTitle }>
                        <h2>Featured Collections</h2>
                    </div>
                    <div className={ styles.worksListSection }>

                        <div className={ styles.leftSection }>
                            <img
                                className={ styles.icon }
                                src={
                                    theme === "light"
                                        ? require( `assets/images/music-select.png` )
                                        : require( `assets/images/music-select.png` )
                                }
                            />
                            <div className={ styles.secContent }>
                                <h4 className="sec-title">New Release</h4>
                                <p className={ styles.secContentText }>This section is where all the music is listed, with the most recent first. The tracks are also listed in the order it was uploaded.</p>
                            </div>
                        </div>
                        <div className={ styles.rightSection }>
                            <img
                                className={ styles.icon }
                                src={
                                    theme === "light"
                                        ? require( `assets/images/music-select.png` )
                                        : require( `assets/images/music-select.png` )
                                }
                            />
                            <div className={ styles.secContent }>
                                <h4 className="sec-title">Top Tracks</h4>
                                <p className={ styles.secContentText }>This section lists the current top running tracks.</p>
                            </div>
                        </div>
                    </div>
                    <div className={ styles.worksListSection }>
                        <div className={ styles.leftSection }>
                            <img
                                className={ styles.icon }
                                src={
                                    theme === "light"
                                        ? require( `assets/images/music-select.png` )
                                        : require( `assets/images/music-select.png` )
                                }
                            />
                            <div className={ styles.secContent }>
                                <h4 className="sec-title">Billboard 100</h4>
                                <p className={ styles.secContentText }>This section shows the list of the Billboard 100 in real-time. If the tracks on the list exist in our database you will have the option to listen and download. If the tracks are not in our database, they will be listed without the listen and download buttons.</p>
                            </div>
                        </div>
                        <div className={ styles.rightSection }>
                            <img
                                className={ styles.icon }
                                src={
                                    theme === "light"
                                        ? require( `assets/images/music-select.png` )
                                        : require( `assets/images/music-select.png` )
                                }
                            />
                            <div className={ styles.secContent }>
                                <h4 className="sec-title">Music Select</h4>
                                <p className={ styles.secContentText }>A list of different music categories and genres will appear here. It also contains other options such as Throwbacks, Instrumentals, Acapellas, DJ tools, Promo Albums, Remixes, and more.</p>
                            </div>
                        </div>
                    </div>
                    <div className={ styles.worksListSection }>
                        <div className={ styles.fullSection }>
                            <img
                                className={ styles.icon }
                                src={
                                    theme === "light"
                                        ? require( `assets/images/music-select.png` )
                                        : require( `assets/images/music-select.png` )
                                }
                            />
                            <div className={ styles.secContent }>
                                <h4 className="sec-title">Grid & List View Icon</h4>
                                <p className={ styles.secContentText }>This icon appears in the top left corner of the screen. Clicking on it will display the Featured Collections section in a list view. Clicking on it again will change it back to grid view.</p>
                            </div>
                        </div>
                    </div>
                    {/* trackListSectionWrap */ }
                    <div className={ styles.trackListSectionWrap }>
                        <div className={ styles.trackListSection }>
                            <div className={ styles.headingTitle }>
                                <h3 className={ styles.secTitle }>Track Listing Features</h3>
                                <p className={ styles.secContent }>You will see seven options on the right side of any track listed in the App. These are as follows:</p>
                            </div>
                        </div>
                        <div className={ styles.worksListSection }>
                            <div className={ styles.leftSection }>
                                <img
                                    className={ styles.icon }
                                    src={
                                        theme === "light"
                                            ? require( `assets/images/track-list1.png` )
                                            : require( `assets/images/track-list1.png` )
                                    }
                                />
                                <div className={ styles.secContent }>
                                    <h4 className="sec-title">More Versions Icons</h4>
                                    <p className={ styles.secContentText }><b>A icon:</b> It will display All the versions of the selected track.</p>
                                    <p className={ styles.secContentText }><b> O icon:</b> Shows all the Original versions of the track.</p>
                                    <p className={ styles.secContentText }><b> I icon:</b> Display all Intro/Outro edits for the track.</p>
                                    <p className={ styles.secContentText }><b> R icon:</b> Shows all Remix versions for the track.</p>
                                </div>
                            </div>
                            <div className={ styles.rightSection }>
                                <img
                                    className={ styles.iconWidth }
                                    src={
                                        theme === "light"
                                            ? require( `assets/images/track-list2.png` )
                                            : require( `assets/images/track-list2.png` )
                                    }
                                />
                                <div className={ styles.secContent }>
                                    <h4 className="sec-title">Download Icon</h4>
                                    <p className={ styles.secContentText }>This button will initiate the download to the current default folder selected. After downloading, the download icon will turn into a check mark indicating that the track has been downloaded.</p>
                                </div>
                            </div>
                        </div>
                        <div className={ styles.worksListSection }>
                            <div className={ styles.leftSection }>
                                <img
                                    className={ styles.iconWidth }
                                    src={
                                        theme === "light"
                                            ? require( `assets/images/track-list3.png` )
                                            : require( `assets/images/track-list3.png` )
                                    }
                                />
                                <div className={ styles.secContent }>
                                    <h4 className="sec-title">Folder icon</h4>
                                    <p className={ styles.secContentText }>Allows you to select, create, or change a folder which will reside on your laptop or desktop to download the music to. In order for this feature to work you will need to install the Spins Desktop App on the desired computer and login using the same login you used to log in to the Mobile App. Click here to download the Spins Desktop App.</p>

                                </div>
                            </div>
                            <div className={ styles.rightSection }>
                                <img
                                    className={ styles.iconWidth }
                                    src={
                                        theme === "light"
                                            ? require( `assets/images/track-list4.png` )
                                            : require( `assets/images/track-list4.png` )
                                    }
                                />
                                <div className={ styles.secContent }>
                                    <h4 className="sec-title">Play Icon</h4>
                                    <p className={ styles.secContentText }>This icon allows you to play the track via a music player which will be located at the bottom of the screen. You will be able to scroll through the track and even download the track from the player.</p>
                                </div>
                            </div>
                        </div>
                        <div className={ styles.worksListSection }>
                            <div className={ styles.leftSection }>
                                <img
                                    className={ styles.icon }
                                    src={
                                        theme === "light"
                                            ? require( `assets/images/track-list5.png` )
                                            : require( `assets/images/track-list5.png` )
                                    }
                                />
                                <div className={ styles.secContent }>
                                    <h4 className="sec-title">Downloads</h4>
                                    <p className={ styles.secContentText }>This area contains your download history for tracks downloaded via the app and the website. Tracks are listed with all options just the same as any track listing page on the app.</p>

                                </div>
                            </div>
                            <div className={ styles.rightSection }>
                                <img
                                    className={ styles.icon }
                                    src={
                                        theme === "light"
                                            ? require( `assets/images/track-list5.png` )
                                            : require( `assets/images/track-list5.png` )
                                    }
                                />
                                <div className={ styles.secContent }>
                                    <h4 className="sec-title">Logout Option </h4>
                                    <p className={ styles.secContentText }>This option is available via the avatar located at the top right corner of the screen. A logout option will appear from the bottom of the screen when you click on it.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className={ styles.faqSecWrap }>
                <div className={ styles.container }>
                    <div className={ styles.faqSectionTitle }>
                        <div className={ styles.headingTitle }>
                            <h2 className={ styles.secTitle }>FAQ</h2>
                        </div>
                    </div>
                    <div className={ styles.faqSectionContent }>
                        <div className={ styles.leftSection }>
                            <div className={ styles.secContent }>
                                <h3 className={ styles.secTitle }>What is FRP Mobile App?</h3>
                                <p className={ styles.secContentText }>The FRP Mobile App allows DJs to use their mobile devices to access FRP Live’s services where they can search, listen or download music to any desktop or laptop. In addition, DJs can chat with other live DJs and even receive music requests or shoutouts from their audience.</p>

                            </div>
                            <div className={ styles.secContent }>
                                <h3 className={ styles.secTitle }>Is there a separate login for the FRP LIVE Mobile App?</h3>
                                <p className={ styles.secContentText }>No. Your mobile login credentials are the same as your FRP website login credentials.</p>

                            </div>
                            <div className={ styles.secContent }>
                                <h3 className={ styles.secTitle }>Can you use the  FPR Live Mobile App To Download Music?</h3>
                                <p className={ styles.secContentText }>Yes. The Mobile App is not just for searching and previewing tracks but also allows you to create folders and download music to any of those folders you created on any computer. In order to download to any computer you will need to install the SPINS Desktop app on the computer you wish to download to. You can download the SPINS Desktop App by clicking here.</p>

                            </div>
                            <div className={ styles.secContent }>
                                <h3 className={ styles.secTitle }>Who do I contact to report issues with the Mobile and Desktop Apps?</h3>
                                <p className={ styles.secContentText }>You can email Tech Support at techsupport@frp.live or call 347-449-5129.</p>
                            </div>
                        </div>
                        <div className={ styles.rightSection }>
                            <div className={ styles.secContent }>
                                <h3 className={ styles.secTitle }>Where can I download the FRP LIVE mobile app?</h3>
                                <p className={ styles.secContentText }>To download the FRP LIVE mobile app, you can go to the Google Play Store for Android devices or the App Store for Apple devices, search for "FRP LIVE," and tap the "Install" or "Get" button to begin the installation.</p>
                            </div>
                            <div className={ styles.secContent }>
                                <h3 className={ styles.secTitle }>Do I need the Desktop App in order to use the Mobile App?</h3>
                                <p className={ styles.secContentText }>No. The Mobile App works as an independent application for searching and previewing all tracks available from the website. But to enhance your experience with our service we recommend you pair it with the SPINS Desktop App.</p>
                            </div>
                            <div className={ styles.secContent }>
                                <h3 className={ styles.secTitle }>Is the Mobile App mandatory to use the FRP Live Service?</h3>
                                <p className={ styles.secContentText }>No. The Mobile App is a great tool for when you are on the go or don’t have access to a computer, but the service is always available via traditional website access at www.FRP.Live.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
