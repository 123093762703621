import React, { useCallback, useContext, useState } from "react";

export const Context = React.createContext({});
Context.displayName = "CommonDialogContext";

export const COMMON_DIALOGS = {
    None: "None",
    ConnectionError: "ConnectionError"
};

const emptyFunc = () => {};

export const INITIAL_STATE = { name: COMMON_DIALOGS.None, callback: emptyFunc };

export const Provider = props => {
    const { children } = props;

    const [dialog, setDialog] = useState(INITIAL_STATE);

    const setCurrentDialog = useCallback(
        (name, callback = emptyFunc) => setDialog({ name, callback }),
        []
    );

    const state = {
        dialog
    };

    const funcs = {
        setCurrentDialog
    };

    return (
        <Context.Provider value={{ ...state, ...funcs }}>
            {children}
        </Context.Provider>
    );
};

export const useCommonDialogContext = () => {
    const commonDialogContext = useContext(Context);

    if (commonDialogContext === undefined) {
        console.error(
            `${Provider.name} should be within ${Context.displayName}`
        );
    }

    return commonDialogContext;
};
