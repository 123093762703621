import React from "react";
import PropTypes from "prop-types";

Close.propTypes = {
    className: PropTypes.string,
    color: PropTypes.string
};

Close.defaultProps = {
    className: "",
    color: "var(--gray)"
};

export default function Close(props) {
    const { color, className } = props;
    return (
        <svg className={className} width="32" height="32" viewBox="0 0 32 32">
            <path
                fill={color}
                fillRule="evenodd"
                d="M16.605 14.356l6.827-6.827 1.951 1.95-6.827 6.828 6.827 6.827-1.95 1.95-6.828-6.826-6.827 6.827-1.95-1.95 6.826-6.828L7.827 9.48l1.95-1.95 6.828 6.826z"
            />
        </svg>
    );
}
