import React from "react";
import Login from "./Login";
import AuthPageLayout from "components/pages/Auth/AuthPageLayout";
import AuthTabButtons from "components/pages/Auth/AuthTabButtons";

export default function LoginPage() {
    return (
        <AuthPageLayout title="Log In" tabsChildren={<AuthTabButtons />}>
            <Login />
        </AuthPageLayout>
    );
}
