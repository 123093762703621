import React from "react";
import styles from "./LoaderPlaceholder.module.scss";

function LoaderPlaceholder() {
    return (
        <div className={styles.container}>
            <div className={styles.animationContainer}>
                <LoadSquares className={styles.svg1} />
                <LoadSquares className={styles.svg2} />
                <LoadSquares className={styles.svg3} />
                <LoadSquares className={styles.svg4} />
                <LoadSquares className={styles.svg5} />
                <LoadSquares className={styles.svg6} />
            </div>
            <span className={styles.label}>Loading</span>
        </div>
    );
}

function LoadSquares({ className }) {
    return (
        <svg width="13" className={className} viewBox="0 0 11.8 53.4">
            <g fill="none" fillRule="evenodd">
                {[0, 1, 2, 3, 4, 5].map(offset => {
                    const baseHeight = offset * 8.9;
                    return (
                        <path
                            key={offset}
                            className={styles[`path${offset}`]}
                            fill="#213044"
                            stroke="#213044"
                            strokeLinejoin="round"
                            strokeWidth="0"
                            d={`M0 ${baseHeight} L11.8 ${baseHeight} L11.8 ${baseHeight +
                                7.4} L0 ${baseHeight + 7.4} L0 ${baseHeight} Z`}
                        />
                    );
                })}
            </g>
        </svg>
    );
}

export default React.memo(LoaderPlaceholder);
