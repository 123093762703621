import React, { useContext, useState } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";

import { AuthContext } from "context/AuthContext";
import { ContentContext } from "context/ContentContext";

import { Star } from "components/common/icons";

import styles from "./Rating.module.scss";

Rating.propTypes = {
    onRated: PropTypes.func
};

Rating.defaultProps = {
    isActive: true,
    onRated: () => {}
};

export default function Rating(props) {
    const { trackId, onRated } = props;
    const { user } = useContext(AuthContext);
    const { setRating } = useContext(ContentContext);

    const updateRating = rating => {
        if (user && trackId) {
            setRating({ rating, trackId }, onRated);
        }
    };
    const [curHover, setCurHover] = useState(0);
    return (
        <div className={classnames(styles.container)}>
            {[1, 2, 3, 4, 5].map(n => {
                return (
                    <div key={n} onMouseOver={() => setCurHover(n)}>
                        <Star
                            isActive={curHover >= n}
                            onClick={() => updateRating.call(this, n)}
                        />
                    </div>
                );
            })}
        </div>
    );
}
