import React from "react";
import { MenuTopDetail } from "../icons";
import styles from "./DropDownMenu.module.scss";
import PropTypes from "prop-types";

DropDownMenu.propTypes = {
    showMenu: PropTypes.bool,
    width: PropTypes.number,
    height: PropTypes.number,
    position: PropTypes.object,
    type: PropTypes.string
};

export default function DropDownMenu(props) {
    const { showMenu, children, width, height, position, type } = props;
    if (type === "userMenu") {
        return (
            showMenu && (
                <div
                    style={{
                        width: width + "px",
                        height: height + "px",
                        ...position
                    }}
                    className={styles.DropDownContainer}
                >
                    <div
                        className={styles.detail}
                        style={{
                            position: "relative",
                            left: width - 39 + "px"
                        }}
                    >
                        <MenuTopDetail />
                    </div>
                    <div
                        className={styles.holder}
                        style={{
                            width: width + "px",
                            left: position.left,
                            height: height + "px"
                        }}
                    >
                        {children}
                    </div>
                </div>
            )
        );
    }
    if (type === "downloadMenu") {
        return (
            <div
                style={{
                    width: width + "px",
                    height: height + "px",
                    ...position
                }}
                className={styles.DropDownContainer}
            >
                <div
                    className={styles.detail}
                    style={{
                        position: "relative",
                        left: "28px",
                        marginTop: "4.25rem",
                        top: "-10px"
                    }}
                >
                    <MenuTopDetail />
                </div>
                <div
                    className={styles.holder}
                    style={{
                        position: "absolute",
                        left: "-72px",
                        marginTop: "5.5rem",
                        width: "232px"
                    }}
                >
                    {children}
                </div>
            </div>
        );
    }
    return <div />;
}
