import React from "react";
import { Route, Redirect } from "react-router-dom";
import TracksPage from "../components/pages/BaseUser/TracksPage";
import DownloadsPage from "../components/pages/BaseUser/DownloadsPage";
import ProfilePage from "../components/pages/BaseUser/ProfilePage";
import RotationPage from "../components/pages/RotationPage";
import ArtistPage from "../components/pages/ArtistPage";
import SplashPage from "../components/pages/SplashPage";
// import TransactionsPage from "../components/pages/Transactions";
// import LabelPage from "../components/pages/LabelPage";
import newHome from "components/pages/newHome";
// import HomePage from "../components/pages/HomePage";
import SearchResultPage from "../components/pages/BaseUser/SearchResultPage";
import spin from "components/pages/Spin";
import frpApp from "components/pages/FrpApp";
import AppsPage from "components/pages/AppsPage";

export default function BaseUserRoutes() {
    return [
        <Route path="/profile/:type?/:subtype?" component={ProfilePage} />,
        <Route exact path="/tracks/:type" component={TracksPage} />,
        <Route exact path="/downloads" component={DownloadsPage} />,
        <Route exact path="/rotation/artist/:id" component={ArtistPage} />,
        <Route exact path="/rotation" component={RotationPage} />,
        //<Route exact path="/labels" component={LabelPage} />, No need anymore
        <Route exact path="/home" component={newHome} />,
        <Route exact path="/search" component={SearchResultPage} />,
        <Route exact path="/" component={SplashPage} />,
        // <Route exact path="/transactions" component={TransactionsPage}/>,
        <Route exact path="/newHome" component={newHome} />,
        <Route exact path="/spins-guide" component={ spin } />,
        <Route exact path="/apps/:type?" component={AppsPage} />,
        <Route exact path="/app-guide" component={ frpApp } />,
        <Redirect to="/tracks/all" />
    ];
}

BaseUserRoutes.propTypes = {};
