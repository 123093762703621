import React, { useContext } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import { NavLink } from "react-router-dom";
import logo from "assets/images/logo.png";
import footerLogo from "assets/images/footerLogo.png";
import { UIContext } from "context/UIContext";
import styles from "./Logo.module.scss";

Logo.propTypes = {
    className: PropTypes.string
};

Logo.defaultProps = {
    className: ""
};

function Logo({ className, isFooter }) {
    const { theme } = useContext(UIContext);
    return (
        <div className={classnames(styles.logoContainer, className)}>
            <NavLink to="/">
                <img src={getSrc(isFooter, theme)} alt="logo" />
            </NavLink>
        </div>
    );
}

function getSrc(isFooter, theme) {
    if (isFooter) {
        return theme === "dark" ? footerLogo : logo;
    } else {
        return theme !== "dark" ? footerLogo : logo;
    }
}

export default React.memo(Logo);
