import React from "react";
import { localStorage } from "helpers/storage";
import bindFunctions from "./helpers/bindFunctions";

export const UIContext = React.createContext();

class UIContextContainer extends React.PureComponent {
    constructor(props) {
        super(props);
        const currentTheme = localStorage.get("colorTheme");

        const theme = currentTheme || "dark";
        this.state = { theme };

        this.funcs = bindFunctions(this, { setColorTheme: this.setColorTheme });
    }

    componentDidMount() {
        this.setColorTheme(this.state.theme);
    }

    setColorTheme = theme => {
        let colorThemeTag = document.getElementById("colorTheme");

        if (!colorThemeTag) {
            colorThemeTag = document.createElement("link");
            colorThemeTag.rel = "stylesheet";
            colorThemeTag.id = "colorTheme";
            document.body.appendChild(colorThemeTag);
        }
        colorThemeTag.href = `/${theme}ColorTheme.css`;
        localStorage.set("colorTheme", theme);
        this.setState({ theme });
    };

    render() {
        return (
            <UIContext.Provider value={{ ...this.state, ...this.funcs }}>
                {this.props.children}
            </UIContext.Provider>
        );
    }
}

export default UIContextContainer;
