import React from "react";
import moment from "moment";
const Bill = ({data}) => {
  return (
  <table width="600" cellpadding="0" cellspacing="0" style={{fontFamily: 'arial', maxWidth: '650px', margin: '0 auto'}}>
    <tbody>
      <tr>
        <td>
          <table width="100%" style={{borderSpacing: 0}}>
            <tbody>
              <tr>
                <td width="33.3%" style={{textAlign: 'left'}}>
                    <img src="/static/media/footerLogo.001f26bf.png" width="146" height="60"  alt="logo" />
                </td>
                <td width="33.3%" />
                <td width="33.3%" >
                  <h4 style={{backgroundColor: '#bb1d7e', color: '#fff', padding: '12px 12px', textAlign: 'left', borderRadius: 5}}>Paid</h4>
                </td>
              </tr>
              <tr>
                <td width="33.3%" style={{padding: '26px 20px 26px 0px', borderBottom: 'solid #eef0ef 5px'}}>
                  <h4 style={{color: '#000', margin: 0, textAlign: 'left'}}>Franchise Record Pool</h4>
                  <p style={{ color: '#000', margin: 0, textAlign: 'left'}}>
                    494 Bridgeport Ave, Ste 101, PMB 310, Shelton, CT, 06484 <br />
                    www.frp.live</p>
                </td>
                <td width="33.6%" style={{padding: '26px 10px 26px 50px', borderBottom: 'solid #eef0ef 5px'}}>
                  <p style={{ color: '#858282', textAlign:'left', lineHeight:'8px', textTransform: 'uppercase'}}>Transaction Id
                  </p>
                  <p style={{ color: '#858282', lineHeight:'8px', textAlign:'left', textTransform: 'uppercase'}}>Issue Date
                  </p>
                  <p style={{ color: '#858282', lineHeight:'8px', textAlign:'left', textTransform: 'uppercase'}}>Due Date
                  </p>
                </td>
                <td width="33%" style={{color: '#000', padding: '26px 0', borderBottom: 'solid #eef0ef 5px'}}>
                  <p style={{ textAlign:'left',lineHeight: '8px'}}>{data.transactionId}</p>
                  <p style={{ textAlign:'left',lineHeight: '8px'}}>{moment(data.submitDate).format("DD/MM/YYYY")}</p>
                  <p style={{ textAlign:'left',lineHeight: '8px'}}>{moment(data.submitDate).format("DD/MM/YYYY")}</p>
                </td>
              </tr>
            </tbody>
          </table>
          <table width="100%" style={{color: '#000', borderSpacing: 0}}>
            <tbody>
              <tr style={{verticalAlign: 'top'}}>
                <td style={{padding: '26px 0'}}>
                  <h4 style={{marginBottom: 0,textAlign: 'left',lineHeight:'12px'}}>Bill To:</h4>
                    <p style={{ color: '#000', textAlign: 'left'}}>
                        {(data.address) ? data.address : 'Address N/A'}<br />
                        {(data.city) ? data.city : 'City N/A'}<br />
                        {(data.country) ? data.country : 'Country N/A'}<br />
                        {(data.zip) ? data.zip : 'Zip N/A'}<br />
                    </p>
                </td>
                <td colSpan={2} style={{padding: '26px 0'}}>
                  <h4 style={{marginBottom: 0,textAlign: 'left',lineHeight:'12px'}}>Deliver To:</h4>
                    <p style={{ color: '#000', textAlign: 'left'}}>
                        {(data.address) ? data.address : 'Address N/A'}<br />
                        {(data.city) ? data.city : 'City N/A'}<br />
                        {(data.country) ? data.country : 'Country N/A'}<br />
                        {(data.zip) ? data.zip : 'Zip N/A'}<br />
                    </p>
                </td>
              </tr>
            </tbody>
          </table>
          <table width="100%" style={{color: '#000', borderSpacing: 0}}>
            <tbody>
              <tr>
                <td>
                  <h4 style={{textAlign:'left'}}>Invoice Items</h4>
                </td>
                <td />
                <td />
              </tr>
            </tbody>
          </table>
          <table width="100%" style={{color: '#000', borderSpacing: 0}}>
            <tbody>
              <tr style={{color: '#000', background: '#F9FAFC', padding: 0}} cellSpacing={0} cellPadding={0}>
                <td width="20%" style={{padding: '21px 12px', borderBottom: 'solid #eef0ef 5px'}}>
                  <h4 style={{margin: 0}}> Plan Name</h4>
                </td>
                <td width="80%" style={{padding: '21px 12px', borderBottom: 'solid #eef0ef 5px'}}>
                  <h4 style={{margin: 0}}>
                    Plan Period (in Months)
                  </h4>
                </td>    
                <td width="20%" style={{padding: '9px 12px', borderBottom: 'solid #eef0ef 5px'}}>
                  <h4 style={{margin: 0}}>Price</h4>
                </td>
              </tr>
            </tbody>
          </table>
          <table width="100%" style={{color: '#000', borderSpacing: 0}}>
            <tbody>
              <tr>
                <td width="20%" style={{borderBottom: 'solid #eef0ef 5px', padding: '11px 12px'}}>
                  <h4>Standard</h4>
                </td>
                <td width="70%" style={{borderBottom: 'solid #eef0ef 5px', padding: '11px 0px'}}>
                  <h4>1</h4>
                </td>
                <td align="right" style={{borderBottom: 'solid #eef0ef 5px', textAlign:'right', padding: '11px 0px', paddingRight: '5px'}}>
                  <h4>${data.amount}</h4>
                </td>
              </tr>
            </tbody>
          </table>
          <table width="100%" style={{color: '#000', backgroundColor: '#EBEFF0', borderSpacing: 0}}>
            <tbody>
              <tr style={{backgroundColor: '#EBEFF0'}}>
                <td colSpan={2} align="right"  style={{background: '#FFFFFF', textAlign:'right', padding: '16px 0', borderBottom: 'solid #eef0ef 5px', paddingRight: 50}}>
                  <h4 style={{margin: 0,textAlign:'right'}} >Subtotal</h4>
                </td>
                <td align="right" width="200px" style={{padding: '16px 0', borderBottom: 'solid #eef0ef 5px', paddingRight: 10}}>
                  <h4 style={{margin: 0}}>${data.amount}</h4>
                </td>
              </tr>
            </tbody>
          </table>
          <table cellSpacing={0} cellPadding={0} width="100%" style={{color: '#000', borderSpacing: 0, marginTop: 50}}>
            <tbody>
              <tr>
                <td width="65%" align="right" style={{borderBottom: 'solid #eef0ef 5px', paddingBottom: 20}}>
                </td>
                <td width="35%" align="right" style={{borderTop: 'solid black 5px', borderBottom: 'solid #eef0ef 5px', paddingBottom: 20}}>
                  <table width="100%" style={{borderSpacing: 0}}>
                    <tbody>
                      <tr>
                        <td  style={{paddingLeft: 15, paddingTop: 13, paddingRight:'10px'}}>
                          <h4 style={{margin: 0,textAlign:'right'}}>Total</h4>
                        </td>
                        <td width="200px" align="right" style={{backgroundColor: '#EBEFF0', margin: 0, paddingRight: 10, paddingLeft:10, paddingTop: 13, paddingBottom: 13}}>
                          <h4 style={{margin: 0}}>${data.amount}</h4>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </td>
              </tr>
            </tbody>
          </table>
        </td>
      </tr>
    </tbody>
  </table>
  );
};

export default Bill;
