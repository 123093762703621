import React, { useContext } from "react";
import classnames from "classnames";
import t from "helpers/getText";
import { UIContext } from "context/UIContext";
import styles from "./StaticPages.module.scss";

export default function Agreement() {
    const { theme } = useContext(UIContext);
    return (
        <div
            className={classnames(
                styles.container,
                styles.aboutUsBack,
                styles[theme]
            )}
        >
            <section className={styles.content}>
                <h1>{t(`agreement.header`)}</h1>
                <div className={styles.text}>
                    <span>{t(`agreement.subHeader`)}</span>
                    <span className={styles.text}>{t("agreement.text")}</span>
                </div>
            </section>
        </div>
    );
}
