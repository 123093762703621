import React, { useContext } from "react";
import { Link } from "react-router-dom";
import classnames from "classnames";

import { getTextOrNull as t } from "helpers/getText";
import routerDecorator from "components/helpers/withRouter";
import { Links } from "components/common/ControlElems";
import { UIContext } from "context/UIContext";

import styles from "./StaticPages.module.scss";

const LINKS = [
    "/aboutUs/join",
    "/aboutUs/get",
    "/aboutUs/benefits",
    "/aboutUs/cost",
    "/aboutUs/service"
];

function AboutUs(props) {
    const {
        match: {
            params: { info }
        }
    } = props;
    const { theme } = useContext(UIContext);
    return (
        <div
            className={classnames(
                styles.container,
                styles.aboutUsBack,
                styles[theme]
            )}
        >
            <section className={styles.content}>
                <h3>{t(`aboutUs.${info}.pubHeader`)}</h3>
                <h1>{t(`aboutUs.${info}.header`)}</h1>
                <Links
                    className={styles.links}
                    prefix="aboutUs.links"
                    type="underline"
                    links={LINKS}
                />
                <h2>{t(`aboutUs.${info}.subHeader`)}</h2>
                <div className={styles.text}>
                    {t(`aboutUs.${info}.text`).map((text, index) => (
                        <span key={index}>{text}</span>
                    ))}
                    {[
                        <p key="1">
                            {t(`aboutUs.${info}.registerLink.inviteText`)}
                        </p>,
                        <Link
                            key="2"
                            className={styles.clickHerelink}
                            to="/register"
                        >
                            {" "}
                            {t(`aboutUs.${info}.registerLink.link`)}
                        </Link>
                    ]}
                </div>
            </section>
        </div>
    );
}

export default routerDecorator(AboutUs);
