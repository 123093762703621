import React, { useContext, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import styles from "./Tracks.module.scss";
import routerDecorator from "components/helpers/withRouter";
import { ContentContext } from "context/ContentContext";
import smoothScrollUp from "helpers/utils/smoothScrollUp";
import ListWithPaginator from "./ListWithPaginator";
import SideLinks from "./SideLinks";
import { NavigationPanel } from "../common/ControlElems";
import SideFilters from "components/common/ControlElems/SideFilters/SideFilters";
import { MEGA_LINKS } from "../../helpers/constants/musicNavigationLinks";

Tracks.propTypes = {
    className: PropTypes.string
};

function Tracks(props) {
    const { className } = props;
    const { requestDownloadTracks, scrollbar, audio, video, type } = useContext(
        ContentContext
    );

    const { limit, offset, count } = type === "audio" ? audio : video;

    const trackContainer = useRef(null);

    useEffect(() => {
        smoothScrollUp(scrollbar, trackContainer);
    }, [offset, limit, count]);

    useEffect(() => {
        requestDownloadTracks({ needToResetList: true });
    }, []);

    return (
        <div className={classnames(styles.container, className)}>
            <SideFilters className={styles.left} />
            <div className={styles.center}>
                <NavigationPanel
                    prefix="tracks.headers"
                    upperCase={false}
                    megaLinks={MEGA_LINKS}
                    withoutRequest
                />
                <ListWithPaginator
                    hideHeaders
                    containerRef={trackContainer}
                    limit={limit}
                    offset={offset}
                    count={count}
                    requestTracks={requestDownloadTracks}
                    type={type}
                    styles={styles}
                    isDownloadTracksPage={true}
                />
            </div>
            <SideLinks />
        </div>
    );
}

export default routerDecorator(Tracks);
