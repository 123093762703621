const aboutUs = {
    aboutUs: {
        links: {
            "/aboutUs/join": "How to join",
            "/aboutUs/get": "DJ benefits",
            "/aboutUs/benefits": "Label benefits",
            "/aboutUs/cost": "The cost",
            "/aboutUs/service": "How to service us"
        },
        pool: {
            pubHeader: "ABOUT THE POOL",
            header: "About the Pool",
            subHeader: "",
            registerLink: {
                inviteText: "Ready to get on board?",
                link: "Click here"
            },
            text: [
                `Franchise Record Pool is the best source of new music for DJs around the world. It is the most effective and technologically advanced tool for labels and artiste to promote their music.`,
                `Founded in 1996, Franchise Record Pool is the largest and most advanced music service that provides DJs with a one stop source for new and pre-released music. With our consistent quest to improve and advance our service, we have added tools that allow DJs to access their music instantly as well as allow Labels and Artist to connect with the DJs tracking the progress of their music in real-time.`,
                `We are dedicated to providing music daily to DJs on a timely basis and using our advance mobile app they can instantly download the music to their pc. At the same time providing record labels with the promotion, feedback of their music and connecting live to the professionals that matters the most. The DJs.`,
                `Franchise Record Pool has working relationships with numerous labels that provides tons of exclusive music for the DJs. As they believe in the DJs opinion, all they ask is for some feedback on their music and Franchise has the quickest and most efficient way of submitting feedback.`,
                `DJs enjoy the benefits of getting the new music first. Labels enjoy the benefits of getting your music heard around the world. Join the Franchise Record Pool now and experience the best.`
            ]
        },
        join: {
            pubHeader: "ABOUT HOW TO JOIN",
            header: "How to join",
            subHeader: "",
            registerLink: null,
            text: [
                "To join Franchise Record Pool all you need to do is fill out our quick and easy application. Simply click on the respective DJ or Label/Artist buttons below to begin. We look forward to having you on aboard."
            ]
        },
        get: {
            pubHeader: "ABOUT DJ BENEFITS",
            header: "DJ Benefits",
            subHeader: "",
            registerLink: {
                inviteText: "Ready to get on board?",
                link: "Click here"
            },
            text: [
                `As largest and most advanced record pool on the web, the benefits are very competitive and you are guaranteed to stay on top of your game. Receiving and downloading new music has never been easier and we at Franchise Record Pool are constantly working hard to bring you the music you need on a timely and daily basis. Become a member now and receive the following:`,
                `Over 400,000 tracks currently available for download in your bins.\n`,
                `We service up to over 4000 tracks per month weeks sometimes months in advance from the Major and Indie labels.\n`,
                `Get access to 20 genres of music.\n`,
                `We built the most advance App which allows DJs to create folders, sub folders and download to any of those folders on their PC at home when they are on the go. You can connect with other DJs and even your audience via our live chat feed eliminating annoying visits to the DJ booth. The app also allows you to quickly download music to your pc while playing live for instant play.\n`,
                `Access to DJ Tools such as Scratch and sound effects Samples you can use for your DJ tricks and remixes.\n`,
                `New and old music videos for all you VJs out there.\n`,
                `Friendly Intros and Outros on the most popular tracks. In addition we offer our own exclusive intro/outro edits we call Snip Hitz and Mega Kutz. Snip Hitz are tracks that are edited down to an Intro beat, the verse, a chorus and then the outro beat. The Mega Kutz edit contains the Intro beat, the chorus and then the outro beat. These special edits will allow you to quick mix or create your own live mega mixes.\n`,
                `We have the most comprehensive Old School / Throwbacks catalog. We are talking old school Hip Hop, R&B, Funk, Mainstream, Reggae and even House from the 60 to the early 2000s.\n`,
                `We service more promo albums from the biggest names before they are released.\n`,
                `We have the Largest selection of specialty genres such as reggae and soca. We are talking reggae riddims direct from the Caribbean that you only get in the stores\n`,
                `We offer a huge selection of Dance/House music in a variety of sub genres.\n`,
                `All our tracks are Serato ready containing BPMs and Key.\n`,
                `We service exclusive remixes and party mixes from top remixers around the world.\n`,
                `Receive Franchise's exclusive Breakerz mixes. A series of continuous 15-20 mins mix in multiple genres. Use these mixes to take a break to get a drink, go to the bathroom or interact with your crowd. You can even pop these in your iPod to listen when you are on the go.\n`,
                `The only record pool to offer TECH SUPPORT of its kind. Our tech support can connect to your pc to directly diagnosed and rectify the problem without having you install any files on your computer.\n`,
                `We are proud to say that we do not modify the original MP3s encoded bit rate to deceive anyone. We guarantee you that the MP3s given to you are the exact same way we get it from the labels.\n`
            ]
        },
        benefits: {
            pubHeader: "ABOUT LABEL BENEFITS",
            header: "Label Benefits",
            subHeader: "",
            registerLink: {
                inviteText: "Ready to get on board?",
                link: "Click here"
            },
            text: [
                `Franchise Record Pool truly understands the importance of the need for an Artist or record label to get their music heard the right way. Housing some of the most influential DJs around the world, we are certain we can help you fulfil this need. But a very important benefit is getting the time critical feedback needed to make the important decisions on the direction of your music. Get on board with Franchise Record Pool and enjoy the following benefits:\n`,
                `Service your music to thousands of DJs around the world in key markets.\n`,
                `Track feedback and trending data via our state of the art interface.\n`,
                `Receive play alerts live when a DJ spins your track.\n`,
                `Connect with our database of DJs live via our mobile app.\n`,
                `Push your tracks to our DJs live via the app to instantly download and play.\n`,
                `Interact live with our DJs via our mobile tools.\n`,
                `Access to additional promotional and marketing tools for further exposure.\n`
            ]
        },
        cost: {
            pubHeader: "ABOUT THE COST",
            header: "The cost",
            subHeader: "",
            registerLink: {
                inviteText: "Ready to get on board?",
                link: "Click here"
            },
            text: [
                "As a member of Franchise Record Pool you can enjoy receiving thousands of tracks per month, up to over 4000, in 20 genres all for only $19.99 per month. We accept all major credit cards and PayPal. We do NOT accept cash app.",
                "Our site is safe and secure and we ensure that it stays this way with periodic audits. Please note your credit card will be charged $19.99 every month until you decide to cancel your subscription with Franchise Record Pool. You can cancel at anytime.\n"
            ]
        },
        service: {
            pubHeader: "ABOUT SERVICE",
            header: "How to service us",
            subHeader: "",
            registerLink: {
                inviteText: "Ready to get on board?",
                link: "Click here"
            },
            text: [
                "Record labels and artist must create an account to upload music in order to view feedback and track the progress of their music. To offset the cost of bandwidth and server support a low fee of $4.99 USD for a DJ Pack and $7.99 for a single is charged for uploads. You can even choose the option of unlimited upload also for $4.99 per month with additional savings when paying in advance. If you choose just to submit your music without the need for feedback please send your music to music@frp.live",
                "For additional information, assistance or general question please do not hesitate to contact us using any of the contact information provided on our Contact page. Join the Franchise Record Pool now experience the difference.\n"
            ]
        }
    }
};

export default aboutUs;
