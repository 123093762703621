import moment from "moment";

export function setTimerToUpdateTime({ waveSurfer, setVisualTimePosition }) {
    console.log({ getCurrentTime: waveSurfer.getCurrentTime() });
    const timer = setInterval(() => {
        setVisualTimePosition(waveSurfer.getCurrentTime());
    }, 300);

    return () => clearInterval(timer);
}

export function getTrackLink(track) {
    const { versionId, versions } = track || {};
    let result = null;
    if (versionId && versions) {
        const currentVersion = versions.find(elem => elem.id === versionId);
        result = currentVersion.link;
    }
    return result;
}

export function setDurationOnLoad({ waveSurfer, setDuration }) {
    // const { current } = audioTag;
    setDuration(0);
    if (waveSurfer) {
        setDuration(waveSurfer.getDuration());
    }
}

export function setTimePositionToTrack({ timePosition, audioTag }) {
    const { current } = audioTag;
    if (current) {
        current.currentTime = timePosition;
    }
}

export function applyVolume({ volume, waveSurfer }) {
    if (waveSurfer) {
        waveSurfer.setVolume(volume);
    }
}

export function applyPlaingStatus({ waveSurfer, isPlaying }) {
    if (isPlaying) {
        waveSurfer.play();
    } else {
        waveSurfer.pause();
    }
}

export function getFormatedTime(seconds) {
    return moment.utc(seconds * 1000).format("mm:ss");
}
