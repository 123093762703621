import React from "react";
import PropTypes from "prop-types";

ProfileIcon.propTypes = {
    className: PropTypes.string,
    color: PropTypes.string,
    activeColor: PropTypes.string,
    isActive: PropTypes.bool
};

ProfileIcon.defaultProps = {
    className: "",
    isActive: false,
    color: "rgba(255,255,255, 0.3)",
    activeColor: "white"
};

export default function ProfileIcon(props) {
    const { color, activeColor, className, isActive } = props;
    return (
        <svg width="22" height="22" viewBox="0 0 22 22" fill={isActive ? activeColor : color} fillRule="evenodd" className={className} >
            <path d="M22 11.0135C22.0006 13.7338 20.9833 16.3561 19.1478 18.366C18.7275 17.1756 18.052 16.0912 17.1685 15.1886C16.2851 14.2859 15.2149 13.5868 14.0328 13.14C14.8979 12.5023 15.5395 11.6082 15.866 10.585C16.1925 9.56189 16.1873 8.46193 15.8512 7.4419C15.5151 6.42187 14.8652 5.5338 13.9941 4.90424C13.123 4.27468 12.0752 3.93576 11 3.93576C9.92472 3.93576 8.8769 4.27468 8.00583 4.90424C7.13475 5.5338 6.48485 6.42187 6.14875 7.4419C5.81264 8.46193 5.80747 9.56189 6.13397 10.585C6.46047 11.6082 7.10199 12.5023 7.96711 13.14C6.78502 13.5868 5.71486 14.2859 4.83139 15.1886C3.94791 16.0912 3.27242 17.1756 2.85211 18.366C1.65126 17.0438 0.791152 15.4491 0.346224 13.7202C-0.0987041 11.9912 -0.115048 10.1801 0.298606 8.44338C0.712261 6.7067 1.54345 5.09686 2.72024 3.75318C3.89703 2.40949 5.38397 1.37246 7.05236 0.731812C8.72075 0.0911692 10.5203 -0.133778 12.2954 0.07644C14.0704 0.286658 15.7674 0.925706 17.2395 1.93828C18.7116 2.95085 19.9144 4.30644 20.7439 5.88773C21.5734 7.46901 22.0045 9.22835 22 11.0135ZM14.5357 9.05176C14.5357 8.35337 14.3283 7.67066 13.9398 7.08998C13.5513 6.50929 12.9991 6.0567 12.353 5.78943C11.707 5.52217 10.996 5.45225 10.3102 5.58849C9.62432 5.72474 8.99432 6.06105 8.49984 6.55488C8.00536 7.04872 7.66861 7.6779 7.53219 8.36287C7.39576 9.04784 7.46578 9.75783 7.73339 10.4031C8.001 11.0483 8.45418 11.5998 9.03563 11.9878C9.61707 12.3758 10.3007 12.5829 11 12.5829C11.9371 12.5808 12.8352 12.2081 13.4978 11.5463C14.1604 10.8846 14.5336 9.98763 14.5357 9.05176ZM11 14.1522C9.4106 14.1529 7.86781 14.6884 6.62049 15.6721C5.37317 16.6558 4.49405 18.0306 4.12496 19.5745C6.07169 21.144 8.49807 22 11 22C13.5019 22 15.9282 21.144 17.875 19.5745C17.5059 18.0306 16.6268 16.6558 15.3794 15.6721C14.1321 14.6884 12.5893 14.1529 11 14.1522Z" fill="#56626E"/>
        </svg>
    );
}