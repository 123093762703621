import React from "react";
import AuthPageLayout from "components/pages/Auth/AuthPageLayout";
import ConfirmResetPassword from "./ConfirmResetPassword";

export default function ConfirmResetPasswordPage(props) {
    return (
        <AuthPageLayout title="Password Reset">
            <ConfirmResetPassword {...props} />
        </AuthPageLayout>
    );
}
