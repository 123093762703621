import React, { useState, useContext } from "react";
import classnames from "classnames";
import PropTypes from "prop-types";

import styles from "./VideoElement.module.scss";
import { Play } from "../icons";
import VideoDialog from "../Dialogs/VideoDialog";
import { ContentContext } from "../../../context/ContentContext";

import {
    getYoutubeLocalAddress,
    getAmazonAddress
} from "helpers/utils/getVideoAddress";

VideoElement.propTypes = {
    onOpen: PropTypes.func,
    address: PropTypes.string,
    key: PropTypes.number,
    size: PropTypes.oneOf(["small", "medium"]),
    useThumbnail: PropTypes.bool
};
VideoElement.defaultProps = {
    address: "",
    key: 0,
    size: "medium",
    useThumbnail: false
};

export default function VideoElement(props) {
    const { pausePlaying } = useContext(ContentContext);
    const { address, key, size, onOpen, useThumbnail } = props;
    let youtubeAddress = getYoutubeLocalAddress(address);
    if (youtubeAddress) youtubeAddress = youtubeAddress[0];
    const amazonAddress = getAmazonAddress(address);
    const [openedVideo, setOpenedVideo] = useState(false);
    return (
        <div>
            {youtubeAddress ? (
                <>
                    <div
                        onClick={() => {
                            pausePlaying();
                            setOpenedVideo(true);
                        }}
                        key={key}
                        className={classnames(styles.artistVideo, styles[size])}
                    >
                        <img
                            style={{ width: "100%" }}
                            alt="Video cover"
                            className={classnames(
                                styles.artistVideo,
                                styles[size]
                            )}
                            src={`https://img.youtube.com/vi/${youtubeAddress}/maxresdefault.jpg`}
                        />
                        <div className={classnames(styles.play, styles[size])}>
                            <Play
                                className={styles.playButton}
                                playColor="white"
                                lineColor="white"
                            />
                        </div>
                    </div>
                    {openedVideo && (
                        <VideoDialog onClose={() => setOpenedVideo(false)}>
                            <iframe
                                className={styles.videoFrame}
                                frameBorder="0"
                                rel="0"
                                allowFullScreen="allowfullscreen"
                                allow=" autoplay;"
                                title="YouTube video player"
                                width="1200"
                                height="680"
                                src={`https://www.youtube.com/embed/${youtubeAddress}`}
                            />
                        </VideoDialog>
                    )}
                </>
            ) : (
                <>
                    <div
                        onClick={() => {
                            setOpenedVideo(true);
                            pausePlaying();
                            onOpen();
                        }}
                        key={key}
                        className={classnames(styles.artistVideo, styles[size])}
                    >
                        {useThumbnail ? (
                            <div
                                className={classnames(
                                    styles.artistVideoThumbnail,
                                    styles[size]
                                )}
                            >
                                <img
                                    alt="thumbnail"
                                    src={getThumbnailFromVideoSrc(address)}
                                />
                            </div>
                        ) : (
                            <video
                                preload="metadata"
                                className={classnames(
                                    styles.artistVideo,
                                    styles[size]
                                )}
                                src={amazonAddress}
                            />
                        )}

                        <div className={classnames(styles.play, styles[size])}>
                            <Play
                                className={styles.playButton}
                                playColor="white"
                                lineColor="white"
                                opacity={0.7}
                            />
                        </div>
                    </div>
                    {openedVideo && (
                        <VideoDialog
                            className={styles.videoDialog}
                            onClose={() => {
                                setOpenedVideo(false);
                            }}
                        >
                            <Video
                                useThumbnail={useThumbnail}
                                amazonAddress={amazonAddress}
                            />
                        </VideoDialog>
                    )}
                </>
            )}
        </div>
    );
}

function Video({ useThumbnail, amazonAddress }) {
    const [showDefaultVideo, setShowDefaultVideo] = useState(false);
    return (
        <video
            autoPlay
            controls
            key={`showDefault:${showDefaultVideo}`}
            onError={() => setShowDefaultVideo(true)}
            src={
                showDefaultVideo
                    ? amazonAddress
                    : getVideoToShow(useThumbnail, amazonAddress)
            }
        />
    );
}

function getVideoToShow(needToChange, link) {
    let result = link;
    if (needToChange && link && link.replace) {
        result = link.replace(".mp4", ".f4v");
        result = result.replace("video/", "videoF4V/");
    }

    return result;
}

function getThumbnailFromVideoSrc(link) {
    if (!link) return;
    let result = link.replace("video/", "videoThumbnail/");
    result = result.replace(".mp4", ".png");
    return result;
}
