import React, { useContext, useEffect } from "react";
import PropTypes from "prop-types";

import { SubscriptionContext } from "context/SubscriptionContext";
import { Button } from "components/common/ControlElems";
import { BaseDialog } from "../../common/Dialogs";
import t from "helpers/getText";
import styles from "./ProfileDialogs.module.scss";

Subscription.propTypes = {
    onClose: PropTypes.func.isRequired,
    onChooseCard: PropTypes.func.isRequired
};

export default function Subscription({
    onClose,
    onChooseCard,
    selectedOption
}) {
    const {
        requestRedirectLink,
        requestRedirectLinkProcessing,
        requestRedirectLinkResult,
        clearRequestedRedirectLink
    } = useContext(SubscriptionContext);

    useEffect(() => {
        if (requestRedirectLinkResult && requestRedirectLinkResult.link) {
            window.location = requestRedirectLinkResult.link;
        }
        return () => {
            clearRequestedRedirectLink();
        };
    }, [requestRedirectLinkResult]);

    return (
        <BaseDialog onClose={onClose} height="short" width="narrow">
            <div className={styles.container}>
                <h2 className={styles.cancelHeader}>
                    {t("subscription.choosePayment")}
                </h2>
                <Button
                    color="bright"
                    width="14.5rem"
                    onClick={onChooseCard}
                    disabled={
                        requestRedirectLinkProcessing ||
                        requestRedirectLinkResult
                    }
                    label={t("subscription.buttons.payWithCard")}
                />
                <Button
                    color="bright"
                    width="14.5rem"
                    onClick={() => requestRedirectLink(selectedOption)}
                    disabled={
                        requestRedirectLinkProcessing ||
                        requestRedirectLinkResult
                    }
                    label={
                        requestRedirectLinkProcessing
                            ? t("subscription.buttons.processing")
                            : t("subscription.buttons.payWithPaypal")
                    }
                />
            </div>
        </BaseDialog>
    );
}
