import React from "react";
import classnames from "classnames";

import rawStyles from "../TracksList.module.scss";
import { DownloadButton } from "../Components";

const renderAlbumTracks = ({
    size,
    track,
    styles = rawStyles,
    presetName,
    className,
    isBillboard
}) => {
    const trackVersionName = track.songName;
    if (size === "superShort" || size === "longVersion") {
        return (
            <div className={classnames(styles.versions, styles[size])}>
                <p title={trackVersionName} className={styles.trackName}>{trackVersionName}</p>
            </div>
        );
    }else {
    return (
            <div className={classnames(className, styles.versions)} key="1">
                <div className={classnames(className, styles.smallVersionContainer)} key="1a">
                {(size === 'long') && (<DownloadButton track={track} key={track.name} size={size}/>)}
                </div>
            </div>
        );
    }
};


export function filterDependsOnPreset(
    { presetName, isBillboard = false },
    { presetId, name }
) {
    const isRemixPreset = presetName === "Remixes";
    const isIntroOutroPrest = presetName === "Intro / Outro";
    const isOriginTrack = presetId !== 14 && presetId !== 9;
    let result = isOriginTrack;

    if (isBillboard) {
        result = isOriginTrack;
    } else if (
        !presetName ||
        ["All", "Breakers", "DJ Tools", "Promo Albums"].includes(presetName)
    ) {
        result = true;
    } else if (isRemixPreset) {
        result = presetId === 9;
    } else if (isIntroOutroPrest) {
        result = presetId === 14;
    } else if (presetName === "Instrumentals") {
        result = isOriginTrack && name.includes("Instrumental");
    } else if (presetName === "Acapellas") {
        result = isOriginTrack && name.includes("Acapella");
    }

    return result;
}

export function getDefaultPresetId(presetName) {
    let result = 1;

    if (presetName === "Remixes") {
        result = 9;
    } else if (presetName === "Intro / Outro") {
        result = 14;
    }

    return { presetId: result };
}

export default renderAlbumTracks;
