import React, { useState, useContext, useEffect } from "react";
import PropTypes from "prop-types";
import _ from "lodash";

import { SubscriptionContext } from "context/SubscriptionContext";
import { TextField, CheckBoxField } from "components/common/form";
import t from "helpers/getText";
import {
    fieldProps,
    checkBoxFieldProps
} from "components/helpers/forms/formFieldHelper";
import validatePaymentFields from "./validatePaymentFields";
import { BaseDialog } from "components/common/Dialogs";
import { Button } from "components/common/ControlElems";

import styles from "./ProfileDialogs.module.scss";

Subscription.propTypes = {
    onClose: PropTypes.func.isRequired,
    isUpdateDialog: PropTypes.bool
};

export default function Subscription({
    onClose,
    isUpdateDialog,
    selectedOption
}) {
    const [fields, setFields] = useState({
        cardNumber: "",
        month: "",
        year: "",
        fname: "",
        lname: "",
        cvc: "",
        address: "",
        state: "",
        city: "",
        zipcode: "",
        country: "",
        email: "",
        phone: "",
        isAuthorizePayment: false,
        isTermsOfUse: false,
        option: selectedOption
    });

    const value = useContext(SubscriptionContext);
    const {
        subscribe,
        clearSubscription,
        subscriptionUserProcessing,
        subscriptionUserError,
        subscriptionUserResult
    } = value;

    const [isSubmit, setSubmit] = useState(false);

    const errors = isSubmit ? validatePaymentFields(fields) : {};

    useEffect(() => {
        if (subscriptionUserResult) {
            onClose();
        }

        return () => clearSubscription();
    }, [subscriptionUserResult]);

    let commonError = _.get(
        subscriptionUserError,
        "details.common.kind.errorMessage",
        null
    );
    // if (typeof commonError !== "string") commonError = "";

    return (
        <BaseDialog onClose={onClose} height="tallest" width="width">
            <h2 className={styles.header}>
                {t("subscription.setCreditCardHeader")}
            </h2>
            <form
                className={styles.container}
                onSubmit={handleSubmit.bind(this, {
                    setSubmit,
                    errors,
                    fields,
                    subscribe,
                    subscriptionUserProcessing
                })}
            >
                <TextField
                    fullWidth
                    lightMode
                    classes={{ root: styles.wide }}
                    label={t("subscription.cardNumber")}
                    controlled
                    autoFocus
                    {...fieldProps("cardNumber", "string", {
                        fields,
                        setFields: handleSetFields.bind(
                            this,
                            setFields,
                            "cardNumber"
                        ),
                        errors
                    })}
                />
                <div className={styles.center}>
                    <div className={styles.left}>
                        <TextField
                            lightMode
                            classes={{ root: styles.short }}
                            controlled
                            label={t("subscription.month")}
                            max={2}
                            {...fieldProps("month", "text", {
                                fields,
                                setFields: handleSetFields.bind(
                                    this,
                                    setFields,
                                    "month"
                                ),
                                errors
                            })}
                        />
                        <TextField
                            lightMode
                            classes={{ root: styles.short }}
                            controlled
                            label={t("subscription.year")}
                            max={2}
                            {...fieldProps("year", "text", {
                                fields,
                                setFields: handleSetFields.bind(
                                    this,
                                    setFields,
                                    "year"
                                ),
                                errors
                            })}
                        />
                    </div>
                    <div className={styles.right}>
                        <TextField
                            lightMode
                            classes={{ root: styles.normal }}
                            controlled
                            label={t("subscription.cvc")}
                            max={3}
                            {...fieldProps("cvc", "password", {
                                fields,
                                setFields: handleSetFields.bind(
                                    this,
                                    setFields,
                                    "cvc"
                                ),
                                errors
                            })}
                        />
                    </div>
                </div>
                <div className={styles["name-group"]}>
                    <div className={styles.left}>
                        <TextField
                            fullWidth
                            lightMode
                            classes={{ root: styles.normal }}
                            label={t("subscription.firstname")}
                            {...fieldProps("fname", "text", {
                                fields,
                                setFields,
                                errors
                            })}
                        />
                    </div>
                    <div className={styles.right}>
                        <TextField
                            fullWidth
                            lightMode
                            classes={{ root: styles.normal }}
                            label={t("subscription.lastname")}
                            {...fieldProps("lname", "text", {
                                fields,
                                setFields,
                                errors
                            })}
                        />
                    </div>
                </div>
                <TextField
                    fullWidth
                    lightMode
                    classes={{ root: styles.wide }}
                    label={t("subscription.address")}
                    {...fieldProps("address", "text", {
                        fields,
                        setFields,
                        errors
                    })}
                />
                <div className={styles.center}>
                    <div className={styles.left}>
                        <TextField
                            fullWidth
                            lightMode
                            classes={{ root: styles.normal }}
                            label={t("subscription.city")}
                            {...fieldProps("city", "text", {
                                fields,
                                setFields,
                                errors
                            })}
                        />
                    </div>
                    <div className={styles.right}>
                        <TextField
                            fullWidth
                            lightMode
                            classes={{ root: styles.normal }}
                            label={t("subscription.state")}
                            {...fieldProps("state", "text", {
                                fields,
                                setFields,
                                errors
                            })}
                        />
                    </div>
                </div>
                <div className={styles.center}>
                    <div className={styles.left}>
                        <TextField
                            fullWidth
                            lightMode
                            classes={{ root: styles.normal }}
                            label={t("subscription.zipcode")}
                            {...fieldProps("zipcode", "text", {
                                fields,
                                setFields,
                                errors
                            })}
                        />
                    </div>
                    <div className={styles.right}>
                        <TextField
                            fullWidth
                            lightMode
                            classes={{ root: styles.normal }}
                            label={t("subscription.country")}
                            {...fieldProps("country", "text", {
                                fields,
                                setFields,
                                errors
                            })}
                        />
                    </div>
                </div>
                <div className={styles.center}>
                    <div className={styles.left}>
                        <TextField
                            fullWidth
                            lightMode
                            classes={{ root: styles.normal }}
                            label={t("subscription.email")}
                            {...fieldProps("email", "text", {
                                fields,
                                setFields,
                                errors
                            })}
                        />
                    </div>
                    <div className={styles.right}>
                        <TextField
                            fullWidth
                            lightMode
                            classes={{ root: styles.normal }}
                            label={t("subscription.phone")}
                            {...fieldProps("phone", "text", {
                                fields,
                                setFields,
                                errors
                            })}
                        />
                    </div>
                </div>
                <div className={styles.commonError}>{commonError}</div>
                <div className={styles["checkbox-group"]}>
                    <CheckBoxField
                        classes={{
                            input: styles.input,
                            checked: styles.checked,
                            root: styles.root
                        }}
                        label={t("subscription.authorizeDesc")}
                        value={fields.isAuthorizePayment}
                        {...checkBoxFieldProps("isAuthorizePayment", {
                            fields,
                            setFields,
                            errors
                        })}
                    />
                    <CheckBoxField
                        classes={{
                            input: styles.input,
                            checked: styles.checked,
                            root: styles.root
                        }}
                        label={t("subscription.termsofuseDesc")}
                        value={fields.isTermsOfUse}
                        {...checkBoxFieldProps("isTermsOfUse", {
                            fields,
                            setFields,
                            errors
                        })}
                    />
                </div>
                <Button
                    color="bright"
                    width="14.5rem"
                    height="30px"
                    disabled={
                        subscriptionUserProcessing ||
                        !fields.isAuthorizePayment ||
                        !fields.isTermsOfUse ||
                        (subscriptionUserError && subscriptionUserError.hold)
                    }
                    label={getButtonLabel(
                        isUpdateDialog,
                        subscriptionUserProcessing
                    )}
                />
            </form>
        </BaseDialog>
    );
}

function getButtonLabel(isUpdateDialog, subscriptionUserProcessing) {
    let result = "";
    if (subscriptionUserProcessing) {
        result = t("subscription.buttons.processing");
    } else if (isUpdateDialog) {
        result = t("subscription.buttons.updateCard");
    } else {
        result = t("subscription.buttons.setCard");
    }

    return result;
}

function handleSetFields(setFields, key, value) {
    const newValue = { ...value };
    newValue[key] = value[key].replace(/[^0-9]+/g, "");
    if (key === "cardNumber") {
        let currentValue = newValue.cardNumber.substring(0, 16);
        currentValue = currentValue.match(/.{1,4}/g);
        if (currentValue !== null) {
            newValue.cardNumber = currentValue.join(" ");
        }
    }
    setFields(newValue);
}

function handleSubmit(
    { setSubmit, fields: rowFields, subscribe, subscriptionUserProcessing },
    e
) {
    e.preventDefault();
    setSubmit(true);
    const fields = { ...rowFields };
    fields.cardNumber = fields.cardNumber.replace(/[^0-9]+/g, "");
    if (
        !subscriptionUserProcessing &&
        Object.keys(validatePaymentFields(fields)).length === 0
    ) {
        subscribe(fields);
    }
}
