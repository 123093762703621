import moment from "moment";

const filterVersions = ({ activeLetter, track }) => {
    let result = splitInLists(track.versions, track);
    const { original, remix, introOutro } = result;
    switch (activeLetter) {
        case "O":
            result = { original };
            break;
        case "I":
            result = { introOutro };
            break;
        case "R":
            result = { remix };
            break;
        default:
            break;
    }
    return result;
};

export function sortByDate(list) {
    return list.sort((a, b) => +moment(b.released) - +moment(a.released));
}

export function checkVersionSuitForIntro(version) {
    let result = false;

    if (version.presetId === 14) {
        result = true;
    }

    return result;
}

export function checkVersionSuitForRemixFilter(version, elem) {
    let result = false;

    if (version.presetId === 9 || version.presetId === "9") {
        result = true;
    }

    return result;
}

export function splitInLists(list, track) {
    const lefted = [];
    const result = { original: [], remix: [], introOutro: [] };
    sortByDate(list).forEach(elem => {
        if (checkVersionSuitForRemixFilter(elem, track)) {
            result.remix.push(elem);
        } else {
            lefted.push(elem);
        }
    });

    lefted.forEach(elem => {
        if (checkVersionSuitForIntro(elem)) {
            result.introOutro.push(elem);
        } else {
            result.original.push(elem);
        }
    });

    return result;
}

export default filterVersions;
