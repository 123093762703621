const footer = {
    footer: {
        info: [
            {
                head: "Franchise Record Pool",
                text:
                    "Connecticut Office/Web Services P.O. Box 241 Norwalk, CT 06856"
            },
            {
                head: "General Info/Inquiry",
                text: ["Phone: 888-377-49-08", "E-mail: info@frp.live"]
            },
            {
                head: "Customer Service",
                text: "347-449-5129"
            }
        ],
        projects: ["FRP TV", "DJ On LIve", "Event Live", "WC Live"],
        rights: "© 2019. Franchise Record Pool. USA. All Rights Reserved.",
        phone: "347-449-5129",
        about: "About",
        pool: "The pool",
        benefits: "Label benefits",
        pricing: "Pricing",
        contact: "Contact",
        rotation: "Heavy Rotation",
        promoPackage: "PROMO PACKAGE",
        frpApps: "Frp Apps",
        frpLive: "FRP Live App",
        spins: "SPINS",
        wcLive: "WC LIVE",
        billboard: "Billboard top 100",
        frpTv: "FRP TV",
        labels: "Labels",
        splash: "Splash",
        agreement: "Agreement",
         disclaimerdmca: "Disclaimer & DMCA"
    }
};

export default footer;
