import React, { useContext, useMemo } from "react";
import routerDecorator from "components/helpers/withRouter";
import { ContentContext } from "../../../../context/ContentContext";
import { AuthContext } from "../../../../context/AuthContext";
import styles from "./Version.module.scss";

const Version = ({
  elem,
  i,
  artist,
  track,
  setShowRate,
  showRate,
  history,
  setActualVersion,
  isAnyVersionDownloaded,
  isAlbumTrack=false
}) => {
  const { downloadMedia } = useContext(ContentContext);
  const { user } = useContext(AuthContext);

  const doDownload = (downloadedElem) => {
    downloadMedia({
      mediaType: "audio",
      artist,
      mediaKey: downloadedElem.fileName,
      mediaName: track.name,
      id: track.id,
      version: downloadedElem.name,
      versionId: elem.id
    });
  };

  const onTryToDownload = () => {
    console.log(elem, 'track---------onTryToDownload-')
    if (user) {
      if(typeof track.tracks !== 'undefined'){
        if (elem.rating > 0) {
          console.log('onTryToDownload--1')
          doDownload(elem);
        } else {
          console.log(showRate,elem.TvId, 'onTryToDownload--2')
          setShowRate(elem.TvId);
          console.log(showRate,elem.TvId, 'onTryToDownload--3')
          setActualVersion(elem);
          console.log(showRate, 'onTryToDownload--4')
        }
      }else{
        if (track.rating > 0) {
          console.log('onTryToDownload--1')
          doDownload(elem);
        } else {
          console.log(showRate,elem.id,'onTryToDownload--2')
          setShowRate((prevState) =>
            prevState === elem.id ? null : elem.id
          );
          console.log(showRate,elem.id,'onTryToDownload--2')
          setActualVersion(elem);
        }
      }
    } else {
      history.push("/register");
    }
  };
  const className = useMemo(() => {
    if (!isAnyVersionDownloaded && !elem.isHightlighted) {
      return styles.defaultVersion;
    }
    if (elem.downloaded) {
      return styles.activeVersion;
    }
    if (
      elem.isHightlighted &&
      (!elem.downloadDate || elem.downloadDate < elem.created)
    ) {
      return styles.newVersion;
    }
    
    return styles.defaultVersion;
  }, [
    elem.downloaded,
    elem.isHightlighted,
    elem.downloadDate,
    elem.created,
    isAnyVersionDownloaded
  ]);
  return ((isAlbumTrack === false) && (<button key={elem.name + i} onClick={onTryToDownload} className={className}> <span>{elem.displayVersion || elem.name}</span> </button>)  );
};

export default routerDecorator(Version);
