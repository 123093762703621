import classnames from "classnames";
import rawStyles from "../TracksList.module.scss";
import React from "react";
import { PlayButton } from "../../ControlElems";
import TrackContent from "./TrackContent";
import { renderButtons } from "../Helpers";

const Track = ({
    activeLetter,
    track,
    index,
    size,
    showCover,
    presetName,
    released: versionReleased,
    superShortScrollbarRef,
    styles = rawStyles,
    isVersionsVisiable = false,
    isTrack= false,
    currentVersion
}) => {
    const { artist, genre, released: trackReleased, rank, bpm, key, mood } = track;

    const released = versionReleased || trackReleased;

    const classNameVersion = classnames(
        size === "longVersion" ? styles.trackVersion : ""
    );

    const trackName = getTrackName(track);
    const style = size === "longVersion" ? { zIndex: 100 - index } : {};
    return (
        <div
            style={style}
            className={classnames(
                styles.trackContainer,
                size === "longVersion" && styles.versionContainer,
                isVersionsVisiable && styles.isVersionsVisiable,
                classNameVersion
            )}
        >
            <div className={styles.index}>{index}</div>
            <div
                className={styles.playInfo}
                data-rank={size === "long" && showCover ? rank : ""}
            >
                <PlayButton
                    showCover={size === "long" && showCover}
                    presetName={presetName}
                    track={track}
                    className={styles.playIcon}
                    isTrack={true}
                    currentVersion={currentVersion}
                />
                <div className={styles.nameAndArtist}>
                    <span title={trackName}>{trackName}</span>
                    <span title={artist}>{artist}</span>
                    {size === "long" && renderButtons({
                        className: styles.buttons,
                        size,
                        track,
                        activeLetter,
                        superShortScrollbarRef,
                        index
                    })}
                
                </div>
            </div>
            <TrackContent
                bpm={bpm}
                mood={mood}
                genre={genre}
                musicKey={key}
                track={track}
                released={released}
                styles={styles}
                size={size}
                superShortScrollbarRef={superShortScrollbarRef}
                presetName={presetName}
                activeLetter={activeLetter}
                index={index}
            />
        </div>
    );
};

function getTrackName({ presetId, versions, name, id }) {
    let result = name;
    let subname = null;

    if (versions && versions.length > 0) {
        versions.find(version => {
            if (version.trackId === id) {
                subname = version.subname;
                //console.log(version, 'getTrackName---------version')
                return true;
            }
            return false;
        });
    }

    if ((presetId === 9) && subname) {
        result += ` (${subname})`;
    }else if (presetId === 14) {
        result += ` (Intro Outro)`;
    }
    return result;
}

export default React.memo(Track);
