import React, { useContext } from "react";
import { UIContext } from "context/UIContext";
import FeaturesList from "../Apps/FeaturesList";
import styles from "./Spin.module.scss";
import t from "helpers/getText";
import classNames from "classnames";

export default function FrpApp( props ) {
    const { theme } = useContext( UIContext );
    // const { text, head, backend } = props;
    return (
        <>
            <div className={ styles.container }>
                <div className={ styles.content }>
                    {/* bannerContentWrap */ }
                    <div className={ styles.bannerContentWrap }>
                        <h1 className={ styles.mainTitle } >Spins Desktop App Help</h1>
                        <p className={ styles.bannerTextField }>SPINS is a desktop app that allows DJs to search and download music while playing. Spins is also the companion to our mobile app which allows downloading from the app to the desktop or laptop. DJs can also interact with each other in private or global chats, share downloadable playlists, and get track recommendations based on preference history. Plus, it allows DJs to connect to the labels and artists by capturing the music when played live and alerting them when the music is played in real-time.
                        </p>
                    </div>
                    {/* bannerContentWrap close */ }
                    {/* stepListSection */ }
                    <div className={ classNames( styles.leftSection, styles.stepListTopSection ) }>
                        <div className={ styles.leftSection }>
                            <div className={ styles.secHeadingContent }>
                                <h4 className={ styles.secTitle }>Install And Run The SPINS Desktop APP</h4>
                            </div>
                            <div className={ styles.secList }>
                                <FeaturesList
                                    items={ t( "apps.frp.appList1" ) || [] }
                                />
                            </div>
                        </div>
                        <div className={ styles.rightSection }>
                            <img
                                className={ styles.icon }
                                src={
                                    theme === "light"
                                        ? require( `assets/images/frp-app-image1.png` )
                                        : require( `assets/images/frp-app-image1.png` )
                                }
                            />
                        </div>
                    </div>
                    <div className={ classNames( styles.stepListRightSection, styles.leftSection ) }>
                        <div className={ styles.rightSection }>
                            <img
                                className={ styles.imgWrap }
                                src={
                                    theme === "light"
                                        ? require( `assets/images/frp-app-image2.png` )
                                        : require( `assets/images/frp-app-image2.png` )
                                }
                            />
                        </div>
                        <div className={ styles.leftSection }>
                            <div className={ styles.secHeadingContent }>
                                <h4 className={ styles.secTitle }>Login And Select A Folder for tracks Download</h4>
                            </div>
                            <div className={ styles.secList }>
                                <FeaturesList
                                    items={ t( "apps.frp.appList2" ) || [] }
                                />
                            </div>
                        </div>
                    </div>
                    <div className={ styles.stepListSection }>
                        <div className={ styles.leftSection }>
                            <div className={ styles.secHeadingContent }>
                                <h4 className={ styles.secTitle }>Message Tab </h4>
                            </div>
                            <div className={ styles.secList }>
                                <FeaturesList
                                    items={ t( "apps.frp.appList3" ) || [] }
                                />
                            </div>
                        </div>
                        <div className={ styles.rightSection }>
                            <img
                                className={ styles.imgWrap }
                                src={
                                    theme === "light"
                                        ? require( `assets/images/frp-app-image3.png` )
                                        : require( `assets/images/frp-app-image3.png` )
                                }
                            />
                        </div>
                    </div>
                    <div className={ classNames( styles.stepListRightSection, styles.leftSection ) }>

                        <div className={ styles.rightSection }>
                            <img
                                className={ styles.imgWrap }
                                src={
                                    theme === "light"
                                        ? require( `assets/images/frp-app-image4.png` )
                                        : require( `assets/images/frp-app-image4.png` )
                                }
                            />
                        </div>
                        <div className={ styles.leftSection }>
                            <div className={ styles.secHeadingContent }>
                                <h4 className={ styles.secTitle }>My Gig List</h4>
                            </div>
                            <div className={ styles.secList }>
                                <FeaturesList
                                    items={ t( "apps.frp.appList4" ) || [] }
                                />
                            </div>
                        </div>
                    </div>
                    <div className={ styles.stepListSection }>
                        <div className={ styles.leftSection }>
                            <div className={ styles.secHeadingContent }>
                                <h4 className={ styles.secTitle }>Music Tab</h4>
                            </div>
                            <div className={ styles.secList }>
                                <FeaturesList
                                    items={ t( "apps.frp.appList5" ) || [] }
                                />
                            </div>
                        </div>
                        <div className={ styles.rightSection }>
                            <img
                                className={ styles.imgWrap }
                                src={
                                    theme === "light"
                                        ? require( `assets/images/frp-app-image5.png` )
                                        : require( `assets/images/frp-app-image5.png` )
                                }
                            />
                        </div>
                    </div>
                    <div className={ classNames( styles.stepListRightSection, styles.leftSection ) }>
                        <div className={ styles.rightSection }>
                            <img
                                className={ styles.imgWrap }
                                src={
                                    theme === "light"
                                        ? require( `assets/images/frp-app-image6.png` )
                                        : require( `assets/images/frp-app-image6.png` )
                                }
                            />
                        </div>
                        <div className={ styles.leftSection }>
                            <div className={ styles.secHeadingContent }>
                                <h4 className={ styles.secTitle }>My Downloads Tab</h4>
                            </div>
                            <div className={ styles.secList }>
                                <FeaturesList
                                    items={ t( "apps.frp.appList6" ) || [] }
                                />
                            </div>
                        </div>
                    </div>
                    <div className={ styles.stepListSection }>
                        <div className={ styles.leftSection }>
                            <div className={ styles.secHeadingContent }>
                                <h4 className={ styles.secTitle }>Profile Tab</h4>
                            </div>
                            <div className={ styles.secList }>
                                <FeaturesList
                                    items={ t( "apps.frp.appList7" ) || [] }
                                />
                            </div>
                        </div>
                        <div className={ styles.rightSection }>
                            <img
                                className={ styles.imgWrap }
                                src={
                                    theme === "light"
                                        ? require( `assets/images/frp-app-image7.png` )
                                        : require( `assets/images/frp-app-image7.png` )
                                }
                            />
                        </div>
                    </div>
                </div>
            </div>
            {/* faqSecWrap */ }
            <div className={ styles.faqSecWrap }>
                <div className={ styles.container }>
                    <div className={ styles.faqSectionTitle }>
                        <div className={ styles.headingTitle }>
                            <h2 className={ styles.secTitle }>FAQ</h2>
                        </div>
                    </div>
                    <div className={ styles.faqSectionContent }>
                        <div className={ styles.leftSection }>
                            <div className={ styles.secContent }>
                                <h3 className={ styles.secTitle }>What is SPINS Desktop App?</h3>
                                <p className={ styles.secContentText }>The SPINS desktop App allows DJs to search, download, manage and organize tracks on their laptop. The Desktop App is designed to conveniently allow DJs to access FRP Live’s music when playing live to quickly search, download and instantly play tracks by simply dragging and dropping the downloaded tracks from the SPINS window right into their DJ software. The SPINS Desktop App also allows DJs to interact with each other via a chat section.</p>

                            </div>
                            <div className={ styles.secContent }>
                                <h3 className={ styles.secTitle }>I get a "Windows protected your PC" message when trying to install the Spins Desktop App on my Windows laptop. What should I do?</h3>
                                <p className={ styles.secContentText }>This message is due to Windows built-in virus protection that blocks any unknown developers who are not registered with them similar to what Apple does. This is perfectly safe to bypass as we can assure you that our installation program is virus and malware-free. To bypass this message click on the "More info" link and select Run anyway.</p>

                            </div>
                            <div className={ styles.secContent }>
                                <h3 className={ styles.secTitle }>Where will the Spins Desktop App be located?</h3>
                                <p className={ styles.secContentText }>The Spins Desktop App will be a floating app that always stays on top of any other application on your computer. This is done so that it can stay in sight when DJing live so that you can quickly search and download tracks for instant play by dragging and dropping directly from the Spins Desktop App. You can even make it smaller via our mini mode icon located at the top right-hand corner so that it is not intrusive while in the mix.</p>

                            </div>
                            <div className={ styles.secContent }>
                                <h3 className={ styles.secTitle }>Can I change the folder location of the Spins Desktop App?</h3>
                                <p className={ styles.secContentText }>Yes. You can select where you want Spins to create the FRP-Downloads default folder by selecting the Folder icon at the top right-hand corner of the track listings.</p>
                            </div>
                            <div className={ styles.secContent }>
                                <h3 className={ styles.secTitle }>Can I run more than multiple Spins Desktop Apps at once?</h3>
                                <p className={ styles.secContentText }>Can I run more than multiple Spins Desktop Apps at once?</p>
                            </div>
                            <div className={ styles.secContent }>
                                <h3 className={ styles.secTitle }>Do I need the FRP Mobile App in order for the Desktop App to work?</h3>
                                <p className={ styles.secContentText }>No. The Spins Desktop app is a standalone application where you can search and download  via the Desktop app to any folder on the PC. You can chat with our Support reps and even other DJs logged in to the Spins Desktop App.</p>
                            </div>
                            <div className={ styles.secContent }>
                                <h3 className={ styles.secTitle }>Does SPINS actually record my conversations and do I have to worry about my privacy?</h3>
                                <p className={ styles.secContentText }>Absolutely not and absolutely not. Spins only listens to audio and the audio captured is translated into computer coded audio information which is matched against similar audio information in our database. In no way does Spins record conversations or audio in its raw form and save it as such. If this was the case it will have to be announced to you for legal reason and you will have to give consent to it.</p>
                            </div>
                            <div className={ styles.secContent }>
                                <h3 className={ styles.secTitle }>Who do I contact to report issues with the Mobile and Desktop Apps?</h3>
                                <p className={ styles.secContentText }>You can email Tech Support at techsupport@frp.live or call 347-449-5129.</p>
                            </div>
                        </div>
                        <div className={ styles.rightSection }>
                            <div className={ styles.secContent }>
                                <h3 className={ styles.secTitle }>Is there a separate login for the FRP LIVE Desktop App?</h3>
                                <p className={ styles.secContentText }>No, Your mobile login credentials are the same as your FRP website login credentials.</p>
                            </div>
                            <div className={ styles.secContent }>
                                <h3 className={ styles.secTitle }>I get a "SPINS can’t be opened" message when trying to open the Spins Desktop App on my Mac laptop. What should I do?</h3>
                                <p className={ styles.secContentText }>This message is due to Apple’s restrictions on outside developers. This is perfectly safe to bypass as we can assure you that our installation program is virus and malware-free. To bypass this message and run SPINS, click on the Apple logo on the top left hand corner of the screen, select System Preferences, Security & Privacy, select the General tab. At the bottom of the screen you will see a notice that says “SPINS was blocked from use because it is not from an identified developer” and next to it is a button that says Open Anyway. Click that button and Open again on the pop up. This will open SPINS and allow you to open SPINS from the Launchpad from that point on.</p>
                            </div>
                            <div className={ styles.secContent }>
                                <h3 className={ styles.secTitle }>I Can't find the music I just downloaded. Where does the music go?</h3>
                                <p className={ styles.secContentText }>The Spins Desktop App creates a default folder called "FRP-Downloads" in your "Documents" folder. In Windows, when you open a new File explorer, click on This PC on the left column and you will see the Documents folder in the folders list at the top. In IOS, when you open a new Finder window you will see the Documents folder in the column list on the left under Favorites.</p>
                            </div>
                            <div className={ styles.secContent }>
                                <h3 className={ styles.secTitle }>Do I need to stay logged in to the Spins Desktop App all the time to download music?</h3>
                                <p className={ styles.secContentText }>No. You can actively initiate downloads via the app when you are on the go and whenever you log in to the Spins Desktop app, all the tracks you downloaded via the Mobile App will be downloaded instantly to the computer, including all folders you created. This means you do not need to keep your computer running all the time.</p>
                            </div>
                            <div className={ styles.secContent }>
                                <h3 className={ styles.secTitle }>Can I use SPINS to receive shoutouts or song requests from my audience?</h3>
                                <p className={ styles.secContentText }>Most certainly. To use this feature you will simply have your audience download the SPINS Mobile App and log into the Guest section. They will be able to browse the track selections and send that track directly to you in the chat. The track will be listed with download functionality to drag and drop directly into your DJ software for instant play. Your audience can simply send their shouts as a text in the chat as well. No more annoying audiences in your DJ booth.</p>
                            </div>
                            <div className={ styles.secContent }>
                                <h3 className={ styles.secTitle }>Why do SPINS ask to give access to my microphone?</h3>
                                <p className={ styles.secContentText }>Spins uses the microphone to figure out the tracks you are playing for a variety of reasons that will be beneficial to you as a member and a DJ. Understanding what you play will allow Spins to precisely recommend tracks to you based on your preferences. You will be able to share and view other DJ's playlist which will be beneficial when needing to know what music is trending in any parts of the world. Last but not least, this will let the labels know that you are playing their music, giving you the respect, recognition and connection you as a DJ deserves. Spins will be bringing you to the forefront of the most important part of the music cycle......music promotions.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* faqSecWrap Close*/ }
        </>
    );
}
