import React from "react";
import PropTypes from "prop-types";

DarkTheme.propTypes = {
    className: PropTypes.string,
    color: PropTypes.string,
    isActive: PropTypes.bool
};

DarkTheme.defaultProps = {
    className: "",
    color: "#0E1521",
    isActive: false
};

export default function DarkTheme(props) {
    const { color, className, isActive } = props;
    return (
        <svg className={className} width="9" height="12" viewBox="0 0 9 12">
            <path
                fill={color}
                fillRule="evenodd"
                d="M9 11.195A6 6 0 1 1 6 0c1.095 0 2.12.291 3 .805a5.987 5.987 0 0 0-2.999 5.194c0 2.22 1.206 4.16 3 5.196"
                opacity={isActive ? "1" : ".2"}
            />
        </svg>
    );
}
