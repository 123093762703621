import React, { useContext, useEffect, useCallback } from "react";
import styles from "./Billing.module.scss";
import { ContentContext } from "context/ContentContext";
import { AuthContext } from "context/AuthContext";
import PropTypes from "prop-types";
import { Paginator } from "components/common/ControlElems";
import LoaderPlaceholder from "components/common/LoaderPlaceholder/LoaderPlaceholder";
import download from "../../assets/images/download.svg";
import downloadWhite from "../../assets/images/download-white.svg";
import html2canvas from 'html2canvas';
import moment from "moment";
import jsPDF from 'jspdf';
import Bill from './bill';

Billing.propTypes = {
    limit: PropTypes.number.isRequired,
    offset: PropTypes.number.isRequired,
    count: PropTypes.number.isRequired,
    containerRef: PropTypes.any,
    requestTracks: PropTypes.func.isRequired,
    hideHeaders: PropTypes.bool,
    hidePaginator: PropTypes.bool,
};

Billing.defaultProps = {
    containerRef: null,
    hideHeaders: false,
    hidePaginator: false,
};

export default function Billing(limit) {
    const { user } = useContext(AuthContext);
    const DEFAULT_TRANS_FILTER = {
        id: null,
        transactionId: null,
        type: "",
        startDate: "1970-01-01",
        endDate: new Date().toISOString(),
        transactionStatus: "",
        invoiceNumber: "",
        lastFourCardDigit: "",
        amount: "",
        subscriptionId: "",
        userId: user.id,
        offset: parseInt(offset) || 0,
        limit: parseInt(limit) || 5,
    };

    let count = 0;
    const { fetchDjTransactionHistory, result, requestBillingProcessing, offset } = useContext(ContentContext);

    useEffect(() => {
        fetchDjTransactionHistory(DEFAULT_TRANS_FILTER);
    }, []);

    if (result !== undefined) {
        count = result.count;
    }

    const downloadPDF = (transactionId) => {
        html2canvas(document.querySelector(`#capture-${transactionId}`)).then(canvas => {
            const imgData = canvas.toDataURL('image/jpeg', 1.0);
            const pdf = new jsPDF('p', 'mm', 'a4');
            const imgProps = pdf.getImageProperties(imgData);
            const pdfWidth = (pdf.internal.pageSize.getWidth()) - 20;
            const pdfHeight = ((imgProps.height * pdfWidth) / imgProps.width) - 20;

            pdf.addImage(imgData, 'JPG', 10, 10, pdfWidth, pdfHeight);
            pdf.save('invoice.pdf');
        });
    };

    const onRequest = useCallback(
        (params) => fetchDjTransactionHistory({
            ...params,
            isBillingTransactions: true,
            userId: DEFAULT_TRANS_FILTER.userId,
            limit: DEFAULT_TRANS_FILTER.limit,
            startDate: DEFAULT_TRANS_FILTER.startDate,
            endDate: DEFAULT_TRANS_FILTER.endDate
        }),
        [result]
    );

    const mystyle = { maxWidth: "1180px", paddingTop: "0px", margin: "auto", width: "auto" };

    return (
        <div className="table_container" style={mystyle}>
            <div className={styles.container}>
                <div className={styles.content}>
                    <div style={{ width: "100%" }}>
                        <Paginator
                            request={onRequest}
                            count={count}
                            limit={DEFAULT_TRANS_FILTER.limit}
                            offset={offset}
                        />
                    </div>
                    <div className={styles.ColumnContainer}>
                        <div className={styles.trasactionsTableHeader}>
                            <div className={`${styles.dHeading} ${styles.transId}`}>TransId</div>
                            <div className={`${styles.dHeading} ${styles.submitDate}`}>Payment Date</div>
                            <div className={`${styles.dHeading} ${styles.email}`}>Email</div>
                            <div className={`${styles.dHeading} ${styles.type}`}>Payment Via</div>
                            <div className={`${styles.dHeading} ${styles.amount}`}>Amount</div>
                            <div className={`${styles.dHeading} ${styles.invoice}`}>Invoice</div>
                        </div>

                        {requestBillingProcessing && (
                            <div className={styles.loader}>
                                <LoaderPlaceholder />
                            </div>
                        )}

                        {(result !== undefined) && (requestBillingProcessing === false) && (result.count === 0) && (
                            <div style={{ width: '100%', paddingTop: '20px' }}>
                                No data found
                            </div>
                        )}

                        {(result !== undefined) && (result.list.length > 0) && (requestBillingProcessing === false) && (
                            result.list.map((el, index) => {
                                return (
                                    <div className={styles.trasactionsTablebody} key={el.transactionId}>
                                        <div className={`${styles.dText} ${styles.transId}`}>#{el.transactionId}</div>
                                        <div className={`${styles.dText} ${styles.submitDate}`}>{moment(el.submitDate).format("DD/MM/YYYY")}</div>
                                        <div className={`${styles.dText} ${styles.email}`}>{el.email}</div>
                                        <div className={`${styles.dText} ${styles.type}`}>{el.type}</div>
                                        <div className={`${styles.dText} ${styles.amount}`}>{el.amount}</div>
                                        <div className={`${styles.dText} ${styles.invoice}`}>
                                            <div id={`capture-${el.transactionId}`} className={styles.capturehide}>
                                                <Bill data={el} />
                                            </div>
                                            <button onClick={() => downloadPDF(el.transactionId)} style={{ background: 'none', border: 'none' }}>
                                                <img className="download-dark-icon" src={download} alt="download" />
                                                <img className="download-white-icon" src={downloadWhite} alt="download" />
                                            </button>
                                        </div>
                                    </div>
                                );
                            })
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
}
