import React, { useContext, useEffect } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import styles from "./Banner.module.scss";
import { ContentContext } from "context/ContentContext";
import { NavLink } from "react-router-dom";

const PrevArrow = ({ className, style, onClick }) => (
    <div className={`${className} ${styles.slickArrowPrev}`} style={{ ...style, display: "block" }} onClick={onClick}>
        <svg width="42" height="26" viewBox="0 0 8 12"><path fill="none" fillRule="evenodd" stroke="#FFFFFF" strokeWidth="1.5" d="M7.243 1L2 6.243l5.243 5.242"></path></svg>
    </div>
);

const NextArrow = ({ className, style, onClick }) => (
    <div className={`${className} ${styles.slickArrowNext}`} style={{ ...style, display: "block" }} onClick={onClick}>
        <svg width="42" height="26" viewBox="0 0 8 12"><path fill="none" fillRule="evenodd" stroke="#FFFFFF" strokeWidth="1.5" d="M1 1l5.243 5.243L1 11.485"></path></svg>
    </div>
);

const Banner = () => {
    const { fetchBanners, banners, requestTracksWithRetry, requestAlbumsWithRetry, setBannerRequest, resetQuery } = useContext(ContentContext);

    useEffect(() => {
        const fetchData = async () => {
            if (!banners) {
                await fetchBanners();
            }
        };
        fetchData();
    }, [banners, fetchBanners]);

    const settings = {
        centerMode: true,
        infinite: true,
        centerPadding: "0px",
        slidesToShow: 1,
        speed: 1000,
        autoplay: true,
        draggable: false,
        arrows: true,
        nextArrow: <NextArrow />,
        prevArrow: <PrevArrow />,
        autoplaySpeed: 10000,
        fade: true         // Enable fade effect
    };

    const handleBannerClick = async (b) => {
        if(b.bannerType === 'url') {
            if (b.targetBlank) {
                window.open(b.bannerUrl, '_blank');
            } else {
                window.location.href = b.bannerUrl;
            }
        }else if(b.bannerType === 'tracks'){
            await resetQuery();
            setBannerRequest(b.id);
            requestTracksWithRetry([
                {
                    "needToResetList": true,
                    "commonParams": {
                        "sortField": "createdAt",
                        "type": "audio"
                    }
                }
            ]);
        }
        else if (b.bannerType === 'album') {
          //  console.log('in album section');
            await resetQuery();
            setBannerRequest(b.id);
            requestAlbumsWithRetry([
                {
                    "needToResetList": true,
                    "commonParams": {
                        "sortField": "createdAt",
                        "type": "audio",
                        "bannerType": "album"
                    }
                }
            ]);
        }
       // console.log(b);
    };
  //  console.log(banners,'banners----------------------');
    
    return (
        <div className={styles.slick__slider}>
            <Slider {...settings}>
                {banners && banners.length > 0 && banners.sort((a, b) => a.bannerOrder - b.bannerOrder).map(b => (
                    <div key={b.id} onClick={() => handleBannerClick(b)}>
                        <NavLink to={`/tracks/all`} key={`/tracks/all`}>
                            <img alt={b.title} src={b.banner} />
                            {/* <span>{b.bannerOrder}</span> */}
                        </NavLink>
                    </div>
                ))}
            </Slider>
        </div>
    );
};

export default React.memo(Banner);
