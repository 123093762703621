import React, { useContext, useEffect, useState } from "react";
import t from "helpers/getText";
import routerDecorator from "components/helpers/withRouter";
import styles from "./Personal.module.scss";
import TextField from "../common/form/TextField";
import { Button } from "../common/ControlElems";
import classnames from "classnames";
import { AuthContext } from "context/AuthContext";
import { fieldProps } from "components/helpers/forms/formFieldHelper";
import validatePasswordProfile from "./validatePasswordProfile";
import AlertDialog from "components/common/Dialogs/AlertDialog";
import parseServerValidationErrors from "sources/api/parseServerValidationErrors";

function DJInfo() {
    const {
        user,
        updatePassword,
        updatePasswordError,
        updatePasswordResult
    } = useContext(AuthContext);
    const [touched, setTouched] = useState(false);
    const [wasSubmit, setWasSubmit] = useState(false);
    const [wasUpdated, setWasUpdated] = useState(false);
    const [updateResult, setUpdateResult] = useState(null);
    const [fields, setFields] = useState({
        email: user.email,
        userId: user.id,
        oldPassword: "",
        newPassword: "",
        confirmPassword: ""
    });
    const serverErrors = parseServerValidationErrors(updatePasswordError);
    const clientErrors = wasSubmit ? validatePasswordProfile(fields) : {};
    const errors = Object.assign({}, clientErrors, serverErrors);
    useEffect(() => {
        if (wasSubmit && updatePasswordResult) {
            setUpdateResult(t("profile.labels.subtitles.onChangePassword"));
            setTouched(false);
            setWasUpdated(true);
        }
    }, [updatePasswordResult]);
    return (
        <div className={styles.container}>
            <div className={styles.center}>
                <TextField
                    type="password"
                    label={t("profile.labels.fields.oldPassword")}
                    value={fields.oldPassword}
                    {...fieldProps("oldPassword", "password", {
                        fields,
                        setFields,
                        errors,
                        setTouched
                    })}
                />
                <TextField
                    type="password"
                    label={t("profile.labels.fields.newPassword")}
                    value={fields.newPassword}
                    {...fieldProps("newPassword", "password", {
                        fields,
                        setFields,
                        errors,
                        setTouched
                    })}
                />
                <TextField
                    type="password"
                    label={t("profile.labels.fields.confirmPassword")}
                    value={fields.oldPassword}
                    {...fieldProps("confirmPassword", "password", {
                        fields,
                        setFields,
                        errors,
                        setTouched
                    })}
                />
            </div>
            <div className={classnames(styles.center, styles.button)}>
                <Button
                    disabled={!touched}
                    color={touched ? "bright" : "white"}
                    label={t("profile.labels.buttons.changePassword")}
                    onClick={() => {
                        saveChanges({
                            fields,
                            setFields,
                            updatePassword,
                            setWasSubmit
                        });
                    }}
                />
            </div>
            {wasUpdated && (
                <AlertDialog onClose={setWasUpdated}>
                    {updateResult}
                </AlertDialog>
            )}
        </div>
    );
}

async function saveChanges({ fields, updatePassword, setWasSubmit }) {
    setWasSubmit(true);
    const errors = validatePasswordProfile(fields);
    if (Object.keys(errors).length === 0) updatePassword(fields);
}

export default routerDecorator(DJInfo);
