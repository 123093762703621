import React from "react";
import PropTypes from "prop-types";

List.propTypes = {
    className: PropTypes.string,
    color: PropTypes.string,
    activeColor: PropTypes.string,
    isActive: PropTypes.bool
};

List.defaultProps = {
    className: "",
    isActive: false,
    color: "rgba(255,255,255, 0.3)",
    activeColor: "white"
};

export default function List(props) {
    const { color, activeColor, className, isActive } = props;
    return (
        <svg className={className} width="21" height="20" viewBox="0 0 16 12">
            <g fill={isActive ? activeColor : color} fillRule="evenodd">
                <path d="M5 0h11v2H5zM0 0h2v2H0zM0 10h2v2H0zM5 5h11v2H5zM5 10h11v2H5zM.008 4.5l2.998 1.502L.008 7.505z" />
            </g>
        </svg>
    );
}
