import React from "react";
import PropTypes from "prop-types";

Mute.propTypes = {
    className: PropTypes.string,
    color: PropTypes.string
};

Mute.defaultProps = {
    className: "",
    color: "rgba(255,255,255, 0.3)"
};

export default function Mute(props) {
    const { color, className } = props;
    return (
        <svg className={className} width="24" height="24" viewBox="0 0 24 24">
            <g fill={color} fillRule="evenodd">
                <path d="M6.3 15.6H2V7.2h4.3L14 3v16.8l-7.7-4.2zm.6-1.267l5.7 3.109V5.358l-5.7 3.11v5.865zM5.5 8.6H3.4v5.6h2.1V8.6z" />
            </g>
        </svg>
    );
}
