import React, { useContext } from "react";
import PropTypes from "prop-types";
import { ContentContext } from "context/ContentContext";
Play.propTypes = {
    lineColor: PropTypes.string,
    playColor: PropTypes.string,
    className: PropTypes.string,
    isActive: PropTypes.bool,
    isPaused: PropTypes.bool,
    opacity: PropTypes.number,
    track: PropTypes.any,
    isTrack: PropTypes.bool
};

Play.defaultProps = {
    lineColor: "var(--theme-changed-color)",
    playColor: "black",
    stopColor: "#213044",
    className: "",
    isActive: false,
    isPaused: false,
    opacity: 1,
    isTrack: false,
    currentVersion: 0,
};

export default function Play(props) {
    const {
        lineColor,
        playColor,
        stopColor,
        className,
        isActive,
        isPaused,
        opacity,
        track,
        isTrack,
        currentVersion
    } = props;
    const { choosenTrack } = useContext(
        ContentContext
    );
    let isPlaying = false;
    if (track && track.id && choosenTrack.track && choosenTrack.track.id && currentVersion !== 0) {
        isPlaying = track.id === choosenTrack.track.id && choosenTrack.track.versionId === currentVersion && choosenTrack.isPlaying;
    }

    let result = (
        <svg className={className} width="82" height="82" viewBox="0 0 82 82">
            <g fill="none" fillRule="evenodd" transform="translate(1 1)">
                <circle
                    cx="40"
                    cy="40"
                    stroke={lineColor}
                    strokeWidth={3}
                    r="40"
                    opacity={opacity}
                />
                <path
                    fill={playColor}
                    d="M53.96 41.104L31.643 53.278a1 1 0 0 1-1.48-.878V28.052a1 1 0 0 1 1.48-.878L53.96 39.348a1 1 0 0 1 0 1.756z"
                    opacity={opacity}
                />
            </g>
        </svg>
    );
    if (isActive || (isPlaying && isTrack)) {
        result = (
            <svg
                className={className}
                width="64"
                height="64"
                viewBox="0 0 64 64"
            >
                <g fill="none" fillRule="evenodd">
                    <circle cx="32" cy="32" r="32" fill={playColor} />
                    <path
                        fill={stopColor}
                        stroke={stopColor}
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M24 23h4v18h-4zM36 23h4v18h-4z"
                    />
                </g>
            </svg>
        );
    }
    if (isPaused) {
        result = (
            <svg
                className={className}
                width="82"
                height="82"
                viewBox="0 0 82 82"
            >
                <g fill="none" fillRule="evenodd" transform="translate(1 1)">
                    <circle
                        cx="40"
                        cy="40"
                        fill={playColor}
                        stroke={playColor}
                        strokeWidth={1.4}
                        r="40"
                        opacity={opacity}
                    />
                    <path
                        fill={stopColor}
                        d="M53.96 41.104L31.643 53.278a1 1 0 0 1-1.48-.878V28.052a1 1 0 0 1 1.48-.878L53.96 39.348a1 1 0 0 1 0 1.756z"
                        opacity={opacity}
                    />
                </g>
            </svg>
        );
    }
    return result;
}
