import React, { useContext, useEffect } from "react";
import PropTypes from "prop-types";
import { UserRegisterContext } from "context/UserRegisterContext";
import { parseServerErrorKey } from "sources/api/parseServerError";

export default function ConfirmEmailPage(props) {
    const { confirmEmailError, confirmEmail } = useContext(UserRegisterContext);

    useConfirmEmailOnMount({ props, confirmEmail });

    if (confirmEmailError) return renderCannotConfirmEmail(confirmEmailError);

    return <div>Processing...</div>;
}

function useConfirmEmailOnMount({ props, confirmEmail }) {
    useEffect(() => {
        const { location } = props;
        const urlSearchParams = new URLSearchParams(location.search);

        const userId = urlSearchParams.get("uid");
        const token = urlSearchParams.get("token");
        confirmEmail(userId, token);
    }, []);
}

function renderCannotConfirmEmail(confirmEmailError) {
    const errorKey = parseServerErrorKey(confirmEmailError);

    if (errorKey === "email-already-verified")
        return <div>Email already verified</div>;

    return <div>Cannot confirm email</div>;
}

ConfirmEmailPage.propTypes = {};
