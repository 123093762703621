import React from "react";
import PropTypes from "prop-types";

Star.propTypes = {
    isActive: PropTypes.bool,
    backColor: PropTypes.string,
    fillColor: PropTypes.string,
    className: PropTypes.string,
    onClick: PropTypes.func,
    value: PropTypes.string
};

Star.defaultProps = {
    backColor: "#0d1c2d",
    activeColor: "#ffffff",
    value: "0%",
    className: "",
    isActive: false
};

export default function Star(props) {
    const { backColor, activeColor, className, isActive, onClick } = props;
    return (
        <svg
            className={className}
            width="24"
            height="24"
            viewBox="0 0 26 30"
            onClick={onClick}
        >
            <g fill={isActive ? activeColor : backColor} fillRule="evenodd">
                <path
                    stroke={isActive ? activeColor : backColor}
                    style={{ transition: "300ms ease", cursor: "pointer" }}
                    strokeWidth="1.4"
                    strokeLinejoin="bevel"
                    d="M11.633 18.289l-7.19 4.024 1.606-8.081L0 8.638l8.182-.97 3.45-7.482 3.452 7.482 8.182.97-6.05 5.594 1.606 8.081z"
                />
            </g>
        </svg>
    );
}
