import React, { useState } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import t from "helpers/getText";
import styles from "./InnerLinks.module.scss";
import { Button } from "./index";

InnerLinks.propTypes = {
    links: PropTypes.array.isRequired,
    className: PropTypes.string,
    prefix: PropTypes.string,
    postfix: PropTypes.string,
    type: PropTypes.oneOf(["cross", "underline"]),
    setLink: PropTypes.func
};

InnerLinks.defaultProps = {
    type: "underline"
};

export default function InnerLinks(props) {
    const { links, className, prefix, postfix, type, setLink } = props;
    const [showLine, setShowLine] = useState(links[0]);
    return (
        <div className={classnames(styles.links, className)}>
            {links.map(link => {
                let linkName = prefix ? `${prefix}.${link}` : link;
                if (postfix) linkName += `.${postfix}`;
                const classes = classnames(
                    styles.link,
                    styles.extraMargin,
                    styles[type],
                    link === showLine && styles.activeLink
                );
                return (
                    <Button
                        key={link}
                        withoutBorder
                        className={classes}
                        onClick={() => {
                            setShowLine(link);
                            setLink(link);
                        }}
                    >
                        {t(linkName)}
                        <div className={styles.line} />
                    </Button>
                );
            })}
        </div>
    );
}
