import React from "react";
import { Apps } from "components/Apps";

export default function AppsPage(props) {
    const {
        match: {
            params: { type }
        }
    } = props;
    return <Apps page={type} />;
}
