import React, { useContext } from "react";
import classnames from "classnames";
import t from "helpers/getText";
import { UIContext } from "context/UIContext";
import styles from "./StaticPages.module.scss";

export default function DisclaimerDmca() {
    const { theme } = useContext(UIContext);
    var liData = t("disclaimerdmca.li");  
    return (
        <div
            className={classnames(
                styles.container,
                styles.aboutUsBack,
                styles[theme]
            )}
        >
            <section className={styles.content}>
                <h1>{t(`disclaimerdmca.header`)}</h1>
                <div className={styles.text}>
                  <span className={styles.text}>
                    {t("disclaimerdmca.text")}
                    <a href='mailto:{t("disclaimerdmca.linkurl")}'>{t("disclaimerdmca.linkTxt")}</a>
                    {t("disclaimerdmca.text3")} 
                </span>
                    <ul>
                    {
                        liData.map((item, index) => 
                            <>
                                <li>
                                    {item}
                                </li>
                            </>
                        )
                    }
                  </ul>

                  <span className={styles.text}>{t("disclaimerdmca.text2")}</span> 
                </div>
            </section>
        </div>
    );
}
