import React from "react";
import PropTyps from "prop-types";
import { Paginator } from "components/common/ControlElems";
import { TracksList } from "components/common/TracksList";

Audios.propTypes = {
    count: PropTyps.number,
    requestTracks: PropTyps.func.isRequired,
    artistId: PropTyps.number.isRequired,
    limit: PropTyps.number.isRequired,
    offset: PropTyps.number.isRequired
};

Audios.defaultProps = {
    count: 0
};

function Audios({ count, requestTracks, artistId, limit, offset }) {
    return (
        <>
            <TracksList />
            {count > limit && (
                <Paginator
                    key={3}
                    request={params =>
                        requestTracks({
                            typeParams: { ...params },
                            commonParams: { type: "audio", artistId }
                        })
                    }
                    count={count}
                    limit={limit}
                    offset={offset}
                />
            )}
        </>
    );
}

export default React.memo(Audios);
