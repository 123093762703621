import React from "react";
import { Route } from "react-router-dom";
import LoginPage from "components/login/LoginPage";
import ResetPasswordPage from "components/login/ResetPasswordPage";
import ConfirmResetPasswordPage from "components/login/ConfirmResetPasswordPage";
import UserRegisterPage from "components/userRegister/UserRegisterPage";
import ConfirmEmailPage from "components/userRegister/ConfirmEmailPage";
import SplashPage from "components/pages/SplashPage";
// import HomePage from "components/pages/HomePage";
import newHome from "components/pages/newHome";
import spin from "components/pages/Spin";
import frpApp from "components/pages/FrpApp";
// import LabelPage from "../components/pages/LabelPage";
import RotationPage from "components/pages/RotationPage";
import ArtistPage from "components/pages/ArtistPage";
import RedirectPage from "components/pages/RedirectPage";
import AppsPage from "components/pages/AppsPage";

export default function GuestRoutes() {
    return [
        <Route exact path="/" component={ SplashPage } />,
        <Route exact path="/home" component={ newHome } />,
        <Route exact path="/home/:info" component={ newHome } />,
        <Route exact path="/register" component={ UserRegisterPage } />,
        <Route exact path="/login" component={ LoginPage } />,
        <Route exact path="/rotation" component={ RotationPage } />,
        <Route exact path="/rotation/artist/:id" component={ ArtistPage } />,
        // <Route path="/labels" component={LabelPage} />, No need anymore
        <Route exact path="/confirmEmail" component={ ConfirmEmailPage } />,
        <Route exact path="/resetPassword" component={ ResetPasswordPage } />,
        <Route exact path="/newHome" component={ newHome } />,
        <Route exact path="/spins-guide" component={ spin } />,
        <Route exact path="/apps/:type?" component={AppsPage} />,
        <Route exact path="/app-guide" component={ frpApp } />,
        <Route exact path="/redirect" component={ RedirectPage } />,
        <Route
            exact
            path="/confirmResetPassword"
            component={ ConfirmResetPasswordPage }
        />
    ];
}

GuestRoutes.propTypes = {};
