import React from "react";
import PropTypes from "prop-types";

RightArrow.propTypes = {
    color: PropTypes.string
};

RightArrow.defaultProps = {
    color: "#fff"
};

export default function RightArrow({ color }) {
    return (
        <svg width="40" height="40" viewBox="0 0 40 40">
            <g fill="none" fillRule="evenodd">
                <circle cx="20" cy="20" r="19.5" stroke={color} opacity=".3" />
                <path
                    fill={color}
                    d="M25.19 21H11v-2h14.19l-4.659-4.659 1.415-1.414 5.658 5.659 1.415 1.413-7.071 7.071-1.415-1.414L25.19 21z"
                />
            </g>
        </svg>
    );
}
