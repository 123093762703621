const rotation = {
    rotation: {
        header: "FRP Heavy Rotation",
        artist: {
            subHeader: "HEAVY ROTATION",
            tracks: "Tracks",
            videos: "Videos",
            noTrack: "No tracks",
            noAlbum: "No albums",
            noVideo: "No videos",
            notExist: "THIS ARTIST DOESN'T EXIST"
        }
    }
};

export default rotation;
