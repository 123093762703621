import React, { useContext, useState, useEffect } from "react";
import PropTypes from "prop-types";
import { NavLink } from "react-router-dom";
import classnames from "classnames";
import t from "helpers/getText";
import styles from "./Links.module.scss";
import { ContentContext } from "context/ContentContext";
Links.propTypes = {
    links: PropTypes.array.isRequired,
    className: PropTypes.string,
    prefix: PropTypes.string,
    type: PropTypes.oneOf(["cross", "underline"]),
    linePosition: PropTypes.oneOf(["above", "below"])
};

Links.defaultProps = {
    type: "underline",
    linePosition: "above"
};

export default function Links(props) {
    const {
        links,
        className,
        prefix,
        type,
        linePosition,
        withoutUnderline,
        callbackAfterClick
    } = props;
    const [showLine, setShowLine] = useState(false);
    const { resetBannerRequest } = useContext(ContentContext);

    useEffect(() => setShowLine(true), []);

    const handleMusicClick = () =>{
        resetBannerRequest();
    };

    return (
        <nav
            className={classnames(
                styles.links,
                styles[linePosition],
                className
            )}
        >
            {links.map(link => {
                return (
                    <NavLink
                        to={`${link}`}
                        key={link}
                        isActive={(match, location) =>
                            isActiveLink(location, link, links)
                        }
                        className={classnames(styles.link, styles[type])}
                        activeClassName={showLine ? styles.activeLink : ""}
                    >
                        <span
                            onClick={() => {
                                const text = t(`${prefix}.${link}`);
                                if (text === 'Music') {
                                    // Call a different callback when the text is 'Music'
                                    handleMusicClick();
                                } else if (callbackAfterClick) {
                                    // Call the original callback if it is provided
                                    callbackAfterClick();
                                }
                            }}
                        >
                            {t(`${prefix}.${link}`)}
                        </span>

                        {!withoutUnderline && (
                            <div
                                className={classnames(
                                    styles.line,
                                    styles[linePosition]
                                )}
                            />
                        )}
                    </NavLink>
                );
            })}
        </nav>
    );
}

function isActiveLink(location, link, links) {
    const { pathname } = location;
    let result = pathname.includes(link);

    if (links.includes(pathname)) {
        result = link === pathname;
    }
    return result;
}
