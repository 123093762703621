import AbstractFieldsValidator from "components/helpers/validation/AbstractFieldsValidator";
import validatePassword from "components/helpers/validation/validatePassword";

export default function(fields, options) {
    return new RegisterFieldsValidator(fields, options).validate();
}

class RegisterFieldsValidator extends AbstractFieldsValidator {
    doCheckForSingleValue() {
        const {
            fullName,
            login,
            email,
            password,
            phone,
            confirmPassword
        } = this.fields;

        if (!fullName.trim())
            this.addError("fullName", "Please type your full name");

        if (!login.trim()) this.addError("login", "Please type your login");

        if (!email.trim()) this.addError("email", "Please type your email");

        if (!phone.trim()) this.addError("phone", "Please type your phone");

        const passwordError = validatePassword(password);
        if (passwordError) this.addError("password", passwordError);

        if (!confirmPassword.trim())
            this.addError("confirmPassword", "Please repeat your password");
    }

    doCheckForRelations() {
        const { password, confirmPassword } = this.fields;

        if (password !== confirmPassword)
            this.addError("confirmPassword", "Passwords do not match");
    }

    doCheckForEmailAndPhone() {
        const { email, phone } = this.fields;
        const regularEmail = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        const regularPhone = /^(\+)?(\d[\(\)\-\s]?){4,12}\d$/;
        if (!regularEmail.test(email.trim()))
            this.addError("email", "Email is not valid");
        if (!regularPhone.test(phone.trim()))
            this.addError("phone", "Phone is not valid");
    }
}
