import React from "react";
import { Scrollbars } from "react-custom-scrollbars";
import classnames from "classnames";

import styles from "./Scrollbar.module.scss";

export default function Scrollbar(props) {
    const { scrollbarRef, withoutZIndex, maxHeight, ...restProps } = props;
    return (
        <Scrollbars
            ref={scrollbarRef}
            className={classnames(
                styles.scrollbar,
                maxHeight && styles.maxHeight,
                {
                    [styles.withoutZIndex]: withoutZIndex
                }
            )}
            {...restProps}
        />
    );
}
