import React, { useState } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";

import styles from "./Paginator.module.scss";
import {
    Dropdown,
    ArrowLeftDouble,
    ArrowLeftSingle,
    ArrowRightDouble,
    ArrowRightSingle
} from "../icons";

Paginator.propTypes = {
    className: PropTypes.string,
    limit: PropTypes.number,
    offset: PropTypes.number,
    count: PropTypes.number,
    request: PropTypes.func.isRequired,
    withLimitChoice: PropTypes.bool,
    country: PropTypes.any,
    dateRange: PropTypes.any,
    genres: PropTypes.any,
    location: PropTypes.any
};

Paginator.defaultProps = {
    limit: 10,
    offset: 0,
    count: 0,
    withLimitChoice: false
};

const NON_ACTIVE_BUTTONS = {
    "<<": <ArrowLeftDouble />,
    "<": <ArrowLeftSingle />,
    ">": <ArrowRightSingle />,
    ">>": <ArrowRightDouble />
};

function Paginator({
    request,
    className,
    offset,
    count,
    limit,
    withLimitChoice,
    country,
    dateRange,
    genres,
    location
}) {
    const offsets = getOffsetsList(count, offset, limit);
    const isOnePage = count <= limit;
    if (!withLimitChoice)
        return (
            <div className={classnames(className, styles.container)}>
                {offsets.map((button, index) => {
                    return renderButton({
                        value: button,
                        count,
                        index,
                        offset,
                        request,
                        limit,
                        styles,
                        genres,
                        country,
                        dateRange
                    });
                })}
            </div>
        );

    return (
        <div>
            {!isOnePage && (
                <div className={styles.limitContainer}>
                    <div className={classnames(className, styles.container)}>
                        {offsets.map((button, index) => {
                            return renderButton({
                                value: button,
                                count,
                                index,
                                offset,
                                request,
                                limit,
                                styles,
                                genres,
                                country,
                                dateRange
                            });
                        })}
                    </div>
                    <div className={styles.limitChoice}>
                        <span>Tracks per page</span>
                        <SelectLimit
                            limit={limit}
                            offset={offset}
                            requestTracks={request}
                            genres={genres}
                            country={country}
                            dateRange={dateRange}

                        />
                    </div>
                </div>
            )}
        </div>
    );
}

const SelectLimit = ({ limit, offset, requestTracks, genres, country, dateRange }) => {
    const [showDropdown, setShowDropdown] = useState(false);
    const limitOptions = [10, 25, 50];
    const onClickHandler = newLimit => {
        const newOffset =
            offset === 0 ? 0 : Math.floor(offset / newLimit) * newLimit;
        requestTracks({ limit: newLimit, offset: newOffset, genres: [genres], country: country, dateRange: dateRange });
        setShowDropdown(false);
    };
    return (
        <div>
            <div
                className={styles.selectLimit}
                onClick={() => setShowDropdown(!showDropdown)}
            >
                <div>{limit}</div>
                <Dropdown />
            </div>
            {showDropdown && (
                <div className={styles.limitDropdown}>
                    {limitOptions.map(elem => (
                        <div onClick={() => onClickHandler(elem)}>{elem}</div>
                    ))}
                </div>
            )}
        </div>
    );
};

function renderButton({ value, offset, request, limit, styles, count, genres, country, dateRange }) {
    const lastPage = Math.ceil(count / limit);
    let currentOffset;
    switch (value) {
        case "<":
            currentOffset = offset - limit > 0 ? offset - limit : 0;
            break;
        case "<<":
            currentOffset = 0;
            break;
        case ">":
            currentOffset =
                offset + limit < count
                    ? offset + limit
                    : (lastPage - 1) * limit;
            break;
        case ">>":
            currentOffset = (lastPage - 1) * limit;
            break;
        default:
            currentOffset = (value - 1) * limit;
            break;
    }

    const result = (
        <button
            onClick={() => request({ offset: currentOffset, genres: genres, country: country, dateRange: dateRange })}
            key={value}
            className={classnames(
                styles.button,
                !NON_ACTIVE_BUTTONS[value] &&
                    offset === currentOffset &&
                    styles.activeButton
            )}
            value={currentOffset}
        >
            {NON_ACTIVE_BUTTONS[value] || value}
        </button>
    );
    return result;
}

function getOffsetsList(count, offset, limit) {
    const counter = Math.ceil(count / limit);
    const curPage = Math.floor(offset / limit) + 1;
    if (counter <= 5) return [1, 2, 3, 4, 5].filter(x => x <= counter);

    let result = [];

    const from = curPage > 2 ? curPage - 2 : 1;
    const to = from + 4;

    for (let i = from; i <= to && i <= counter; i++) result.push(i);
    for (let i = from - 1; result.length < 5 && i > 0; i--) result.unshift(i);
    if (count > 0) result = ["<<", "<", ...result, ">", ">>"];

    return result;
}

export default React.memo(Paginator);
