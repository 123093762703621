import React, { useContext, useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import routerDecorator from "components/helpers/withRouter";
import { NavigationPanel } from "components/common/ControlElems";
import { ContentContext } from "context/ContentContext";
import smoothScrollUp from "helpers/utils/smoothScrollUp";
import styles from "./Tracks.module.scss";
import ListWithPaginator from "./ListWithPaginator";
import SideLinks from "./SideLinks";
import SideFilters from "components/common/ControlElems/SideFilters/SideFilters";
import AlertDialog from "../common/Dialogs/AlertDialog";

import { MEGA_LINKS } from "helpers/constants/musicNavigationLinks";

SearchResult.propTypes = {
    location: PropTypes.object,
    className: PropTypes.string
};

function SearchResult(props) {
    const { className } = props;
    const {
        requestTracksWithRetry,
        scrollbar,
        audio,
        video,
        type,
        resetSearchQuery,
        isTrackLimited,
        trackLimitedMessage
    } = useContext(ContentContext);

    const { limit, offset, count } = type === "audio" ? audio : video;

    const trackContainer = useRef(null);
    const [showTrackLimitedDialog, setShowTrackLimitedDialog] = useState(false);

    useEffect(() => {
        smoothScrollUp(scrollbar, trackContainer);
    }, [offset, limit]);

    useEffect(() => {
        return resetSearchQuery;
    }, []);

    useEffect(() => {
        if (isTrackLimited) {
            setShowTrackLimitedDialog(true);
        }
    }, [isTrackLimited]);

    return (
        <div
            className={classnames(
                styles.container,
                className,
                styles.searchContainer
            )}
        >
            <SideFilters className={styles.left} />
            <div className={styles.center}>
                <NavigationPanel
                    initRequestForTrack={false}
                    prefix="tracks.headers"
                    className={styles.navbar}
                    upperCase={false}
                    megaLinks={MEGA_LINKS}
                />
                <ListWithPaginator
                    containerRef={trackContainer}
                    limit={limit}
                    offset={offset}
                    count={count}
                    requestTracks={requestTracksWithRetry}
                    type={type}
                    styles={styles}
                />
                {showTrackLimitedDialog && (
                    <AlertDialog onClose={setShowTrackLimitedDialog}>
                        {trackLimitedMessage}
                    </AlertDialog>
                )}
            </div>
            <SideLinks />
        </div>
    );
}

export default routerDecorator(SearchResult);
