import React, { useContext, useState, useEffect, useRef } from "react";
import JSZipUtils from "jszip-utils";
import { saveAs } from "file-saver";
import JSZip from "jszip";
import styles from "./DownloadList.module.scss";
import { ContentContext } from "../../../context/ContentContext";
import { ClosePlayer, Download } from "../icons";
import { Button, DropDownMenu, IconButton } from "../ControlElems";
import t from "helpers/getText";
import Scrollbar from "../Scrollbar/Scrollbar";
import multiDownload from "../../../helpers/multiDownloadByURL";

export function DownloadList({ setShowMenu, className }) {
    const {
        downloadList,
        downloadMedia,
        setDownloads,
        resetTrackLimiting
    } = useContext(ContentContext);
    const [showDropdown, setShowDropdown] = useState(false);
    const downloadCount = downloadList.length;

    useEffect(() => {
        if (downloadCount === 0) {
            setShowDropdown(false);
            resetTrackLimiting();
        }
    }, [downloadCount]);
    const menu = useRef(null);

    useEffect(() => {
        document.body.onclick = e => {
            (menu.current && menu.current.contains(e.target)) ||
                setShowDropdown(false);
        };
    }, [showDropdown]);
    if (downloadCount === 0) return <></>;

    const toggleDropDown = () => {
        setShowMenu(false);
        setShowDropdown(!showDropdown);
    };

    const leftButtonHander = () => {
        if (downloadList.length > 0) {
            LoadAll({
                downloadList,
                downloadMedia,
                cleanList: () => setDownloads({ type: "deleteAll" })
            });
        }
    };

    const downloadZipBtnHandler = () => {
        if (downloadList.length > 0) {
            DownloadInZip({
                downloadList,
                downloadMedia,
                cleanList: () => setDownloads({ type: "deleteAll" })
            });
        }
    };

    return (
        <>
            <div ref={menu} className={className}>
                <div
                    className={styles.container}
                    onClick={() => toggleDropDown()}
                >
                    <p>
                        &nbsp;
                        {downloadCount}
                        &nbsp;
                        {downloadCount % 100 !== 1
                            ? t("navbar.downloadList.plural")
                            : t("navbar.downloadList.singular")}
                    </p>
                    <Download
                        color="var(--theme-second-color)"
                        filledColor="var(--theme-main-color)"
                        opacity={1}
                        withCircle={false}
                        withFilledCircle
                    />
                </div>
                {showDropdown && (
                    <DropDownMenu
                        type="downloadMenu"
                        position={{
                            top: "5%",
                            right: "50%",
                            transform: "translateX(-50%)"
                        }}
                    >
                        <Scrollbar
                            autoHeight
                            autoHide
                            autoHideTimeout={1000}
                            autoHideDuration={300}
                        >
                            <div className={styles.mediaList}>
                                {downloadList.map((track, index) => (
                                    <LoadTrack
                                        key={index}
                                        id={track.key}
                                        version={track.version}
                                        versionId={track.versionId}
                                        name={track.name}
                                        setDownloads={setDownloads}
                                    />
                                ))}
                            </div>
                        </Scrollbar>
                        <hr color="#711D48" />
                        <div className={styles.ButtonContainer}>
                            <Button
                                height="34px"
                                style={{
                                    // minWidth: "136px",
                                    marginBottom: "0.5rem"
                                }}
                                color="black"
                                withoutBorder
                                withoutPadding
                                size="tiny"
                                label="Download All"
                                onClick={leftButtonHander}
                            />
                            <Button
                                height="34px"
                                style={{ marginBottom: "0.5rem" }}
                                color="black"
                                withoutBorder
                                withoutPadding
                                size="tiny"
                                label="Download in zip"
                                onClick={downloadZipBtnHandler}
                            />
                            <Button
                                height="34px"
                                // style={{ margin: "-8px 0 0 -16px" }}
                                withoutBorder
                                withoutPadding
                                size="tiny"
                                label="Clear"
                                onClick={() =>
                                    setDownloads({ type: "deleteAll" })
                                }
                            />
                        </div>
                    </DropDownMenu>
                )}
            </div>
        </>
    );
}

// async function saveActionAndCheckLimitedTracks({
//     downloadList,
//     downloadMedia
// }) {
//     const downloadListWithoutLimitedTracks = [];
//     await Promise.all(
//         downloadList.map(async media => {
//             const isErrorWithLoading = await downloadMedia({
//                 mediaType: media.type,
//                 mediaKey: media.fileName,
//                 mediaName: media.name,
//                 artist: media.artist,
//                 version: media.version,
//                 versionId: media.versionId
//                 // onlySaveToDB: true
//             });
//             if (!isErrorWithLoading) {
//                 downloadListWithoutLimitedTracks.push(media);
//             }
//         })
//     );

//     return downloadListWithoutLimitedTracks;
// }

async function LoadAll({ downloadList, downloadMedia, cleanList }) {
    // const downloadListWithoutLimitedTracks = await saveActionAndCheckLimitedTracks(
    //     { downloadList, downloadMedia }
    // );

    const downloadlinks = await Promise.all(
        downloadList.map(async media => {
            return await downloadMedia({
                mediaType: media.type,
                mediaKey: media.fileName,
                mediaName: media.name,
                artist: media.artist,
                version: media.version,
                versionId: media.versionId,
                onlyLinkRequired: true
                // onlySaveToDB: true
            });
        })
    );

    if (downloadlinks.length > 0) {
        multiDownload(downloadlinks);

        cleanList();
    }
}

function urlToPromise(url) {
    return new Promise(function(resolve, reject) {
        JSZipUtils.getBinaryContent(url, function(err, data) {
            if (err) {
                reject(err);
            } else {
                resolve(data);
            }
        });
    });
}

async function DownloadInZip({ downloadList, downloadMedia, cleanList }) {
    const downloadLinks = await Promise.all(
        downloadList.map(async media => {
            const url = await downloadMedia({
                mediaType: media.type,
                mediaKey: media.fileName,
                mediaName: media.name,
                artist: media.artist,
                version: media.version,
                versionId: media.versionId,
                onlyLinkRequired: true
            });

            return {
                url,
                fileName: media.fileName
            };
        })
    );

    if (downloadLinks.length > 0) {
        const zip = new JSZip();

        downloadLinks.forEach(({ url, fileName }) => {
            zip.file(fileName, urlToPromise(url), { binary: true });
        });

        zip.generateAsync({ type: "blob" }).then(function callback(blob) {
            saveAs(blob, getArchiveName());
        });

        cleanList();
    }
}

function getArchiveName() {
    const currentDate = new Date();
    return (
        "FRP-" +
        ("0" + (currentDate.getMonth() + 1)).slice(-2) +
        ("0" + currentDate.getDate()).slice(-2) +
        ("0" + currentDate.getHours()).slice(-2) +
        ("0" + currentDate.getMinutes()).slice(-2)
    );
}

function LoadTrack({ name, version, versionId, setDownloads, id }) {
    return (
        <div>
            <div>
                <p>{name}</p>
                <div>
                    <button disabled className={styles.version}>
                        {version}
                    </button>
                </div>
            </div>
            <IconButton
                withoutResize
                onClick={() =>
                    setDownloads({
                        type: "delete",
                        element: { key: id, version, versionId }
                    })
                }
            >
                <ClosePlayer color="black" />
            </IconButton>
        </div>
    );
}

// function downloadURI(uri) {
//     const link = document.createElement("a");
//     link.download = `FRP-${moment().format("MM DD HH:MM:SS")}.zip`;
//     link.href = uri;
//     link.click();
// }
