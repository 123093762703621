const apps = {
    apps: {
        links: {
            "/apps/spins": "Spins",
            "/apps/frp": "FRP live app"
        },
        spins: {
            text1:
                "FRP's innovative and advance interactive software is now bigger and better with its new AI companion AiM. Let our SPINS desktop app create your playlist for you by simply speaking or typing your request.",
            text2:
                "You will be able to connect with other members, view their playlist when playing live and download any tracks instantly from their playlist that available on Franchise by simple clicking the download button next to each track in their list. Best of all you will be able to download music to your computer when you are on the road using our App.\n" +
                "\n" +
                "If that’s not enough you will be able to interact with your audience via live chat for  shout outs and requests eliminating those distracting trips to the DJ booth. \n" +
                "\n" +
                " Get ready to exceed your destiny.",
            textHeader: "Features",
            list: [
                "Request playlists of any of our available genres via voice or text",
                "Download complete playlist in a folder or individually",
                "Quickly download tracks when playing at gigs for instant play via drag and drop feature",
                "Get live track recommendation based on current track being played",
                "Organize your playlist by gigs every time you start your DJ software",
                "Playlists are auto saved by gig name & date for future party rocking sets.",
                "Connect with other members via chat.",
                "When used with our mobile app SPINS allows you to can create, organize and download tracks to folders on your laptop remotely using the mobile app."
            ],
            download: {
                mac_x64: "Download for Mac(Intel)",
                mac_arm64: "Download for Mac(M1)",
                windows: "Download for Windows"
            }
        },
        frp: {
            text1:
                "FRP Live's mobile App takes it up a notch by allowing our members to not only view and listen to all tracks but to download any of those tracks to any Laptop while on the go.",
            text2:
                "Load the companion SPINS desktop software shown above onto the PC you want music downloaded to, create folders via app or use the default folder, simply click the download button next to each track on the app and the tracks are automatically downloaded to the selected folder on the PC. There are no queues or additional steps to take on the PC as the SPINS software instantly downloads the tracks the moment you click the download button on the app.\n" +
                "\n" +
                "The most advanced record pool app has gotten better with more features to come.",
            textHeader: "Features",
            list: [
                "Search, listen and download tracks instantly to any PC when on the go",
                "Create unlimited amount folders for limitless playlists",
                "Rename, sort or delete folders",
                "Make any created folder the default folder for fast download when browsing through tracks",
                "Search and preview tracks when on the go",
                "View download history",
                "Aim will be coming soon to the mobile app for the ultimate playlists creation via a simple command"
            ],
            download: {
                ios: "Download for Iphone",
                android: "Download for Android"
            },
            appList1: [
                "Visit frp.live/apps/spins using your computer web browser.",
                "Click on the download option that matches your computer Operating System. i.e., Download for Mac (Intel), Download for Mac (M1), or Download for Windows.",
                "Follow the installer instructions to launch the SPINS app on your desktop.",
                "A new icon will appear in your menu bar (macOS) or taskbar (Windows).",
                "Once all the libraries are installed, click on the SPINS icon to start using the app.",
            ],
            appList2: [
                `Use your "frp.live" credentials to login into the desktop app.`,
                "On Login, you will see five tabs at the bottom of the screen.",
            ],
            appList3: [
                `This tab shows the online DJ list. A DJ can chat privately with other online DJs by clicking on his/her name. `,
                "You can also search for a particular DJ via the search bar.",
                "On clicking the Chats option, you will see the list of past chat history.",
                "Global Chat allows DJs to chat with one another in a group setting. Clicking on a DJs name in the list will allow you to have a private chat with that DJ.",
                "The chat also includes a live support tech for any queries or issues.",
            ],
            appList4: [
                `This is your playlist from your live session. `,
                "In order for this feature to function you must allow access to your mic which is prompted when you launch  Serato.",
                "Once you give permission to use the mic, the mic icon on the top right corner will start blinking.",
                "You can toggle it on/off by clicking on it.",
                "When on the mic will only record music which is fed into the system and converted to computer data used to identify the track and inserted into the gig list.",
                "Turning off the Serato will automatically stop the recording by turning OFF the mic.",
            ],
            appList5: [
                `Displays the tracks from the FRP Live’s website. You can search for tracks or filter tracks by clicking on the filter icon to the right of the search bar   `,
                "The curated Set option contains playlists created for DJs in a variety of categories by the FRP team.",
                "The three dots on the right side of the curated set shows the entire list of tracks for that set.",
                "You can download tracks individually from each set or download the entire set as a zip file.",
                "The downloaded track will automatically be saved in the FRP- Downloads folder which is the default folder and is located in your Documents folder. You can change this location by clicking on the folder icon to the right of the search bar.",
                "The music icon next to the Curated Sets tab is the track recommendation section. The music recommendation is based on rating, download history and live plays history. This section will also contain music pushed directly from the record labels.",
                "In the music recommendation tab there is a filter icon on the right of the search bar to filter music based on preferences.",
            ],
            appList6: [
                "This tab contains the download history for all music downloaded via Spins Desktop, Spins Mobile and the website.",
            ],
            appList7: [
                "The last is the Profile tab, which contains all the information and settings related to a DJ.",
            ]
        }
    }
};

export default apps;
