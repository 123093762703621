import React, { useState } from "react";
import routerDecorator from "components/helpers/withRouter";

import { LetterButtons, AddAndDownloadButtons } from "../Components";

const renderButtons = ({
    size,
    history,
    track,
    activeLetter,
    superShortScrollbarRef,
    className,
    index,
    isPlayer,
}) => {
    const [showRate, setShowRate] = useState(null);
    let result = null;
    switch (size) {
        case "long":
            result = (
                <div className={className}>
                    <LetterButtons
                        track={track}
                        history={history}
                        activeLetter={activeLetter}
                    />
                </div>
            );
            break;
        case "superShort":
        case "longVersion":
            result = (
                <>
                    <AddAndDownloadButtons
                        superShortScrollbarRef={superShortScrollbarRef}
                        size={size}
                        className={className}
                        track={track}
                        showRate={showRate}
                        setShowRate={setShowRate}
                        index={index}
                        isPlayer={isPlayer}
                    />
                </>
            );
            break;
        default:
            result = null;
    }
    return result;
};

export default routerDecorator(renderButtons);
