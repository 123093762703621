const disclaimerdmca = {
    disclaimerdmca: {
        header: "Disclaimer & DMCA",
        text: `Franchise Record Pool 
         Ultimate Excitement Inc. respects the intellectual property rights of others and takes copyright infringement very seriously. Per the DMCA, Ultimate Excitement Inc. will respond expeditiously to claims of
        copyright infringement on the Site if submitted to Ultimate Excitement Inc. as described below. Upon receipt of a notice alleging copyright infringement, Ultimate Excitement Inc. will take whatever action it deems appropriate within
        its sole discretion, including removal of the allegedly infringing materials and termination of access for repeat infringers of copyright protected content.
        If you believe that your intellectual property rights have been violated by Ultimate Excitement Inc. or by a third party who has uploaded materials to our website, please notify us with a written communication to
         If you believe that your intellectual property rights have been violated by Ultimate Excitement Inc. or by a third party who has uploaded materials to our website, please notify us with a written communication to
        `,
    linkurl: 'mailto:info@franchiserecordpool.com',
    linkTxt : 'info@franchiserecordpool.com ', 
    text3: 'that includes the following information listed below Pursuant to 17 U.S.C. 512(c) of the Digital Millennium Copyright Act (“DMCA”):',
        li: ['A description of the copyrighted work or other intellectual property that you claim has been infringed such as the artist name and song name.',
            'A description of where the material that you claim is infringing is located on the Site.',
            'An address, telephone number, and email address where we can contact you.',
            'A statement that you have a good-faith belief that the use is not authorized by the copyright owner or other intellectual property rights owner, by its agent, or by law.',
            'A statement by you under penalty of perjury that the information in your notice is accurate and that you are the copyright or intellectual property owner or are authorized to act on the owner’s behalf.'
        ],
        text2: `  Ultimate Excitement Inc. may request additional information before removing any allegedly infringing material. In the event Ultimate Excitement Inc. removes the allegedly infringing materials, Ultimate Excitement Inc. will
                 immediately notify the person responsible for posting such materials that Ultimate Excitement Inc. removed or disabled access to the materials. Ultimate Excitement Inc. may also provide the responsible person with your email address
                 so that the person may respond to your allegations.`
    }
};

export default disclaimerdmca;
