const promopackage = {
    promopackage: {
        pubHeader: "Promo Package",
        subHeader:
            "Reach the largest database of radio DJs, mobile DJs and club DJs on the planet. The only service that built the tools you need from the ground up.You wanted visual exposure so we built FRP TV for you. We are serious about marketing You! Contact us today to learn how we can use what we learnt in the last 20 years of business to help you.",
        header: "The Future of Artist Marketing is Here",
        links: {
            "home/promo-audio": "AUDIO PROMO",
            "home/promo-video": "VIDEO PROMO",
            "home/promo-artist": "ARTIST DEVELOPMENT",
            "home/promo-social": "SOCIAL MEDIA MARKETING"
        },
        "promo-audio": {
            header: "Audio promo",
            text:
                "Fixie tote bag ethnic keytar. Neutra vinyl American Apparel kale chips tofu art. Banjo Tonx beard biodiesel narwhal. "
        },
        "promo-video": {
            header: "Video promo",
            text:
                "Video promo text. Video promo text. \nVideo promo text.\nVideo promo text. Video promo text."
        },
        "promo-artist": {
            header: "Artist development",
            text:
                "Artist development text. Artist development text. \nArtist development text.\nArtist development text. Artist development text."
        },
        "promo-social": {
            header: "Social media marketing",
            text:
                "Social media marketing promo text \n Social media marketing promo text \nSocial media marketing promo text"
        }
    }
};

export default promopackage;
