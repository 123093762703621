import React from "react";
import PropTypes from "prop-types";

Note.propTypes = {
    className: PropTypes.string,
    color: PropTypes.string
};

Note.defaultProps = {
    className: "",
    color: "#BB1D7E"
};

export default function Note(props) {
    const { color, className } = props;
    return (
        <svg className={className} width="11" height="15" viewBox="0 0 11 15">
            <path
                fill={color}
                fillRule="nonzero"
                d="M4.714 0v9.111a3.131 3.131 0 0 0-1.571-.427A3.15 3.15 0 0 0 0 11.842 3.15 3.15 0 0 0 3.143 15a3.15 3.15 0 0 0 3.143-3.158V3.947H11V0H4.714z"
            />
        </svg>
    );
}
