import React from "react";
import PropTypes from "prop-types";

IGfooter.propTypes = {
    className: PropTypes.string
};

IGfooter.defaultProps = {
    className: ""
};

export default function IGfooter(props) {
    const { className } = props;
    return (
        <svg className={className} width="30" height="30" viewBox="0 0 34 34">
            <g fill="none" fillRule="evenodd">
                <path
                    fill="#9A9A9B"
                    d="M1 17C1 8.163 8.163 1 17 1s16 7.163 16 16-7.163 16-16 16S1 25.837 1 17z"
                />
                <path
                    fill="var(--theme-footer-background-color)"
                    d="M16.966 9.742h-.002c1.972 0 2.219.008 2.993.044.773.035 1.3.158 1.764.339.478.186.882.436 1.285.842.404.406.652.813.838 1.293.179.464.301.995.338 1.772.034.78.043 1.028.043 3.01 0 1.984-.009 2.232-.043 3.01-.037.778-.16 1.309-.338 1.773-.186.48-.434.887-.838 1.293a3.55 3.55 0 0 1-1.285.843c-.462.18-.99.303-1.763.34-.774.035-1.02.043-2.993.043-1.971 0-2.219-.008-2.993-.044-.773-.036-1.3-.159-1.762-.34a3.553 3.553 0 0 1-1.286-.842 3.58 3.58 0 0 1-.837-1.293c-.18-.464-.302-.995-.338-1.772-.035-.78-.044-1.027-.044-3.01 0-1.983.01-2.232.044-3.01.035-.778.157-1.309.337-1.773.186-.48.435-.887.838-1.293a3.553 3.553 0 0 1 1.286-.842c.462-.18.99-.304 1.763-.34.774-.035 1.021-.043 2.993-.043zm3.875 2.528a.874.874 0 0 0-.87.875c0 .484.39.877.87.877a.874.874 0 0 0 .872-.877.874.874 0 0 0-.872-.876zm-3.875 1.023c-2.059 0-3.728 1.68-3.728 3.75 0 2.07 1.67 3.749 3.728 3.749 2.06 0 3.728-1.678 3.728-3.75 0-2.07-1.669-3.749-3.728-3.749zm0 1.316c1.337 0 2.42 1.09 2.42 2.434a2.427 2.427 0 0 1-2.42 2.434 2.427 2.427 0 0 1-2.42-2.434 2.427 2.427 0 0 1 2.42-2.434z"
                />
            </g>
        </svg>
    );
}
