import React, { useCallback } from "react";

import {
    COMMON_DIALOGS,
    INITIAL_STATE,
    useCommonDialogContext
} from "context/CommonDialogContext";

import { ConnectionErrorDialog } from "./ConnectionErrorDialog";

const CommonDialogProvider = props => {
    const { children } = props;

    const { dialog, setCurrentDialog } = useCommonDialogContext();

    const handleClose = useCallback(() => {
        setCurrentDialog(INITIAL_STATE);
        dialog.callback();
    }, [dialog]);

    return (
        <div>
            {dialog.name === COMMON_DIALOGS.ConnectionError && (
                <ConnectionErrorDialog onClose={handleClose} />
            )}
            {children}
        </div>
    );
};

export default React.memo(CommonDialogProvider);
