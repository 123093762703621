import React, { useContext, useState, useEffect } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";

import { getTextOrNull as t } from "helpers/getText";
import { ContentContext } from "context/ContentContext";
import routerDecorator from "components/helpers/withRouter";
import { LoaderPlaceholder } from "components/common/LoaderPlaceholder";
import { IconButton } from "components/common/ControlElems";
import { Filter } from "components/common/icons";
import { DropDown } from "components/common/DropDown";

import AllFilters from "./AllFilters";
import styles from "./SideFilters.module.scss";

import Preset from "./Preset";

SideFilters.propTypes = {
    className: PropTypes.string,
    mediaType: PropTypes.string,
    location: PropTypes.object,
    links: PropTypes.array
};

SideFilters.defaultProps = {
    className: "",
    mediaType: "audio",
    links: []
};

function isDownloads(pathname) {
    return pathname === "/downloads";
}

function Loader(size = "forHome") {
    return (
        <div
            className={classnames(
                styles.loader,
                size === "forHome" && styles.autoWidth
            )}
        >
            <LoaderPlaceholder />
        </div>
    );
}

function SideFilters({ className, location: { pathname }, history, links }) {
    const {
        // type,
        presetForNextRequest,
        requestTracksWithRetry,
        requestDownloadTracks,
        // sortField,
        presetName,
        setPreset,
        presets,
        fetchPresetsWithFilters
    } = useContext(ContentContext);

    const [allPresets, setAllPresets] = useState([{ name: "All", filter: { bpm: [], genres: [], versions: [], } }]);
    useEffect(()=>{
        fetchPresetsWithFilters();
    }, [])
    useEffect(()=>{
        if(typeof presets != "undefined" &&  Object.keys(presets).length > 0 && typeof presets[0].filter != "undefined" ){ 
            setAllPresets([{ name: "All", filter: { bpm: [], genres: [], versions: [], } }])
            presets.map((elm)=>{
                return setAllPresets(prevState => [
                    ...prevState,
                    elm
                ]);
            })
        }
    },[presets])

    const [currentPreset, setCurrentPreset] = useState(
        presetForNextRequest ? presetForNextRequest.name : "All"
    );

    const [openedPreset, setOpenedPreset] = useState(false);

    const [showFilters, setShowFilters] = useState(false);

    useEffect(() => {
        return () => {
            setPreset({ name: "All" });
        };
    }, []);

    useEffect(() => {
        setCurrentPreset(presetName);
        setOpenedPreset(presetName);
    }, [presetName]);

    const needToRedirect = pathname === "/tracks/curated";
    const filterLabel = t(`tracks.headers.mega[${pathname}]`);

    const { navLinks, currentNavLink } = React.useMemo(() => {
        let currentNavLinkValue = false;
        const result = links.map(link => {
            const newValue = {
                value: link,
                label: t(`tracks.headers.mega[${link}]`)
            };
            if (pathname === link) currentNavLinkValue = newValue;
            return newValue;
        });

        return { navLinks: result, currentNavLink: currentNavLinkValue };
    }, [links, pathname]);

    const goToLink = React.useCallback(link => history.push(link.value), []);
    // const showVideoAudioSwitch = !isBillboard;
    // const onChangeType = type => {
    //     makeTracksRequest({
    //         commonParams: {
    //             type,
    //             sortField
    //         }
    //     });
    // };
    const makeTracksRequest = data => {
        if (isDownloads(pathname)) {
            requestDownloadTracks(data);
        } else {
            requestTracksWithRetry(data);
        }
    };

    return (
        <div className={classnames(styles.container, className)}>
            <div className={styles.filterContent}>
                <div>
                    <div className={styles.mainFilterContainer}>
                        {filterLabel && <div>{filterLabel}</div>} 
                    </div>

                    <div className={styles.links}>
                        <DropDown
                            currentValue={currentNavLink}
                            onChange={goToLink}
                            list={navLinks}
                        />
                    </div>

                    <div className={styles.presetContainer}>
                        {((typeof allPresets) != 'undefined' && allPresets.length > 1 ) && allPresets.map(preset => {
                            return (
                                <Preset
                                    key={preset.name}
                                    currentPreset={currentPreset}
                                    preset={preset}
                                    onPresetChange={makeTracksRequest}
                                    openedPreset={openedPreset}
                                    setOpenedPreset={setOpenedPreset}
                                    needToRedirect={needToRedirect}
                                />
                            );
                        })}
                        
                        {((typeof allPresets) == 'undefined' || allPresets.length < 2 ) && (
                            <>
                            <div>
                                </div>
                            <Loader/>
                            <p>allPresets.length : {allPresets.length}</p>
                            <p>typeof allPresets : {typeof allPresets}</p>
                            </>
                            
                        )
                        }
                    </div>

                    {needToRedirect === false && (
                        <div className={styles.filterContainer}>
                            <div
                                onClick={() => setShowFilters(!showFilters)}
                                className={styles.header}
                            >
                                <p>{t("tracks.filters.title")}</p>
                                <IconButton withoutResize>
                                    <Filter isActive={showFilters} />
                                </IconButton>
                            </div>
                        </div>
                    )}
                </div>
                {showFilters && (
                    <AllFilters
                        setCurrentPreset={setCurrentPreset}
                        showFilters={showFilters}
                        setShowFilters={setShowFilters}
                    />
                )}
            </div>
        </div>
    );
}

export default routerDecorator(SideFilters);
