import React from "react";
import PropTypes from "prop-types";

FBfooter.propTypes = {
    className: PropTypes.string
};

FBfooter.defaultProps = {
    className: ""
};

export default function FBfooter(props) {
    const { className } = props;
    return (
        <svg className={className} width="30" height="30" viewBox="0 0 34 34">
            <g fill="none" fillRule="evenodd">
                <path
                    fill="#9A9A9B"
                    d="M1 17C1 8.163 8.163 1 17 1s16 7.163 16 16-7.163 16-16 16S1 25.837 1 17z"
                />
                <path
                    fill="var(--theme-footer-background-color)"
                    d="M17.989 24.613V16.7h2.172l.288-2.728h-2.46l.003-1.364c0-.712.068-1.093 1.083-1.093h1.358V8.788h-2.172c-2.61 0-3.528 1.323-3.528 3.547v1.638h-1.627V16.7h1.627v7.913h3.256z"
                />
            </g>
        </svg>
    );
}
