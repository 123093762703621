const profile = {
    profile: {
        links: {
            "/profile/subscription": "subscription",
            "/profile/personal": "personal",
            "/profile/dj": "dj profile",
            "/profile/password": "password",
            "/profile/billing": "billing"
        },
        title: "Personal",
        subtitle: "ACCOUNT",
        area: {
            subscription: {
                title: "Subscription"
            },
            personal: {
                title: "Personal"
            },
            dj: {
                title: "DJ Profile"
            },
            password: {
                title: "Password"
            },
            billing: {
                title: "Billing History"
            }
        },
        labels: {
            updateAvatar: "Update Avatar",
            subtitles: {
                personalInformation: "Personal Information",
                addressInformation: "Address Information",
                socialMedia: "Social Media",
                onUpdate: "Profile was updated successfully",
                onNotUpdate:
                    "Sorry, profile wasn't updated because of internal error",
                onChangePassword: "Your password was changed successfully",
                onNotChangePassword:
                    "Sorry, password wasn't changed because of internal error"
            },
            fields: {
                djname: "DJ Name",
                djbio: "DJ Bio",
                fullName: "Full Name",
                email: "Email",
                phone: "Phone",
                login: "Login",
                password: "Password",
                fax: "Fax",
                website: "Website",
                cellpage: "Cellpage",
                comment: "Comment",
                country: "Country",
                city: "City",
                region: "Region",
                zipCode: "Zip",
                street: "Street name",
                facebookUrl: "Facebook",
                twitterUrl: "Twitter",
                instagramUrl: "Instagram",
                snapchatUrl: "Snapchat",
                isradio: "I am radio DJ",
                ismobile: "I am mobile DJ",
                isclub: "I am club DJ",
                oldPassword: "Old password",
                newPassword: "New Password",
                confirmPassword: "Confirm password"
            },
            buttons: {
                update: "Update Profile",
                changePassword: "Change Password",
                chooseAvatarFile: "Choose File",
                saveAvatar: "Save"
            }
        },
        unpaidDialogText: "You don't have active subscription"
    }
};

export default profile;
