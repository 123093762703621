import React from "react";
import PropTypes from "prop-types";

Mute.propTypes = {
    className: PropTypes.string,
    color: PropTypes.string
};

Mute.defaultProps = {
    className: "",
    color: "white"
};

export default function Mute(props) {
    const { color, className } = props;
    return (
        <svg className={className} width="24" height="24" viewBox="0 0 24 24">
            <g fill="none" fillRule="evenodd">
                <path d="M-1288-24H144v1024h-1432z" />
                <path stroke={color} d="M-1288.5-24.5h1433v73h-1433z" />
                <path
                    fill={color}
                    d="M11.814 9.786l9.8-9.8 2.027 2.028-9.8 9.8 9.8 9.8-2.027 2.027-9.8-9.8-9.8 9.8-2.028-2.027 9.8-9.8-9.8-9.8L2.014-.014l9.8 9.8z"
                />
            </g>
        </svg>
    );
}
