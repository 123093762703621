import AbstractFieldsValidator from "components/helpers/validation/AbstractFieldsValidator";
import checkForAddressFields from "./checkForAddressFields";

export default function(fields, options) {
    return new RegisterFieldsValidator(fields, options).validate();
}

class RegisterFieldsValidator extends AbstractFieldsValidator {
    doCheckForSingleValue() {
        const { djName } = this.fields;

        checkForAddressFields(this.fields, this.addError);

        if (!djName.trim()) this.addError("djName", "Please type your DJ Name");
    }
}
