import React from "react";
import SearchResult from "../../Tracks/SearchResult";
import styles from "./TracksPage.module.scss";

export default function SearchResultPage() {
    return (
        <div className={styles.container}>
            <SearchResult />
        </div>
    );
}
