import React, { useContext } from "react";
import classnames from "classnames";

import { DropDownMenu, Links } from "components/common/ControlElems";
import routerDecorator from "components/helpers/withRouter";
import styles from "components/main/Navbar.module.scss";
import { AuthContext } from "context/AuthContext";
import { SearchRow } from "components/common/SearchRow";
import ThemeSwitcher from "components/main/ThemeSwithcer";
import ButtonsForGuests from "components/main/NavbarComponents/ButtonsForGuests";
import { DownloadList } from "components/common/DownloadList/DownloadList";
import { ImageWithPlaceholder } from "components/common/ImageWithPlaceholder";
import DropDownContent from "components/main/NavbarComponents/DropDownContent";
import { ContentContext } from "context/ContentContext";
import { Logo } from "components/common/Logo";

function DesktopNavbar({
    opacity,
    links,
    history,
    location,
    setShowMenu,
    userMenu,
    showMenu,
    searchQuery,
    setSearchQuery,
    isHome
}) {
    const { user } = useContext(AuthContext);
    const { downloadList } = useContext(ContentContext);
    const containerClassName = classnames(
        styles.container,
        styles.bottomBorder,
        opacity ? styles.withOpacity : styles.withoutOpacity,
        user && styles.loggedUser
    );
    return (
        <>

            {isHome ? (
                <AuthorizedUserNavbarElements
                    user={user}
                    setShowMenu={setShowMenu}
                    showMenu={showMenu}
                    userMenu={userMenu}
                    history={history}
                    isHome={isHome}
                />
            ) : (

                <div className={containerClassName}>
                    <Logo />
                    <div
                        className={classnames(
                            styles.center,
                            downloadList.length > 0
                                ? styles.withDownloads
                                : styles.withoutDownload
                        )}
                    >
                        <Links links={links} className={styles.links} prefix="navbar" />
                        {user && (
                            <SearchRow
                                history={history}
                                localSearchQuery={searchQuery}
                                setLocalSearchQuery={setSearchQuery}
                            />
                        )}
                        <ThemeSwitcher className={styles.themeSwitcher} />
                    </div>
                    {user ? (
                        <AuthorizedUserNavbarElements
                            user={user}
                            setShowMenu={setShowMenu}
                            showMenu={showMenu}
                            userMenu={userMenu}
                            history={history}
                            isHome={isHome}
                        />
                    ) : (
                        <ButtonsForGuests location={location} />
                    )}
                </div>
            )}
        </>
    );
}

function AuthorizedUserNavbarElements({
    user,
    setShowMenu,
    userMenu,
    showMenu,
    history,
    isHome
}) { 
    return (
        <>
            <div className={styles.avatarContainer}>
                {user.dj && (
                    <div className={styles.avatarInfo}>
                        <span className={styles.djName}>{user.dj.djName}</span>
                        <DownloadList
                            className={styles.downloadList}
                            setShowMenu={setShowMenu}
                        />
                    </div>
                )}
                <div ref={userMenu}>
                    <div
                        className={styles.imageContainer}
                        onClick={() => setShowMenu(!showMenu)}
                    >
                        <ImageWithPlaceholder
                            src={user.avatar}
                            className={styles.avatar}
                        />
                    </div>
                    <DropDownMenu
                        type="userMenu"
                        showMenu={showMenu}
                        width={180}
                        position={{ right: "10px", top: "105%" }}
                        height={192}
                    >
                        <DropDownContent
                            history={history}
                            setShowMenu={setShowMenu}
                        />
                    </DropDownMenu>
                </div>
            </div>
        </>
    );
}

export default routerDecorator(DesktopNavbar);
