import React from "react";
import PropTypes from "prop-types";

ClosePlayer.propTypes = {
    className: PropTypes.string,
    color: PropTypes.string
};

ClosePlayer.defaultProps = {
    className: "",
    color: "white"
};

export default function ClosePlayer(props) {
    const { color, className } = props;
    return (
        <svg className={className} width="16" height="16" viewBox="0 0 16 16">
            <g fill="none" fillRule="evenodd">
                <path
                    fill={color}
                    d="M8.114 7.024l5.268-5.269 1.09 1.09-5.268 5.269 5.268 5.268-1.09 1.09-5.268-5.268-5.269 5.268-1.09-1.09 5.269-5.268-5.269-5.269 1.09-1.09 5.269 5.269z"
                />
                <path d="M0 0h16v16H0z" />
            </g>
        </svg>
    );
}
