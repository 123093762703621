const delay = milliseconds =>
    new Promise(resolve => {
        setTimeout(resolve, milliseconds);
    });

const download = async url => {
    const a = document.createElement("a");
    a.download = null;
    a.href = url;
    a.style.display = "none";
    document.body.append(a);
    a.click();

    await delay(100);
    a.remove();
};

const multiDownload = async urls => {
    if (!urls) {
        throw new Error("`urls` required");
    }

    for (const [index, url] of urls.entries()) {
        await delay(index * 1000);
        download(url);
    }
};

export default multiDownload;
