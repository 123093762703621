import React, { useContext } from "react";
import classnames from "classnames";

import Spins from "./Spins";
import Frp from "./Frp";

import styles from "./Apps.module.scss";
import { UIContext } from "context/UIContext";

export default function Apps() {
    const { theme } = useContext( UIContext );
    return (
        <div className={ classnames( styles.container, styles[theme] ) }>
            <Spins />
            <Frp />
        </div>
    );
}
