import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";

import styles from "./Filter.module.scss";

Filter.propTypes = {
    className: PropTypes.string,
    color: PropTypes.string,
    isActive: PropTypes.bool,
    passiveColor: PropTypes.string
};

Filter.defaultProps = {
    className: "",
    color: "white",
    isActive: true
};

export default function Filter({ isActive, className, color }) {
    return (
        <svg
            className={classnames(
                styles.container,
                className,
                isActive && styles.active
            )}
            width="24"
            height="25"
            viewBox="0 0 24 25"
        >
            <g fill="none" fillRule="evenodd">
                <path d="M-4-4h32v32H-4z" />
                <path
                    fill={color}
                    d="M13.126 11a4.002 4.002 0 0 1 7.748 0H24v2.6h-3.333a4.001 4.001 0 0 1-7.334 0H0V11h13.126zM17 14a2 2 0 1 0 0-4 2 2 0 0 0 0 4zM3.216 3a4.002 4.002 0 0 1 7.568 0H24v2.6H10.784a4.002 4.002 0 0 1-7.568 0H0V3h3.216zM7 6.3a2 2 0 1 0 0-4 2 2 0 0 0 0 4zM3.216 21.6H0V19h3.216a4.002 4.002 0 0 1 7.568 0H24v2.6H10.784a4.002 4.002 0 0 1-7.568 0zM7 22.3a2 2 0 1 0 0-4 2 2 0 0 0 0 4z"
                />
            </g>
        </svg>
    );
}
