import React, { useContext, useState } from "react";
import PropTypes from "prop-types";
import styles from "./Rotation.module.scss";
import { IconButton } from "../ControlElems";
import { FB, IG, TW } from "../icons";
import { ContentContext } from "../../../context/ContentContext";
import TextBlock from "./TextBlock";
import { VideoElement } from "../VideoList";
import { ImageWithPlaceholder } from "components/common/ImageWithPlaceholder";
import routerDecorator from "components/helpers/withRouter";
import {
    getAmazonAddress,
    getYoutubeLocalAddress
} from "helpers/utils/getVideoAddress";

ArtistBlock.propTypes = {
    isSpotlight: PropTypes.bool
};
ArtistBlock.defaultProps = {
    isSpotlight: false
};

function ArtistBlock(props) {
    const { pausePlaying } = useContext(ContentContext);
    const [isPlayingVideo, setIsPlayingVideo] = useState(false);
    const [bioWidth, setBioWidth] = useState(window.innerWidth > 400 ? 50 : 40);
    window.addEventListener("resize", () =>
        window.innerWidth > 400 ? setBioWidth(50) : setBioWidth(40)
    );
    const {
        id,
        bio,
        fullName,
        facebook,
        instagram,
        twitter,
        cover,
        isSpotlight,
        avatar,
        history
    } = props;
    const youtubeAddress = false && getYoutubeLocalAddress(cover);
    const amazonAddress = getAmazonAddress(cover || avatar);
    return (
        <div
            className={isSpotlight ? styles.spotlightBlock : styles.artistBlock}
        >
            {youtubeAddress || amazonAddress ? (
                <div
                    className={styles.artistVideo}
                    onClick={() => {
                        setIsPlayingVideo(!isPlayingVideo);
                        pausePlaying();
                    }}
                >
                    <VideoElement address={cover} />
                </div>
            ) : (
                <div
                    onClick={() => history.push(`/rotation/artist/${id}`)}
                    className={styles.artistVideo}
                >
                    <ImageWithPlaceholder
                        className={styles.artistVideo}
                        src={cover || avatar}
                    />
                </div>
            )}

            <div
                className={styles.artistInfo}
                onClick={() => history.push(`/rotation/artist/${id}`)}
            >
                <h2>{fullName}</h2>
                <TextBlock bio={bio} width={bioWidth} />
            </div>

            <div className={styles.socialBlock}>
                {facebook && (
                    <IconButton>
                        <a
                            href={facebook}
                            target="_blank"
                            rel="noreferrer noopener"
                        >
                            <FB color="var(--theme-main-color)" />
                        </a>
                    </IconButton>
                )}
                {instagram && (
                    <IconButton>
                        <a
                            href={instagram}
                            target="_blank"
                            rel="noreferrer noopener"
                        >
                            <IG color="var(--theme-main-color)" />
                        </a>
                    </IconButton>
                )}
                {twitter && (
                    <IconButton>
                        <a
                            href={twitter}
                            target="_blank"
                            rel="noreferrer noopener"
                        >
                            <TW color="var(--theme-main-color)" />
                        </a>
                    </IconButton>
                )}
            </div>
        </div>
    );
}

export default routerDecorator(React.memo(ArtistBlock));
