import React, { useState } from "react";
import UserRegister from "./UserRegister";
import AuthPageLayout from "components/pages/Auth/AuthPageLayout";
import AuthTabButtons from "components/pages/Auth/AuthTabButtons";
import UserRegisterTabs from "./UserRegisterTabs";

export default function UserRegisterPage() {
    const [stage, setStage] = useState("common");

    return (
        <AuthPageLayout
            {...getTitles(stage)}
            tabsChildren={renderTabs(stage, setStage)}
        >
            <UserRegister stage={stage} setStage={setStage} />
        </AuthPageLayout>
    );
}

function getTitles(stage) {
    if (stage === "common") return { title: "Sign Up" };

    return {
        title: "Welcome to FRP!",
        smallTitle: "Sign Up"
    };
}

function renderTabs(stage, setStage) {
    if (stage === "common") return <AuthTabButtons key="common" />;

    return <UserRegisterTabs stage={stage} setStage={setStage} />;
}
