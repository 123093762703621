export const withRetryHandling = (
    callback,
    { basaDelay = 1000, maxAttempts = 5 } = {}
) => async (...params) => {
    const retry = async (attempt = 1) => {
        try {
            return await callback(...params);
        } catch (error) {
console.log("Error in retry : ", error)

            if (attempt >= maxAttempts) throw error;

            const delay = basaDelay * attempt;

            return new Promise(resolve =>
                setTimeout(() => resolve(retry(attempt + 1)), delay)
            );
        }
    };

    return retry();
};
