import React, { useContext } from "react";
import t from "helpers/getText";
import styles from "./Frp.module.scss";
import classnames from "classnames";
import { UIContext } from "context/UIContext";
import FeaturesList from "./FeaturesList";
import frpImage from "assets/images/frpBoxImage.png";
import spinMobile from "assets/images/spin-mobile.jpg";
const links = {
    ios: "https://apps.apple.com/us/app/frp-live/id1360454793?ls=1",
    android: "https://play.google.com/store/apps/details?id=com.st.frpnew"
};

const images = {
    ios: "light-appstore.png",
    android: "light-google.png"
};

function Frp() {
    const { theme } = useContext( UIContext );
    return (
        <div className={ styles.container }>
            <div className={ classnames( styles.headContainer, styles[theme] ) }>
                <div className={ classnames( styles.appBack, styles.frpBack ) }>
                    <div className={ styles.flexContainer }>
                        <div className={ styles.bannerTextContent }>
                            <span className={ styles.headerTitle1 }>Spins Mobile</span>
                            <div className={ styles.headerText }>
                                { t( "apps.frp.text1" ) }
                            </div>
                        </div>
                        <div className={ styles.bannerImage }>
                            <img
                                className={ styles.icon }
                                src={ spinMobile }
                                alt="banner frp image"
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div className={ styles.load }>
                <img
                    className={`${styles.icon} ${styles.ml0}`}
                    src={
                        theme === "light"
                            ? require( `assets/images/download.png` )
                            : require( `assets/images/light-download.png` )
                    }
                />
                <span className={ styles.text2 }>{ t( "apps.frp.text2" ) }</span>
            </div>

            <div className={ `${ styles.boxWrap } ${ styles.featureBoxWrap }` }>
                <div className={ styles.textContainer }>
                    <h3>{ t( "apps.frp.textHeader" ) }</h3>
                    <FeaturesList
                        className={ ` ${ styles.features } ${ styles.featuresWrap } ` }
                        items={ t( "apps.frp.list" ) || [] }
                    />
                    <div className={ styles.buttonsContainer }>
                        { ["ios", "android"].map( os => {
                            return (
                                <button
                                    className={ styles.imageButton }
                                    onClick={ () =>
                                        window.open( links[os], "_blank" )
                                    }
                                >
                                    <img
                                        src={ require( `assets/images/${ images[os]
                                            }` ) }
                                    />
                                </button>
                            );
                        } ) }
                    </div>
                </div>
                <div className={ styles.boxImageWrap }>
                    <div>
                        <img
                            className={ styles.icon }
                            src={ frpImage }
                            alt="frp Image mobile"
                        />
                        <a className={styles.redText} href="/app-guide">
                            Click HERE for a step by step guide ON our SPINS mobile app
                        </a>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Frp;
