import React, { useContext, useEffect, useRef, useState } from "react";
import classnames from "classnames";
import routerDecorator from "components/helpers/withRouter";
import { ContentContext } from "context/ContentContext";
import { IconButton } from "components/common/ControlElems";
import { FB, IG, TW } from "components/common/icons";
import t from "helpers/getText";
import smoothScrollUp from "helpers/utils/smoothScrollUp";
import { ImageWithPlaceholder } from "components/common/ImageWithPlaceholder";
import { UIContext } from "context/UIContext";
import Videos from "./Videos";
import Audios from "./Audios";
import TextBlock from "./TextBlock";
import styles from "./Artist.module.scss";

function Artist(props) {
    const {
        match: {
            params: { id }
        }
    } = props;
    const {
        getArtist,
        artists,
        setArtist,
        video,
        audio,
        requestTracksWithRetry,
        scrollbar,
        clearMedia
    } = useContext(ContentContext);

    const { id: artistId, artist = {} } = artists;
    const { name, bio, avatar, facebook, twitter, instagram } = artist;

    useEffect(() => {
        getArtist({ id });
        return () => {
            setArtist({ artistId: "", name: "" });
            clearMedia();
        };
    }, []);

    const { theme } = useContext(UIContext);

    const trackContainer = useRef(null);
    const videoContainer = useRef(null);
    const [bioWidth, setBioWidth] = useState(
        window.innerWidth > 700 ? 100 : window.innerWidth / 8
    );

    window.addEventListener("resize", () => {
        window.innerWidth > 700
            ? setBioWidth(100)
            : setBioWidth(window.innerWidth / 8);
    });

    const [prevOffset, setPrevOffset] = useState(0);

    useEffect(() => {
        if (prevOffset !== audio.offset) {
            smoothScrollUp(scrollbar, trackContainer);
        }
        setPrevOffset(audio.offset);
    }, [audio]);

    useEffect(() => {
        smoothScrollUp(scrollbar, videoContainer);
    }, [video]);

    return name ? (
        <div
            className={classnames(
                styles.container,
                styles.background,
                styles[theme]
            )}
        >
            <h3>{t("rotation.artist.subHeader")}</h3>

            <ImageWithPlaceholder
                alt="artist avatar"
                src={avatar}
                className={styles.photo}
            />

            <h1>{name}</h1>

            <div className={styles.socialBlock}>
                {facebook && (
                    <IconButton>
                        <a
                            href={facebook}
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <FB color="var(--theme-main-color)" />
                        </a>
                    </IconButton>
                )}
                {instagram && (
                    <IconButton>
                        <a
                            href={instagram}
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <IG color="var(--theme-main-color)" />
                        </a>
                    </IconButton>
                )}
                {twitter && (
                    <IconButton>
                        <a
                            href={twitter}
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <TW color="var(--theme-main-color)" />
                        </a>
                    </IconButton>
                )}
            </div>

            <TextBlock bio={bio} width={bioWidth} />

            <div ref={trackContainer} className={styles.contentContainer}>
                <h2>{t("rotation.artist.tracks")}</h2>
                <Audios
                    requestTracks={requestTracksWithRetry}
                    {...audio}
                    artistId={artistId}
                />
            </div>

            <div ref={videoContainer} className={styles.contentContainer}>
                <h2>{t("rotation.artist.videos")}</h2>
                <Videos {...video} />
            </div>
        </div>
    ) : (
        <div className={classnames(styles.container, styles.background)}>
            <h3>{t("rotation.artist.notExist")}</h3>
            <div style={{ height: "100vh" }} />
        </div>
    );
}

export default React.memo(routerDecorator(Artist));
