import React, { useContext, useState } from "react";
import { AuthContext } from "context/AuthContext";
import TextField from "components/common/form/TextField";
import Button from "components/common/ControlElems/Button";
import { fieldProps } from "components/helpers/forms/formFieldHelper";
import parseServerValidationErrors from "sources/api/parseServerValidationErrors";
import validateConfirmResetPasswordFields from "./validateConfirmResetPasswordFields";
import styles from "components/userRegister/Auth.module.scss";

export default function ConfirmResetPassword(props) {
    const [wasSubmit, setWasSubmit] = useState(false);
    const [fields, setFields] = useState({
        newPassword: ""
    });

    const {
        confirmResetPassword,
        confirmResetPasswordProcessing,
        confirmResetPasswordError
    } = useContext(AuthContext);

    const { location } = props;
    const urlSearchParams = new URLSearchParams(location.search);
    const token = urlSearchParams.get("token");

    const clientErrors = wasSubmit
        ? validateConfirmResetPasswordFields(fields)
        : {};
    const serverErrors = parseServerValidationErrors(confirmResetPasswordError);
    const errors = Object.assign({}, clientErrors, serverErrors);

    return (
        <section className={styles.container}>
            <div className={styles.center}>
                <TextField
                    label="New password"
                    {...fieldProps("newPassword", "password", {
                        fields,
                        setFields,
                        errors
                    })}
                />

                <div className={styles.commonError}>
                    {confirmResetPasswordError ? "Something goes wrong" : ""}
                </div>

                <Button
                    label="Change password"
                    fullWidth
                    disabled={confirmResetPasswordProcessing}
                    onClick={handleConfirmResetPassword({
                        token,
                        fields,
                        confirmResetPassword,
                        setWasSubmit
                    })}
                />
            </div>
        </section>
    );
}

function handleConfirmResetPassword({
    token,
    fields,
    confirmResetPassword,
    setWasSubmit
}) {
    return () => {
        setWasSubmit(true);

        const errors = validateConfirmResetPasswordFields(fields);
        if (Object.keys(errors).length > 0) return;

        confirmResetPassword(token, fields);
    };
}
