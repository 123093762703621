import React, { useState, useContext } from "react";
import { InnerLinks } from "components/common/ControlElems";
import t from "../../../helpers/getText";
import styles from "../StaticPages.module.scss";
import classnames from "classnames";
import { UIContext } from "context/UIContext";

const LINKS = ["promo-audio", "promo-video", "promo-artist", "promo-social"];

function PromoPackage() {
    let [activeLink, setLink] = useState(LINKS[0]);
    const { theme } = useContext(UIContext);
    return (
        <div
            className={classnames(
                styles.container,
                styles.promoSection,
                styles[theme]
            )}
        >
            <section className={styles.content}>
                <h3>{t(`promopackage.pubHeader`)}</h3>
                <h1>{t(`promopackage.header`)}</h1>
                <div className={styles.promo}>
                    <h2>
                        {t(`promopackage.subHeader`).replace(/&#8232;/g, " ")}
                    </h2>
                </div>
                <InnerLinks
                    prefix="promopackage"
                    postfix="header"
                    className={styles.links}
                    links={LINKS}
                    type="underline"
                    setLink={setLink}
                    closer="true"
                />
                <div className={styles.innerContent}>
                    <img
                        className={styles.icon}
                        src={require(`assets/images/${theme}-${activeLink}.png`)}
                        alt=""
                    />
                    <div>
                        <h4>{t(`promopackage.${activeLink}.header`)}</h4>
                    </div>
                    <div>
                        <span>
                            {t(`promopackage.${activeLink}.text`).replace(
                                /&#8232;/g,
                                " "
                            )}
                        </span>
                    </div>
                    {/*
                    <div className={styles.buttonDiv}>
                        <Button
                            className={styles.promoButton}
                            label="Learn More"
                        />
                    </div>

                     */}
                </div>
            </section>
        </div>
    );
}
export default React.memo(PromoPackage);
