const spin = {
    spin:
    {
        list: [
            "Open the App Store app on your device.",
            "In the search bar, type  FRP LIVE and select the app from the search results.",
            `Tap the "Get" button to begin downloading the app.`,
            "Once the app is installed, open it from your home screen.",
            "Enter your FRP login credentials (username & password) and agree to our terms & conditions to continue.",
            `Tap the "Sign In" button to access your account.`,
            "Now, the app is ready to use, and you can now easily browse, listen and download music.",
        ],
        listRight: [
            "Open your device's Google Play Store app.",
            "In the search bar, type FRP LIVE and select the app from the search results.",
            `Tap the "Install" button to begin downloading the app.`,
            "Once the app is installed, open it from your app drawer.",
            "Enter your FRP login credentials (username & password) and agree to our terms & conditions to continue.",
            `Next, tap "Sign In" to access your account.`,
            "Now, the app is ready to use, and you can easily browse, listen and download music.",
        ],
    }
};

export default spin;
