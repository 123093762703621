import React from "react";
import PropTypes from "prop-types";

Burger.propTypes = {
    className: PropTypes.string,
    color: PropTypes.string,
    opacity: PropTypes.number
};

Burger.defaultProps = {
    className: "",
    color: "var(--theme-main-color)",
    opacity: 0.3
};

export default function Burger(props) {
    const { color, className } = props;
    return (
        <svg
            className={className}
            height="32px"
            viewBox="0 0 32 32"
            width="32px"
            style={{ transform: "scale(0.66)" }}
        >
            <path
                d="M4,10h24c1.104,0,2-0.896,2-2s-0.896-2-2-2H4C2.896,6,2,6.896,2,8S2.896,10,4,10z M28,14H4c-1.104,0-2,0.896-2,2  s0.896,2,2,2h24c1.104,0,2-0.896,2-2S29.104,14,28,14z M28,22H4c-1.104,0-2,0.896-2,2s0.896,2,2,2h24c1.104,0,2-0.896,2-2  S29.104,22,28,22z"
                fill={color}
            />
        </svg>
    );
}
