const webStorage = require("json-web-storage");

const { jsonLocalStorage } = webStorage;

class LocalStorage {
    set(key, data) {
        return jsonLocalStorage.setItem(key, data);
    }

    get(key) {
        return jsonLocalStorage.getItem(key);
    }

    remove(key) {
        return jsonLocalStorage.removeItem(key);
    }
}

const localStorage = new LocalStorage();
export { localStorage };
