import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import styles from "./IconButton.module.scss";

IconButton.propTypes = {
    withoutResize: PropTypes.bool,
    className: PropTypes.string,
    style: PropTypes.object,
    onClick: PropTypes.func,
    disabled: PropTypes.bool,
    children: PropTypes.oneOfType([PropTypes.object, PropTypes.array])
};

export default function IconButton({
    withoutResize,
    className,
    onClick,
    disabled,
    style,
    children
}) {
    return (
        <button
            style={style}
            className={classnames(
                styles.container,
                className,
                withoutResize && styles.withoutResize
            )}
            onClick={onClick}
            disabled={disabled}
        >
            {children}
        </button>
    );
}
