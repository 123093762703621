export const splitArray = (arr, numberOfParts = 3) => {
    const len = Math.ceil(arr.length / numberOfParts);
    const newArr = [];
    for (let i = 0; i < numberOfParts; i++) {
        newArr[i] = arr.slice(i * len, (i + 1) * len);
    }
    return newArr;
};

export function handleSetCheckbox(value, oldResult, key, cb) {
    const newResult = [...oldResult[key]];
    if (newResult.includes(value)) {
        newResult.splice(newResult.indexOf(value), 1);
    } else {
        newResult.push(value);
    }
    cb({ ...oldResult, [key]: newResult });
}

export const DEFAULT_VALUES = {
    bpm: [0, 256],
    versions: [],
    genres: [],
    keys: []
};
