import React, { useContext } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import classnames from "classnames";
import { getTextOrNull as t } from "helpers/getText";

import { Links } from "components/common/ControlElems";
import { AuthContext } from "context/AuthContext";
import { UIContext } from "context/UIContext";

import styles from "./Profile.module.scss";
import Subscription from "./Subscription";
import Personal from "./Personal";
import DJInfo from "./DJInfo";
import PasswordTab from "./PasswordTab";
import Billing from "./Billing";

export default function Profile(props) {
    const { user } = useContext(AuthContext);
    const { theme } = useContext(UIContext);
    const LINKS =
        user.type === "label"
            ? [
                  "/profile/personal",
                  "/profile/subscription",
                  "/profile/password"
              ]
            : [
                  "/profile/personal",
                  "/profile/dj",
                  "/profile/subscription",
                  "/profile/password",
                  "/profile/billing"
              ];
    const { page } = props;
    return (
        <div className={classnames(styles.container, styles[theme])}>
            <h3 className={styles.subTitleText}>{t("profile.subtitle")}</h3>
            <h1 className={styles.head}>
                {t(`profile.area.${page}.title`) || t(`profile.title`)}
            </h1>
            <Links
                className={styles.links}
                prefix="profile.links"
                type="underline"
                links={LINKS}
            />
            <Switch>
                <Route
                    path="/profile/subscription/:type?"
                    component={Subscription}
                />
                <Route path="/profile/personal" component={Personal} />
                <Route path="/profile/dj" component={DJInfo} />
                <Route path="/profile/password" component={PasswordTab} />
                <Route path="/profile/billing" component={Billing} />
                <Redirect to="/profile/personal" />
            </Switch>
        </div>
    );
}
