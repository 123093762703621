import React from "react";
import PropTypes from "prop-types";

Download.propTypes = {
    className: PropTypes.string,
    color: PropTypes.string,
    filledColor: PropTypes.string,
    opacity: PropTypes.number,
    withCircle: PropTypes.bool,
    withFilledCircle: PropTypes.bool
};

Download.defaultProps = {
    className: "",
    color: "white",
    filledColor: "white",
    opacity: 0.3,
    withCircle: true,
    withFilledCircle: false,
    strokeWidth: 1.5
};

export default function Download({
    color,
    filledColor,
    opacity,
    className,
    withCircle,
    withFilledCircle,
    strokeWidth
}) {
    return (
        <svg className={className} width="26" height="26" viewBox="0 0 26 26">
            <g fill="none" fillRule="evenodd">
                {withCircle && (
                    <circle
                        cx="12"
                        cy="12"
                        r="11.5"
                        stroke={color}
                        strokeOpacity={opacity}
                        strokeWidth={strokeWidth}
                    />
                )}
                {withFilledCircle && (
                    <circle cx="12" cy="12" r="11.5" fill={filledColor} />
                )}
                <path
                    fill={color}
                    d="M11.492 16.138V6h1.5v10.135l3.433-3.432 1.06 1.06-4.183 4.184.001.002-1.06 1.06L7 13.768l1.06-1.06 3.432 3.43z"
                    opacity={opacity}
                />
            </g>
        </svg>
    );
}
