import React from "react";
import classnames from "classnames";
import styles from "./BillboardTrack.module.scss";
import TrackContent from "./TrackContent";
import { PlayButton } from "../../ControlElems";
import { NO_KEY } from "../../../../helpers/constants/keys";
import { renderButtons } from "../Helpers";

const BillboardTrack = ({ activeLetter, track, index, size, presetName }) => {
    const {
        name,
        artist,
        versions,
        genre,
        rank,
        bpm,
        key: rawKey,
        released,
        mood
    } = track;

    const key =
        (rawKey !== NO_KEY && rawKey) ||
        versions.reduce((oldKey, version) => {
            let result = oldKey;
            if (oldKey === NO_KEY && version.key !== NO_KEY)
                result = version.key;
            return result;
        }, rawKey);

    return (
        <div
            className={classnames(
                styles.trackContainer,
                activeLetter && styles.isVersionsVisiable,
                styles.billboardTracks

            )}
        >
            <div className={styles.playInfo} data-rank={rank}>
                <PlayButton
                    showCover
                    presetName={presetName}
                    track={track}
                    className={styles.playIcon}
                    isBillboard
                />
                <div className={styles.nameAndArtist}>
                    <span title={name}>{name}</span>
                    <span title={artist}>{artist}</span>
                    {size === "long" && renderButtons({
                        className: styles.buttons,
                        size,
                        track,
                        activeLetter,
                        superShortScrollbarRef:{},
                        index
                    })}
                </div>
            </div>
            <TrackContent
                mood={mood}
                bpm={bpm}
                genre={genre}
                musicKey={key}
                size={size}
                track={track}
                released={released}
                styles={styles}
                activeLetter={activeLetter}
                presetName={presetName}
                index={index}
                isBillboard
            />
        </div>
    );
};

export default React.memo(BillboardTrack);
