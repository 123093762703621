import React, { useContext } from "react";
import classnames from "classnames";
import t from "helpers/getText";
import { UIContext } from "context/UIContext";

import styles from "./StaticPages.module.scss";

export default function ContactUs() {
    const { theme } = useContext(UIContext);
    return (
        <div
            className={classnames(
                styles.container,
                styles.contactUsBack,
                styles[theme]
            )}
        >
            <section className={styles.content}>
                <h1>{t("contactUs.header")}</h1>
                <h2>{t("contactUs.subHeader")}</h2>
                <div className={classnames(styles.text, styles.contactUs)}>
                    {t("contactUs.text", []).map((elem, index) => {
                        return (
                            <div key={index}>
                                <span>{elem.head}</span>
                                <span>{elem.text}</span>
                            </div>
                        );
                    })}
                </div>
            </section>
        </div>
    );
}
