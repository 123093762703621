export const DEFAULT_PRESETS = [
    { name: "All", filter: {} },
    {
        name: "Intro / Outro",
        kind: "audio",
        filter: {
            genres: ["Hip Hop", "R&B", "Reggae", "Mainstream", "Latin", "Soca"]
        }
    },
    { name: "Hip-Hop", filter: {} },
    { name: "R&B", filter: {} },
    {
        name: "Afrobeat",
        filter: {
            genres: [
                "Independents",
            ]
        }
    },
    {
        name: "Mainstream",
        filter: {
            genres: [
                "Mainstream",
                "Rock",
                "Country",
                "Pop",
                "Electronica",
                "Dubstep",
                "Alternative"
            ]
        }
    },
    {
        name: "House",
        filter: {
            genres: [
                "Club",
                "Trance",
                "Soulful House",
                "House",
                "Dance",
                "Electro",
                "Progressive",
                "Techno",
                "Amapiano",
                "Slap House"
            ]
        }
    },
    {
        name: "Reggae",
        filter: { genres: ["Reggae"] }
    },
    {
        name: "Soca",
        filter: { genres: ["Soca"] }
    },
    {
        name: "Latin",
        filter: { genres: ["Latin Pop", "Reggaeton", "Salsa", "Merengue", "Bachata", "Dembow", "Cumbia", "Regional"] }
    },
    {
        name: "Throwbacks",
        filter: {
            genres: [
                "Hip Hop",
                "R&B",
                "Mainstream",
                "House",
                "Reggae",
                "Soca",
                "Latin"
            ]
        }
    },

    {
        name: "Independents",
        filter: { genres: ["R&B", "Hip Hop"] }
    },

    {
        name: "Remixes",
        filter: {}
    },

    {
        name: "Instrumentals",
        filter: {
            genres: [
                "Hip Hop",
                "R&B",
                "Mainstream",
                "House",
                "Reggae",
                "Soca",
                "Latin"
            ]
        }
    },

    {
        name: "Acapellas",
        filter: {
            genres: [
                "Hip Hop",
                "R&B",
                "Mainstream",
                "House",
                "Reggae",
                "Soca",
                "Latin"
            ]
        }
    },

    {
        name: "Breakers",
        filter: {}
    },

    {
        name: "Promo Albums",
        filter: {
            genres: [
                "Hip Hop",
                "R&B",
                "Mainstream",
                "House",
                "Reggae",
                "Soca",
                "Latin",
                "Country"
            ]
        }
    },

    {
        name: "DJ Tools",
        kind: "audio",
        filter: { genres: ["DJ Tools"] }
    }
];
