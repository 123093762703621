import React from "react";
import PropTypes from "prop-types";

RoundToggle.propTypes = {
    isActive: PropTypes.bool,
    color: PropTypes.string,
    className: PropTypes.string
};

RoundToggle.defaultProps = {
    color: "var(--theme-main-color)",
    className: "",
    isActive: false
};

export default function RoundToggle(props) {
    const { className, color/*, isActive*/ } = props;
    return (
        <svg className={className} width="16" height="16" viewBox="0 0 16 16">
            <g fill="none" fillRule="evenodd" transform="rotate(-180 8 8)">
                <circle cx="8" cy="8" r="7.5" stroke={color} opacity=".3" />
                <path
                    fill={color}
                    fillRule="nonzero"
                    d="M8.337 10.809a.393.393 0 0 1-.674 0l-2.596-4.15C4.89 6.377 5.083 6 5.404 6h5.192c.321 0 .514.377.337.66l-2.596 4.149z"
                />
            </g>
        </svg>
    );
}
