import React from "react";
import PropTypes from "prop-types";

FullVolume.propTypes = {
    className: PropTypes.string,
    color: PropTypes.string
};

FullVolume.defaultProps = {
    className: "",
    color: "rgba(255,255,255, 0.3)"
};

export default function FullVolume(props) {
    const { color, className } = props;
    return (
        <svg className={className} width="24" height="24" viewBox="0 0 24 24">
            <g fill={color} fillRule="evenodd">
                <path d="M6.3 15.6H2V7.2h4.3L14 3v16.8l-7.7-4.2zm.6-1.267l5.7 3.109V5.358l-5.7 3.11v5.865zM5.5 8.6H3.4v5.6h2.1V8.6zM17.006 8.82c.618.62 1 1.476.998 2.42A3.413 3.413 0 0 1 17 13.657l1.016 1.019a4.846 4.846 0 0 0 1.429-3.434 4.842 4.842 0 0 0-1.418-3.438l-1.021 1.017zm1.785-1.778a5.916 5.916 0 0 1 1.732 4.2 5.927 5.927 0 0 1-1.746 4.2l1.041 1.045a7.398 7.398 0 0 0 2.18-5.243A7.376 7.376 0 0 0 19.836 6L18.79 7.042z" />
            </g>
        </svg>
    );
}
