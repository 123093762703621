import React, { useCallback, useState, useEffect } from "react";
import PropTypes from "prop-types";
import TracksList from "components/common/TracksList/TracksList";
import VideoList from "components/common/VideoList/VideoList";
import { Paginator } from "components/common/ControlElems";

ListWithPaginator.propTypes = {
    limit: PropTypes.number.isRequired,
    offset: PropTypes.number.isRequired,
    count: PropTypes.number.isRequired,
    containerRef: PropTypes.any,
    requestTracks: PropTypes.func.isRequired,
    hideHeaders: PropTypes.bool,
    hidePaginator: PropTypes.bool,
    styles: PropTypes.object,
    genres: PropTypes.any,
    countries: PropTypes.any,
    requestFetchCountriesProcessing:PropTypes.any,
    requestTracksProcessing:PropTypes.any,
    location: PropTypes.any,
    isDownloadTracksPage: PropTypes.bool,
};

ListWithPaginator.defaultProps = {
    containerRef: null,
    hideHeaders: false,
    hidePaginator: false,
    styles: {},
    location: '',
    isDownloadTracksPage: false,
};

export default function ListWithPaginator({
    limit,
    offset,
    containerRef,
    requestTracks,
    type,
    count,
    hideHeaders,
    hidePaginator,
    styles,
    location,
    genres,
    countries,
    requestFetchCountriesProcessing,
    requestTracksProcessing,
    isDownloadTracksPage
}) {
if( typeof genres !== 'undefined' && genres.length > 0){
    genres = genres.sort();
}
    const onRequest = useCallback(
        params => requestTracks({ typeParams: { ...params } }),
        [requestTracks]
    );
    useEffect(() => {
        if (requestTracksProcessing == false) {
            setIsHidePaginator(false);
        }
    }, [requestTracksProcessing]);

    const [genre, setGenre] = useState('');
    const [country, setCountry] = useState('');
    const [dateRange, setDateRange] = useState('');
    const [isHidePaginator, setIsHidePaginator] = useState(hidePaginator);

    useEffect(() => {
        setGenre('');
        setCountry('');
        setDateRange(''); 
    }, ['', location]);

    const setResetFilters = function(){
        setGenre('');
        setCountry('');
        setDateRange('');
        setIsHidePaginator(true);
        onRequest({
            offset: 0,
            limit: 10
        });
    }

    return (
        <div ref={containerRef} style={{ width: "100%" }}>
            {location === '/tracks/top' && 
                <div style={{marginTop: 20, display:'flex'}}>
                    <div  />
                    <select
                        style={{
                            marginRight: 5,
                            height: '2.5rem',
                            minWidth: '2.4375rem',
                            width: 'auto',
                            padding: '0 12px',
                            border: 'none',
                            outline: 'none',
                            backgroundColor: 'var(--theme-background-changed-color)',
                            cursor: 'pointer',
                            color: 'var(--theme-main-color)'
                        }}
                        onChange={(e) =>setGenre(e.target.value)}
                        value={genre}
                    >
                        <option value="">All Genre</option>
                        {genres.map(el => {
                            return(<option value={el} key={el}>{el}</option>)
                        })}
                    </select>
                    <select
                         style={{
                            marginRight: 5,
                            height: '2.5rem',
                            minWidth: '2.4375rem',
                            width: 'auto',
                            padding: '0 12px',
                            border: 'none',
                            outline: 'none',
                            backgroundColor: 'var(--theme-background-changed-color)',
                            cursor: 'pointer',
                            color: 'var(--theme-main-color)'
                        }}
                        onChange={(e) =>setDateRange(e.target.value)}
                        value={dateRange}>
                        <option value="" key="0d">Select Date</option>
                        <option value="7d" key="7d">Last 7 Days</option>
                        <option value="14d" key="14d">Last 14 Days</option>
                        <option value="30d" key="30d">Last 30 Days</option>
                    </select>
                    <select
                         style={{
                            marginRight: 5,
                            height: '2.5rem',
                            minWidth: '2.4375rem',
                            width: '20.04rem',
                            padding: '0 12px',
                            border: 'none',
                            outline: 'none',
                            backgroundColor: 'var(--theme-background-changed-color)',
                            cursor: 'pointer',
                            color: 'var(--theme-main-color)',
                            maxWidth: '21.55rem'
                        }}
                        onChange={(e) =>setCountry(e.target.value)}
                        value={country}
                    >
                        <option value="">All Country</option>
                        {requestFetchCountriesProcessing === false && (countries) && (countries.sort().map(el => {
                            return(<option value={el.code} key={el.code}>{el.name}</option>)
                        }))}
                    </select>
                    <button
                         style={{
                            marginRight: 5,
                            height: '2.5rem',
                            minWidth: '2.4375rem',
                            width: 'auto',
                            padding: '0 12px',
                            border: 'none',
                            outline: 'none',
                            backgroundColor: 'var(--theme-changed-color)',
                            cursor: 'pointer',
                            color: 'var(--theme-main-color)'
                        }}
                        onClick={()=>{onRequest({
                            offset: 0,
                            genres: [genre],
                            country: country,
                            dateRange: dateRange
                        });setIsHidePaginator(true);}}
                    >
                    Filter
                    </button>
                    <button
                         style={{
                            height: '2.5rem',
                            minWidth: '2.4375rem',
                            width: 'auto',
                            padding: '0 12px',
                            border: 'none',
                            outline: 'none',
                            backgroundColor: 'var(--theme-changed-color)',
                            cursor: 'pointer',
                            color: 'var(--theme-main-color)'
                        }}
                        onClick={setResetFilters}
                    >
                        Reset
                    </button>
                </div>
            }
            {isHidePaginator === false && count > 0 && (
                <Paginator
                    request={onRequest}
                    count={count}
                    limit={limit}
                    offset={offset}
                    country={country}
                    dateRange={dateRange}
                    genres={genre}
                    withLimitChoice
                    location={location}
                />
            )}
            {type === "audio" && (
                <TracksList
                    hideHeaders={hideHeaders}
                    className={styles.tracks}
                    limit={limit}
                    indexShift={offset}
                    isDownloadTracksPage={isDownloadTracksPage}
                />
            )}
            {type === "video" && (
                <VideoList
                    className={styles.tracks}
                    limit={limit}
                    indexShift={offset}
                />
            )}
            {isHidePaginator === false && count > 0 && (
                <Paginator
                    request={onRequest}
                    count={count}
                    limit={limit}
                    offset={offset}
                    country={country}
                    dateRange={dateRange}
                    genres={genre}
                    withLimitChoice
                    location={location}
                />
            )}
        </div>
    );
}
