import React from "react";

import { Links } from "components/common/ControlElems";
import styles from "./AuthTabButtons.module.scss";

export default function(props) {
    return (
        <Links
            links={["register", "login"]}
            prefix="oauth"
            className={styles.links}
        />
    );
}
