import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import avatarPlaceholder from "assets/images/avatarPlaceholder.svg";

ImageWithPlaceholder.propTypes = {
    src: PropTypes.string
};

ImageWithPlaceholder.defaultProps = {
    src: ""
};

function ImageWithPlaceholder({ src: rawSrc, ...lastProps }) {
    const [src, setSrc] = useState(rawSrc);

    useEffect(() => {
        if (src !== rawSrc) setSrc(rawSrc);
    }, [rawSrc]);

    return (
        <img
            src={src || avatarPlaceholder}
            alt="image"
            onError={() => setSrc(avatarPlaceholder)}
            {...lastProps}
        />
    );
}

export default React.memo(ImageWithPlaceholder);
