import React, { useState } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import { NavLink } from "react-router-dom";
import FRPexclusivesPopUp from "./FRPexclusivesPopUp";
import { DownloadList, FRPSmallLogo, ProfileIcon, FRPexclusives } from "components/common/icons";
import withRouter from "components/helpers/withRouter";

// import { Banner } from "./Banner";

import styles from "./SideLinks.module.scss";

const WHAT_NEW_LIST = [
    "Completely new website & navigation",
    "New logo",
    "New web address",
    "Revamped Spins with Windows Version and new features",
    "Day & Night mode",
    "Search is now 3x faster",
    "More features to come"
];

SideLinks.propTypes = {
    whatNewList: PropTypes.array,
    showWhatNew: PropTypes.bool
};
SideLinks.defaultProps = {
    whatNewList: WHAT_NEW_LIST,
    showWhatNew: false
};

function SideLinks({ location: { pathname }, whatNewList, showWhatNew }) {
    // const [isFRPexclusives, setIsFRPexclusives] = useState(false);
    // const handleFRPexclusives = () => {
    //     console.log("Clicked");
    //     setIsFRPexclusives(true);
    // };
    return (
        <div className={styles.container}>
            <div className={styles.links}>
                {pathname !== "/apps" && (
                    <NavLink to="/apps">
                        <FRPSmallLogo />
                        <span>FRP Live Apps</span>
                    </NavLink>
                )}
                <NavLink
                    to="/downloads"
                    className={classnames({
                        [styles.active]: pathname === "/downloads"
                    })}
                >
                    <DownloadList />
                    <span>My Downloads</span>
                </NavLink>
                <NavLink
                    to="/profile/personal"
                    className={classnames({
                        [styles.active]: pathname === "/profile/personal"
                    })}
                >
                    <ProfileIcon />
                    <span>My Profile</span>
                </NavLink>
                {/* <NavLink
                    to="#"
                    onClick={handleFRPexclusives}
                    // className={classnames({
                    //     [styles.active]: pathname === "/downloads"
                    // })}
                >
                    <FRPexclusives />
                    <span>FRP Exclusives</span>
                </NavLink> */}
                {/* {isFRPexclusives && 
                    <FRPexclusivesPopUp classNames={styles.frpExclusivePopup} styles={styles} setIsFRPexclusives={setIsFRPexclusives}/>
                } */}
                {/*{pathname !== "/upload" && (  todo revert when page is ready*/}
                {/*<NavLink to="/upload">*/}
                {/*<Remix />*/}
                {/*<span>Upload Remix</span>*/}
                {/*</NavLink>*/}
                {/*)}*/}
            </div>

            {/* <Banner /> */}
        </div>
    );
}

export default withRouter(React.memo(SideLinks));
