import React from "react";
import PropTyps from "prop-types";
import t from "helpers/getText";
import { LoaderPlaceholder } from "components/common/LoaderPlaceholder";

import { VideoElement } from "../../VideoList";
import styles from "./Artist.module.scss";

Videos.propTypes = {
    count: PropTyps.number,
    list: PropTyps.array,
    empty: PropTyps.bool
};

Videos.defaultProps = {
    count: 0,
    list: [],
    empty: false
};

function Videos({ count, list, empty }) {
    return (
        <div>
            {count > 0 ? (
                list.map((elem, index) => {
                    return (
                        <div key={index} style={{ margin: "15px" }}>
                            <VideoElement address={elem.link} key={index} />
                            <p className={styles.videoName}>{elem.name}</p>
                            <p className={styles.videoAuthor}>{elem.artist}</p>
                        </div>
                    );
                })
            ) : (
                <div className={styles.loader}>
                    {empty ? (
                        <h3>{t("rotation.artist.noTrack")}</h3>
                    ) : (
                        <LoaderPlaceholder />
                    )}
                </div>
            )}
        </div>
    );
}

export default React.memo(Videos);
