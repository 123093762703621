import React from "react";
import PropTypes from "prop-types";

TW.propTypes = {
    className: PropTypes.string,
    color: PropTypes.string
};

TW.defaultProps = {
    className: "",
    color: "#C0C1CA"
};

export default function TW(props) {
    const { color, className } = props;
    return (
        <svg className={className} width="34" height="34" viewBox="0 0 34 34">
            <g fill="none" fillRule="evenodd">
                <path
                    fill={color}
                    d="M17.295 14.044l.033.54-.542-.067c-1.973-.253-3.696-1.111-5.16-2.553l-.715-.715-.184.528c-.39 1.177-.141 2.42.672 3.257.433.462.336.529-.412.253-.26-.088-.488-.154-.51-.12-.075.076.185 1.078.39 1.474.282.55.857 1.09 1.486 1.409l.53.253-.628.01c-.607 0-.629.012-.564.243.217.715 1.073 1.475 2.027 1.805l.672.23-.585.353a6.077 6.077 0 0 1-2.905.814c-.488.011-.889.055-.889.088 0 .11 1.323.727 2.092.969 2.31.715 5.051.407 7.11-.814 1.464-.87 2.927-2.598 3.61-4.27.369-.892.737-2.52.737-3.302 0-.506.033-.572.64-1.178.358-.352.694-.737.759-.847.108-.21.097-.21-.456-.022-.92.33-1.05.286-.596-.21.336-.351.737-.99.737-1.177 0-.033-.162.022-.346.121-.196.11-.63.276-.954.375l-.586.187-.53-.363c-.293-.199-.705-.419-.922-.485-.553-.154-1.398-.132-1.897.044-1.355.496-2.211 1.772-2.114 3.17z"
                />
                <path
                    stroke={color}
                    d="M1 17C1 8.163 8.163 1 17 1s16 7.163 16 16-7.163 16-16 16S1 25.837 1 17z"
                    opacity=".4"
                />
            </g>
        </svg>
    );
}
