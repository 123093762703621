import React from "react";
import classnames from "classnames";
import { NavLink } from "react-router-dom";

import { Button } from "components/common/ControlElems";
import routerDecorator from "components/helpers/withRouter";
import styles from "components/main//Navbar.module.scss";

const LINKS_WITH_ONLY_LOGO = ["/register", "/login", "/resetPassword"];

function ButtonsForGuests({ location, classNames }) {
    return (
        LINKS_WITH_ONLY_LOGO.includes(location.pathname) === false && (
            <div className={classnames(styles.logButtonsContainer, classNames)}>
                <NavLink to="/register">
                    <Button
                        withoutPadding
                        size="tiny"
                        width="7.5rem"
                        label="Sign Up"
                    />
                </NavLink>
                <NavLink to="/login">
                    <Button
                        withoutBorder
                        withoutPadding
                        size="tiny"
                        width="7.5rem"
                        label="Log In"
                    />
                </NavLink>
            </div>
        )
    );
}

export default routerDecorator(ButtonsForGuests);
