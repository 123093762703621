import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import { NavLink } from "react-router-dom";
import style from "assets/newHome/css/style.scss";
import routerDecorator from "components/helpers/withRouter";
import t from "helpers/getText";
import { Player } from "components/Player";
import { Logo } from "components/common/Logo";
import styles from "./Footer.module.scss";
import footerLogo from "assets/newHome/images/footer-logo.png";
import app from "assets/newHome/images/app.png"; 
import google from "assets/newHome/images/google.png";
 
import { FBfooter, IGfooter, TWfooter } from "../common/icons";
import { BlockList } from "net";
const LINKS_WITHOUT_MAIN_FOOTER = ["/splash", "/register", "/login"];

Footer.propTypes = {
  location: PropTypes.object.isRequired
}; 

function Footer(props) {
  const { location } = props;
  const containerClassName = classnames(styles.container);
  const mainFooterClassName = classnames(
    styles.mainFooter,
    LINKS_WITHOUT_MAIN_FOOTER.includes(location.pathname) && styles.hidden
  );

  return (
   
    <div className={containerClassName} id="footerWrapper" style={{display:'block'}}>
      <Player />
     <div className="mainHomePage">
      <footer id="footer-wrap" className="footer-section footer-spacing">
        <div className="footer-wrap">
          <div className="container">
            <div className="row">
              <div className="col-12 col-sm-12 col-md-12 col-lg-2 grid-item-space mb-lg-0 mb-4 align-self-center">
                <div className="footer-sec footer-sec-1">
                  <div className="footer-widget-area mb-7">
                    <a href="index.html" className="footer-logo"><img src={footerLogo} width={188} height={78} alt="Footer Logo" /></a>
                  </div>
                </div>
              </div>
              {/*Footer social icon close */}
              <div className="col-sm-12 col-md-8 col-lg-7 ">
                <div className="row">
                  <div className="col-6 col-sm-6 col-md-4 col-lg-4 footer-top-space grid-item-space mb-md-0 mb-3">
                    <div className="footer-sec  ml-lg-4">
                      <div className="footer-widget-area">
                        <div className="footer-menu-list">
                          <div className="footer-sec-title">
                            <h6>What we do</h6>
                          </div>
                          <div className="footer-menu footer-menu1">
                            <ul className="menu-list list-unstyled px-0 m-0">
                              <li>
                                <NavLink className="active-link" to="/home">Home</NavLink>
                              </li>
                              <li><NavLink className="active-link" to="/tracks/all">Music</NavLink>
                              </li>
                              <li>
                                <NavLink to="/apps">Apps</NavLink>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-6 col-sm-6 col-md-4 col-lg-4 footer-top-space grid-item-space mb-md-0 mb-3">
                    <div className="footer-sec ml-lg-4">
                      <div className="footer-widget-area">
                        <div className="footer-menu-list">
                          <div className="footer-sec-title">
                            <h6>Connect</h6>
                          </div>
                          <div className="footer-menu footer-menu2 footer-social-icon">
                            <ul className="menu-list list-unstyled px-0 m-0">
                              <li><a href="https://www.facebook.com/frppool" target="_blank" >
                                <FBfooter /></a></li>
                              <li><a href="https://www.instagram.com/franchiserecordpool/" target="_blank">                                <IGfooter /></a></li>
                              <li><a href="https://twitter.com/frpmusicpool" target="_blank">
                                <TWfooter /></a></li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-6 col-sm-6 col-md-4 col-lg-4 footer-top-space grid-item-space mb-md-0 mb-3">
                    <div className="footer-sec ml-lg-4">
                      <div className="footer-widget-area">
                        <div className="footer-menu-list">
                          <div className="footer-sec-title">
                            <h6>Help</h6>
                          </div>
                          <div className="footer-menu footer-menu3">
                            <ul className="menu-list list-unstyled px-0 m-0">
                              <li><NavLink to="/faq">FAQ </NavLink></li>
                              <li> <NavLink to="/contactUs">Contact</NavLink></li>
                              <li><NavLink to="/agreement">Agreement</NavLink></li>
                              <li><NavLink to="/disclaimer">Disclaimer & DMCA</NavLink></li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* Footer page links close */}
            <div className="col-12 col-sm-12 col-md-4  col-lg-3 footer-top-space grid-item-space">
              <div className="footer-sec footer-sec-4">
                <div className="footer-widget-area">
                  <div className="footer-sec-title pb-2">
                    <h6>Download our app</h6>
                  </div>
                  <div className="footer-image">
                    <div className="image-wrap">
                      <a href="https://apps.apple.com/us/app/frp-live/id1360454793?ls=1"> <img src={app} width={140} alt="app store image" /></a>
                    </div>
                    <div className="image-wrap">
                      <a href="https://play.google.com/store/apps/details?id=com.st.frpnew"><img src={google} width={140} alt="app store image" /></a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* Working hours close */}
          </div>
        </div>
        <div className="footer-widget-area">
          <div className="copyright-info">
            <p className="mb-0">© 2023. Franchise Record Pool. USA. All Rights Reserved.</p>
          </div>
        </div>
    </div>
        </footer >
</div>
        </div >
    );
}

export default routerDecorator(Footer);
