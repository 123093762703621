const splash = {
    splash: {
        section2: {
            welcome: "Welcome to FRP!",
            subtext: "Music professionals only",
            features: [
                {
                    head: "Feature",
                    text:
                        "Reach the largest database of radio DJs , mobile DJs and club DJs on the planet. The only service that built the tools you need from."
                },
                {
                    head: "Feature",
                    text:
                        "Reach the largest database of radio DJs , mobile DJs and club DJs on the planet. The only service that built the tools you need from."
                },
                {
                    head: "Feature",
                    text:
                        "Reach the largest database of radio DJs , mobile DJs and club DJs on the planet. The only service that built the tools you need from."
                }
            ],
            enterButton: "Enter"
        },
        section3: {
            features: [
                {
                    head: "Members",
                    text:
                        "The largest and most diverse music pool servicing tons of the most influential radio and club DJs on the planet."
                },
                {
                    head: "Website",
                    text:
                        "Modern state of the art UI facilitating ultra-easy navigation with warp speed search and download."
                },
                {
                    head: "Apps",
                    text:
                        "Our futuristic apps allow downloading to PC remotely, quick drag & drop into DJ software during gigs and audience music request chat with download button."
                }
            ],
            enterButton: "Enter"
        }
    }
};

export default splash;
