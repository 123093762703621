import React from "react";
import moment from "moment";
import { renderAlbumTrackButtons, renderAlbumTracks } from "../Helpers";
import { NO_KEY } from "../../../../helpers/constants/keys";

function AlbumTrackContent({
    styles,
    genre,
    bpm,
    musicKey,
    released,
    track,
    presetName,
    activeLetter,
    superShortScrollbarRef,
    index,
    isBillboard = false,
    size
}) {
    const displayKey = musicKey === NO_KEY ? "" : musicKey;
    return (
        <>
            <div className={styles.genre}>Album<br/>{genre}</div>
            <div className={styles.bpm}>{bpm}</div>
            <div className={styles.key}>{displayKey}</div>
            <div className={styles.released}>
                {moment(released).format("MM-DD-YYYY")}
            </div>
            {renderAlbumTrackButtons({
                className: styles.buttons,
                size,
                track,
                activeLetter,
                superShortScrollbarRef,
                index
            })}
            {renderAlbumTracks({
                size,
                track,
                styles,
                className: styles.vers,
                presetName,
                isBillboard
            })}
        </>
    );
}

export default AlbumTrackContent;
