const registration = {
    registration: {
        name: "Full Name",
        email: "Email",
        phone: "Phone",
        login: "Login",
        password: "Password",
        confirmPassword: "Confirm Password",

        accountMessage: "Please do not create a new account if you already have an account on" +
            " www.franchiserecordpool.com. Both sites are the same and the old site will be shut down very soon.",
        instruction: "If you have an account on www.franchiserecordpool.com just login into www.frp.live with the" +
            " same exact username and password and you will gain access to the site under your existing account.",
        contactsInfo: "For any questions or concerns please feel free to contact the support service by phone" +
            " ( 347-449-5129 ) or email (techsupport@franchiserecordpool.com)."
    }
};

export default registration;
