import AbstractFieldsValidator from "components/helpers/validation/AbstractFieldsValidator";
import checkForEmail from "components/helpers/validation/checkForEmail";

export default function(fields, options) {
    return new ResetPasswordFieldsValidator(fields, options).validate();
}

class ResetPasswordFieldsValidator extends AbstractFieldsValidator {
    doCheckForSingleValue() {
        const { email } = this.fields;

        checkForEmail(email, this.addError);
    }
}
