import React, { useState } from 'react';
import JSZip from 'jszip';
import styles from "./Version.module.scss";
import classnames from "classnames";
const DownloadButton = ({track,size}) => {
  const [isDownloading, setIsDownloading] = useState(false);
  const handleDownload = async (track) => {
    if(isDownloading === true){
      return;
    }
    setIsDownloading(true);
    const trackUrls = (track.tracks && track.tracks.length > 0) ? await track.tracks.map(elm => elm.source) : [];
    // const trackUrls = ['http://localhost:3000/tracks/Alkaline - Open A Brain new12 (Clean).mp3','http://localhost:3000/tracks/Alkaline - Open A Brain new12 (Dirty).mp3','http://localhost:3000/tracks/Alkaline - Open A Brain track 111 (Dirty).mp3'];
    const zip = new JSZip();
    console.log(track, 'track-----------track');
    try {
      const downloadPromises = trackUrls.map(async (url, index) => {
        const response = await fetch(url);
        const buffer = await response.arrayBuffer();
        const fileName = decodeURIComponent(url).split('/').pop();
        zip.file(fileName, buffer);
      });

      await Promise.all(downloadPromises);

      const content = await zip.generateAsync({ type: 'blob' });

      const link = document.createElement('a');
      link.href = URL.createObjectURL(content);
      link.download = track.artist+' - '+track.name+'.zip';
      link.click();
      setIsDownloading(false);
    } catch (error) {
      console.error('Error:', error);
    }
  };

  return (
    (typeof track !== 'undefined' && track && track.tracks && track.tracks.length > 0) ? (
      <button onClick={() => handleDownload(track)} className={classnames(styles.defaultVersion)} data-size={size}>
        {(isDownloading === true) ? 'Downloading...' : 'Download Album'}
      </button>
    ) : null  // return null when don't want to render anything
  );
};

export default DownloadButton;
