const tracks = {
    tracks: {
        headers: {
            "tracks/releases": "New Release",
            "tracks/top": "Top Tracks",
            "tracks/pick": "FRP Picks",
            mega: {
                "/tracks/rank": "Billboard 100",
                "/tracks/all": "All Music",
                "/tracks/top": "Top Tracks",
                "/downloads": "My downloads",
                "/search": "Search",
                "/tracks/curated": "Curated Sets",
                downloads: "My downloads"
            }
        },
        filters: {
            title: "Show only",
            types: {
                bpm: "BPM",
                genres: "Genres",
                version: "Version"
            },
            buttons: {
                reset: "RESET ALL FILTERS",
                showAll: "Show all",
                hide: "Hide"
            }
        },
        whatNew: {
            header: "What's new?"
        },
        labels: {
            remix: "Remixes",
            original: "Original Tracks",
            introOutro: "Intro / Outro Tracks"
        },

        previewTrackLimitedMessage:
            "We are sorry but you have reached the maximum PREVIEW limit for this track",
        downloadTrackLimitedMessage:
            "We are sorry but you have reached the maximum DOWNLOAD limit for this track",
        downloadArchiveLimitedMessage:
            "We are sorry but you have reached the maximum DOWNLOAD limits for some tracks in the archive",
    }
};

export default tracks;
