export function createRequestHelper(targetKey) {
    const processingKey = targetKey + "Processing";
    const resultKey = targetKey + "Result";
    const errorKey = targetKey + "Error";

    return {
        initialState: {
            [processingKey]: false,
            [resultKey]: null,
            [errorKey]: null
        },

        processing() {
            return {
                [processingKey]: true,
                [resultKey]: null,
                [errorKey]: null
            };
        },

        result(resultObject) {
            return {
                [processingKey]: false,
                [resultKey]: resultObject,
                [errorKey]: null
            };
        },

        error(errorObject) {
            return {
                [processingKey]: false,
                [resultKey]: null,
                [errorKey]: errorObject
            };
        },

        clear() {
            return {
                [processingKey]: false,
                [resultKey]: null,
                [errorKey]: null
            };
        }
    };
}
