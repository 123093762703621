import React from "react";
import PropTypes from "prop-types";
import { Button } from "components/common/ControlElems";
import styles from "./Auth.module.scss";

export default function UserRegisterTabs(props) {
    const { stage, setStage } = props;

    return (
        <div className={styles.tabButtonsContainer}>
            <Button
                label="I am dj"
                style={{ padding: 0, width: "6.5rem" }}
                underlined
                active={stage === "dj"}
                onClick={() => setStage("dj")}
            />
            {/*
            <Button
                label="I am label"
                underlined
                style={{ padding: 0, width: "6.5rem" }}
                active={stage === "label"}
                onClick={() => setStage("label")}
            />
            */}
        </div>
    );
}

UserRegisterTabs.propTypes = {
    stage: PropTypes.string,
    setStage: PropTypes.func.isRequired
};
