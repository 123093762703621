import React from "react";

export default function ArrowRightDouble({ color }) {
    const stroke = color || "#BB1D7E";
    return (
        <svg width="13" height="12" viewBox="0 0 13 12">
            <g fill="none" fillRule="evenodd" stroke={stroke} strokeWidth="1.5">
                <path d="M6.757 1L12 6.243l-5.243 5.242M.757 1L6 6.243.757 11.485" />
            </g>
        </svg>
    );
}
