export const FILTERS_INITIAL_STATE = {
    type: "audio",
    presetName: "All",
    presetForNextRequest: false,
    sortField: "createdAt",
    order: "DESC",
    artistId: "",
    status: "",
    searchQuery: "",
    bpm: [0, 256],
    audio: {
        artistId: "",
        list: [],
        displayVersions: [],
        count: 0,
        offset: 0,
        limit: 10,
        status: "A"
    },
    video: {
        list: [],
        count: 0,
        offset: 0,
        limit: 10
    },
    genres: [],
    versions: [],
    keys: []
};

export const INITIAL_STATE = {
    ...FILTERS_INITIAL_STATE,
    artists: {
        list: [],
        spotlight: {},
        count: 0,
        limit: 6,
        offset: 0,
        id: "",
        name: "",
        active: "",
        bio: "",
        facebook: "",
        twitter: "",
        snapchat: "",
        instagram: "",
        cover: "",
        avatar: ""
    },
    splash: { list: [], count: 0 },
    choosenTrack: { track: null, isPlaying: false },
    downloadList: [],
    allGenres: [],
    allVersions: [],
    VersionsData: [],
    allKeys: [],
    galleryElems: [],
    scrollbar: null,
    filterOpacity: 1,
    isTrackLimited: false,
    trackLimitedMessage: '',
    banners: false,
    bannerRequest: false
};
