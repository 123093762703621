import React from "react";

import { Button } from "components/common/ControlElems";
import { BaseDialog } from "components/common/Dialogs";

import t from "helpers/getText";

import styles from "./ConnectionErrorDialog.module.scss";

const ConnectionErrorDialog = props => {
    const { onClose } = props;

    return (
        <BaseDialog
            height="short-up"
            withoutCloseButton
            withoutScrollbar
            onClose={() => {}}
        >
            <div className={styles.container}>
                <h2 className={styles.header}>
                    {t("commonDialogs.connectionError.title")}
                </h2>
                <span className={styles.text}>
                    {t("commonDialogs.connectionError.text")}
                </span>
                <div style={{ flex: 1 }} />
                <Button
                    color="bright"
                    width="14.5rem"
                    label={t("commonDialogs.connectionError.button")}
                    onClick={onClose}
                />
            </div>
        </BaseDialog>
    );
};

export default React.memo(ConnectionErrorDialog);
