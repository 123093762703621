import React from "react";
import PropTypes from "prop-types";

Dropdown.propTypes = {
    className: PropTypes.string,
    color: PropTypes.string
};

Dropdown.defaultProps = {
    className: "",
    color: "#9B2468"
};

export default function Dropdown(props) {
    const { color, className } = props;
    return (
        <svg className={className} width="24" height="24" viewBox="0 0 24 24">
            <g fill="none" fillRule="evenodd">
                <path
                    stroke={color}
                    strokeWidth="2"
                    d="M7 10l5.183 5.183L17.367 10"
                />
                <path d="M0 0h24v24H0z" />
            </g>
        </svg>
    );
}
