import React from "react";
import PropTypes from "prop-types";

Added.propTypes = {
    className: PropTypes.string,
    color: PropTypes.string
};

Added.defaultProps = {
    className: "",
    color: "#bb1d7e"
};

export default function Added(props) {
    const { color, className } = props;
    return (
        <svg className={className} width="26" height="26" viewBox="0 0 26 26">
            <g fill="none">
                <circle
                    cx="12"
                    cy="12"
                    r="11.5"
                    stroke={color}
                    strokeWidth={1.5}
                />
                <path
                    fill="none"
                    fillRule="evenodd"
                    stroke={color}
                    strokeWidth="2"
                    d="M4.966 12.5l4.5 4.5 9-9"
                />
            </g>
        </svg>
    );
}
