import React from "react";
import makeHttpRequest from "sources/api/http/makeHttpRequest";
import bindFunctions from "./helpers/bindFunctions";
import { createRequestHelper } from "./helpers/requestHelper";
import consumeToContext from "./helpers/consumeToContext";
import { AuthContext } from "./AuthContext";

export const UserRegisterContext = React.createContext();

const registerRequestHelper = createRequestHelper("registerUser");

class UserRegisterContextContainer extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            ...registerRequestHelper.initialState,
            needDisplayErrorScreen: false,
            confirmEmailError: null
        };

        this.funcs = bindFunctions(this, {
            registerUser: this.registerUser,
            clearNeedDisplayErrorScreen: this.clearNeedDisplayErrorScreen,
            confirmEmail: this.confirmEmail
        });
    }

    async registerUser(fields, userType) {
        this.setState(registerRequestHelper.processing());

        try {
            const userPath = userType === "dj" ? "djusers" : "labelusers";
            const result = await makeHttpRequest({
                method: "post",
                path: `/${userPath}/register`,
                data: fields
            });

            if (result.user) this.props.auth.setUser(result.user);
            this.setState(registerRequestHelper.clear());
        } catch (error) {
            this.setState(registerRequestHelper.error(error));
            this.setState({
                needDisplayErrorScreen: true
            });
        }
    }

    clearNeedDisplayErrorScreen() {
        this.setState({
            needDisplayErrorScreen: false
        });
    }

    async confirmEmail(userId, token) {
        try {
           await makeHttpRequest({
                method: "post",
                path: `/users/confirmEmail`,
                data: { userId, token }
            });

            await this.props.auth.loginWithToken();
        } catch (error) {
            this.setState({
                confirmEmailError: error
            });
        }
    }

    render() {
        return (
            <UserRegisterContext.Provider
                value={{ ...this.state, ...this.funcs }}
            >
                {this.props.children}
            </UserRegisterContext.Provider>
        );
    }
}

export default consumeToContext("auth", AuthContext)(
    UserRegisterContextContainer
);
