import React, { Fragment, useContext } from "react";
import classnames from "classnames";
import styles from "./FeaturesList.module.scss";
import { UIContext } from "context/UIContext";
import ArrowRightDouble from "../common/icons/ArrowRightDouble";

export default function FeaturesList({ items, className }) {
    const { theme } = useContext(UIContext);
    return (
        <div className={classnames(styles.container, className)}>
            {items.map((el, index) => {
                return (
                    <Fragment key={index}>
                        <ArrowRightDouble color={"#d9002f"} />
                        <div>{el}</div>
                    </Fragment>
                );
            })}
        </div>
    );
}
