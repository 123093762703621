import React from "react";
import PropTypes from "prop-types";

MenuTopDetail.propTypes = {
    className: PropTypes.string,
    color: PropTypes.string
};

MenuTopDetail.defaultProps = {
    className: "",
    color: "#bb1d7e"
};

export default function MenuTopDetail(props) {
    const { color, className } = props;
    return (
        <svg
            className={className}
            width="29px"
            height="25px"
            viewBox="0 0 29 25"
        >
            <g
                id="Media"
                stroke="none"
                strokeWidth="1"
                fill="none"
                fillRule="evenodd"
            >
                <g
                    id="FRP--Media-Popups"
                    transform="translate(-1282.000000, -76.000000)"
                    fill={color}
                >
                    <g
                        id="Element"
                        transform="translate(1277.000000, 74.000000)"
                    >
                        <path
                            d="M7.21939928,7.131568 C14.9854294,10.6792464 21.0094848,10.3120452 25.2915656,6.02996448 L33.8062842,14.5446831 L14.402865,33.9481023 L5.7982536,25.5232764 C10.0420431,21.2794869 10.5157584,15.1489174 7.21939928,7.131568 Z"
                            id="Rectangle"
                            transform="translate(19.802269, 19.989033) rotate(45.000000) translate(-19.802269, -19.989033) "
                        />
                    </g>
                </g>
            </g>
        </svg>
    );
}
