function addToDownloadList({ track, setDownloads }) {
    const { name, key } = track;
    const currentVersion = track.versions[0];
    setDownloads({
        type: "add",
        element: {
            versionId: currentVersion.id,
            name,
            artist: track.artist,
            fileName: currentVersion.fileName,
            version: currentVersion.name,
            key,
            type: "audio"
        }
    });
}

export default addToDownloadList;
