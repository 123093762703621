import React, { useState } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import styles from "./TextField.module.scss";

TextField.propTypes = {
    label: PropTypes.string,
    error: PropTypes.string,
    name: PropTypes.string.isRequired,
    type: PropTypes.string,
    max: PropTypes.number,
    classes: PropTypes.object,
    value: PropTypes.any,
    placeholder: PropTypes.string,
    fullWidth: PropTypes.bool,
    lightMode: PropTypes.bool,
    controlled: PropTypes.bool,
    onChange: PropTypes.func.isRequired
};

TextField.defaultProps = {
    label: "",
    error: "",
    placeholder: "",
    type: "text",
    value: "",
    fullWidth: false,
    classes: {},
    lightMode: false,
    controlled: false
};

export default function TextField(props) {
    const {
        name,
        type,
        error,
        label,
        classes,
        value,
        fullWidth,
        max,
        onChange,
        lightMode,
        lastProps,
        controlled
    } = props;

    const [filled, setFilled] = useState(Boolean(value));

    const inputProperties = {
        id: name,
        name,
        type,
        maxLength: max,
        onChange,
        className: classnames(styles.input, filled && styles.filled),
        ...lastProps
    };
    if (controlled) {
        inputProperties.value = value;
    } else {
        inputProperties.defaultValue = value;
    }

    return (
        <div
            className={classnames(
                styles.field,
                fullWidth && styles.fullWidth,
                lightMode && styles.lightMode,
                { [classes.root]: Boolean(classes.root) },
                { [styles.hasError]: Boolean(error) }
            )}
        >
            <label htmlFor={name} className={styles.inputContainer} />
            <input
                {...inputProperties}
                onFocus={e => checkToFill(setFilled, e, true)}
                onChange={e =>
                    checkToFill(setFilled, e, false, inputProperties)
                }
                onBlur={e => checkToFill(setFilled, e)}
            />
            <span className={styles.label}>{label}</span>
            <div className={styles.error}>{props.error}</div>
        </div>
    );
}

function checkToFill(setFilled, event, force, inputProperties) {
    let result = false;
    if (force || event.target.value.length > 0) {
        result = true;
    }
    setFilled(result);
    if (inputProperties && inputProperties.onChange) {
        inputProperties.onChange(event);
    }
}
