import React from "react";
import Checkbox from "components/common/form/CheckBoxField";

import styles from "./AllFilters.module.scss";

import { handleSetCheckbox } from "./SideFIlterHelper";

export default function GenreCheckbox({
    filters,
    setFilters,
    elem,
    commonKey
}) {
    return (
        <Checkbox
            key={elem}
            classes={{
                root: styles.checkbox,
                input: styles.checkboxInput,
                checked: styles.checkboxInputChecked
            }}
            label={elem}
            reverse
            value={filters[commonKey].includes(elem)}
            onChange={() =>
                handleSetCheckbox(elem, filters, commonKey, setFilters)
            }
        />
    );
}
