export const NO_KEY = "No key";
export const NUMERICS_KEYS = {
    A: "11B",
    Ab: "4B",
    Abm: "1A",
    Am: "8A",
    "A#m": "3A",
    "A#maj": "4B",
    B: "1B",
    Bb: "6B",
    Bm: "10A",
    Bbm: "3A",
    C: "8B",
    Cm: "5A",
    "C#maj": "8B",
    "C#mi": "5A",
    "C#m": "12A",
    D: "10B",
    "D#": "5B",
    "D#m": "2A",
    "D-": "10B",
    Db: "3B",
    Dm: "7A",
    E: "12B",
    Eb: "5B",
    Ebm: "2A",
    Em: "9A",
    Emaj: "12B",
    F: "7B",
    Fm: "4A",
    Fmi: "4A",
    "F#m": "11A",
    G: "9B",
    Gb: "2B",
    "G#m": "1A",
    Gmin: "6A",
    Gm: "6A"
};

export const LITERAL_KEYS = {
    "1A": ["G#m", "Abm"],
    "1B": ["B"],
    "2A": ["Ebm", "D#m"],
    "2B": ["Gb"],
    "3A": ["Bbm", "A#m"],
    "3B": ["Db"],
    "4A": ["Fmi", "Fm"],
    "4B": ["A#maj"],
    "5A": ["C#mi", "Cm"],
    "5B": ["Eb", "D#"],
    "6A": ["Gm", "Gmin"],
    "6B": ["Bb"],
    "7A": ["Dm"],
    "7B": ["F"],
    "8A": ["Am"],
    "8B": ["C#maj", "C"],
    "9A": ["Em"],
    "9B": ["G"],
    "10A": ["Bm"],
    "10B": ["D-", "D"],
    "11A": ["F#m"],
    "11B": ["A"],
    "12A": ["C#m"],
    "12B": ["Emaj", "E"]
};
