import React, { useContext, useEffect } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import styles from "./Rotation.module.scss";
import { ContentContext } from "../../../context/ContentContext";
import { Paginator } from "components/common/ControlElems";
import t from "helpers/getText";
import { UIContext } from "context/UIContext";
import ArtistBlock from "./ArtistBlock";
import LoaderPlaceholder from "components/common/LoaderPlaceholder/LoaderPlaceholder";

Rotation.propTypes = {
    isLong: PropTypes.bool
};
Rotation.defaultProps = {
    isLong: true
};

export default function Rotation(props) {
    const { isLong } = props;
    const { theme } = useContext(UIContext);
    const {
        requestArtists,
        artists,
        requestActualArtists,
        requestArtistsProcessing
    } = useContext(ContentContext);
    useEffect(() => {
        if (isLong) {
            requestArtists({ id: "", name: "", visible: true });
        } else {
            requestActualArtists();
        }
    }, []);

    return (
        <div
            className={classnames(
                styles.container,
                isLong && styles.background,
                styles[theme]
            )}
        >
            <div className={styles.content}>
                {isLong && (
                    <div className={styles.headerContainer}>
                        <h1>{t("rotation.header")}</h1>
                    </div>
                )}
                <div className={styles.artistsList}>
                    {artists.list.map(artist => {
                        const {
                            id,
                            bio,
                            cover,
                            name,
                            instagram,
                            facebook,
                            twitter,
                            avatar
                        } = artist;
                        return (
                            <ArtistBlock
                                key={id}
                                id={id}
                                bio={bio}
                                cover={cover}
                                avatar={avatar}
                                fullName={name}
                                facebook={facebook}
                                twitter={twitter}
                                instagram={instagram}
                            />
                        );
                    })}
                    {requestArtistsProcessing && (
                        <div className={styles.loader}>
                            <LoaderPlaceholder />
                        </div>
                    )}
                </div>
                {isLong && (
                    <Paginator
                        className={styles.paginator}
                        request={requestArtists}
                        count={artists.count}
                        limit={artists.limit}
                        offset={artists.offset}
                    />
                )}
            </div>
        </div>
    );
}
