import React from "react";
import PropTypes from "prop-types";

LeftArrow.propTypes = {
    color: PropTypes.string
};

LeftArrow.defaultProps = {
    color: "#fff"
};

export default function LeftArrow({ color }) {
    return (
        <svg width="40" height="40" viewBox="0 0 40 40">
            <g fill="none" fillRule="evenodd">
                <circle cx="20" cy="20" r="19.5" stroke={color} opacity=".3" />
                <path
                    fill={color}
                    d="M14.829 21l4.656 4.656-1.414 1.414L11 20l1.414-1.415 5.66-5.658 1.413 1.414L14.83 19h14.19v2h-14.19z"
                />
            </g>
        </svg>
    );
}
