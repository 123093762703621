import AbstractFieldsValidator from "components/helpers/validation/AbstractFieldsValidator";
import validatePassword from "components/helpers/validation/validatePassword";

export default function(fields, options) {
    return new ConfirmResetPasswordFieldsValidator(fields, options).validate();
}

class ConfirmResetPasswordFieldsValidator extends AbstractFieldsValidator {
    doCheckForSingleValue() {
        const { newPassword } = this.fields;

        const newPasswordError = validatePassword(newPassword);
        if (newPasswordError) this.addError("newPassword", newPasswordError);
    }
}
