import React, { useContext, useEffect } from "react";
import PropTypes from "prop-types";

import { SubscriptionContext } from "context/SubscriptionContext";
import { Button } from "components/common/ControlElems";
import { BaseDialog } from "../../common/Dialogs";
import routerDecorator from "components/helpers/withRouter";
import t from "helpers/getText";
import styles from "./ProfileDialogs.module.scss";

ProcessPaypalSubscriptionDialog.propTypes = {
    onClose: PropTypes.func.isRequired,
    location: PropTypes.object
};

function ProcessPaypalSubscriptionDialog({ onClose, location, history }) {
    const { search } = location;
    const query = search.split("?");
    const params = query[1].split("&");
    const token = params[0].split("=");
    const { subscribe, subscriptionUserResult } = useContext(
        SubscriptionContext
    );
    useEffect(() => {
        subscribe({ token: token[1], type: "paypal" });
    }, []);
    useEffect(() => {
        if (subscriptionUserResult) {
            onClose();
            history.push("/profile");
        }
    }, [subscriptionUserResult]);

    return (
        <BaseDialog onClose={onClose} height="short" width="narrow">
            <div className={styles.container}>
                <h2 className={styles.cancelHeader}>
                    {t("subscription.processingPaypalSubscription")}
                </h2>
                <Button
                    color="bright"
                    width="14.5rem"
                    disabled
                    label={t("subscription.buttons.processing")}
                />
            </div>
        </BaseDialog>
    );
}

export default routerDecorator(ProcessPaypalSubscriptionDialog);
