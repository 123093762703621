import React, { useContext } from "react";
import _ from "lodash";
import { ContentContext } from "../../../../context/ContentContext";
import { IconButton } from "../../../../components/common/ControlElems";
import { Download, Add, Added } from "../../icons";
import { addToDownloadList } from "../Helpers";
import { RatingDropdown } from "./index";

const AddAndDownloadButtons = ({
    track,
    showRate,
    setShowRate,
    className,
    superShortScrollbarRef,
    size,
    index,
    isPlayer=false,
}) => {
    const { downloadList, setDownloads, downloadMedia } = useContext(
        ContentContext
    );

    const ifInDownloadList = downloadList.some(
        media => media.versionId === track.versions[0].id
    );
    const onDelete = () =>
        setDownloads({
            type: "delete",
            element: {
                key: track.id,
                version: track.versions[0].name,
                versionId: track.versions[0].id
            }
        });
    const onTryAddToList = () => {
        if (track.rating > 0) {
            addToDownloadList({ track, setDownloads });
        } else {
            setShowRate(showRate === "add" ? null : "add");
        }
    };
    const onTryToDownload = () => {
        if (track.rating > 0) {
          //  console.log(track, 'track.rating-----if')
            downloadMedia({
                mediaType: "audio",
                mediaKey: track.versions[0].fileName,
                mediaName: track.name,
                nameToDisplay: _.get(track, "versions[0].nameToDisplay"),
                artist: track.artist,
                id: track.id,
                version: track.versions[0].name,
                versionId: (typeof track.TvId !== 'undefined' && track.TvId > 0) ? track.TvId : track.versions[0].id
            });
        } else {
            setShowRate(showRate === "download" ? null : "download");
        }
    };
   // console.log(track, 'track.rating-----i3333333')
    return (
        <div className={className} style={{ position: "relative" }}>
            <IconButton onClick={onTryToDownload}>
                {track && track.versions && track.versions[0] && track.versions[0].downloaded ? (
                    <Added />
                ) : (
                    <Download color="rgb(167, 91, 29)" opacity={1} strokeWidth="2"/>
                )}
            </IconButton>
            {ifInDownloadList ? (
                <IconButton onClick={onDelete}>
                    <Add opacity={1} color="#bb1d7e" />
                </IconButton>
            ) : (
                <IconButton onClick={onTryAddToList}>
                    <Add color="rgb(21, 93, 180)" opacity={1} strokeWidth="2"/>
                </IconButton>
            )}
            {track.rating === 0 && showRate && (
                <RatingDropdown
                    superShortScrollbarRef={superShortScrollbarRef}
                    showRate={showRate}
                    size={size}
                    setShowRate={setShowRate}
                    track={track}
                    index={index}
                    offsetStyle="downloadButton"
                    isPlayer={isPlayer}
                />
            )}
        </div>
    );
};

export default AddAndDownloadButtons;
