import React, { useEffect, useState, useRef } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import { IconButton } from "components/common/ControlElems";
import t from "helpers/getText";
import { DropDownArrow } from "../icons";

const styles = require("./DropDown.module.scss");

DropDown.propTypes = {
    currentValue: PropTypes.any,
    list: PropTypes.array.isRequired,
    onChange: PropTypes.func.isRequired
};

DropDown.defaultProps = {
    currentValue: undefined
};

function DropDown({ currentValue, list, onChange }) {
    const handleArrowClick = e => {
        e.preventDefault();
        e.stopPropagation();
        showDropDown(true);
    };
    const [dropDownIsShowed, showDropDown] = useState(false);
    const hideHandler = useRef({ current: null });

    useEffect(() => {
        if (dropDownIsShowed) {
            const documentClickHandler = () => {
                showDropDown(false);
            };
            hideHandler.current = documentClickHandler;
            document.addEventListener("click", documentClickHandler);
        } else {
            document.removeEventListener("click", hideHandler.current);
        }
        return () => document.removeEventListener("click", hideHandler.current);
    }, [dropDownIsShowed]);

    return (
        <div className={styles.mainContainer}>
            <IconButton
                className={styles.showButton}
                onClick={handleArrowClick}
                withoutResize
            >
                <DropDownArrow
                    className={classnames(
                        styles.arrow,
                        dropDownIsShowed && styles.rotated
                    )}
                />
                <div>
                    {typeof currentValue === "object"
                        ? currentValue.label
                        : currentValue}
                </div>
            </IconButton>
            <div
                className={classnames(
                    styles.list,
                    dropDownIsShowed && styles.show
                )}
            >
                {list.map(elem => (
                    <button
                        key={
                            typeof elem === "object" && elem.value
                                ? elem.value
                                : elem
                        }
                        className={elem === currentValue ? styles.active : ""}
                        onClick={() => onChange(elem)}
                    >
                        {typeof elem === "object" ? elem.label : elem}
                    </button>
                ))}
            </div>
        </div>
    );
}

export default React.memo(DropDown);
