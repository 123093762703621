// import moment from "moment";

const filterTracks = ({ activeLetter, album }) => {
    let result = splitInLists(album.tracks, album);
    const { original, remix, introOutro } = result;
    switch (activeLetter) {
        case "O":
            result = { original };
            break;
        case "I":
            result = { introOutro };
            break;
        case "R":
            result = { remix };
            break;
        default:
            break;
    }
    
    return result;
};

export function checkVersionSuitForIntro(track) {
    let result = false;

    if (track.presetId === 14) {
        result = true;
    }

    return result;
}

export function checkVersionSuitForRemixFilter(track, elem) {
    let result = false;

    if (track.presetId === 9 || track.presetId === "9") {
        result = true;
    }

    return result;
}

export function splitInLists(list, album) {
    const lefted = [];
    const result = { original: [], remix: [], introOutro: [] };
    (list && list.length > 0) && list.forEach(elem => {
        if (checkVersionSuitForRemixFilter(elem, album)) {
            result.remix.push(elem);
        } else {
            lefted.push(elem);
        }
    });

    (lefted && lefted.length > 0) && lefted.forEach(elem => {
        if (checkVersionSuitForIntro(elem)) {
            result.introOutro.push(elem);
        } else {
            result.original.push(elem);
        }
    });

    return result;
}

export default filterTracks;
