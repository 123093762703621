import React, { useRef } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import styles from "./TracksListSuperShort.module.scss";
import { Track } from "./Components";
import Scrollbar from "../Scrollbar/Scrollbar";
import t from "helpers/getText";
import { splitInLists } from "./Helpers/filterVersions";

TracksListSuperShort.propTypes = {
    className: PropTypes.string,
    track: PropTypes.object.isRequired,
    indexShift: PropTypes.number
};

TracksListSuperShort.defaultProps = {
    className: "",
    indexShift: 0
};

Track.defaultProps = {
    version: null,
    rating: 0
};

function TracksListSuperShort(props) {
    const { className, track, indexShift } = props;
    const ref = useRef(null);

    const { original, remix, introOutro } = splitInLists(track.versions, track);

    return (
        <div
            className={classnames(
                styles.mainContainer,
                styles.mainContainer_superShort,
                className
            )}
        >
            <div>
                <div className={styles.tracksColumnContainer}>
                    <Scrollbar
                        scrollbarRef={ref}
                        maxHeight
                        autoHeight
                        style={{ width: "320px" }}
                        autoHeightMax="calc(100vh - 35rem)"
                        autoHideTimeout={1000}
                        autoHideDuration={300}
                        autoHide
                    >
                        {renderList({
                            label: "original",
                            list: original,
                            indexShift,
                            ref,
                            track
                        })}
                        {renderList({
                            label: "introOutro",
                            list: introOutro,
                            indexShift,
                            ref,
                            track
                        })}
                        {renderList({
                            label: "remix",
                            list: remix,
                            indexShift,
                            ref,
                            track
                        })}
                    </Scrollbar>
                </div>
            </div>
        </div>
    );
}

const renderList = ({ label, list, indexShift, ref, track }) => {
    if (list.length === 0) return null;
    const labelElem = (
        <div className={styles.versionHeader} key={label}>
            <span className={styles.versionText}>
                {t(`tracks.labels.${label}`)}
            </span>
            <div className={styles.headerLine} />
        </div>
    );
    const listElems = list.map((version, index) => {
        return (
            <div key={version.id} className={styles.trackMainContainer}>
                <Track
                    styles={styles}
                    size="superShort"
                    key={version.id}
                    showCover
                    index={index + 1 + indexShift}
                    track={{ ...track, versions: [version] }}
                    superShortScrollbarRef={ref}
                />
            </div>
        );
    });
    return [labelElem, ...listElems];
};

export default React.memo(TracksListSuperShort);
