import React, { useCallback, useContext, useEffect, useState } from "react";

export const Context = React.createContext({});
Context.displayName = "EnvironmentContext";

const DEFAULT_VERSION = "0.9.0";

export const Provider = props => {
    const { children } = props;

    const [latestSpinsVersion, setLatestSpinsVersion] = useState(
        DEFAULT_VERSION
    );
    const [environment] = useState(
        process.env.REACT_APP_FRP_ENVIRONMENT || "develop"
    );

    const getSpinsAppLinks = useCallback(
        (env, version) => ({
            mac_x64: `https://spinsrelease.frp.live/download/${version}/osx/SpinsInstaller.dmg`,
            mac_arm64: `https://spinsrelease.frp.live/download/${version}/osx_arm64/SpinsInstaller.dmg`,
            windows: `https://spinsrelease.frp.live/download/${version}/windows_64/SpinsInstaller.exe`
        }),
        []
    );

    const state = {
        latestSpinsVersion,
        environment
    };
    const funcs = {
        getSpinsAppLinks
    };

    useEffect(() => {
        const getLatestVersion = async () => {
            try {
                const res = await fetch(
                    `${document.location.origin}/latestSpinsVersion`,
                    {
                        headers: {
                            "Content-Type": "application/json"
                        }
                    }
                );
                const result = await res.json();
                if (result.status === "success") {
                    setLatestSpinsVersion(result.version);
                }
            } catch (error) {
                console.log(error);
            }
        };

        if (process.env.REACT_APP_FRP_ENVIRONMENT === "production") {
            getLatestVersion();
        }
    }, []);

    return (
        <Context.Provider value={{ ...state, ...funcs }}>
            {children}
        </Context.Provider>
    );
};

export const useEnvironmentContext = () => {
    const environmentContext = useContext(Context);

    if (environmentContext === undefined) {
        console.error(
            `${Provider.name} should be within ${Context.displayName}`
        );
    }

    return environmentContext;
};
