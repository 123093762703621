import React, { useContext, useEffect } from "react";
import classnames from "classnames";
import { ContentContext } from "../../../context/ContentContext";
import styles from "../../main/Navbar.module.scss";
import { Cross, Search } from "../icons";
import withRouter from "../../helpers/withRouter";
import { AuthContext } from "../../../context/AuthContext";
import { IconButton } from "../ControlElems";

const SearchRow = ({
    history,
    className,
    localSearchQuery,
    setLocalSearchQuery,
    callbackAfterSearch,
    location
}) => {
    const { user } = useContext(AuthContext);
    const {
        requestTracksWithRetry,
        searchQuery,
        presetName,
        setSearchQuery,
        type
    } = useContext(ContentContext);

    useEffect(() => {
        setLocalSearchQuery(searchQuery);
    }, [searchQuery]);

    useEffect(() => {
        if (location.pathname === "/search") {
            let query = new URLSearchParams(location.search);
            const name = query.get("name");
            if (name === "live-n-livin") {
                requestTracksWithRetry({
                    needToResetList: true,
                    commonParams: { type, searchQuery: "Sean Paul" },
                    presetName
                });
                callbackAfterSearch && callbackAfterSearch();
            }
        }
    }, [location]);

    const onSearchSubmit = e => {
        e.preventDefault();
        e.stopPropagation();
        setSearchQuery(localSearchQuery);
        requestTracksWithRetry({
            needToResetList: true,
            commonParams: { type, searchQuery: localSearchQuery },
            presetName
        });
        callbackAfterSearch && callbackAfterSearch();
        return user ? history.push("/search") : history.push("/register");
    };

    const onDeleteSearchQuery = () => {
        setSearchQuery("");
        history.push("/tracks/");
    };

    return (
        <div className={classnames(styles.search, className)}>
            <div className={styles.input}>
                <form onSubmit={e => onSearchSubmit(e)}>
                    <input
                        id="QueryInputId"
                        value={localSearchQuery}
                        type="text"
                        placeholder="Search"
                        onChange={e => setLocalSearchQuery(e.target.value)}
                    />
                </form>
            </div>
            {searchQuery && (
                <IconButton onClick={onDeleteSearchQuery}>
                    <Cross
                        className={styles.cleanIcon}
                        color="var(--theme-main-color)"
                    />
                </IconButton>
            )}
            <IconButton onClick={e => onSearchSubmit(e)}>
                <Search
                    className={styles.searchIcon}
                    color="var(--theme-main-color)"
                />
            </IconButton>
        </div>
    );
};

export default withRouter(SearchRow);
