import AbstractFieldsValidator from "components/helpers/validation/AbstractFieldsValidator";

export default function(fields, options) {
    return new RegisterFieldsValidator(fields, options).validate();
}

class RegisterFieldsValidator extends AbstractFieldsValidator {
    doCheckForSingleValue() {
        const { djName } = this.fields;

        if (!djName.trim()) this.addError("djName", "Please type your DJ name");
    }
}
