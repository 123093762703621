import React, { useContext, useState } from "react";
import classnames from "classnames";

import { Button, DropDownMenu, Links } from "components/common/ControlElems";
import routerDecorator from "components/helpers/withRouter";
import styles from "../Navbar.module.scss";
import { AuthContext } from "../../../context/AuthContext";
import { Burger, ClosePlayer } from "../../common/icons";
import { SearchRow } from "../../common/SearchRow";
import ThemeSwitcher from "../ThemeSwithcer";
import ButtonsForGuests from "./ButtonsForGuests";
import { DownloadList } from "../../common/DownloadList/DownloadList";
import { ImageWithPlaceholder } from "../../common/ImageWithPlaceholder";
import DropDownContent from "./DropDownContent";
import { ContentContext } from "../../../context/ContentContext";

function MobileNavbar({
    links,
    history,
    location,
    showMenu,
    setShowMenu,
    userMenu,
    searchQuery,
    setSearchQuery
}) {
    const { user } = useContext(AuthContext);
    const [showBurgerMenu, setShowBurgerMenu] = useState(false);
    const {
        downloadList: { length: downloadCounter }
    } = useContext(ContentContext);
    return (
        <div
            className={classnames(
                styles.container,
                !showBurgerMenu && styles.hidden
            )}
        >
            <div />
            <div className={styles.toggleButton}>
                <Button
                    withoutBorder
                    minWidth="2.5rem"
                    width="1rem"
                    withoutPadding
                    onClick={() => setShowBurgerMenu(!showBurgerMenu)}
                >
                    {[
                        showBurgerMenu ? (
                            <ClosePlayer
                                key="close"
                                color="var(--theme-main-color)"
                            />
                        ) : (
                            <Burger
                                key="burger"
                                color="var(--theme-main-color)"
                            />
                        )
                    ]}
                </Button>
            </div>
            <Links
                withoutUnderline
                links={links}
                className={styles.links}
                prefix="navbar"
                callbackAfterClick={setShowBurgerMenu.bind(this, false)}
            />
            {user && (
                <SearchRow
                    className={styles.searchRow}
                    history={history}
                    localSearchQuery={searchQuery}
                    setLocalSearchQuery={setSearchQuery}
                    callbackAfterSearch={setShowBurgerMenu.bind(this, false)}
                />
            )}
            <ThemeSwitcher className={styles.themeSwitcher} />
            {!user && (
                <ButtonsForGuests
                    className={styles.guestButtons}
                    location={location}
                />
            )}
            {downloadCounter ? (
                <DownloadList
                    className={styles.downloadList}
                    setShowMenu={setShowMenu}
                />
            ) : (
                <div className={styles.downloadList} />
            )}
            {user && (
                <div className={styles.avatarContainer}>
                    <div ref={userMenu}>
                        <div
                            className={styles.imageContainer}
                            onClick={() => setShowMenu(!showMenu)}
                        >
                            <ImageWithPlaceholder
                                src={user.avatar}
                                className={styles.avatar}
                            />
                        </div>
                        <DropDownMenu
                            type="userMenu"
                            showMenu={showMenu}
                            width={180}
                            height={192}
                            position={{ left: "-131px" }}
                        >
                            <DropDownContent
                                history={history}
                                setShowMenu={setShowMenu}
                            />
                        </DropDownMenu>
                    </div>
                    {user.dj && (
                        <div className={styles.avatarInfo}>
                            <span className={styles.djName}>
                                {user.dj.djName}
                            </span>
                        </div>
                    )}
                </div>
            )}
        </div>
    );
}

export default routerDecorator(MobileNavbar);
