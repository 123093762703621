const commonDialogs = {
    commonDialogs: {
        connectionError: {
            title: "Connection is not \n established",
            text: "Please check your Internet and try again",
            button: "Try again"
        }
    }
};

export default commonDialogs;
