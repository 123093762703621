import React from "react";
import PropTypes from "prop-types";

Search.propTypes = {
    className: PropTypes.string,
    color: PropTypes.string
};

Search.defaultProps = {
    className: "",
    color: "white"
};

export default function Search(props) {
    const { color, className } = props;
    return (
        <svg className={className} width="40" height="40" viewBox="0 0 40 40">
            <path
                fill={color}
                fillRule="evenodd"
                d="M15.9 26.489L6.139 36.25 3.75 33.861l9.761-9.76a12.612 12.612 0 0 1-2.595-7.684c0-6.996 5.67-12.667 12.667-12.667 6.996 0 12.667 5.671 12.667 12.667 0 6.996-5.671 12.667-12.667 12.667-2.89 0-5.552-.967-7.683-2.595zm7.683-.783a9.29 9.29 0 1 0 0-18.578 9.29 9.29 0 0 0 0 18.578z"
            />
        </svg>
    );
}
