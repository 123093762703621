import React, { useState, useContext } from "react";
import PropTypes from "prop-types";
import { NavLink } from "react-router-dom";
import classnames from "classnames";
import UserRegisterFormCommon from "./UserRegisterFormCommon";
import UserRegisterFormDj from "./UserRegisterFormDj";
import UserRegisterFormLabel from "./UserRegisterFormLabel";
import Button from "components/common/ControlElems/Button";
import { UserRegisterContext } from "context/UserRegisterContext";
import parseServerValidationErrors from "sources/api/parseServerValidationErrors";
import BaseDialog from "../common/Dialogs/BaseDialog";
import t from "helpers/getText";

import styles from "components/userRegister/Auth.module.scss";

export default function UserRegister(props) {
    return <section>{renderContent(props)}</section>;
}

function renderContent(props) {
    const { stage, setStage } = props;
    const [fields, setFields] = useState({
        fullName: "",
        login: "",
        email: "",
        password: "",
        phone: "",
        confirmPassword: "",
        country: "",
        street: "",
        region: "",
        zipCode: "",
        city: "",
        promoCode: "",
        djName: "",
        isClubDj: false,
        isRadioDj: false,
        isMobileDj: false,
        facebookUrl: "",
        twitterUrl: "",
        instagramUrl: "",
        snapchatUrl: "",
        labelList: "",
        comment: ""
    });

    const {
        registerUserProcessing,
        registerUserResult,
        registerUserError,
        registerUser,
        needDisplayErrorScreen,
        clearNeedDisplayErrorScreen
    } = useContext(UserRegisterContext);

    const serverErrors = parseServerValidationErrors(registerUserError);
    const screenKind = needDisplayErrorScreen
        ? findEarliestScreenForError(serverErrors)
        : stage;

    const [showInfoMessage, setShowInfoMessage] = useState(true);

    if (registerUserResult || registerUserProcessing) {
        return (
            <section className={styles.container}>
                <div className={classnames(styles.center, styles.narrow)}>
                    <h4
                        className={classnames(
                            styles.center,
                            styles.subTitleText
                        )}
                    >
                        {registerUserResult
                            ? "Success! Check your email!"
                            : "Processing..."}
                    </h4>
                    {registerUserResult && (
                        <NavLink to="/login">
                            <Button size="small" fullWidth label="Log In" />
                        </NavLink>
                    )}
                </div>
            </section>
        );
    }

    if (screenKind === "common") {
        return (
            <div>
                <UserRegisterFormCommon
                    fields={fields}
                    setFields={setFields}
                    serverErrors={serverErrors}
                    onContinue={() => {
                        setStage("dj");
                        clearNeedDisplayErrorScreen();
                    }}
                />
                {showInfoMessage &&
                <BaseDialog
                    dialogType={"alert"}
                    onClose={setShowInfoMessage}
                    height={"short-up"}
                    width={"narrow"}
                >
                    {t("registration.accountMessage")}
                    <p>{t("registration.instruction")}</p>
                    <p>{t("registration.contactsInfo")}</p>
                </BaseDialog>}
            </div>
        );
    }

    if (screenKind === "dj") {
        return (
            <UserRegisterFormDj
                fields={fields}
                setFields={setFields}
                serverErrors={serverErrors}
                onFinish={finishRegistration({
                    fields,
                    userType: "dj",
                    registerUser
                })}
            />
        );
    }

    if (false && screenKind === "label") {
        return (
            <UserRegisterFormLabel
                fields={fields}
                setFields={setFields}
                serverErrors={serverErrors}
                onFinish={finishRegistration({
                    fields,
                    userType: "label",
                    registerUser
                })}
            />
        );
    }

    return null;
}

function findEarliestScreenForError(errors) {
    const baseFieldKeys = ["fullName", "login", "email", "password", "phone"];

    const labelFieldKeys = ["labelList", "comment"];

    const isBaseScreen = baseFieldKeys.some(key => Boolean(errors[key]));
    const isLabelScreen = labelFieldKeys.some(key => Boolean(errors[key]));

    if (isBaseScreen) return "common";
    if (isLabelScreen) return "label";
    return "dj";
}

function finishRegistration({ baseFields, userType, registerUser }) {
    return concreteFields => {
        const fields = { ...baseFields, ...concreteFields };
        registerUser(fields, userType);
    };
}

UserRegister.propTypes = {
    stage: PropTypes.string,
    setStage: PropTypes.func.isRequired
};
