import React, { useState } from "react";
import { Button } from "components/common/ControlElems";
import styles from "./Artist.module.scss";

function TextBlock({ bio, width }) {
    if (!bio) return <></>;
    const bioBlocks = bio.split("\n");
    const result = bioBlocks.reduce((rows, block) => {
        if (block.length === 0) {
            return [...rows, "\u0000"];
        }
        const words = block.split(" ");
        const fullText = words.reduce(
            (accum, word) => {
                if (accum.list[accum.index].length + word.length > width) {
                    accum.index = accum.index + 1;
                    accum.list[accum.index] = word;
                } else {
                    accum.list[accum.index] += " " + word;
                }
                return accum;
            },
            { index: 0, list: [""] }
        );
        return [...rows, ...fullText.list];
    }, []);

    const numberOfRows = 3;
    const [showFullText, setShowFullText] = useState(false);
    const fullTextList = result;
    let cutText = [];
    if (fullTextList.length > numberOfRows - 1) {
        cutText = fullTextList.slice(0, numberOfRows);
        const lastString = cutText[numberOfRows - 1]
            .split(" ")
            .reduce(
                (accum, word) =>
                    accum.length > width - 20 ? accum : accum + " " + word,
                ""
            );
        cutText[numberOfRows - 1] = lastString + "...";
    } else {
        cutText = fullTextList;
    }
    return result.length > numberOfRows && showFullText ? (
        <div className={styles.bio}>
            {fullTextList.map((elem, index) => (
                <div className={styles.bioString} key={index}>
                    {elem}
                </div>
            ))}
            <Button
                withoutBorder
                withoutPadding
                isUpperCase={false}
                onClick={() => setShowFullText(!showFullText)}
                label="Hide bio"
                className={styles.hide}
            />
        </div>
    ) : (
        <div className={styles.bio}>
            {cutText.map((elem, index) => (
                <div className={styles.bioString} key={index}>
                    {elem}
                    {index === numberOfRows - 1 &&
                        result.length >= numberOfRows && (
                            <Button
                                className={styles.learnMore}
                                onClick={() => setShowFullText(!showFullText)}
                                isUpperCase={false}
                                withoutBorder
                                withoutPadding
                                label="Learn more"
                            />
                        )}
                </div>
            ))}
        </div>
    );
}

export default React.memo(TextBlock);
