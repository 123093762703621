import React from "react";
import DownloadTracks from "components/Tracks/DownloadTracks";
import styles from "./TracksPage.module.scss";

export default function TracksPage() {
    return (
        <div className={styles.container}>
            <DownloadTracks />
        </div>
    );
}
