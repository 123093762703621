import React from "react";
import PropTypes from "prop-types";

DropDownArrow.propTypes = {
    className: PropTypes.string,
    color: PropTypes.string
};

DropDownArrow.defaultProps = {
    className: "",
    color: "#bb1d7e"
};

export default function DropDownArrow({ color, className }) {
    return (
        <svg className={className} width="13" height="9" viewBox="0 0 13 9">
            <path
                fill="none"
                fillRule="evenodd"
                stroke={color}
                strokeWidth="2"
                d="M.964 1.483l5.67 5.67 5.67-5.67"
            />
        </svg>
    );
}
