import React, { useContext } from "react";
import { ContentContext } from "../../../../context/ContentContext";
import { AuthContext } from "../../../../context/AuthContext";
import { Letter } from "../../icons";
import { IconButton } from "../../ControlElems";
import styles from "./Version.module.scss"



const LetterButtons = ( { activeLetter, track, history, isAlbumTrack=false } ) => {
    const { setDisplayOfTrackVersions } = useContext( ContentContext );
    const { user } = useContext( AuthContext );
    const LETTER_BUTTONS = [
        { letter: "A", shift: { x: 0.404 }, color: '#a75b1d', letterText: 'All versions' },
        { letter: "O", shift: { x: -0.064 }, color: '#a4941d', letterText: 'Original versions' },
        { letter: "I", shift: { x: 3.032 }, color: '#155db4', letterText: 'Intro / Outro versions' },
        { letter: "R", shift: { x: 0.59 }, color: '#27660e', letterText: 'Remix versions' },
    ];
    const onClickHandler = letter => {
        if( user ) {
            setDisplayOfTrackVersions( {
                displayType: letter,
                trackId: track.uiId,
                uniqueUid: track.uniqueUid
            } );
        } else {
            history.push( "/register" );
        }
    };
    let letterIconWrap = '';

    return LETTER_BUTTONS.map(button => (
        <IconButton
          className={`${letterIconWrap} ${styles.tooltip}`}
          key={button.letter}
          onClick={() => onClickHandler(button.letter)}
          style={{ margin: button.letter === 'A' ? 0 : 'margin' }}
        >
          <Letter
            letter={button.letter}
            active={activeLetter === button.letter}
            shift={button.shift}
            bgColor={button.color}
            toolTipClass={styles.tooltiptext}
            letterText={button.letterText}
          />
        </IconButton>
      ));
      
};

export default LetterButtons;
