import React, { useContext, useEffect, useState, useRef } from "react";
import classnames from "classnames";
import { ContentContext } from "../../../../context/ContentContext";
import { Rating } from "../../../../components/common/infoElems";
import styles from "./RatingDropdown.module.scss";
import { MenuTopDetail } from "../../icons";
import { addToDownloadList } from "../Helpers";
import { Link } from "react-router-dom";


const RatingDropdown = ({
    showRate,
    setShowRate,
    track,
    size,
    style,
    offsetStyle,
    superShortScrollbarRef,
    isPlayer = false,
}) => {
    const { downloadMedia, setDownloads } = useContext(ContentContext);
    const ref = useRef(null);
    const [hide, setHide] = useState(false);
console.log(showRate,track, 'showRate-------RatingDropdown');
    useEffect(() => {
        if (hide) {
            setShowRate(false);
        }
        setHide(true);
    }, [
        superShortScrollbarRef &&
        superShortScrollbarRef.current &&
        superShortScrollbarRef.current.viewScrollTop
    ]);

    useEffect(() => {
        const { current } = ref;
        document.body.onclick = e =>
            (current && current.contains(e.target)) || setShowRate(null);
    }, [showRate]);

    const onRated = () => {
        if (showRate === "add") {
            return addToDownloadList({ track, setDownloads });
        } else {
            
            return downloadMedia({
                mediaType: "audio",
                mediaKey: track.versions[0].fileName,
                artist: track.artist || track.artiste,
                mediaName: track.name || track.songName,
                id: track.id,
                version: track.versions[0].name || track.versions[0].versionType,
                versionId: (typeof track.TvId !== 'undefined' && track.TvId > 0) ? track.TvId : track.versions[0].id
            });
        }
    };
    const onSubmit = (e) => {
        e.preventDefault();
        setShowRate(false);
    }

    return (
        <div id="customRateStyle"
            ref={ref}
            style={style}
            className={classnames({
                [styles[offsetStyle]]: true,
                [styles[size]]: true,
                [styles.container]: true,
                [styles[showRate]]: true,
                [styles.player]: isPlayer,
            })}
        >
            <MenuTopDetail />
            <div className={styles.text}>
                <p>Rate to download</p>
            </div>
            <div  className={classnames({
                [styles.stars]: true,
                "customstar": true
            })} >
                <Link to="/" onClick={onSubmit} >
                    <Rating trackId={track.id} onRated={onRated} />
                </Link>

            </div>
        </div>
    );
};

export default RatingDropdown;
