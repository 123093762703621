import React, { useContext } from "react";
import classnames from "classnames";
import PropTypes from "prop-types";
import { UIContext } from "context/UIContext";
import styles from "./AuthPageLayout.module.scss";

export default function AuthPageLayout(props) {
    const { theme } = useContext(UIContext);
    const { smallTitle, title, tabsChildren, children } = props;

    return (
        <div className={classnames(styles.container, styles[theme])}>
            <h5>{smallTitle}</h5>
            <h1>{title}</h1>

            <div>{tabsChildren}</div>

            <div className={styles.content}>{children}</div>
        </div>
    );
}

AuthPageLayout.propTypes = {
    smallTitle: PropTypes.string,
    title: PropTypes.string,
    tabsChildren: PropTypes.any,
    children: PropTypes.any
};
