import AbstractFieldsValidator from "components/helpers/validation/AbstractFieldsValidator";

export default function(fields, options) {
    return new RegisterFieldsValidator(fields, options).validate();
}

class RegisterFieldsValidator extends AbstractFieldsValidator {
    doCheckForSingleValue() {
        const { oldPassword, newPassword, confirmPassword } = this.fields;

        if (!oldPassword)
            this.addError("oldPassword", "Please type your old password");
        if (!newPassword)
            this.addError("newPassword", "Please type your new password");
        if (!confirmPassword)
            this.addError(
                "confirmPassword",
                "Please confirm your new password"
            );
    }

    doCheckForRelations() {
        const { newPassword, confirmPassword } = this.fields;

        if (newPassword !== confirmPassword)
            this.addError("confirmPassword", "Passwords do not match");
    }

    doCheckForEmailAndPhone() {
        const { newPassword } = this.fields;

        if (newPassword.length < 6)
            this.addError(
                "newPassword",
                "Password should be 6 symbols or longer"
            );
    }
}
