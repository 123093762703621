import React, { useEffect, useContext, useState } from "react";
import Navbar from "components/main/Navbar"; 
import { AuthContext } from "context/AuthContext";
import { NavLink } from "react-router-dom";   
import logo from "assets/newHome/images/logo.png";
import bannerImages from "assets/newHome/images/banner-images.png";
import musicIcon from "assets/newHome/images/music-icon.svg";
import siteIcon from "assets/newHome/images/site-icon.svg";
import playIcon from "assets/newHome/images/play-icon.svg";
import downloadIcon from "assets/newHome/images/download-icon.svg";
import advanceMobileImg from "assets/newHome/images/advance_mobile_img.png";
import appDeskImg from "assets/newHome/images/app-desk-img.png";
import pop from "assets/newHome/images/pop.png";
import musicHipHop from "assets/newHome/images/music-hip-hop.png";
import rb from "assets/newHome/images/rb.png";
import rock from "assets/newHome/images/rock.png";
import reggae from "assets/newHome/images/reggae.png";
import soca from "assets/newHome/images/soca.png";
import latin from "assets/newHome/images/latin.png";
import reggaeton from "assets/newHome/images/reggaeton.png";
import country from "assets/newHome/images/country.png";
import alternative from "assets/newHome/images/alternative.png";
import dubster from "assets/newHome/images/dubster.png";
import jazz from "assets/newHome/images/jazz.png";
import footerLogo from "assets/newHome/images/footer-logo.png";
import app from "assets/newHome/images/app.png";
import google from "assets/newHome/images/google.png";

import magnific from "assets/newHome/css/magnific-popup.css";
import all from "assets/newHome/css/all.min.css";
import slick from "assets/newHome/css/slick.css";

import { FBfooter, IGfooter, TWfooter } from "../common/icons";
 
export default function newHome() {
  const [scrollClass, setScrollClass] = useState('');
  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  });
  const { user } = useContext(AuthContext);
  const handleScroll = () => {
    if (window.scrollY > 0) {
      setScrollClass('header-fixed');
    } else {
      setScrollClass('header-fixed');
    }
  };
// const [headerOpacity, setHeaderOpacity] = useState(0);
  const isHome = true;
//   const changeHeaderOpacity = ({ current }) => {
//     const offset = (current && current.viewScrollTop) || 0;
//     const opacity = offset > 8 ? 1 : 0;
//     setHeaderOpacity(opacity);
// };
// const onScrollHandler = ref => {
//     changeHeaderOpacity(ref);
// };
  return (
    <div className="mainHomePage">
       
      <link rel="stylesheet" href={all} />
      <link rel="preconnect" href="https://fonts.gstatic.com" />
      <link href="https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700;800;900&display=swap" rel="stylesheet" />
      <link rel="stylesheet" href={slick} />
      <link rel="stylesheet" href={magnific} />

      <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/magnific-popup.js/1.1.0/magnific-popup.min.css" integrity="sha512-+EoPw+Fiwh6eSeRK7zwIKG2MA8i3rV/DGa3tdttQGgWyatG/SkncT53KHQaS5Jh9MNOT3dmFL0FjTY08And/Cw==" crossOrigin="anonymous" referrerPolicy="no-referrer" />
      {/* Back to top button */}
      {/* <a id="button" /> */}
      <div className="wrapper">
        <header id="header-sec" className={`header-v1 header-transparent ${scrollClass}`} >
          <div className="header-wrap">
            {/* <div class="container"> */}
            <div className="container">
              <nav className="navbar navbar-expand-lg navbar-dark theme-navbar px-0">
              <NavLink to="/" className="navbar-brand"><img src={logo} width={170} height={84} alt="header-logo" /></NavLink> 
                <button className="navbar-toggler collapsed" type="button" data-toggle="collapse" data-target="#main-header" aria-controls="main-header" aria-expanded="false" aria-label="Toggle navigation">
                  <span className="navbar-toggler-icon" />
                </button>
                <div className="navbar-collapse collapse" id="main-header">
                  <ul className="navbar-nav mx-auto">
                    <li className="nav-item">
                      <a className="nav-link" href="javascript:void(0)">HOME </a>
                    </li>
                    {
                      (user) && (
                        <li className="nav-item"> 
                          <NavLink to="/tracks/all" className="nav-link">MUSIC </NavLink>
                        </li>
                      )
                    }
                    <li className="nav-item"> 
                      <NavLink to="/apps" className="nav-link">FRP APPS </NavLink>
                    </li>
                    <li className="nav-item">
                    <NavLink to="/rotation" className="nav-link">HEAVY ROTATION </NavLink> 
                    </li>
                    <li className="nav-item">
                    <NavLink to="/contactUs" className="nav-link">CONTACT</NavLink>  
                    </li>
                  </ul>
                  <div className="icon-list flex-content navbar-rightbar">
                  {user ? (
                   <Navbar opacity={0}  isHome={isHome}  />
                   
            ) : (
                <><NavLink to="/login">
                <div className="btn btn-primary-overlay header-btn-radius mr-2">SIGN
                  IN</div>  </NavLink>
              <NavLink to="/register">
                <div className="btn btn-outline-primary header-btn-radius head-overlay">SIGN
                  UP</div>
              </NavLink></>
            )}
                    
                  </div>
                </div>
              </nav>
            </div> 
            {/* </div> */}
          </div>
        </header>
        {/* header close */}
        <section className="banner-section banner-slider-wrap overlay-slider-elem">
          <div className="banner-slider">
            <div className="banner-image-item banner-wrap">
              <div className="banner-overlay">
                <div className="container">
                  <div className="row">
                    <div className="col-12 col-md-6 col-lg-5 align-self-center ">
                      <div className="banner-sec-wrap">
                        <div className="sec-title">
                          <h6 className="banner-title">
                            Welcome to <span className="primary-text-color">FRP!</span> <br />
                            Music professionals only
                          </h6>
                          <p className="banner-content">
                            FRP Live is the most advanced Record Pool on the web for DJs looking
                            for the right music and for record labels and
                            artists looking to promote their music worldwide. We successfully
                            bridged the gap between music creators, DJs and
                            listeners via innovative ideas and highly technological tools. With
                            our state of the art website, mobile app and desktop
                            application, the music industry network is at your fingertips 24/7.
                          </p>
                        </div>
                        <div className="banner-btn-wrap">
                        <NavLink className="btn btn-primary-overlay" to="/register">Get
                            Started</NavLink>  
                        </div>
                      </div>
                    </div>
                    <div className="col-12 col-md-6 col-lg-7 align-self-center flex-end">
                      <div className="image-wrap">
                        <img className="img-responsive" src={bannerImages} height={400} alt="banner" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="corner-bg" />
            </div>
          </div>
        </section>
        {/* banner sec close */}
        <div className="content py-0">
          <section className="benefits-sec-wrap">
            <div className="container">
              <div className="sec-heading-title">
                <h2 className="sec-title">SUBSCRIPTION BENEFITS</h2>
              </div>
              <div className="benefits-sec-col">
                <div className="row">
                  <div className="col-12 col-md-6 col-lg-3">
                    <div className="benefits-sec-content">
                      <div className="image-icon">
                        <img src={musicIcon} alt="icon" />
                      </div>
                      <div className="sec-title-wrap">
                        <h5 className="sec-title">New Music Daily</h5>
                      </div>
                      <div className="sec-content-wrap">
                        <p className="sec-content">Discover new music from popular and upcoming artists
                          uploaded daily. Even on holidays.</p>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-md-6 col-lg-3">
                    <div className="benefits-sec-content">
                      <div className="image-icon">
                        <img src={siteIcon} alt="icon" />
                      </div>
                      <div className="sec-title-wrap">
                        <h5 className="sec-title">Modern Site Layout</h5>
                      </div>
                      <div className="sec-content-wrap">
                        <p className="sec-content">State of the art website layout and enhanced search
                          filter for easy navigation and downloading.</p>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-md-6 col-lg-3">
                    <div className="benefits-sec-content">
                      <div className="image-icon">
                        <img src={playIcon} alt="icon" />
                      </div>
                      <div className="sec-title-wrap">
                        <h5 className="sec-title">Curated Playlists:</h5>
                      </div>
                      <div className="sec-content-wrap">
                        <p className="sec-content">Guaranteed party favorites handpicked for you ready
                          to bulk download for quick play during live gigs.</p>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-md-6 col-lg-3">
                    <div className="benefits-sec-content">
                      <div className="image-icon">
                        <img src={downloadIcon} alt="icon" />
                      </div>
                      <div className="sec-title-wrap">
                        <h5 className="sec-title">Best App/Desktop Downloader</h5>
                      </div>
                      <div className="sec-content-wrap">
                        <p className="sec-content">Our mobile app and Desktop download works seamlessly
                          to create unlimited folders on PC and download to any folders using
                          the mobile app.</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="music-app-sec-wrap">
            <div className="container">
              <div className="sec-heading-title">
                <h2 className="sec-title">ADVANCE MOBILE APP FOR MUSIC <br />
                  ON THE GO</h2>
              </div>
              <div className="music-app-sec-col">
                <div className="row">
                  <div className="col-12 col-md-6 col-lg-6">
                    <div className="music-sec-content">
                      <div className="image-icon">
                      <NavLink to="/apps"><img src={appDeskImg} alt="app" /></NavLink>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-md-6 col-lg-6 align-self-center">
                    <div className="music-sec-content">
                      <div className="image-icon">
                      <NavLink to="/apps"><img src={advanceMobileImg} alt="app" /> </NavLink>
                        {/* </a> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="app-sec-content">
                <p className="sec-content">Use the mobile app to instantly create folders/playlist on your PC
                  the right way. Create,
                  select, and download to
                  folders directly on PC. No additional downloading steps.</p>
              </div>
            </div>
          </section>
          <section className="music-list-sec">
            <div className="container">
              <div className="sec-heading-title">
                <h2 className="sec-title">TONS OF MUSIC IN 28 GENRES</h2>
              </div>
              <div className="music-sec-col">
                <div className="row">
                  <div className="col-12 col-sm-6 col-md-4 col-lg-3">
                    <div className="music-sec-content">
                      <div className="image-wrap">
                        <img src={musicHipHop} alt="icon" />
                        <div className="overlay-text">
                          <h4 className="sec-title">HIP HOP</h4>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-sm-6 col-md-4 col-lg-3">
                    <div className="music-sec-content">
                      <div className="image-wrap">
                        <img src={rb} alt="icon" />
                        <div className="overlay-text">
                          <h4 className="sec-title">R&amp;B</h4>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-sm-6 col-md-4 col-lg-3">
                    <div className="music-sec-content">
                      <div className="image-wrap">
                        <img src={pop} alt="icon" />
                        <div className="overlay-text">
                          <h4 className="sec-title">Pop</h4>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-sm-6 col-md-4 col-lg-3">
                    <div className="music-sec-content">
                      <div className="image-wrap">
                        <img src={rock} alt="icon" />
                        <div className="overlay-text">
                          <h4 className="sec-title">Rock</h4>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-sm-6 col-md-4 col-lg-3">
                    <div className="music-sec-content">
                      <div className="image-wrap">
                        <img src={reggae} alt="icon" />
                        <div className="overlay-text">
                          <h4 className="sec-title">Reggae</h4>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-sm-6 col-md-4 col-lg-3">
                    <div className="music-sec-content">
                      <div className="image-wrap">
                        <img src={soca} alt="icon" />
                        <div className="overlay-text">
                          <h4 className="sec-title">Soca</h4>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-sm-6 col-md-4 col-lg-3">
                    <div className="music-sec-content">
                      <div className="image-wrap">
                        <img src={latin} alt="icon" />
                        <div className="overlay-text">
                          <h4 className="sec-title">latin</h4>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-sm-6 col-md-4 col-lg-3">
                    <div className="music-sec-content">
                      <div className="image-wrap">
                        <img src={reggaeton} alt="icon" />
                        <div className="overlay-text">
                          <h4 className="sec-title">Reggaeton</h4>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-sm-6 col-md-4 col-lg-3">
                    <div className="music-sec-content">
                      <div className="image-wrap">
                        <img src={country} alt="icon" />
                        <div className="overlay-text">
                          <h4 className="sec-title">Country</h4>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-sm-6 col-md-4 col-lg-3">
                    <div className="music-sec-content">
                      <div className="image-wrap">
                        <img src={alternative} alt="icon" />
                        <div className="overlay-text">
                          <h4 className="sec-title">Alternative</h4>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-sm-6 col-md-4 col-lg-3">
                    <div className="music-sec-content">
                      <div className="image-wrap">
                        <img src={dubster} alt="icon" />
                        <div className="overlay-text">
                          <h4 className="sec-title">Dubstep</h4>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-sm-6 col-md-4 col-lg-3">
                    <div className="music-sec-content">
                      <div className="image-wrap">
                        <img src={jazz} alt="icon" />
                        <div className="overlay-text">
                          <h4 className="sec-title">JAzz</h4>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="industry-sec-wrap">
            <div className="container">
              <div className="sec-heading-title">
                <h2 className="sec-title">CONNECT WITH THE INDUSTRY</h2>
                <p className="sec-content">Connect with Artist, Record Labels and other DJs via our Spins app.
                </p>
                <div className="banner-btn-wrap">
                <NavLink className="btn btn-primary-overlay" to="/register">Get
                            Started</NavLink>  
                        </div>
              </div>
              <div className="industry-sec-col">
                <div className="row">
                  <div className="col-12 col-md-4 col-lg-4">
                    <div className="industry-sec-content">
                      <div className="sec-title-wrap">
                        <h3 className="sec-title">Labels</h3>
                      </div>
                      <div className="sec-content-wrap">
                        <p className="sec-content">Get music notification of priority records from label
                          reps with download function.</p>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-md-4 col-lg-4">
                    <div className="industry-sec-content">
                      <div className="sec-title-wrap">
                        <h3 className="sec-title">Artists</h3>
                      </div>
                      <div className="sec-content-wrap">
                        <p className="sec-content">Connect with your favorite artist for drops, personal
                          reviews or show love.</p>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-md-4 col-lg-4">
                    <div className="industry-sec-content">
                      <div className="sec-title-wrap">
                        <h3 className="sec-title">DJs</h3>
                      </div>
                      <div className="sec-content-wrap">
                        <p className="sec-content">Follow your fellow DJs, view &amp; download each other's
                          playlist based on geographic trending.</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
        <footer id="footer-wrap" className="footer-section footer-spacing hhhhh">
          <div className="footer-wrap">
            <div className="container">
              <div className="row">
                <div className="col-12 col-sm-12 col-md-12 col-lg-2 grid-item-space mb-lg-0 mb-4 align-self-center">
                  <div className="footer-sec footer-sec-1">
                    <div className="footer-widget-area mb-7">
                      <a href="index.html" className="footer-logo"><img src={footerLogo} width={188} height={78} alt="Footer Logo" /></a>
                    </div>
                  </div>
                </div>
                {/*Footer social icon close */}
                <div className="col-sm-12 col-md-8 col-lg-7 ">
                  <div className="row">
                    <div className="col-6 col-sm-6 col-md-4 col-lg-4 footer-top-space grid-item-space mb-md-0 mb-3">
                      <div className="footer-sec  ml-lg-4">
                        <div className="footer-widget-area">
                          <div className="footer-menu-list">
                            <div className="footer-sec-title">
                              <h6>What we do</h6>
                            </div>
                            <div className="footer-menu footer-menu1">
                              <ul className="menu-list list-unstyled px-0 m-0">
                                <li>
                                <NavLink className="active-link" to="/home">Home</NavLink>  
                                </li>
                                <li><NavLink className="active-link" to="/tracks/all">Music</NavLink>  
                                </li>
                                <li>
                                <NavLink to="/apps">Apps</NavLink> 
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-6 col-sm-6 col-md-4 col-lg-4 footer-top-space grid-item-space mb-md-0 mb-3">
                      <div className="footer-sec ml-lg-4">
                        <div className="footer-widget-area">
                          <div className="footer-menu-list">
                            <div className="footer-sec-title">
                              <h6>Connect</h6>
                            </div>
                            <div className="footer-menu footer-menu2 footer-social-icon">
                              <ul className="menu-list list-unstyled px-0 m-0">
                                <li><a href="https://www.facebook.com/frppool" target="_blank" rel="noreferrer" >
                                <FBfooter /></a></li>
                                <li><a href="https://www.instagram.com/franchiserecordpool/" target="_blank" rel="noreferrer">                                <IGfooter /></a></li>
                                <li><a href="https://twitter.com/frpmusicpool" target="_blank" rel="noreferrer">
                                  <TWfooter /></a></li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-6 col-sm-6 col-md-4 col-lg-4 footer-top-space grid-item-space mb-md-0 mb-3">
                      <div className="footer-sec ml-lg-4">
                        <div className="footer-widget-area">
                          <div className="footer-menu-list">
                            <div className="footer-sec-title">
                              <h6>Help</h6>
                            </div>
                            <div className="footer-menu footer-menu3">
                              <ul className="menu-list list-unstyled px-0 m-0"> 
                                <li><NavLink to="/faq">FAQ </NavLink></li>
                              <li> <NavLink to="/contactUs">Contact</NavLink></li>
                              <li><NavLink to="/agreement">Agreement</NavLink></li>
                              <li><NavLink to="/disclaimer">Disclaimer & DMCA</NavLink></li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* Footer page links close */}
                <div className="col-12 col-sm-12 col-md-4  col-lg-3 footer-top-space grid-item-space">
                  <div className="footer-sec footer-sec-4">
                    <div className="footer-widget-area">
                      <div className="footer-sec-title pb-2">
                        <h6>Download our app</h6>
                      </div>
                      <div className="footer-image">
                        <div className="image-wrap">
                          <a href="https://apps.apple.com/us/app/frp-live/id1360454793?ls=1"> <img src={app} width={140} alt="app store" /></a>
                        </div>
                        <div className="image-wrap">
                          <a href="https://play.google.com/store/apps/details?id=com.st.frpnew"><img src={google} width={140} alt="app store" /></a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* Working hours close */}
              </div>
            </div>
            <div className="footer-widget-area">
              <div className="copyright-info">
                <p className="mb-0">© 2023. Franchise Record Pool. USA. All Rights Reserved.</p>
              </div>
            </div>
          </div>
        </footer>
      </div>
    </div>
  );
}
