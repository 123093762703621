import { useLocation } from "react-router-dom";

export default function RedirectPage() {
  const search = useLocation().search;
  const url = new URLSearchParams(search).get("url");

  window.location.replace(url);

  return;
}
