import React from "react";
import bindFunctions from "./helpers/bindFunctions";
import { createRequestHelper } from "./helpers/requestHelper";
import consumeToContext from "./helpers/consumeToContext";
import { AuthContext } from "./AuthContext";
import t from "helpers/getText";

export const SubscriptionContext = React.createContext();

const subscriptionRequestHelper = createRequestHelper("subscriptionUser");
const cancelSubscriptionRequestHelper = createRequestHelper(
    "cancelSubscription"
);
const requestRedirectLinkHelper = createRequestHelper("requestRedirectLink");

class UserSubscriptionContextContainer extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            ...subscriptionRequestHelper.initialState,
            ...requestRedirectLinkHelper.initialState,
            history: [],
            price: 0
        };

        this.funcs = bindFunctions(this, {
            subscribe: this.subscribe,
            getPrice: this.getPrice,
            getHistory: this.getHistory,
            clearSubscription: this.clearSubscription,
            cancelSubscription: this.cancelSubscription,
            clearCancelSubscription: this.clearCancelSubscription,
            requestRedirectLink: this.requestRedirectLink,
            clearRequestedRedirectLink: this.clearRequestedRedirectLink
        });
    }

    async clearSubscription() {
        this.setState({
            ...subscriptionRequestHelper.initialState
        });
    }

    async clearCancelSubscription() {
        this.setState({
            ...cancelSubscriptionRequestHelper.initialState
        });
    }

    async cancelSubscription({ message }) {
        const { makeAuthenticateHttpRequest, loginWithToken } = this.props.auth;
        this.setState(cancelSubscriptionRequestHelper.processing());

        try {
            const result = await makeAuthenticateHttpRequest({
                method: "post",
                path: `/subscriptions/cancel?message=${encodeURIComponent(
                    message
                )}`
            });
            this.setState(cancelSubscriptionRequestHelper.result(result));
            loginWithToken();
        } catch (error) {
            this.setState(cancelSubscriptionRequestHelper.error(error));
        }
    }

    async requestRedirectLink(selectedOption) {
        const { makeAuthenticateHttpRequest } = this.props.auth;

        this.setState(requestRedirectLinkHelper.processing());

        try {
            const result = await makeAuthenticateHttpRequest({
                method: "post",
                path: `/subscriptions/generatePaypalLink`,
                data: { option: selectedOption }
            });

            if (this.state.requestRedirectLinkProcessing) {
                this.setState(
                    requestRedirectLinkHelper.result({ link: result.link })
                );
            }
        } catch (error) {
            this.setState(requestRedirectLinkHelper.error(error));
        }
    }

    async clearRequestedRedirectLink() {
        this.setState(requestRedirectLinkHelper.clear());
    }

    async subscribe(fields) {
        const { makeAuthenticateHttpRequest, loginWithToken } = this.props.auth;
        this.setState(subscriptionRequestHelper.processing());

        try {
            const result = await makeAuthenticateHttpRequest({
                method: "post",
                path: `/subscriptions/subscribe`,
                data: fields
            });
            if (result.hold) {
                this.setState(
                    subscriptionRequestHelper.error({
                        details: {
                            common: {
                                kind: {
                                    errorMessage: t(
                                        "subscription.paypalHoldError"
                                    )
                                }
                            }
                        },
                        hold: true
                    })
                );
                return;
            }
            await loginWithToken();
            this.setState(subscriptionRequestHelper.result(result));
        } catch (error) {
            this.setState(subscriptionRequestHelper.error(error));
        }
    }

    getHistory = async () => {
        const { user, makeAuthenticateHttpRequest } = this.props.auth;
        try {
            const result = await makeAuthenticateHttpRequest({
                method: "get",
                path: `/subscriptions/getChargeHistory?id=${user.id}`
            });
            this.setState({ history: result });
        } catch (error) {
            this.setState(subscriptionRequestHelper.error(error));
        }
    };
    getPrice = async () => {
        const { user, makeAuthenticateHttpRequest } = this.props.auth;

        try {
            const result = await makeAuthenticateHttpRequest({
                method: "get",
                path: `/subscriptions/getPrice`,
                data: { type: user.type }
            });
            this.setState({ price: result.price });
        } catch (error) {
            this.setState(subscriptionRequestHelper.error(error));
        }
    };

    render() {
        return (
            <SubscriptionContext.Provider
                value={{ ...this.state, ...this.funcs }}
            >
                {this.props.children}
            </SubscriptionContext.Provider>
        );
    }
}

export default consumeToContext(
    "auth",
    AuthContext
)(UserSubscriptionContextContainer);
