import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import { Scrollbar } from "components/common/Scrollbar";
import { IconButton } from "components/common/ControlElems";
import { Close } from "components/common/icons";
import { componentWillAppendToBody } from "react-append-to-body";

import styles from "./BaseDialog.module.scss";

Dialog.propTypes = {
    dialogType: PropTypes.oneOf(["alert", "video"]),
    width: PropTypes.oneOf(["narrow", "normal", "width", "widest"]),
    height: PropTypes.oneOf(["tall", "short", "tiny", "tallest", "short-up"]),
    onClose: PropTypes.func.isRequired,
    withoutScrollbar: PropTypes.bool,
    withoutCloseButton: PropTypes.bool,
    className: PropTypes.string
};

function Dialog({
    width,
    height,
    dialogType,
    children,
    onClose,
    withoutScrollbar,
    withoutCloseButton,
    className
}) {
    const [active, setActive] = useState(false);

    useEffect(() => setActive(true), []);

    const containerClassName = classnames(
        className,
        styles.container,
        active && styles.active,
        styles[width],
        styles[height],
        styles[dialogType]
    );

    return (
        <div
            onClick={e =>
                dialogType === "video" &&
                e.target.tagName === "DIV" &&
                onClose()
            }
            className={styles.rootContainer}
        >
            <div className={containerClassName}>
                {withoutScrollbar ? (
                    <>
                        {children}
                        {!withoutCloseButton && (
                            <div className={styles.buttonContainer}>
                                <IconButton
                                    className={classnames(
                                        styles.closeButton,
                                        dialogType === "video" &&
                                            styles.whiteBackground
                                    )}
                                    onClick={() => onClose()}
                                >
                                    <Close />
                                </IconButton>
                            </div>
                        )}
                    </>
                ) : (
                    <Scrollbar
                        id="DialogScrollbar"
                        autoHeight
                        autoHeightMin="800px"
                        autoHide
                        autoHideTimeout={1000}
                        autoHideDuration={300}
                    >
                        {children}
                    </Scrollbar>
                )}
                {!withoutCloseButton && (
                    <div className={styles.buttonContainer}>
                        <IconButton
                            className={classnames(
                                styles.closeButton,
                                dialogType === "video" && styles.whiteBackground
                            )}
                            onClick={() => onClose()}
                        >
                            <Close />
                        </IconButton>
                    </div>
                )}
            </div>
        </div>
    );
}

export default componentWillAppendToBody(Dialog);
