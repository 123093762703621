import React, { useContext } from "react";
import PropTypes from "prop-types";
import moment from "moment";
import classnames from "classnames";
import { ContentContext } from "context/ContentContext";
import { IconButton } from "components/common/ControlElems";
import styles from "./VideoList.module.scss";
import { Download, Add, Added } from "../icons";
import { VideoElement } from "./index";
import { LoaderPlaceholder } from "components/common/LoaderPlaceholder";
import { getYoutubeLocalAddress } from "../../../helpers/utils/getVideoAddress";
import t from "../../../helpers/getText";

const HEADERS = ["", "TRACK / ARTIST", "GENRE", "RELEASED", "", ""];

const MemoTrack = React.memo(Track);

VideoList.propTypes = {
    className: PropTypes.string,
    list: PropTypes.array,
    limit: PropTypes.number,
    short: PropTypes.bool,
    hideHeaders: PropTypes.bool
};

VideoList.defaultProps = {
    limit: 10,
    short: false,
    hideHeaders: false
};

export default function VideoList(props) {
    const { className, limit, short, hideHeaders } = props;
    const { video, setVersion } = useContext(ContentContext);
    let { list } = props;
    if (!list) list = video.list;
    if (!list || list.length === 0) list = getLoaderList(limit);

    if (video.empty) {
        return (
            <div className={styles.noTracks}>
                <h3>{t("rotation.artist.noTrack")}</h3>
            </div>
        );
    }

    if (video.count === 0) {
        return (
            <div
                className={classnames(
                    short ? styles.mainContainerShort : styles.mainContainer,
                    className
                )}
            >
                <div className={styles.loader}>
                    <LoaderPlaceholder />
                </div>
            </div>
        );
    }

    return (
        <>
            <div
                className={classnames(
                    short ? styles.mainContainerShort : styles.mainContainer,
                    className
                )}
            >
                <div className={styles.tracksColumnContainer}>
                    <div
                        className={classnames(
                            styles.tracksColumnHeader,
                            hideHeaders && styles.hideHeaders
                        )}
                    >
                        {HEADERS.map((head, index) => {
                            return (
                                <div
                                    key={head + index}
                                    className={styles["head_" + index]}
                                >
                                    {head}
                                </div>
                            );
                        })}
                    </div>
                    {list.map((track, index) => {
                        return (
                            <MemoTrack
                                short={short}
                                setVersion={setVersion}
                                key={index}
                                index={index + 1}
                                track={track}
                            />
                        );
                    })}
                </div>
            </div>
        </>
    );
}

Track.defaultProps = {
    versions: [],
    version: null,
    rating: 0,
    released: false
};

function Track(props) {
    const { track, index, short } = props;
    const {
        name,
        artist,
        genre,
        released,
        id,
        key,
        placeHolderInfo,
        versions,
        version,
        versionId
    } = track;
    const {
        downloadList,
        downloadMedia,
        setDownloads,
        addActions
    } = useContext(ContentContext);
    const currentVersion = versions[0];
    const isYouTube =
        currentVersion && getYoutubeLocalAddress(currentVersion.link);
    let result = null;
    if (placeHolderInfo) {
        result = <TrackPlaceholder key={index} />;
    } else {
        result = (
            <>
                <div key={id} className={styles.trackContainer}>
                    <div>
                        <VideoElement
                            onOpen={() =>
                                addActions("listen", [{ id, version: "Video" }])
                            }
                            useThumbnail
                            address={track.versions[0].link}
                            size="small"
                        />
                    </div>
                    <div className={styles.playInfo}>
                        <div className={styles.nameAndArtist}>
                            <p>{name}</p>
                            <p>{artist}</p>
                        </div>
                    </div>
                    <div>{genre}</div>
                    <div>
                        {released && moment(released).format("MM-DD-YYYY")}
                    </div>
                    {!short && (!isYouTube || !isYouTube[0]) && (
                        <>
                            <div>
                                <IconButton
                                    onClick={() =>
                                        downloadMedia({
                                            mediaKey: currentVersion.fileName,
                                            mediaType: "video",
                                            mediaName: name,
                                            artist,
                                            version,
                                            versionId: currentVersion.id,
                                            id
                                        })
                                    }
                                >
                                    <Download color="var(--theme-main-color)" />
                                </IconButton>
                            </div>
                            <div>
                                {downloadList.some(
                                    media => media.versionId === versionId
                                ) ? (
                                    <IconButton
                                        onClick={() =>
                                            setDownloads({
                                                type: "delete",
                                                element: {
                                                    key,
                                                    version: "Video"
                                                }
                                            })
                                        }
                                    >
                                        <Added />
                                    </IconButton>
                                ) : (
                                    <IconButton
                                        onClick={() =>
                                            AddToDownloadList(
                                                versions,
                                                track,
                                                setDownloads
                                            )
                                        }
                                    >
                                        <Add color="var(--theme-main-color)" />
                                    </IconButton>
                                )}
                            </div>
                        </>
                    )}
                </div>
            </>
        );
    }

    return result;
}

function AddToDownloadList(versions, track, setDownloads) {
    const { name, key, artist, id } = track;
    const { fileName, id: versionId } = versions[0];
    setDownloads({
        type: "add",
        element: {
            name,
            fileName,
            id,
            versionId,
            artist,
            version: "Video",
            key,
            type: "video"
        }
    });
}

function TrackPlaceholder() {
    return (
        <div className={classnames(styles.trackContainer, styles.placeholder)}>
            <div />
            <div className={styles.playPlaceholder}>
                <div />
                <div>
                    <div />
                    <div />
                </div>
            </div>
            <div />
            <div />
            <div />
            <div />
            <div />
        </div>
    );
}

function getLoaderList(limit) {
    const result = [];
    let count = limit;
    while (count) {
        result.push({ placeHolderInfo: true, versions: [] });
        count--;
    }
    return result;
}
