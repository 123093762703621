import React, { useState } from "react";
import PropTypes from "prop-types";
import { Button } from "components/common/ControlElems";
import TextField from "components/common/form/TextField";
import { fieldProps } from "components/helpers/forms/formFieldHelper";
import classnames from "classnames";
import t from "helpers/getText";
import styles from "./Auth.module.scss";
import validateBaseFields from "./validateBaseFields";

UserRegisterFormCommon.propTypes = {
    fields: PropTypes.object.isRequired,
    setFields: PropTypes.func.isRequired,
    errors: PropTypes.object,
    onContinue: PropTypes.func.isRequired
};

export default function UserRegisterFormCommon(props) {
    const [wasSubmit, setWasSubmit] = useState(false);
    const { fields, setFields, serverErrors, onContinue } = props;

    const clientErrors = wasSubmit ? validateBaseFields(fields) : {};
    const errors = Object.assign({}, clientErrors, serverErrors);

    return (
        <div className={styles.container}>
            <div className={styles.left}>
                <TextField
                    label={t("registration.name")}
                    {...fieldProps("fullName", "string", {
                        fields,
                        setFields,
                        errors
                    })}
                />
                <TextField
                    label={t("registration.email")}
                    {...fieldProps("email", "email", {
                        fields,
                        setFields,
                        errors
                    })}
                />
                <TextField
                    label={t("registration.phone")}
                    {...fieldProps("phone", "string", {
                        fields,
                        setFields,
                        errors
                    })}
                />
            </div>
            <div className={styles.right}>
                <TextField
                    label={t("registration.login")}
                    {...fieldProps("login", "string", {
                        fields,
                        setFields,
                        errors
                    })}
                />
                <TextField
                    label={t("registration.password")}
                    {...fieldProps("password", "password", {
                        fields,
                        setFields,
                        errors
                    })}
                />

                <TextField
                    label={t("registration.confirmPassword")}
                    {...fieldProps("confirmPassword", "password", {
                        fields,
                        setFields,
                        errors
                    })}
                />
            </div>
            <div className={classnames(styles.center, styles.button)}>
                <Button
                    width="10.625rem"
                    label="Continue"
                    onClick={continueRegistration({
                        fields,
                        onContinue,
                        setWasSubmit
                    })}
                />
            </div>
        </div>
    );
}

function continueRegistration({ fields, onContinue, setWasSubmit }) {
    return () => {
        setWasSubmit(true);

        const errors = validateBaseFields(fields);
        if (Object.keys(errors).length > 0) return;
        onContinue();
    };
}
