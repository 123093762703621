import React from "react";
import t from "helpers/getText";
import { Button } from "components/common/ControlElems";
import { NavLink } from "react-router-dom";
import membersImage from "assets/images/splash/members.png";
import appsImage from "assets/images/splash/apps.png";
import websiteImage from "assets/images/splash/website.png";
import Youtube from "react-youtube";
import styles from "./Splash.module.scss";

const YOUTUBE_OPTIONS = {
    videoId: "wY0Fi7iUeB0",
    opts: {
        width: "100%",
        height: "100%"
    },
    playerVars: {
        autoplay: 1
    }
};

export default function Splash() {
    return (
        <div className={styles.container}>
            <div className={styles.content}>
                <section className={styles.info}>
                    <h2>{t("splash.section2.welcome")}</h2>
                    <span className={styles.subText}>
                        {t("splash.section2.subtext")}
                    </span>
                    <NavLink to="/register">
                        <Button
                            className={styles.enterButton}
                            spanClassName={styles.enterButtonSpan}
                            label={t("splash.section2.enterButton")}
                        />
                    </NavLink>
                </section>
                <section className={styles.featuresContainer}>
                    <div className={styles.feature}>
                        <img src={membersImage} alt="members" />
                        <div>
                            <h3>{t("splash.section3.features[0].head")}</h3>
                            <span>{t("splash.section3.features[0].text")}</span>
                        </div>
                    </div>
                    <div className={styles.feature}>
                        <img src={websiteImage} alt="website" />
                        <div>
                            <h3>{t("splash.section3.features[1].head")}</h3>
                            <span>{t("splash.section3.features[1].text")}</span>
                        </div>
                    </div>
                    <div className={styles.feature}>
                        <img src={appsImage} alt="apps" />
                        <div>
                            <h3>{t("splash.section3.features[2].head")}</h3>
                            <span>{t("splash.section3.features[2].text")}</span>
                        </div>
                    </div>
                </section> 
            </div>
        </div>
    );
}
