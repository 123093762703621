import React from "react";
import PropTypes from "prop-types";
import { MoonLoader } from "react-spinners";

Spinner.propTypes = {
    color: PropTypes.string,
    backgroundColor: PropTypes.string,
    className: PropTypes.string
};

Spinner.defaultProps = {
    color: "#bb1d7e",
    backgroundColor: "rgba(0,0,0, 0.3)",
    size: 64,
    sizeUnit: "px"
};

export default function Spinner(props) {
    const { color, className, backgroundColor, size, sizeUnit } = props;
    return (
        <div
            style={{
                backgroundColor,
                height: size + sizeUnit,
                width: size + sizeUnit
            }}
            className={className}
        >
            <MoonLoader color={color} size={size} sizeUnit={sizeUnit} />
        </div>
    );
}
