const titles = {
    titles: {
        "/": " FRP Splash",
        default: "FRP",
        "/aboutUs": "FRP About us",
        "/home": "FRP Homepage",
        "/frpApps": "FRP Apps",
        "/downloads": "FRP Downloads",
        "/labels": "FRP Labels",
        "/frpTv": "FRP TV",
        "/apps": "FRP Apps",
        "/billboard": "FRP Billboard",
        "/contactUs": "FRP Contact us",
        "/agreement": "FRP Agreement",
        "/disclaimer": "FRP Disclaimer & DMCA",
        "/rotation": "FRP Heavy Rotation",
        "/tracks": "FRP Media",
        "/profile": "FRP Profile",
        "/login": " Log In",
        "/register": "Sign Up",
        "/spins-guide": "FRP Spins Guide",
        "/app-guide": "FRP App Guide"
    }
};

export default titles;
