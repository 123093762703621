import React from "react";
import { Route } from "react-router-dom";
import ContactUsPage from "components/pages/ContactUsPage";
import AgreementPage from "components/pages/AgreementPage";
import DisclaimerDmcaPage from "components/pages/DisclaimerDmcaPage";
import NoPage from "components/pages/NoPage";
import AppsPage from "components/pages/AppsPage";
import spin from "components/pages/Spin";
import frpApp from "components/pages/FrpApp";

export default function InfoRoutes() {
    return [
        <Route exact path="/contactUs" component={ContactUsPage} />,
        <Route exact path="/agreement" component={AgreementPage} />,
        <Route exact path="/disclaimer" component={DisclaimerDmcaPage} />,
        <Route exact path="/apps/:type?" component={AppsPage} />,
        <Route exact path="/spins-guide" component={ spin } />,
        <Route exact path="/app-guide" component={ frpApp } />,
        <Route exact path="/upload" component={NoPage} />
    ];
}
