import React, { useContext } from "react";
import { Button } from "components/common/ControlElems";
import routerDecorator from "components/helpers/withRouter";
import { AuthContext } from "context/AuthContext";
import t from "helpers/getText";

const DropDownContent = ({ setShowMenu, history }) => {
    const { logout } = useContext(AuthContext);
    const hideMenuAndRedirectTo = address => {
        setShowMenu(false);
        history.push(address);
    };
    const stylesForButton = extraPadding => {
        return {
            fontSize: "14px",
            fontWeight: "500",
            padding: extraPadding ? "16px 16px 8px 16px" : "16px 16px 8px 0"
        };
    };
    return (
        <>
            <Button
                isUpperCase={false}
                style={stylesForButton(true)}
                withoutBorder
                color="bright"
                label={t("navbar.['/menuProfile']")}
                onClick={() => hideMenuAndRedirectTo("/profile/personal")}
            />
            <Button
                isUpperCase={false}
                style={stylesForButton(true)}
                withoutBorder
                color="bright"
                label={t("navbar.['/menuSubscription']")}
                onClick={() => hideMenuAndRedirectTo("/profile/subscription")}
            />
             <Button
                isUpperCase={false}
                style={stylesForButton(true)}
                withoutBorder
                color="bright"
                label={t("navbar.['/menuBilling']")}
                onClick={() => hideMenuAndRedirectTo("/profile/billing")}
            />
            <hr color="#711D48" />
            <Button
                color="grey"
                isUpperCase={false}
                style={stylesForButton(false)}
                withoutBorder
                label={t("navbar.['/menuLogout']")}
                onClick={async () => {
                    await logout();
                    hideMenuAndRedirectTo("/login");
                }}
            />
        </>
    );
};

export default routerDecorator(DropDownContent);
