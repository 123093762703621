import React from "react";
import classnames from "classnames";
import styles from "components/static/StaticPages.module.scss";
import t from "helpers/getText";

export default function NoPage() {
    return (
        <div className={classnames(styles.container, styles.contactUsBack)}>
            <section className={styles.content}>
                <h1>{t("noPage.header")}</h1>
            </section>
        </div>
    );
}
