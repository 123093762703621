import React from "react";

export default function Arrow() {
    const color = "#BB1D7E";
    return (
        <svg width="8" height="12" viewBox="0 0 8 12">
            <path
                fill="none"
                fillRule="evenodd"
                stroke={color}
                strokeWidth="1.5"
                d="M1 1l5.243 5.243L1 11.485"
            />
        </svg>
    );
}
