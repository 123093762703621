import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import propTypes from "prop-types";



Letter.propTypes = {
    className: PropTypes.string,
    color: PropTypes.string,
    activeColor: PropTypes.string,
    letterOpacity: PropTypes.number,
    circleOpacity: PropTypes.number,
    letter: PropTypes.string,
    shift: PropTypes.object,
    active: PropTypes.bool,
    toolTipClass: propTypes.string,
    letterText: propTypes.string
};

Letter.defaultProps = {
    className: "",
    toolTipClass: "",
    color: "var(--theme-main-color)",
    activeColor: "#bb1d7e",
    active: false,
    circleOpacity: 0.3,
    letterOpacity: 0.4,
    letter: "A",
    bgColor: "#fff",
    shift: { x: 0, y: 0 },
    letterText: ''
};



export default function Letter( props ) {
    const {
        color,
        // activeColor,
        className,
        letterOpacity,
        // circleOpacity,
        letter,
        shift,
        active,
        bgColor,
        toolTipClass,
        letterText
    } = props;
    let letterStyle = {
        backgroundColor: bgColor,
        borderRadius: "40%",
        fontSize: "1.1rem",
        marginLeft: '0.5px',
    };

    let letterTextStyle = {
        fill: '#fff',
        opacity: '1',
    };
    let letterTextTspinStyle = {
        fontFamily: 'azonix',
        fontSize: "1.1rem",
    };
    return (
        <>
            <svg className={ classNames( className, letterStyle ) } width="28" height="18" style={ letterStyle } viewBox="0 0 26 26">
                <g
                    fill={ active ? bgColor : "none" }
                    fillRule="evenodd"
                    transform="translate(1 1)"
                >
                    {/* <circle
                        cx="12"
                        cy="12"
                        r="12"
                        stroke={ active ? bgColor : color }
                        strokeLinejoin="bevel"
                        strokeWidth="0"
                        opacity={ active ? 1 : circleOpacity }
                    /> */}
                    <text
                        fill={ active ? "var(--white)" : color }
                        fontFamily="Montserrat-Bold, Montserrat"
                        fontSize="12"
                        fontWeight="bold"
                        opacity={ active ? 1 : letterOpacity }
                        style={ letterTextStyle }
                    >
                        <tspan x={ 5 + ( shift.x || 0 ) } y={ 18 + ( shift.y || 0 ) } style={ letterTextTspinStyle }>
                            { letter }
                        </tspan>
                    </text>
                </g>
            </svg>
            <span className={ classNames( toolTipClass ) }>{letterText}</span>
        </>
    );
}
