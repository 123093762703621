import React, { useState } from "react";
import PropTypes from "prop-types";
import Button from "components/common/ControlElems/Button";
import TextField from "components/common/form/TextField";
import CheckBoxField from "components/common/form/CheckBoxField";
import {
    fieldProps,
    checkBoxFieldProps
} from "components/helpers/forms/formFieldHelper";
import styles from "./Auth.module.scss";
import classnames from "classnames";
import validateDjFields from "./validateDjFields";

export default function UserRegisterFormDj(props) {
    const [wasSubmit, setWasSubmit] = useState(false);

    const { fields, setFields, serverErrors, onFinish } = props;

    const clientErrors = wasSubmit ? validateDjFields(fields) : {};
    const errors = Object.assign({}, clientErrors, serverErrors);

    return (
        <div className={styles.container}>
            <h3 className={classnames(styles.center, styles.head)}>
                Address information
            </h3>

            <div className={styles.left}>
                <TextField
                    label="Country"
                    {...fieldProps("country", "string", {
                        fields,
                        setFields,
                        errors
                    })}
                />

                <TextField
                    label="Street"
                    {...fieldProps("street", "string", {
                        fields,
                        setFields,
                        errors
                    })}
                />

                <TextField
                    label="Region"
                    {...fieldProps("region", "string", {
                        fields,
                        setFields,
                        errors
                    })}
                />
            </div>
            <div className={styles.right}>
                <TextField
                    label="ZipCode"
                    {...fieldProps("zipCode", "string", {
                        fields,
                        setFields,
                        errors
                    })}
                />

                <TextField
                    label="City"
                    {...fieldProps("city", "string", {
                        fields,
                        setFields,
                        errors
                    })}
                />

                <TextField
                    label="Promo code"
                    {...fieldProps("promoCode", "string", {
                        fields,
                        setFields,
                        errors
                    })}
                />
            </div>

            <h3 className={classnames(styles.center, styles.head)}>
                DJ information
            </h3>

            <div className={styles.left}>
                <TextField
                    label="DJ Name"
                    {...fieldProps("djName", "string", {
                        fields,
                        setFields,
                        errors
                    })}
                />

                <CheckBoxField
                    label="I am a club DJ"
                    {...checkBoxFieldProps("isClubDj", {
                        fields,
                        setFields,
                        errors
                    })}
                />
            </div>

            <div className={styles.right}>
                <CheckBoxField
                    label="I am a radio DJ"
                    {...checkBoxFieldProps("isRadioDj", {
                        fields,
                        setFields,
                        errors
                    })}
                />

                <CheckBoxField
                    label="I am a mobile DJ"
                    {...checkBoxFieldProps("isMobileDj", {
                        fields,
                        setFields,
                        errors
                    })}
                />
            </div>

            <h3 className={classnames(styles.center, styles.head)}>
                Social Media
            </h3>
            <div className={styles.left}>
                <TextField
                    label="Facebook URL"
                    {...fieldProps("facebookUrl", "string", {
                        fields,
                        setFields,
                        errors
                    })}
                />

                <TextField
                    label="Twitter URL"
                    {...fieldProps("twitterUrl", "string", {
                        fields,
                        setFields,
                        errors
                    })}
                />
            </div>

            <div className={styles.right}>
                <TextField
                    label="Instagram URL"
                    {...fieldProps("instagramUrl", "string", {
                        fields,
                        setFields,
                        errors
                    })}
                />

                <TextField
                    label="Snapchat URL"
                    {...fieldProps("snapchatUrl", "string", {
                        fields,
                        setFields,
                        errors
                    })}
                />
            </div>

            <div className={classnames(styles.center, styles.button)}>
                <Button
                    label="Complete registration"
                    onClick={finish({ onFinish, fields, setWasSubmit })}
                />
            </div>
        </div>
    );
}

function finish({ fields, onFinish, setWasSubmit }) {
    return () => {
        setWasSubmit(true);

        const errors = validateDjFields(fields);
        if (Object.keys(errors).length > 0) return;

        onFinish(fields);
    };
}

UserRegisterFormDj.propTypes = {
    fields: PropTypes.object.isRequired,
    setFields: PropTypes.func.isRequired,
    errors: PropTypes.object,
    onFinish: PropTypes.func.isRequired
};
