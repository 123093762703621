import _ from "lodash";
import {
    errors,
    navbar,
    profile,
    footer,
    oauth,
    registration,
    subscription,
    home,
    tracks,
    splash,
    promopackage,
    aboutUs,
    contactUs,
    agreement,
    disclaimerdmca,
    titles,
    rotation,
    noPage,
    apps,
    commonDialogs,
    spin,
} from "./texts";

const text = {
    ...errors,
    ...navbar,
    ...profile,
    ...footer,
    ...oauth,
    ...registration,
    ...subscription,
    ...home,
    ...tracks,
    ...splash,
    ...promopackage,
    ...aboutUs,
    ...contactUs,
    ...agreement,
    ...disclaimerdmca,
    ...titles,
    ...rotation,
    ...noPage,
    ...apps,
    ...commonDialogs,
    ...spin,
};

export default function getText( key, placeholder ) {
    return _.get( text, key, placeholder || key );
}

export function getTextOrNull( key ) {
    return _.get( text, key, null );
}
