import React, { Component } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import AuthContextContainer from "context/AuthContext.js";
import ContentContextContainer from "context/ContentContext.js";
import UserRegisterContextContainer from "context/UserRegisterContext";
import SubscriptionContextContainer from "context/SubscriptionContext";
import UIContextContainer from "context/UIContext";
import { Provider as CommonDialogContextProvider } from "context/CommonDialogContext";
import { Provider as EnvironmentContextProvider } from "context/EnvironmentContext";
import Routers from "routers";
import styles from "assets/style/Root.module.scss";

class App extends Component {
    renderContextProviders(contextProviders, children) {
        if (contextProviders.length === 0) return children;

        const [ContextProvider, ...restContextProviders] = contextProviders;

        return (
            <ContextProvider>
                {this.renderContextProviders(restContextProviders, children)}
            </ContextProvider>
        );
    }

    render() {
        const contextProviders = [
            EnvironmentContextProvider,
            CommonDialogContextProvider,
            AuthContextContainer,
            ContentContextContainer,
            UserRegisterContextContainer,
            SubscriptionContextContainer,
            UIContextContainer
        ];

        return (
            <div className={styles.root} id="content">
                {this.renderContextProviders(
                    contextProviders,
                    <Router>
                        <Routers />
                    </Router>
                )}
            </div>
        );
    }
}

export default App;
