import React, { useContext, useState } from "react";
import { AuthContext } from "context/AuthContext";
import TextField from "components/common/form/TextField";
import Button from "components/common/ControlElems/Button";
import { fieldProps } from "components/helpers/forms/formFieldHelper";
import styles from "components/userRegister/Auth.module.scss";
import { NavLink } from "react-router-dom";
import classnames from "classnames";
import parseServerValidationErrors from "sources/api/parseServerValidationErrors";
import validateResetPasswordFields from "./validateResetPasswordFields";

export default function ResetPassword() {
    const [wasSubmit, setWasSubmit] = useState(false);
    const [fields, setFields] = useState({
        email: ""
    });

    const {
        resetPassword,
        resetPasswordProcessing,
        resetPasswordResult,
        resetPasswordError
    } = useContext(AuthContext);

    const clientErrors = wasSubmit ? validateResetPasswordFields(fields) : {};
    const serverErrors = parseServerValidationErrors(resetPasswordError);
    const errors = Object.assign({}, clientErrors, serverErrors);

    if (resetPasswordResult)
        return (
            <section className={styles.container}>
                <h4 className={classnames(styles.center, styles.subTitleText)}>
                    Your request has been received.
                    Please check your email for your login information.
                </h4>
                <div className={classnames(styles.center, styles.narrow)}>
                    <NavLink to="/login">
                        <Button
                            size="small"
                            fullWidth
                            label="Sign In"
                        />
                    </NavLink>
                </div>
            </section>
        );

    return (
        <section className={styles.container}>
            <h4 className={classnames(styles.center, styles.subTitleText)}>
                Please enter your Email to request a new password. Please make
                sure you enter the email address you used when signing up. Your
                new password will be emailed to that email address and you will
                now be able to login and change your password to the one of your
                choice.
            </h4>
            <div className={classnames(styles.center, styles.narrow)}>
                <TextField
                    label="Email"
                    {...fieldProps("email", "email", {
                        fields,
                        setFields,
                        errors
                    })}
                />

                <Button
                    label="Submit"
                    fullWidth
                    disabled={resetPasswordProcessing}
                    onClick={startResetPassword({
                        fields,
                        resetPassword,
                        setWasSubmit
                    })}
                />
                <NavLink to="/login">
                    <Button
                        withoutBorder
                        size="small"
                        fullWidth
                        className={styles.fadedButton}
                        label="Sign In"
                    />
                </NavLink>
            </div>
        </section>
    );
}

function startResetPassword({ fields, resetPassword, setWasSubmit }) {
    return () => {
        setWasSubmit(true);

        const errors = validateResetPasswordFields(fields);
        if (Object.keys(errors).length > 0) return;

        resetPassword(fields);
    };
}
