import React from "react";
import Dialog from "./BaseDialog";

export default function AlertDialog(props) {
    const { onClose, children } = props;

    return (
        <Dialog dialogType="alert" onClose={onClose}>
            {children}
        </Dialog>
    );
}
