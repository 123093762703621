import React from "react";
import PropTypes from "prop-types";

Download.propTypes = {
    className: PropTypes.string,
    color: PropTypes.string,
    opacity: PropTypes.number
};

Download.defaultProps = {
    className: "",
    color: "red",
    opacity: 0.3,
    strokeWidth: 1.5
};

export default function Download(props) {
    const { color, className, opacity, strokeWidth } = props;
    return (
        <svg className={className} width="26" height="26" viewBox="0 0 26 26">
            <g fill="none" fillRule="evenodd">
                <circle
                    cx="12"
                    cy="12"
                    r="11.5"
                    stroke={color}
                    strokeOpacity={opacity}
                    strokeWidth={strokeWidth}
                />
                <path
                    fill={color}
                    d="M12.75 11.25H18v1.5h-5.25V18h-1.5v-5.25H6v-1.5h5.25V6h1.5v5.25z"
                    opacity={opacity}
                />
            </g>
        </svg>
    );
}
