import React, { useState, useEffect, useRef } from "react";
import styles from "../../Profile/Dialogs/UpdateAvatarDialog.module.scss";
import ReactCrop from "react-image-crop";
import { Button } from "components/common/ControlElems";
import "react-image-crop/dist/ReactCrop.css";

export default function CropImageArea(props) {
    const { setFile, setPixels, onFinished } = props;

    const reader = new FileReader();

    const [state, setState] = useState({
        src: null,
        image: null,
        crop: {
            x: 40,
            y: 40,
            width: 20,
            aspect: 1
        }
    });

    const listener = () => setState({ src: reader.result });

    useEffect(() => {
        return () => {
            reader.removeEventListener("load", listener, false);
        };
    }, []);

    const onSelectFile = e => {
        if (e.target.files && e.target.files.length > 0) {
            setFile("file was chosen");
            reader.addEventListener("load", listener, false);
            reader.readAsDataURL(e.target.files[0]);
        }
    };

    const onImageLoaded = oldState => {
        return image => {
            setState({ ...oldState, image });
            return false;
        };
    };
    const onCropChange = oldState => (absoluteCrop, crop) =>
        setState({ ...oldState, crop: { ...crop, aspect: 1 } });

    const onSave = () => {
        if (state.crop === undefined) return;
        const { x, y, width, height } = state.crop;
        const { naturalWidth, naturalHeight } = state.image;
        const pixels = {
            x: (x * naturalWidth) / 100,
            y: (y * naturalHeight) / 100,
            width: (width * naturalWidth) / 100,
            height: (height * naturalHeight) / 100
        };
        setFile(state.src);
        setPixels(pixels);
        onFinished(true);
    };

    const chooseFileRef = useRef(null);

    useEffect(() => {
        if (chooseFileRef.current) {
            chooseFileRef.current.click();
        }
    }, []);

    const labelForUploadButton = (
        <label>
            <div className={styles.label}>
                <input
                    ref={chooseFileRef}
                    type="file"
                    onChange={onSelectFile}
                />
                <div>Choose file</div>
            </div>
        </label>
    );

    return (
        <div className="App">
            <div className={styles.buttonContainer}>
                <Button
                    className={styles.button}
                    color="bright"
                    width="14.5rem"
                    withoutPadding
                    label={labelForUploadButton}
                />
                {state.image && (
                    <Button
                        className={styles.button}
                        color="bright"
                        width="14.5rem"
                        onClick={onSave}
                        label="Save"
                    />
                )}
            </div>
            {state.src && (
                <ReactCrop
                    src={state.src}
                    crop={state.crop}
                    onImageLoaded={onImageLoaded(state)}
                    onComplete={() => {}}
                    onChange={onCropChange(state)}
                />
            )}
        </div>
    );
}
