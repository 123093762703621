export default function(response) {
    if (response.success) return Promise.resolve(response.data);

    if (!response.success) {
        const error = new Error(response.message);
        error.code = response.code;
        error.details = response.details;
        return Promise.reject(error);
    }

    return Promise.reject(new Error("Broken http server response"));
}
