import React from "react";
import PropTypes from "prop-types";
import Slider from "rc-slider";
import "rc-slider/assets/index.css";

import styles from "./BaseSlider.module.scss";

const STYLES_FOR_HAND = {
    backgroundColor: "#bb1d7e",
    transition: "100ms ease",
    marginTop: "-3px",
    border: "none",
    height: "0.5rem",
    width: "0.5rem"
};

BaseSlider.propTypes = {
    value: PropTypes.oneOfType([PropTypes.array, PropTypes.number]).isRequired,
    min: PropTypes.number,
    max: PropTypes.number,
    step: PropTypes.number,
    range: PropTypes.bool,
    onChange: PropTypes.func.isRequired
};

BaseSlider.defaultProps = {
    range: false,
    min: 0,
    max: 100,
    step: 1
};

export default function BaseSlider(props) {
    const { min, max, step, value, onChange, range } = props;
    return (
        <div className={styles.container}>
            {range ? (
                <Slider.Range
                    min={min}
                    max={max}
                    step={step}
                    value={value}
                    railStyle={{
                        backgroundColor: "var(--theme-muffled-color)",
                        height: "2px"
                    }}
                    trackStyle={[
                        {
                            backgroundColor: "#bb1d7e",
                            height: "2px",
                            transition: "100ms ease"
                        }
                    ]}
                    handleStyle={[STYLES_FOR_HAND, STYLES_FOR_HAND]}
                    defaultValue={[min, max]}
                    onChange={newValue => onChange(newValue)}
                />
            ) : (
                <Slider
                    min={min}
                    max={max}
                    step={step}
                    value={value}
                    railStyle={{
                        backgroundColor: "var(--theme-muffled-color)",
                        height: "2px"
                    }}
                    trackStyle={[
                        {
                            backgroundColor: "#bb1d7e",
                            height: "2px",
                            transition: "100ms ease"
                        }
                    ]}
                    handleStyle={[STYLES_FOR_HAND]}
                    onChange={newValue => onChange(newValue)}
                />
            )}
        </div>
    );
}
