import React from "react";
import makeHttpRequest from "sources/api/http/makeHttpRequest";
import makeHttpRequestLabel from "sources/api/httpLabel/makeHttpRequest";
import bindFunctions from "./helpers/bindFunctions";
import { createRequestHelper } from "./helpers/requestHelper";
import { isUnauthorizeError } from "sources/api/ApiError";

export const AuthContext = React.createContext();

const loginRequestHelper = createRequestHelper("login");
const updateProfileRequestHelper = createRequestHelper("updateProfile");
const updatePasswordRequestHelper = createRequestHelper("updatePassword");
const logoutRequestHelper = createRequestHelper("logout");
const resetPasswordRequestHelper = createRequestHelper("resetPassword");
const setAvatarHelper = createRequestHelper("setAvatar");
const confirmResetPasswordRequestHelper = createRequestHelper(
    "confirmResetPassword"
);

export default class AuthContextContainer extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            ...updateProfileRequestHelper.initialState,
            ...loginRequestHelper.initialState,
            ...logoutRequestHelper.initialState,
            ...resetPasswordRequestHelper.initialState,
            ...confirmResetPasswordRequestHelper.initialState,
            ...updatePasswordRequestHelper.initialState,
            ...setAvatarHelper.initialState,
            user: null,
            triedLoginByToken: false
        };

        this.funcs = bindFunctions(this, {
            login: this.login,
            logout: this.logout,
            loginWithToken: this.loginWithToken,
            resetPassword: this.resetPassword,
            clearResetPasswordResult: this.clearResetPasswordResult,
            confirmResetPassword: this.confirmResetPassword,
            setUser: this.setUser,
            makeAuthenticateHttpRequest: this.makeAuthenticateHttpRequest,
            updateProfile: this.updateProfile,
            updateDj: this.updateDj,
            updatePassword: this.updatePassword,
            setAvatar: this.setAvatar
        });
    }

    async componentDidMount() {
        await this.loginWithToken();
        this.setState({
            triedLoginByToken: true
        });
    }

    setAvatar = async file => {
        this.setState(setAvatarHelper.processing());
        try {
            const result = await this.makeAuthenticateHttpRequest({
                method: "post",
                path: `/users/setAvatar`,
                files: { file }
            });

            this.setState({
                ...setAvatarHelper.result(true)
            });

            this.setUser({ ...this.state.user, ...result });
        } catch (error) {
            this.setState(setAvatarHelper.error(error));
        }
    };

    logout = async () => {
        try {
            const result = await makeHttpRequest({
                method: "post",
                path: "/users/logout"
            });
            this.setState({
                user: null
            });
        } catch (error) {
            this.setState(logoutRequestHelper.error(error));
        }
    };

    async login({ login, password, remember }) {
        this.setState(loginRequestHelper.processing());
        try {
            await makeHttpRequest({
                method: "post",
                path: "/users/login-custom",
                data: { login, password, remember }
            });
            await this.loginWithToken(login, password);
            this.setState({
                ...loginRequestHelper.clear()
            });
        } catch (error) {
            this.setState(loginRequestHelper.error(error));
        }
    }

    updateProfile = async fields => {
        this.setState(updateProfileRequestHelper.processing());
        try {
            const result = await makeHttpRequest({
                method: "post",
                path: "/users/updateProfile",
                data: { fields }
            });
            await this.loginWithToken();
            this.setState(updateProfileRequestHelper.result(result));
        } catch (error) {
            this.setState(updateProfileRequestHelper.error(error));
        }
    };

    updateDj = async fields => {
        this.setState(updateProfileRequestHelper.processing());
        try {
            const result = await makeHttpRequest({
                method: "post",
                path: "/users/updateDj",
                data: { fields }
            });
            await this.loginWithToken();
            this.setState(updateProfileRequestHelper.result(result));
        } catch (error) {
            this.setState(updateProfileRequestHelper.error(error));
        }
    };

    updatePassword = async fields => {
        this.setState(updatePasswordRequestHelper.processing());
        try {
            const result = await makeHttpRequest({
                method: "post",
                path: "/users/updatePassword",
                data: { fields }
            });
            this.setUser(result);
            this.setState(updatePasswordRequestHelper.result(result));
            await this.loginWithToken();
            return true;
        } catch (error) {
            this.setState(updatePasswordRequestHelper.error(error));
            return false;
        }
    };

    async loginWithToken(login, password) {
        try {
            const result = await makeHttpRequest({
                method: "post",
                path: "/users/loginWithToken"
            });
            if (result.user.type === "label" && login && password) {
                const result = await makeHttpRequestLabel({
                    method: "post",
                    path: "/auth/login",
                    data: { username: login, password }
                });
                const { user, token } = result;
                console.log("user, token1", user, token);
                localStorage.setItem("authUser", JSON.stringify(user));
                localStorage.setItem("token", token);
                console.log("user, token2", user, token);
            }
            this.setUser(result.user);
        } catch (error) {
            try {
                const result = await makeHttpRequest({
                    method: "post",
                    path: "/users/refreshAccessToken"
                });
                this.setUser(result);
            } catch (e) {}
        }
    }

    async resetPassword({ email }) {
        this.setState(resetPasswordRequestHelper.processing());
        try {
            const result = await makeHttpRequest({
                method: "post",
                path: "/users/resetPassword",
                data: { email }
            });
            this.setState(resetPasswordRequestHelper.result(result));
        } catch (error) {
            this.setState(resetPasswordRequestHelper.error(error));
        }
    }

    clearResetPasswordResult() {
        this.setState({
            ...resetPasswordRequestHelper.clear()
        });
    }

    async confirmResetPassword(token, { newPassword }) {
        this.setState(confirmResetPasswordRequestHelper.processing());
        try {
            await makeHttpRequest({
                method: "post",
                path: "/users/confirmResetPassword",
                data: { newPassword, token }
            });

            await this.loginWithToken();
        } catch (error) {
            this.setState(confirmResetPasswordRequestHelper.error(error));
        }
    }

    makeAuthenticateHttpRequest = async requestOptions => {
        if (!this.state.user)
            throw Error("Authorise required for subscription");
        try {
            const result = await makeHttpRequest(requestOptions);
            return result;
        } catch (error) {
            if (isUnauthorizeError(error)) {
                this.setState({ user: null });
            }
            throw error;
        }
    };

    setUser = user => {
        this.setState({ user });
    };

    render() {
        const { triedLoginByToken } = this.state;
        if (!triedLoginByToken) return null;
        return (
            <AuthContext.Provider value={{ ...this.state, ...this.funcs }}>
                {this.props.children}
            </AuthContext.Provider>
        );
    }
}
