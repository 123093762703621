import AbstractFieldsValidator from "components/helpers/validation/AbstractFieldsValidator";

export default function(fields, options) {
    return new RegisterFieldsValidator(fields, options).validate();
}

class RegisterFieldsValidator extends AbstractFieldsValidator {
    doCheckForSingleValue() {
        const {
            fullName,
            email,
            phone,
            city,
            country,
            region,
            street
        } = this.fields;

        if (!fullName.trim())
            this.addError("fullName", "Please type your full name");

        if (!email.trim()) this.addError("email", "Please type your email");

        if (!phone.trim()) this.addError("phone", "Please type your phone");

        if (!country.trim())
            this.addError("country", "Please type your country");

        if (!region.trim()) this.addError("region", "Please type your region");

        if (!city.trim()) this.addError("city", "Please type your city");

        if (!street.trim()) this.addError("street", "Please type your street");
    }

    doCheckForEmailAndPhone() {
        const { email, phone } = this.fields;
        const regularEmail = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        const regularPhone = /^(\+)?(\d[\(\)\-\s]?){4,12}\d$/;
        if (!regularEmail.test(email.trim()))
            this.addError("email", "Email is not valid");
        if (!regularPhone.test(phone.trim()))
            this.addError("phone", "Phone is not valid");
    }
}
