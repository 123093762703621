import React, { useContext, useEffect, useState, useRef } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import styles from "./Tracks.module.scss";
import routerDecorator from "components/helpers/withRouter";
import { NavigationPanel, SideFilters } from "components/common/ControlElems";
import { ContentContext } from "context/ContentContext";
import smoothScrollUp from "helpers/utils/smoothScrollUp";
import ListWithPaginator from "./ListWithPaginator";
import SideLinks from "./SideLinks";
import AlertDialog from "../common/Dialogs/AlertDialog";

import { MEGA_LINKS } from "helpers/constants/musicNavigationLinks";

Tracks.propTypes = {
    location: PropTypes.object,
    className: PropTypes.string
};
function Tracks(props) {
    const {
        className,
        location: { pathname }
    } = props;  
    const {
        requestTracksWithRetry,
        scrollbar,
        audio,
        video,
        type,
        clearMedia,
        isTrackLimited,
        trackLimitedMessage,
        allGenres,
        fetchCountries,
        countries,
        requestFetchCountriesProcessing,
        requestTracksProcessing
    } = useContext(ContentContext);

    const { limit, offset, count } = type === "audio" ? audio : video;

    const trackContainer = useRef(null);
    const [showTrackLimitedDialog, setShowTrackLimitedDialog] = useState(false);

    useEffect(() => {
        fetchCountries();
    }, []);

    useEffect(() => {
        smoothScrollUp(scrollbar, trackContainer);
    }, [offset, limit, count]);

    useEffect(() => clearMedia, []);

    useEffect(() => {
        if (isTrackLimited) {
            setShowTrackLimitedDialog(true);
        }
    }, [isTrackLimited]);

    //TODO: delete after the curated page is added
    const hidePaginator = pathname === "/tracks/curated";

    return (
        <div className={classnames(styles.container, className)}>
            <SideFilters className={styles.left} links={MEGA_LINKS} />
            <div className={styles.center}>
                <NavigationPanel
                    prefix="tracks.headers"
                    className={styles.navbar}
                    upperCase={false}
                    megaLinks={MEGA_LINKS}
                />
                <ListWithPaginator
                    containerRef={trackContainer}
                    hidePaginator={hidePaginator}
                    limit={limit}
                    offset={offset}
                    count={count}
                    requestTracks={requestTracksWithRetry}
                    requestTracksProcessing={requestTracksProcessing}
                    type={type}
                    styles={styles}
                    location={pathname}
                    genres={allGenres}
                    countries={countries}
                    requestFetchCountriesProcessing={requestFetchCountriesProcessing}
                />
                {showTrackLimitedDialog && (
                    <AlertDialog onClose={setShowTrackLimitedDialog}>
                        {trackLimitedMessage}
                    </AlertDialog>
                )}
            </div>
            <SideLinks showWhatNew />
        </div>
    );
}

export default routerDecorator(Tracks);
