export const getYoutubeLocalAddress = fullAddress => {
    const result = (fullAddress || "").match(
        /(?:youtube\.com\/(?:[^\/]+\/.+\/|(?:v|e(?:mbed)?)\/|.*[?&]v=)|youtu\.be\/)([^"&?\/ ]{11})/i
    );
    return [result && result[1]];
};

export function getAmazonAddress(address) {
    if ((address || "").match(/^http(.)*mp4$/i)) {
        return address;
    }
    return (
        (address || "").match(/\.mp4$/gi) &&
        `https://s3.amazonaws.com/frp-new-dev/video/${address}`
    );
}
