import React, { useContext, useEffect, useState } from "react";
import { Switch, withRouter } from "react-router-dom";
import createActivityDetector from "activity-detector";

import { AuthContext } from "context/AuthContext";

import Main from "components/main/Main";

import { CommonDialogProvider } from "../providers/CommonDialogsProvider";

import InfoRoutes from "./InfoRoutes";
import GuestRoutes from "./GuestRoutes";
import BaseUserRoutes from "./BaseUserRoutes";
import UnpaidUserRoutes from "./UnpaidUserRoutes";

// import FlyerPopup from "components/Profile/Dialogs/FlyerPopupDialog";
// import { localStorage } from "helpers/storage";

const INACTIVE_USER_TIME_THRESHOLD = 1000 * 60 * 30;

function AppRouters(props) {
    const { user, logout } = useContext(AuthContext);
    const [inactive, setInactive] = useState(false);
    const [detector, setDetector] = useState(null);
    // const [showFlyer, setShowFlyer] = useState(true);
    const { history } = props;
    const commonRoutes = InfoRoutes(props);
    const roleRoutes = getRoutes(user, props);
    const routes = [...commonRoutes, ...roleRoutes];

    useEffect(() => {
        async function logoutUser() {
            await logout();
            history.push("/login");
        }

        if (user && inactive) {
            setInactive(false);
            logoutUser();
        }

        if (user && user.type === "label") {
            if (typeof window !== "undefined") {
                window.location.href = "/label";
            }
        }
    }, [inactive, user]);

    useEffect(() => {
        if (detector && !user) {
            detector.stop();
            setDetector(null);
        } else if (!detector && user) {
            const activityDetector = createActivityDetector({
                timeToIdle: INACTIVE_USER_TIME_THRESHOLD,
                initialState: "active",
                inactivityEvents: []
            });
            activityDetector.on("idle", () => {
                setInactive(true);
            });
            activityDetector.on("active", () => {
                setInactive(false);
            });
            setDetector(activityDetector);
        }
    }, [user]);

    // const handleDontShowFlyer = () => {
    //     setShowFlyer(false);
    //     localStorage.set("flyer", true);
    // };

    return (
        <Main>
            <CommonDialogProvider>
                <Switch>
                    {routes.map((Element, index) => (
                        <Element.type key={index} {...Element.props} />
                    ))}
                </Switch>
            </CommonDialogProvider>
            {/* {user && showFlyer && !localStorage.get("flyer") && (
                <FlyerPopup
                    onClose={() => setShowFlyer(false)}
                    onDontShow={handleDontShowFlyer}
                    onContinue={() => setShowFlyer(false)}
                />
            )} */}
        </Main>
    );
}

function getRoutes(user, props) {
    let result = GuestRoutes(props);
    if (
        user &&
        user.allow_user !== 0 &&
        user.subscription &&
        user.subscription.active
    ) {
        if (user.type === "dj") {
            result = BaseUserRoutes(props);
        }
    } else if (user) {
        result = UnpaidUserRoutes(props);
    }
    return result;
}

export default withRouter(AppRouters);
