import React, { useContext, useEffect, useState } from "react";
import t from "helpers/getText";
import routerDecorator from "components/helpers/withRouter";
import styles from "./Personal.module.scss";
import TextField from "../common/form/TextField";
import TextArea from "../common/form/TextArea";
import CheckBoxField from "../common/form/CheckBoxField";
import { Button } from "../common/ControlElems";
import classnames from "classnames";
import { AuthContext } from "context/AuthContext";
import {
    fieldProps,
    checkBoxFieldProps
} from "components/helpers/forms/formFieldHelper";
import validateDjProfile from "./validateDjProfile";
import AlertDialog from "components/common/Dialogs/AlertDialog";
import parseServerValidationErrors from "../../sources/api/parseServerValidationErrors";

function DJInfo(props) {
    const {
        user,
        updateDj,
        updateProfileError,
        updateProfileResult
    } = useContext(AuthContext);

    const [touched, setTouched] = useState(false);
    const [wasSubmit, setWasSubmit] = useState(false);
    const [wasUpdated, setWasUpdated] = useState(false);
    const [updatedResult, setUpdatedResult] = useState(null);
    const [fields, setFields] = useState({
        userId: user.id,
        djName: user.dj.djName,
        djBio: user.dj.djBio,
        isClubDj: user.dj.isClubDj,
        isMobileDj: user.dj.isMobileDj,
        isRadioDj: user.dj.isRadioDj
    });
    const serverErrors = parseServerValidationErrors(updateProfileError);
    const clientErrors = wasSubmit ? validateDjProfile(fields) : {};
    const errors = Object.assign({}, clientErrors, serverErrors);

    useEffect(() => {
        if (wasSubmit) {
            if (updateProfileResult) {
                setUpdatedResult(t("profile.labels.subtitles.onUpdate"));
                setTouched(false);
            } else {
                setUpdatedResult(t("profile.labels.subtitles.onNotUpdate"));
            }
            setWasUpdated(true);
        }
    }, [updateProfileResult]);

    return (
        <div className={styles.container}>
            <div className={styles.semiWide}>
                <TextField
                    label={t("profile.labels.fields.djname")}
                    value={fields.djName}
                    max="100"
                    {...fieldProps("djName", "string", {
                        fields,
                        setFields,
                        errors,
                        setTouched
                    })}
                />
            </div>
            <div className={styles.semiWide}>
                <TextArea
                    label={t("profile.labels.fields.djbio")}
                    max="512"
                    value={user.dj.djBio}
                    {...fieldProps("djBio", "string", {
                        fields,
                        setFields,
                        errors,
                        setTouched
                    })}
                />
            </div>
            <div className={styles.center}>
                <CheckBoxField
                    classes={{ checked: styles.checked }}
                    containerStyle={{ marginTop: "-16px" }}
                    label={t("profile.labels.fields.isclub")}
                    value={user.dj.isClubDj}
                    {...checkBoxFieldProps("isClubDj", {
                        fields,
                        setFields,
                        errors,
                        setTouched
                    })}
                />
                <CheckBoxField
                    classes={{ checked: styles.checked }}
                    containerStyle={{ marginTop: "-40px" }}
                    label={t("profile.labels.fields.isradio")}
                    value={user.dj.isRadioDj}
                    {...checkBoxFieldProps("isRadioDj", {
                        fields,
                        setFields,
                        errors,
                        setTouched
                    })}
                />
                <CheckBoxField
                    classes={{ checked: styles.checked }}
                    containerStyle={{ marginTop: "-40px" }}
                    label={t("profile.labels.fields.ismobile")}
                    value={user.dj.isMobileDj}
                    {...checkBoxFieldProps("isMobileDj", {
                        fields,
                        setFields,
                        errors,
                        setTouched
                    })}
                />
            </div>
            <div className={classnames(styles.center, styles.button)}>
                <Button
                    disabled={!touched}
                    color={touched ? "bright" : "white"}
                    label={t("profile.labels.buttons.update")}
                    onClick={() =>
                        saveChanges({ fields, updateDj, setWasSubmit })
                    }
                />
            </div>
            {wasUpdated && (
                <AlertDialog onClose={setWasUpdated}>
                    {updatedResult}
                </AlertDialog>
            )}
        </div>
    );
}

async function saveChanges({ fields, updateDj, setWasSubmit }) {
    setWasSubmit(true);
    const errors = validateDjProfile(fields);
    if (Object.keys(errors).length === 0) updateDj(fields);
}

export default routerDecorator(DJInfo);
