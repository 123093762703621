import React, { useContext, useEffect, useState } from "react";
import t from "helpers/getText";
import routerDecorator from "components/helpers/withRouter";
import styles from "./Personal.module.scss";
import TextField from "../common/form/TextField";
import { Button } from "../common/ControlElems";
import classnames from "classnames";
import { AuthContext } from "context/AuthContext";
import validatePersonalProfile from "./validatePersonalProfile";
import { fieldProps } from "components/helpers/forms/formFieldHelper";
import AlertDialog from "../common/Dialogs/AlertDialog";
import UpdateAvatarDialog from "./Dialogs/UpdateAvatarDialog";
import parseServerValidationErrors from "sources/api/parseServerValidationErrors";
import { ImageWithPlaceholder } from "components/common/ImageWithPlaceholder";

function Personal() {
    const {
        user,
        updateProfile,
        updateProfileError,
        updateProfileResult
    } = useContext(AuthContext);
    const [fields, setFields] = useState({
        userId: user.id,
        fullName: user.fullName || "",
        email: user.email || "",
        phone: user.phone || "",
        country: user.country || "",
        street: user.street || "",
        region: user.region || "",
        zipCode: user.zipCode || "",
        city: user.city || "",
        facebookUrl: user.facebookUrl || "",
        twitterUrl: user.twitterUrl || "",
        instagramUrl: user.instagramUrl || "",
        snapchatUrl: user.snapchatUrl || "",
        comment: user.comment || "",
        fax: user.fax || "",
        website: user.website || "",
        cellpage: user.cellpage || ""
    });
    const [touched, setTouched] = useState(false);
    const [wasSubmit, setWasSubmit] = useState(false);
    const [wasUpdated, setWasUpdated] = useState(false);
    const [showUpdatePhotoDialog, setShowUpdatePhotoDialog] = useState(false);
    const [updatedResult, setUpdatedResult] = useState(null);
    const serverErrors = parseServerValidationErrors(updateProfileError);
    const clientErrors = wasSubmit ? validatePersonalProfile(fields) : {};
    const errors = Object.assign({}, clientErrors, serverErrors);

    useEffect(() => {
        if (wasSubmit) {
            if (updateProfileResult) {
                setUpdatedResult(t("profile.labels.subtitles.onUpdate"));
                setTouched(false);
            } else {
                setUpdatedResult(t("profile.labels.subtitles.onNotUpdate"));
            }
            setWasUpdated(true);
        }
    }, [updateProfileResult]);

    const dataForFields = { fields, user, setFields, errors, setTouched };

    return (
        <div className={styles.container}>
            <div className={styles.photoContainer}>
                <div className={styles.photo}>
                    <ImageWithPlaceholder src={user.avatar} />
                </div>

                <Button
                    className={styles.changePhotoButton}
                    size="small"
                    withoutBorder
                    withoutPadding
                    label="Update Photo"
                    onClick={() => setShowUpdatePhotoDialog(true)}
                />
            </div>

            <LeftRightFields
                data={{
                    ...dataForFields,
                    left: ["fullName", "phone", "fax"],
                    right: ["email", "website", "cellpage"],
                    header: t("profile.labels.subtitles.personalInformation"),
                    wide: "comment"
                }}
            />

            <LeftRightFields
                data={{
                    ...dataForFields,
                    left: ["country", "city"],
                    right: ["region", "zipCode"],
                    header: t("profile.labels.subtitles.addressInformation"),
                    wide: "street"
                }}
            />

            <LeftRightFields
                data={{
                    ...dataForFields,
                    left: ["facebookUrl", "instagramUrl"],
                    right: ["twitterUrl", "snapchatUrl"],
                    header: t("profile.labels.subtitles.socialMedia")
                }}
            />

            <div className={classnames(styles.center, styles.button)}>
                <Button
                    disabled={!touched}
                    color={touched ? "bright" : "white"}
                    label={t("profile.labels.buttons.update")}
                    onClick={() =>
                        saveChanges({
                            fields,
                            updateProfile,
                            setWasSubmit
                        })
                    }
                />
            </div>
            {showUpdatePhotoDialog && (
                <UpdateAvatarDialog
                    onClose={() => setShowUpdatePhotoDialog(false)}
                />
            )}
            {wasUpdated && (
                <AlertDialog onClose={setWasUpdated}>
                    {updatedResult}
                </AlertDialog>
            )}
        </div>
    );
}

function LeftRightFields({ data }) {
    const {
        left,
        right,
        wide,
        fields,
        setFields,
        errors,
        setTouched,
        user,
        header
    } = data;
    return (
        <>
            <p className={styles.subTitleText}>{header}</p>
            <div className={styles.left}>
                {left.map(key => {
                    return (
                        <TextField
                            label={t("profile.labels.fields." + key)}
                            value={user[key]}
                            // max="100"
                            {...fieldProps(
                                key,
                                key === "email" ? "email" : "string",
                                {
                                    fields,
                                    setFields,
                                    errors,
                                    setTouched
                                }
                            )}
                        />
                    );
                })}
            </div>
            <div className={styles.right}>
                {right.map(key => {
                    return (
                        <TextField
                            label={t("profile.labels.fields." + key)}
                            value={user[key]}
                            max="100"
                            {...fieldProps(
                                key,
                                key === "email" ? "email" : "string",
                                {
                                    fields,
                                    setFields,
                                    errors,
                                    setTouched
                                }
                            )}
                        />
                    );
                })}
            </div>
            {wide && (
                <div className={styles.wide}>
                    <TextField
                        label={t("profile.labels.fields." + wide)}
                        value={user[wide]}
                        max="512"
                        {...fieldProps(wide, "string", {
                            fields,
                            setFields,
                            errors,
                            setTouched
                        })}
                    />
                </div>
            )}
        </>
    );
}

async function saveChanges({ fields, updateProfile, setWasSubmit }) {
    setWasSubmit(true);
    const errors = validatePersonalProfile(fields);
    if (Object.keys(errors).length === 0) updateProfile(fields);
}

export default routerDecorator(Personal);
