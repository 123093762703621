import React, { memo, useState, useContext, useEffect } from "react";
import { BaseDialog } from "../../common/Dialogs";
import t from "helpers/getText";
import styles from "./UpdateAvatarDialog.module.scss";
import getImageBlob from "helpers/utils/getCropedImage";
import { AuthContext } from "context/AuthContext";
import CropImageArea from "../../common/CropImageArea/CropImageArea";

function UpdateAvatarDialog({ onClose }) {
    const { setAvatar } = useContext(AuthContext);
    const [fileToEdit, setFileToEdit] = useState(null);
    const [crop, setCrop] = useState(null);
    const [shouldUpdateAvatar, setShouldUpdateAvatar] = useState(false);

    useEffect(() => {
        if (shouldUpdateAvatar) {
            saveAvatar({ fileToEdit, crop, setAvatar, onClose });
        }
        setShouldUpdateAvatar(false);
    }, [shouldUpdateAvatar]);

    return (
        <BaseDialog
            onClose={onClose}
            height={fileToEdit ? "tallest" : "short"}
            width={fileToEdit ? "widest" : "narrow"}
        >
            <div className={styles.container}>
                <h2 className={styles.cancelHeader}>
                    {t("profile.labels.updateAvatar")}
                </h2>
                <CropImageArea
                    setFile={setFileToEdit}
                    setPixels={setCrop}
                    onFinished={setShouldUpdateAvatar}
                />
            </div>
        </BaseDialog>
    );
}

export default memo(UpdateAvatarDialog);

async function saveAvatar({ fileToEdit, crop, setAvatar, onClose }) {
    const croppedImage = await getImageBlob(fileToEdit, crop);
    setAvatar(croppedImage);
    croppedImage.name = "Filename.jpeg";
    onClose();
}
