import React from "react";
import PropTypes from "prop-types";

FB.propTypes = {
    className: PropTypes.string,
    color: PropTypes.string
};

FB.defaultProps = {
    className: "",
    color: "#57636F"
};

export default function FB(props) {
    const { color, className } = props;
    return (
        <svg className={className} width="21" height="20" viewBox="0 0 21 20">
            <path
                fill={color}
                fillRule="nonzero"
                d="M20.206.015L14.767 1.17A1.669 1.669 0 0 0 13.44 2.8v9.462c0 1.624-5.04.266-5.04 4.805C8.4 17.9 8.906 20 11.312 20c2.957 0 3.808-2.229 3.808-4.704V5.448l5.107-1.112A.978.978 0 0 0 21 3.383V.65a.655.655 0 0 0-.794-.636zM.84 1.667A.837.837 0 0 0 0 2.5c0 .46.376.834.84.834h10.08c.464 0 .84-.374.84-.834a.837.837 0 0 0-.84-.833H.84zm0 4.167a.837.837 0 0 0-.84.833c0 .46.376.834.84.834h10.08c.464 0 .84-.374.84-.834a.837.837 0 0 0-.84-.833H.84zm0 4.167a.837.837 0 0 0-.84.833c0 .46.376.834.84.834h10.08c.464 0 .84-.374.84-.834a.837.837 0 0 0-.84-.833H.84zm0 4.167A.837.837 0 0 0 0 15c0 .46.376.834.84.834h5.993a4.882 4.882 0 0 1 .619-1.667H.84z"
            />
        </svg>
    );
}
