export default function checkForAddressFields(fields, addError) {
    const { country, street, region, zipCode, city } = fields;

    if (!country.trim()) addError("country", "Please enter country");

    if (!street.trim()) addError("street", "Please enter street");

    if (!region.trim()) addError("region", "Please enter region");

    if (!zipCode.trim()) addError("zipCode", "Please enter zipCode");

    if (!city.trim()) addError("city", "Please enter city");
}
