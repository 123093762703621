import React from "react";
import { Profile } from "components/Profile";

export default function ProfilePage(props) {
    const {
        match: {
            params: { type }
        }
    } = props;
    return <Profile page={type} />;
}
