const contactUs = {
    contactUs: {
        header: "Contact us",
        subHeader:
            "The Franchise Record Pool would love to hear from you. Please fell free to contact us using any of the convenient options listed below. You are guaranteed we will get back to you in a timely and professional manner.\n",
        text: [
            {
                head: "Franchise Record Pool",
                text:
                    "Connecticut Office/Web Services P.O. Box 241 Norwalk, CT 06856"
            },
            {
                head: "General Info/Inquiry",
                text: "Phone: 888-377-4908\n info@frp.live"
            },
            { head: "Customer Service", text: "347-449-5129" },
            { head: "Fax", text: "(866) 430-0649" },
            { head: "Pool Director", text: "mrexcitment@frp.live" },
            { head: "Submitting Music", text: "music@frp.live" },
            {
                head: "Tech Support",
                text: "Phone : 888-377-4908 option 2\n techsupport@frp.live"
            },
            {
                head: "Billing",
                text: "Phone : 888-377-4908 option 4\n billing@frp.live"
            }
        ]
    }
};

export default contactUs;
