import React, { useState, useContext, useEffect } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";

import { ContentContext } from "context/ContentContext";

import routerDecorator from "components/helpers/withRouter";
import { Button } from "components/common/ControlElems";
import TracksList from "components/common/TracksList/TracksList";
import VideoList from "components/common/VideoList/VideoList";

import { FILTERS_INITIAL_STATE } from "helpers/constants";

import AlertDialog from "../common/Dialogs/AlertDialog";

import styles from "./HomeTracks.module.scss";

HomeTracks.propTypes = {
    location: PropTypes.object,
    className: PropTypes.string
};

function HomeTracks(props) {
    const { className } = props;
    const {
        requestTracksWithRetry,
        audio,
        video,
        audio: { count: countAudio },
        video: { count: countVideo },
        resetDisplayOfTrackVersions,
        isTrackLimited,
        trackLimitedMessage
    } = useContext(ContentContext);

    const [mediaType, setMediaType] = useState("audio");
    const [rateType, setRateType] = useState("createdAt");

    const [showTrackLimitedDialog, setShowTrackLimitedDialog] = useState(false);

    useEffect(() => {
        setTimeout(() => {
            requestTracksWithRetry({
                commonParams: {
                    ...FILTERS_INITIAL_STATE,
                    sortField: rateType,
                    type: mediaType
                },
                typeParams: { offset: 0 }
            });
        }, 40);
    }, [rateType, mediaType]);

    useEffect(() => {
        resetDisplayOfTrackVersions();
    }, []);

    useEffect(() => {
        if (isTrackLimited) {
            setShowTrackLimitedDialog(true);
        }
    }, [isTrackLimited]);

    return (
        <div className={classnames(styles.container, className)}>
            <div className={styles.navigation}>
                <div>
                    <div className={styles.buttonContainer}>
                        <Button
                            label="New Release"
                            underlined
                            size="large"
                            withoutPadding
                            isUpperCase={false}
                            active={rateType === "createdAt"}
                            onClick={() => setRateType("createdAt")}
                        />
                        <Button
                            label="Top tracks"
                            underlined
                            size="large"
                            withoutPadding
                            isUpperCase={false}
                            active={rateType === "downloads"}
                            onClick={() => setRateType("downloads")}
                        />
                    </div>
                </div>
                <div className={styles.buttonContainer}>
                    <Button
                        label="Audio"
                        underlined
                        size="normal"
                        withoutPadding
                        isUpperCase={false}
                        active={mediaType === "audio"}
                        onClick={() => setMediaType("audio")}
                    />
                    <Button
                        label="Video"
                        underlined
                        size="normal"
                        withoutPadding
                        isUpperCase={false}
                        active={mediaType === "video"}
                        onClick={() => setMediaType("video")}
                    />
                </div>
            </div>
            {mediaType === "audio" ? (
                <div className={styles.wide}>
                    <div className={styles.element}>
                        <TracksList
                            size="forHome"
                            list={audio.list.slice(0, 5)}
                            className={styles.tracks}
                        />
                    </div>
                    <div className={styles.element}>
                        <TracksList
                            hideHeaders
                            size="forHome"
                            indexShift={5}
                            list={
                                countAudio > 5
                                    ? audio.list.slice(5, 10)
                                    : audio.list
                            }
                            className={styles.tracks}
                        />
                    </div>
                </div>
            ) : (
                <div className={styles.wide}>
                    <div>
                        <VideoList short={true} list={video.list.slice(0, 4)} />
                    </div>
                    <div>
                        <VideoList
                            hideHeaders={true}
                            short={true}
                            list={
                                countVideo > 4
                                    ? video.list.slice(4, 8)
                                    : video.list
                            }
                        />
                    </div>
                </div>
            )}
            {showTrackLimitedDialog && (
                <AlertDialog onClose={setShowTrackLimitedDialog}>
                    {trackLimitedMessage}
                </AlertDialog>
            )}
        </div>
    );
}

export default routerDecorator(HomeTracks);
