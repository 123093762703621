import cardValidator from "card-validator";
import AbstractFieldsValidator from "components/helpers/validation/AbstractFieldsValidator";

export default function(fields, options) {
    return new CardNumberFieldsValidator(fields, options).validate();
}

class CardNumberFieldsValidator extends AbstractFieldsValidator {
    doCheckForSingleValue() {
        const {
            cardNumber,
            month,
            year,
            fname,
            lname,
            cvc,
            address,
            state,
            country,
            city,
            email,
            zipcode
        } = this.fields;

        const regularLetters = /^[0-9a-zA-Z]+$/;
        if (!cardNumber.trim()) {
            this.addError("cardNumber", "Card number is required");
        }

        if (cardNumber.trim() && !cardValidator.number(cardNumber).isValid) {
            this.addError("cardNumber", "Card number is invalid");
        }

        if (!fname.trim()) {
            this.addError("fname", "First Name is required");
        } else if (!regularLetters.test(fname.trim())) {
            this.addError("fname", "First Name is not valid");
        }

        if (!lname.trim()) {
            this.addError("lname", "Last Name is required");
        } else if (!regularLetters.test(lname.trim())) {
            this.addError("lname", "Last Name is not valid");
        }

        if (!address.trim()) {
            this.addError("address", "Address is required");
        }

        if (!state.trim()) {
            this.addError("state", "State is required");
        }

        if (!city.trim()) {
            this.addError("city", "City is required");
        }

        if (!zipcode.trim()) {
            this.addError("zipcode", "Zipcode is required");
        }

        if (!country.trim()) {
            this.addError("country", "Country is required");
        }

        if (!email.trim()) {
            this.addError("email", "Email is required");
        }

        if (!cardValidator.expirationDate({ month, year }).isValid) {
            this.addError("month", "Month is required");
            this.addError("year", "Year is required");
        }

        if (
            !cardValidator.cvv(cvc).isPotentiallyValid ||
            !cvc.trim() ||
            cvc.trim().length < 3
        ) {
            this.addError("cvc", "Please type valid cvc");
        }
    }

    doCheckForEmailAndPhone() {
        const { email, phone } = this.fields;
        const regularEmail = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        const regularPhone = /^(\+)?(\d[\(\)\-\s]?){4,12}\d$/;
        if (email.trim() && !regularEmail.test(email.trim()))
            this.addError("email", "Email is not valid");
        if (phone.trim() && !regularPhone.test(phone.trim()))
            this.addError("phone", "Phone is not valid");
    }
}
