import React, {
    useContext,
    useState,
    useEffect,
    useCallback,
    useRef
} from "react";
import WaveSurfer from "wavesurfer.js";
import classnames from "classnames";
import { Route } from "react-router-dom";
import RegionsPlugin from "wavesurfer.js/dist/plugin/wavesurfer.regions";
import {
    PlayButton,
    BaseSlider,
    IconButton
} from "components/common/ControlElems";
import { TracksListSuperShort } from "components/common/TracksList";
import { Mute, FullVolume, List, ClosePlayer } from "components/common/icons";
import { ContentContext } from "context/ContentContext";
import { getFormatedTime } from "./playerHelper";
import { AuthContext } from "../../context/AuthContext";
import { renderButtons } from "../common/TracksList/Helpers";
import styles from "./Player.module.scss";

const SKIP_TRACK_FIRST_PART_IN_SEC = 30;

function Player() {
    const {
        choosenTrack: { track, isPlaying },
        stopPlaying,
        previewTimeInSec,
        getTrackLink,
        choosenTrack
    } = useContext(ContentContext);
    const [playingVersion, setPlayingVersion] = useState([]);
    const maxTrackDurationLimit =
        SKIP_TRACK_FIRST_PART_IN_SEC + previewTimeInSec;

    const { user } = useContext(AuthContext);
    const { artist, name, versionId, versions, key, uiId } = track || {
        versions: []
    };
    useEffect(()=>{
        if(track){
            let playingV = {
                ...track, // Create a shallow copy of the track object
                versions: track.versions.filter((v) => v.id === versionId)
              };
            setPlayingVersion(playingV);
            // console.log('playing version', {playingVersion, playingV});
        }
    },[versionId,track,choosenTrack]);
    
    const [duration, setDuration] = useState(0);
    const [prevTrack, setPrevTrack] = useState(track);
    const [visualTimePosition, setVisualTimePosition] = useState(0);
    const [chooseVersionVisible, setChooseVersionVisible] = useState(false);
    const [volume, setVolume] = useState(1);
    const [trackLink, setTrackLink] = useState(null);
    const [isWaveSurferReady, setWaveSurferReady] = useState(false);

    const versionsRef = useRef(null);
    const chooseVersionButtonRef = useRef(null);
    const wavePlayerContainerRef = useRef(null);
    const durationTimer = useRef(null);

    const waveSurfer = useRef(null);

    const { version, trackName } = versions.reduce(
        (result, elem) => {
            if (elem.id === versionId) {
                return { version: elem.name, trackName: elem.nameToDisplay };
            }
            return result;
        },
        { version: "Main", trackName: name }
    );

    useEffect(() => {
        document.onclick = e => {
            const { current } = versionsRef;
            const { current: choosenCurrent } = chooseVersionButtonRef;
            if (
                (current && current.contains(e.target)) === false &&
                (choosenCurrent && choosenCurrent.contains(e.target)) === false
            ) {
                setChooseVersionVisible(false);
            }
        };
    }, [chooseVersionVisible]);

    const stopWaveSurferPlayer = useCallback(() => {
        setWaveSurferReady(false);

        waveSurfer.current.stop();
        waveSurfer.current.destroy();
        waveSurfer.current = null;

        clearInterval(durationTimer.current);
    }, []);

    useEffect(() => {
        if (
            (!prevTrack ||
            prevTrack.key !== key ||
            prevTrack.versionId !== versionId ||
            prevTrack.uiId !== uiId) && track
        ) {
            setPrevTrack(track);

            const linkGettingPromise = new Promise(async (res, rej) => {
                try {
                    const url = await getTrackLink(versionId);
                    res(url);
                } catch (e) {
                    rej(e);
                }
            });

            linkGettingPromise
                .then((res) => {
                    setTrackLink(res);
                })
                .catch((e) => {
                    console.log('Track link getting', { error: e });
                });
        }
    }, [track]);

    useEffect(() => {
        if (waveSurfer.current) {
            if (isPlaying) {
                waveSurfer.current.play();
            } else {
                waveSurfer.current.pause();
            }
        }
    }, [isPlaying, waveSurfer.current]);

    useEffect(() => {
        let timer;
        if (waveSurfer.current) {
            timer = setInterval(() => {
                setVisualTimePosition(waveSurfer.getCurrentTime());
            }, 300);
        }
        return () => clearInterval(timer);
    }, []);

    useEffect(() => {
        if (waveSurfer.current) {
            waveSurfer.current.setVolume(volume);
        }
    }, [volume]);

    const currentTimePosition = Math.floor(visualTimePosition);

    useEffect(() => {
        if (
            currentTimePosition < SKIP_TRACK_FIRST_PART_IN_SEC &&
            waveSurfer.current
        ) {
            waveSurfer.current.skipForward(
                SKIP_TRACK_FIRST_PART_IN_SEC - currentTimePosition
            );
        }

        if (currentTimePosition > maxTrackDurationLimit && waveSurfer.current) {
            stopWaveSurferPlayer();
            stopPlaying();
        }
    }, [currentTimePosition]);

    const durationInPreviewMode =
        duration < maxTrackDurationLimit ? duration : maxTrackDurationLimit;

    useEffect(() => {
        if (wavePlayerContainerRef.current && trackLink) {
            if (waveSurfer.current) {
                stopWaveSurferPlayer();
            }

            waveSurfer.current = WaveSurfer.create({
                container: wavePlayerContainerRef.current,
                height: 25,
                waveColor: "#fff",
                progressColor: "#fff",
                cursorColor: "rgb(187, 29, 126)",
                cursorWidth: 2,
                barWidth: 1,
                barRadius: 1,
                plugins: [RegionsPlugin.create({})]
            });

            waveSurfer.current.load(trackLink);

            waveSurfer.current.on("ready", () => {
                const trackDuration = waveSurfer.current.getDuration();

                setWaveSurferReady(true);
                setDuration(trackDuration);
                waveSurfer.current.setVolume(volume);

                waveSurfer.current.skipForward(SKIP_TRACK_FIRST_PART_IN_SEC);
                waveSurfer.current.setPlayEnd(maxTrackDurationLimit);
                waveSurfer.current.addRegion({
                    id: "disabled-region",
                    start: 0,
                    end: SKIP_TRACK_FIRST_PART_IN_SEC,
                    loop: false,
                    drag: false,
                    resize: false,
                    showTooltip: false,
                    color: "#213044be"
                });
                waveSurfer.current.addRegion({
                    id: "disabled-region",
                    start: maxTrackDurationLimit + 1,
                    end: trackDuration,
                    loop: false,
                    resize: false,
                    drag: false,
                    showTooltip: false,
                    color: "#213044be"
                });

                const disableRegions = document.querySelectorAll(
                    '[data-id="disabled-region"]'
                );

                disableRegions.forEach(el => {
                    el.onclick = e => {
                        e.stopImmediatePropagation();
                    };
                });

                waveSurfer.current.play(SKIP_TRACK_FIRST_PART_IN_SEC);
            });

            waveSurfer.current.on("play", () => {
                durationTimer.current = setInterval(() => {
                    if (waveSurfer.current) {
                        setVisualTimePosition(
                            waveSurfer.current.getCurrentTime()
                        );
                    }
                }, 300);
            });

            waveSurfer.current.on("pause", () => {
                clearInterval(durationTimer.current);
            });

            waveSurfer.current.on("error", e => {
                console.log("Player error", { e });
            });

            waveSurfer.current.on("loading", () => {
                console.log("loading");
            });
        }
    }, [trackLink]);

    useEffect(
        () => () => {
            clearInterval(durationTimer.current);
        },
        []
    );
    const isPlayer = true;
    return [
        <div
            key="1"
            className={classnames(styles.container, track && styles.show)}
        >
            <div key="mainPlayer" className={styles.content}>
                <PlayButton
                    withSpinner={(!duration && isPlaying) || !isWaveSurferReady}
                    rootClassName={styles.playButton}
                    track={track}
                    isPLayer={true}
                />
                <div className={styles.trackInfo}>
                    <span title={name}>{trackName}</span>
                    <span title={artist}>{artist}</span>
                </div>
                <div className={styles.timeline}>
                    <div>{getFormatedTime(visualTimePosition)}</div>
                    <div
                        ref={wavePlayerContainerRef}
                        className={styles.wavePlayerContainer}
                    ></div>
                    <div>{getFormatedTime(durationInPreviewMode)}</div>
                    <div className={styles.fullTrackTime}>
                        ({getFormatedTime(duration)})
                    </div>
                </div>
                {/* <div className={styles.previewIcon} /> */}
                {playingVersion && playingVersion.versions && renderButtons({
                    className: styles.buttons,
                    size: 'longVersion',
                    track: playingVersion,
                    index: 0,
                    isPlayer: isPlayer
                })}
                <Route
                    render={({ history }) => (
                        <div
                            ref={chooseVersionButtonRef}
                            className={classnames(
                                styles.version,
                                chooseVersionVisible && styles.active
                            )}
                            onClick={() => {
                                if (user) {
                                    setChooseVersionVisible(
                                        !chooseVersionVisible
                                    );
                                } else {
                                    history.push("/register");
                                    stopWaveSurferPlayer();
                                    stopPlaying();
                                }
                            }}
                        >
                            {version}
                            <IconButton>
                                <List
                                    isActive={chooseVersionVisible}
                                    color="var(--theme-muffled-color)"
                                />
                            </IconButton>
                        </div>
                    )}
                />
                <div className={styles.rating} />
                <div className={styles.download} />
                <div className={styles.add} />
                <div className={styles.volume}>
                    <IconButton onClick={() => setVolume(0)}>
                        <Mute color="var(--theme-muffled-color)" />
                    </IconButton>
                    <BaseSlider
                        onChange={setVolume}
                        min={0}
                        max={1}
                        step={0.01}
                        value={volume}
                    />
                    <IconButton onClick={() => setVolume(1)}>
                        <FullVolume color="var(--theme-muffled-color)" />
                    </IconButton>
                </div>
                <div className={styles.closeButton}>
                    <IconButton
                        onClick={() => {
                            stopPlaying();
                            setChooseVersionVisible(false);
                        }}
                    >
                        <ClosePlayer color="var(--theme-muffled-color)" />
                    </IconButton>
                </div>
            </div>
        </div>,
        <div
            ref={versionsRef}
            key="chooseVersion"
            className={classnames(
                styles.container,
                styles.chooseVersionContainer,
                chooseVersionVisible && styles.active
            )}
        >
            {track && <TracksListSuperShort size="superShort" track={track} />}
        </div>
    ];
}

export default React.memo(Player);
